/** @format */

import { convertISOStringToDateNew } from "@roadflex/utils";
import { add, addMilliseconds } from "date-fns";

export function webLib(): string {
  return "web-lib";
}

export const calculateDueDate = (latestCycleEndDt: Date, tz = "") => {
  const dueDate = addMilliseconds(
    add(new Date(latestCycleEndDt), { days: 7 }),
    1,
  );
  return convertISOStringToDateNew(
    dueDate.toISOString(),
    false,
    false,
    true,
    tz,
  );
};

export const valueIsNullOrUndefined = (val: any) =>
  val === null || val === undefined;
