/** @format */

import { useLinkEldToVehicle } from "@roadflex/react-hooks";
import { FleetExtended, GeoTabGetDevicesValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { Dialog } from "primereact/dialog";
import { useEffect } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";
import { Toast } from "../../toast-message/toast";

export interface EditGeoTabDeviceModalProps
  extends FormikProps<GeoTabGetDevicesValues> {
  openEditGeoTabDeviceModal: boolean;
  setOpenEditGeoTabDeviceModal: (value: boolean) => void;
  editGeoTabDeviceValues: {
    geoTabDeviceId: string;
    geoTabDeviceName: string;
    vehicleId: string;
  };
  vehiclesList: FleetExtended[];
}

export default function EditGeoTabDeviceModal({
  openEditGeoTabDeviceModal,
  setOpenEditGeoTabDeviceModal,
  editGeoTabDeviceValues,
  vehiclesList,
  handleSubmit,
}: EditGeoTabDeviceModalProps) {
  const vehiclesListLabels = vehiclesList.map((item) => {
    return {
      label: item.fleetName,
      value: item.id,
    };
  });
  const {
    linkEldToVehicleValues,
    linkEldToVehicleHandleChange,
    linkEldToVehicleHandleSubmit,
    setFieldValue: linkEldToVehicleSetFieldValues,
    linkEldToVehicleIsSubmitting,
  } = useLinkEldToVehicle({
    initialValues: {
      fleetId: "",
      eldDeviceDetailsId: "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      Toast({ type: "success", message: "Vehicle mapped successfully" });
      handleSubmit();
      setOpenEditGeoTabDeviceModal(false);
    },
  });

  useEffect(() => {
    if (openEditGeoTabDeviceModal) {
      linkEldToVehicleSetFieldValues(
        "eldDeviceDetailsId",
        editGeoTabDeviceValues.geoTabDeviceId,
      );
      linkEldToVehicleSetFieldValues(
        "fleetId",
        editGeoTabDeviceValues.vehicleId,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openEditGeoTabDeviceModal]);

  const renderFooter = () => {
    return (
      <div className="flex justify-end">
        <Button
          variant={ButtonVariant.Transparent}
          size={ButtonSize.AppSize}
          onClick={() => setOpenEditGeoTabDeviceModal(false)}
        >
          Close
        </Button>
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.AppSize}
          onClick={() => {
            linkEldToVehicleHandleSubmit();
          }}
          disabled={linkEldToVehicleIsSubmitting}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      visible={openEditGeoTabDeviceModal}
      className="w-[95%] sm:w-[50vw]"
      footer={renderFooter()}
      onHide={() => setOpenEditGeoTabDeviceModal(false)}
      closable={false}
    >
      <div className="sm:rounded-md">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 sm:col-span-12">
            <div className="w-full">
              <label
                htmlFor="mapVehicleToEld"
                className="block text-sm font-medium text-left text-blue-gray-900"
              >
                Map Vehicle
              </label>
              <Select
                disabled={linkEldToVehicleIsSubmitting}
                value={linkEldToVehicleValues.fleetId}
                onChange={(e) =>
                  linkEldToVehicleSetFieldValues("fleetId", e.value)
                }
                name="fleetId"
                options={vehiclesListLabels}
              ></Select>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
