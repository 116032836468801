/** @format */
import { usePlatformDetect } from "@roadflex/react-hooks";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Skeleton } from "primereact/skeleton";

/* eslint-disable-next-line */
type WeeklySpendGraphProps = {
  weeklySpendsData: Array<[number, number]>;
  dashboardLoading: boolean;
};

const NoDataToShowMessage = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
      <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
        No Data to Show
      </div>
    </div>
  );
};

export function WeeklySpendGraph({
  weeklySpendsData,
  dashboardLoading,
}: WeeklySpendGraphProps) {
  const { isMobile } = usePlatformDetect();
  const highestValue = Math.max(...weeklySpendsData.map((item) => item[1]));
  const graphOfWeeklySpendsOptions: Highcharts.Options = {
    responsive: {
      rules: [
        {
          chartOptions: {
            title: {
              style: {
                textAlign: "left",
                color: "#893f2b",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "600",
              },
            },
          },
          condition: {
            callback: () => {
              return isMobile();
            },
          },
        },
      ],
    },
    subtitle: {
      text: "Click and drag in the plot area to zoom in",
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
      enabled: true,
    },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Weekly Spend (Past 8 weeks)",
      style: {
        textAlign: "left",
        color: "#893f2b",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "600",
      },
      align: "left",
    },
    chart: {
      zoomType: "x",
      type: "column",
      borderRadius: 10,
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify",
      },
      tickInterval: 7 * 24 * 60 * 60 * 1000,
    },
    yAxis: [
      {
        title: {
          text: "Total Amount Spend($)",
        },
      },
      {
        title: {
          text: "Total Amount Spend($)",
        },
        linkedTo: 0,
        opposite: true,
      },
    ],
    colors: ["#893f2b"],
    tooltip: {
      formatter: function () {
        const dateStr = Highcharts.dateFormat(
          "%A, %e. %b %Y",
          this.x as number,
        );
        return `Total Amount Spend: <b>$${this.y}</b><br/>Date: <b>${dateStr}</b>`;
      },
      shared: true,
    },
    series: [
      {
        name: "Total Amount Spend",
        data: weeklySpendsData.map((item) => ({
          x: item[0],
          y: item[1],
          color: item[1] === highestValue ? "#ff5100" : "#893f2b",
        })),
        type: "column",
      },
    ],
  };

  return (
    <div className="relative p-5 overflow-hidden bg-white border border-white rounded-md shadow sm:pt-6 sm:px-6">
      {dashboardLoading ? (
        <>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
          <Skeleton width="100%" height="5rem" className="mb-2"></Skeleton>
        </>
      ) : (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOfWeeklySpendsOptions}
          />
          {weeklySpendsData.length > 0 ? null : <NoDataToShowMessage />}
        </>
      )}
    </div>
  );
}

export default WeeklySpendGraph;
