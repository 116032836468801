/** @format */

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ENUM_CUSTOMER_TYPE, ENUM_FLEET_DRIVER_STATUS } from "@prisma/client";
import { ChildFeatureType, DriverType, TabsType } from "@roadflex/types";
import { phoneFormatter } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { FormEvent, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { CheckboxInput, TextInput } from "../../../inputs";
import { FuelCardComponentPermissionGuard } from "../../../permission-guard";

interface DriversEditUIProps extends FormikProps<DriverType> {
  tabs: TabsType[];
  customerType: ENUM_CUSTOMER_TYPE;
  driverId?: string;
  inviteDriverToAppHandleSubmit?: (
    e?: FormEvent<HTMLFormElement> | undefined,
  ) => void;
  inviteDriverToAppIsSubmitting?: boolean;
  timer?: number;
  update: boolean;
  setUpdate: (value: boolean) => void;
  childFeatures: ChildFeatureType[];
}

export const DriversUI = ({
  tabs,
  driverId,
  handleChange,
  inviteDriverToAppHandleSubmit,
  inviteDriverToAppIsSubmitting,
  timer,
  isSubmitting,
  values,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  resetForm,
  setFieldValue,
  customerType,
  update,
  setUpdate,
  childFeatures,
}: DriversEditUIProps) => {
  const router = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const backToDriversVehiclesPanel = () => {
    router.push("/drivers");
  };

  const access = {
    DriverAppAccess:
      childFeatures?.find(
        (item) => item.name === "DRIVER_PAGE_DETAILS_INVITE_DRIVER_APP",
      ) !== undefined,
  };
  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToDriversVehiclesPanel}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg">
                {driverId ? `Driver - ${values.name}` : "Add New Driver"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-5 md:mt-0">
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
            <div className="bg-white rounded-md shadow">
              <form
                className="flex flex-col h-full p-8 overflow-y-auto"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col flex-grow h-full space-y-4">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-12 sm:col-span-12">
                      <TextInput
                        label={"Driver Name"}
                        required
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="name"
                        disabled={driverId !== undefined && !update}
                        id="name"
                        touched={touched.name}
                        error={touched.name && Boolean(errors.name)}
                        errorMessage={errors.name}
                      ></TextInput>
                    </div>

                    <div className="relative col-span-12 sm:col-span-12">
                      <TextInput
                        label={"Email"}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="email"
                        disabled={driverId !== undefined && !update}
                        id="email"
                        touched={touched.email}
                        error={touched.email && Boolean(errors.email)}
                        errorMessage={errors.email}
                      ></TextInput>
                    </div>

                    <div className="relative col-span-12 sm:col-span-12">
                      <TextInput
                        label={"Phone Number"}
                        type="tel"
                        required={values.inviteDriver}
                        value={values.phoneNumber}
                        onChange={(event) => {
                          setFieldValue(
                            "phoneNumber",
                            phoneFormatter(event?.target?.value),
                          );
                        }}
                        onBlur={handleBlur}
                        name="phoneNumber"
                        disabled={
                          driverId !== undefined &&
                          (!update ||
                            (update &&
                              values?.status !==
                                ENUM_FLEET_DRIVER_STATUS.NOT_INVITED))
                        }
                        id="phoneNumber"
                        touched={touched.phoneNumber}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        errorMessage={errors.phoneNumber}
                      ></TextInput>
                    </div>

                    <div className="relative col-span-12 sm:col-span-12">
                      <TextInput
                        label={"Notes"}
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="notes"
                        disabled={driverId !== undefined && !update}
                        id="notes"
                        touched={touched.notes}
                        error={touched.notes && Boolean(errors.notes)}
                        errorMessage={errors.notes}
                      ></TextInput>
                    </div>
                  </div>
                </div>
                {values.status !== ENUM_FLEET_DRIVER_STATUS.DELETED ? (
                  <div className="flex flex-row justify-end">
                    {driverId ? (
                      <>
                        {!update && (
                          <div className="flex flex-row justify-end w-full mt-8">
                            <Button
                              variant={ButtonVariant.AppOrange}
                              size={ButtonSize.AppSize}
                              className=""
                              onClick={() => {
                                setUpdate(true);
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        )}
                        {update && (
                          <div className="flex flex-row justify-end w-full mt-8">
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.AppSize}
                              className=""
                              onClick={() => {
                                setUpdate(false);
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant={ButtonVariant.Primary}
                              size={ButtonSize.AppSize}
                              className="ml-4"
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              type={ButtonType.Submit}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <Button
                        variant={ButtonVariant.AppOrange}
                        size={ButtonSize.AppSize}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type={ButtonType.Submit}
                        className="flex flex-row items-center w-full mt-8 sm:w-auto"
                      >
                        Add New Driver
                      </Button>
                    )}
                  </div>
                ) : null}
              </form>
              <div className="px-8 pb-8 overflow-x-auto ">
                {driverId &&
                inviteDriverToAppHandleSubmit &&
                timer !== undefined &&
                inviteDriverToAppIsSubmitting !== undefined ? (
                  <div className="flex flex-row justify-between gap-4">
                    <div className="flex flex-col">
                      <div className="">
                        Driver App{" "}
                        <span className="font-normal text-yellow-600">
                          (Premium)
                        </span>
                        <div className="italic">
                          Drivers can use the RoadFlex Driver App to claim fuel
                          discounts at select gas stations and truck stops
                        </div>
                      </div>
                    </div>
                    <div className="align-middle">
                      {values.status !== ENUM_FLEET_DRIVER_STATUS.DELETED ? (
                        <>
                          {values.status !== "ACCOUNT_CREATED" && (
                            <FuelCardComponentPermissionGuard
                              type="button"
                              featurePermission={access.DriverAppAccess}
                              messageType="premium"
                            >
                              <Button
                                variant={ButtonVariant.AppOrange}
                                size={ButtonSize.Small}
                                onClick={() => inviteDriverToAppHandleSubmit()}
                                loading={inviteDriverToAppIsSubmitting}
                                disabled={timer > 0}
                                className="flex flex-row items-center w-full sm:w-auto"
                              >
                                {!values.status ||
                                values.status ===
                                  ENUM_FLEET_DRIVER_STATUS.NOT_INVITED
                                  ? "Send Invite"
                                  : "Resend Invite"}
                              </Button>
                            </FuelCardComponentPermissionGuard>
                          )}
                          {timer > 0 && <div className="ml-2">{timer}s</div>}
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="relative flex col-span-12 sm:col-span-12">
                    <div className="relative flex items-center h-5">
                      <FuelCardComponentPermissionGuard
                        type="checkbox"
                        featurePermission={access.DriverAppAccess}
                        messageType="premium"
                      >
                        <CheckboxInput
                          id={"inviteDriver"}
                          name={"inviteDriver"}
                          type="checkbox"
                          checked={values.inviteDriver}
                          onChange={() => {
                            setFieldValue("inviteDriver", !values.inviteDriver);
                          }}
                          label={
                            "Invite Driver to RoadFlex App (Phone Number required)"
                          }
                          labelClass="font-medium text-gray-700"
                        ></CheckboxInput>
                      </FuelCardComponentPermissionGuard>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
