/** @format */
import { useQuery } from "@apollo/client";
import {
  READ_CURRENT_USER_AUTH,
  READ_CURRENT_USER_BASIC,
} from "@roadflex/graphql";
import { UserAuthType, UserBasicType } from "@roadflex/types";

export function useReadCurrentUserAuth() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_AUTH, {
    // fetchPolicy: "no-cache",
  });
  const {
    readCurrentUserAuth = null,
  }: { readCurrentUserAuth: UserAuthType | null } = data || {};
  return { readCurrentUserAuth, refetch, ...rest };
}

export function useReadCurrentUserBasicInformation() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_BASIC, {
    // fetchPolicy: "no-cache",
  });
  const { readCurrentUserBasic }: { readCurrentUserBasic: UserBasicType } =
    data || {};

  return { readCurrentUserBasic, refetch, ...rest };
}
