/** @format */

import { useReadAccountDocuments } from "@roadflex/react-hooks";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { UserAuthType } from "@roadflex/types";
import { Loader } from "../../loader";
import { DocumentsUI } from "./account-documents.ui";

interface DocumentsProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  refetch: () => void;
}
export const AccountDocuments = ({
  readCurrentUserAuth,
  loading,
  refetch,
}: DocumentsProps) => {
  const {
    data,
    loading: accountDocumentsLoading,
    refetch: accountDocumentsRefetch,
    ...rest
  } = useReadAccountDocuments({ email: undefined, showAll: false });
  const { isMobile } = usePlatformDetect();

  if (loading) {
    return <Loader />;
  }
  return (
    <DocumentsUI
      {...{
        accountDocuments: data?.readAccountDocuments?.accountDocuments || [],
        readCurrentUserAuth,
        isMobile,
      }}
    />
  );
};
