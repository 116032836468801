/** @format */

import { ApolloProvider } from "@apollo/client";
import {
  ErrorBoundaries,
  FuelCardLayout,
  HeadSeo,
} from "@roadflex/react-components";
import { useReadCurrentUserAuth } from "@roadflex/react-hooks";
import { deleteCookie } from "@roadflex/web-lib";
import NextProgress from "next-progress";
import type { AppProps } from "next/app";
import getConfig from "next/config";
import { useRouter } from "next/router";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { useApollo } from "../apollo/client";
import CurrentUserContext from "../contexts/currentUser";
import "./styles.css";
import "./stylesPrimeReact.css";
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

function MyAppWithCurrentUser({ children }: { children: JSX.Element }) {
  const { readCurrentUserAuth, loading, refetch } = useReadCurrentUserAuth();

  const router = useRouter();
  const handleSignOut = () => {
    deleteCookie("token");
    router.push({
      pathname: `${NEXT_PUBLIC_WWW_URL}/signin`,
    });
  };
  if (readCurrentUserAuth?.fuelCard?.status === "COMPLETED") {
    handleSignOut();
  }

  return (
    <CurrentUserContext.Provider
      value={{ ...readCurrentUserAuth, isLoading: loading, refetch }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

function CustomApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ApolloProvider client={apolloClient}>
      <HeadSeo />

      <NextProgress
        color="#ed4e34"
        height={5}
        delay={0}
        options={{ minimum: 0.3, trickleSpeed: 100 }}
      />

      <ToastContainer />
      <MyAppWithCurrentUser>
        <FuelCardLayout>
          <ErrorBoundaries>
            <Component {...pageProps} />
          </ErrorBoundaries>
        </FuelCardLayout>
      </MyAppWithCurrentUser>
    </ApolloProvider>
  );
}

export default CustomApp;
