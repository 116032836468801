import Axios from "axios";
import getConfig from "next/config";
import { RefObject } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

export const verifyGoogleReCaptchaToken = async (token: string) => {
  try {
    const response = await Axios.post(
      `${NEXT_PUBLIC_API_URL}/api/verifyGoogleReCaptchaToken`,
      {
        reCAPTCHA_TOKEN: token,
      },
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const handleGoogleReCaptchaSubmit = async (
  event: React.FormEvent,
  captchaRef: RefObject<ReCAPTCHA>,
  handleSubmit: () => void,
) => {
  event.preventDefault();
  // const token = captchaRef.current?.getValue();
  const token = await captchaRef.current?.executeAsync(); // Use this for invisible recaptcha
  captchaRef.current?.reset();
  if (token) {
    const validToken = await verifyGoogleReCaptchaToken(token);
    // console.log("validToken => ", validToken);
    if (validToken?.success && validToken?.verificationInfo?.success) {
      handleSubmit();
    }
  }
};
