/** @format */
import { UserOnboardType } from "@roadflex/types";
import AdditionalBusinessInfo from "./additional-business-information";
import BankVerification from "./bank-verification";
import BeneficialOwners from "./beneficial-owners";
import BusinessInformation from "./business-information";
import EmailVerification from "./email-verification";
import HomeAddress from "./home-address";
import PersonalInformation from "./personal-information";
import Review from "./review-stage";
import SetPassword from "./set-password";
import TermsOfService from "./terms-of-service";
export type FuelcardOnboardUIProps = {
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  onBoardStatus: number;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
  onJumpToStep: (step: number) => void;
  onSubmitApplication: () => void;
  isSubmittingApplication: boolean;
};

const FuelcardOnboardUI = ({
  readCurrentUserOnboard,
  nextStep,
  onBoardStatus,
  isSubmittingOnboardStatus,
  onSubmitApplication,
  onPreviousStep,
  onJumpToStep,
  isSubmittingApplication,
}: FuelcardOnboardUIProps) => {
  const list = [
    {
      title: "Email Verification",
      component: EmailVerification,
    },
    {
      title: "Set Password",
      component: SetPassword,
    },
    {
      title: "Business Info",
      component: BusinessInformation,
    },
    {
      title: "Additional Business Info",
      component: AdditionalBusinessInfo,
    },
    {
      title: "Personal Info",
      component: PersonalInformation,
    },
    {
      title: "Addi. Personal Info",
      component: HomeAddress,
    },
    {
      title: "Beneficial Owners",
      component: BeneficialOwners,
    },
    {
      title: "Your Finances",
      component: BankVerification,
    },
    {
      title: "Review",
      component: Review,
    },
    {
      title: "Terms of Service",
      component: TermsOfService,
    },
  ];
  return (
    <div className="w-full">
      {list.map(({ title, component: Component }, idx) => {
        if (onBoardStatus === idx) {
          return (
            <div key={idx} className="w-full">
              <div>
                <Component
                  {...{
                    title,
                    nextStep,
                    readCurrentUserOnboard,
                    onJumpToStep,
                    onSubmitApplication,
                    isSubmittingOnboardStatus,
                    isSubmittingApplication,
                    onPreviousStep,
                  }}
                />
              </div>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default FuelcardOnboardUI;
