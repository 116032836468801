import { DriversFilterOptions, VehiclesFilterOptions } from "@roadflex/types";
import { convertISOStringToDateNew } from "@roadflex/utils";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";

export const driverItemTemplate = (option: DriversFilterOptions) => {
  return (
    <div className="whitespace-nowrap">
      <span className="image-text">{option.driverName}</span>
    </div>
  );
};

export const driverFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
  driversFilterOptions: DriversFilterOptions[],
) => {
  return (
    <MultiSelect
      value={options.value}
      options={driversFilterOptions}
      onChange={(e) => {
        options.filterCallback(e.value);
      }}
      style={{
        fontFamily: "Inter",
        fontSize: "0.75rem",
      }}
      placeholder="Any"
      className="custom-multiselect-filter"
      panelClassName="custom-multiselect-filter-panel"
      optionLabel="driverName"
      optionValue="driverName"
      maxSelectedLabels={1}
      itemTemplate={driverItemTemplate}
    />
  );
};

export const vehicleItemTemplate = (option: VehiclesFilterOptions) => {
  return (
    <div className="whitespace-nowrap">
      <span className="image-text">{option.vehicleName}</span>
    </div>
  );
};

export const vehicleFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
  vehiclesFilterOptions: VehiclesFilterOptions[],
) => {
  return (
    <MultiSelect
      value={options.value}
      options={vehiclesFilterOptions}
      onChange={(e) => {
        options.filterCallback(e.value);
      }}
      style={{
        fontFamily: "Inter",
        fontSize: "0.75rem",
      }}
      placeholder="Any"
      className="custom-multiselect-filter"
      panelClassName="custom-multiselect-filter-panel"
      optionLabel="vehicleName"
      optionValue="vehicleName"
      maxSelectedLabels={1}
      itemTemplate={vehicleItemTemplate}
    />
  );
};

export const dateTemplate = (date: string, timeZone: string) => {
  let tz = "";
  if (timeZone === "EST") {
    tz = "America/New_York";
  } else if (timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (timeZone === "UTC") {
    tz = "UTC";
  } else if (timeZone === "CT") {
    tz = "America/Chicago";
  }
  return (
    <span className="whitespace-nowrap">
      <div>
        {date ? (
          <>
            {" "}
            <div>
              {
                convertISOStringToDateNew(
                  date.toString(),
                  false,
                  false,
                  true,
                  tz,
                ).split(",")[0]
              }
            </div>
            <div className="text-sm">
              {
                convertISOStringToDateNew(
                  date.toString(),
                  false,
                  false,
                  true,
                  tz,
                ).split(",")[1]
              }
            </div>
          </>
        ) : (
          "-"
        )}
      </div>
    </span>
  );
};

export const dateOnlyTemplate = (date: string, timeZone: string) => {
  let tz = "";
  if (timeZone === "EST") {
    tz = "America/New_York";
  } else if (timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (timeZone === "UTC") {
    tz = "UTC";
  } else if (timeZone === "CT") {
    tz = "America/Chicago";
  }
  return (
    <span className="whitespace-nowrap">
      <div>
        {date ? (
          <>
            {" "}
            <div>
              {
                convertISOStringToDateNew(
                  date.toString(),
                  false,
                  false,
                  true,
                  tz,
                ).split(",")[0]
              }
            </div>
          </>
        ) : (
          "-"
        )}
      </div>
    </span>
  );
};
