/** @format */

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { split } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { getCookie } from "@roadflex/web-lib";
import merge from "deepmerge";
import getConfig from "next/config";
import { useMemo } from "react";

const { publicRuntimeConfig } = getConfig();

const { APP_ENV, NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

let apolloClient: ApolloClient<unknown>;

const wsLink =
  typeof window === "undefined"
    ? null
    : new WebSocketLink({
        // if you instantiate in the server, the error will be thrown
        uri: `${
          APP_ENV === "local"
            ? NEXT_PUBLIC_API_URL.replace("http", "ws")
            : NEXT_PUBLIC_API_URL.replace("https", "wss")
        }/graphql`,
        options: {
          reconnect: true,
          timeout: 2000,
          lazy: true,
          connectionParams: {
            authToken: getCookie("impersonatorToken") || getCookie("token"),
          },
        },
        // webSocketImpl: ws,
      });

const httpLink = createHttpLink({
  uri: `${NEXT_PUBLIC_API_URL}/graphql`,
  // credentials: "included",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookie("token");
  const impersonatorToken = getCookie("impersonatorToken");
  const deviceToken = getCookie("deviceToken");

  let finalAuthtorization = "";
  if (impersonatorToken) {
    finalAuthtorization = `ImpersonatorBearer ${impersonatorToken}`;
  } else if (token) {
    finalAuthtorization = `Bearer ${token}`;
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: finalAuthtorization,
      ...(deviceToken ? { [`Device-Token`]: getCookie("deviceToken") } : {}),
    },
  };
});

const link =
  typeof window === "undefined"
    ? httpLink
    : split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authLink.concat(httpLink),
      );

export function createApolloClient() {
  return new ApolloClient({
    // ssrMode: typeof window === "undefined",
    ssrMode: true,
    link,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function useApollo(initialState: null | undefined) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);

  return store;
}
