/** @format */
import { ENUM_ADDRESS_TYPE, ENUM_DELIVERY_TYPE } from "@prisma/client";
import {
  useAdditionaBusinessInfoUpdate,
  useReadAdditionalBusinessInformation,
} from "@roadflex/react-hooks";
import { AdditionalBusinessInfoType } from "@roadflex/types";
import { fuelFormatter } from "@roadflex/web-lib";
import getConfig from "next/config";
import { useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Toast } from "../toast-message/toast";
import AdditionalBusinessInfoUI from "./additional-business-information.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;
interface AdditionalBusinessInfoProps {
  nextStep: (jumpStepBy?: number) => void;
  onPreviousStep: () => void;
  additionalBusinessInfo: AdditionalBusinessInfoType;
  isSubmittingOnboardStatus: boolean;
}

const AdditionalBusinessInfo = ({
  nextStep,
  additionalBusinessInfo,
  isSubmittingOnboardStatus,
  onPreviousStep,
}: AdditionalBusinessInfoProps) => {
  const businessAddress = additionalBusinessInfo?.addressList.find((value) =>
    value.type.includes("PERMANENT"),
  );

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    ...rest
  } = useAdditionaBusinessInfoUpdate({
    initialValues: {
      businessId: additionalBusinessInfo?.id,
      businessAddress: {
        id: businessAddress?.id || "",
        streetAddress: businessAddress?.streetAddress || "",
        streetDetails: businessAddress?.streetDetails || "",
        state: businessAddress?.state || "",
        city: businessAddress?.city || "",
        zip: businessAddress?.zip || "",
        type: ENUM_ADDRESS_TYPE.PERMANENT,
      },
      deliveryType: ENUM_DELIVERY_TYPE.DAYS_5_7,
      fuel: additionalBusinessInfo?.weeklyfuelSpends
        ? fuelFormatter(
            (Number(additionalBusinessInfo?.weeklyfuelSpends) / 100).toString(),
          )
        : "",
      annualBusinessRevenue: additionalBusinessInfo?.annualBusinessRevenue
        ? fuelFormatter(
            (
              Number(additionalBusinessInfo?.annualBusinessRevenue) / 100
            ).toString(),
          )
        : "",
      fleetType: additionalBusinessInfo?.fleetType || "",
      fleetNum: additionalBusinessInfo?.fleetNum || "",
    },
    onSubmit(res, err) {
      if (!err) {
        nextStep();
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    },
  });

  const { ref: businessAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      if (place.address_components) {
        let streetAddress = "";
        let zip = "";
        let state = "";
        let city = "";
        for (const component of place.address_components) {
          const componentType = component.types;
          if (componentType.includes("street_number")) {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType.includes("route")) {
            streetAddress += component.long_name;
          }
          if (componentType.includes("postal_code")) {
            zip = component.long_name;
          }
          if (componentType.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (
            componentType.includes("locality") ||
            componentType.includes("sublocality")
          ) {
            city = component.long_name;
          }
        }
        setFieldValue("businessAddress.zip", zip);
        setFieldValue("businessAddress.state", state);
        setFieldValue("businessAddress.city", city);
        setFieldValue("businessAddress.streetAddress", streetAddress);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  const autoCompleteRef = useRef({
    businessAutoCompleteRef,
  });

  return (
    <AdditionalBusinessInfoUI
      {...{
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        isSubmittingOnboardStatus,
        touched,
        errors,
        setFieldValue,
        ...rest,
        additionalBusinessInfo,
        onPreviousStep,
      }}
      ref={autoCompleteRef}
    />
  );
};

function Business(props: {
  nextStep: (jumpStepBy?: number) => void;
  onPreviousStep: () => void;
  isSubmittingOnboardStatus: boolean;
}) {
  const { data, loading } = useReadAdditionalBusinessInformation();

  if (loading) {
    return <div className="mt-6 text-center text-black">Loading...</div>;
  }

  return (
    <AdditionalBusinessInfo
      {...{
        ...props,
        additionalBusinessInfo: data.readAdditionalBusinessInformation,
      }}
    />
  );
}

export default Business;
