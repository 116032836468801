/** @format */
import { useMutation } from "@apollo/client";
import { SAVE_TERMS_OF_SERVICE } from "@roadflex/graphql";
import { TermsOfServiceValues } from "@roadflex/types";
import { useFormik } from "formik";
import * as Yup from "yup";

type useTermsOfServiceUpdateProps = {
  onSubmit: (val: { code: string } | null, err: Error | null) => void;
  initialValues: TermsOfServiceValues;
  validationSchema: Yup.AnyObjectSchema;
};

export const useTermsOfServiceUpdate = ({
  onSubmit,
  initialValues,
  validationSchema,
}: useTermsOfServiceUpdateProps) => {
  const [saveTermsOfService] = useMutation(SAVE_TERMS_OF_SERVICE, {});

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await saveTermsOfService({
          variables: { data },
        });
        onSubmit(null, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
