/** @format */

import { Dialog } from "primereact/dialog";
import { Button, ButtonType, ButtonVariant } from "../../buttons";

type Props = {
  showBusinessInformationConfirmationModal: boolean;
  setShowBusinessInformationConfirmationModal: (
    showBusinessInformationConfirmationModal: boolean,
  ) => void;
  confirm: () => void;
  submitting: boolean;
  customerType: string;
};
export function BusinessInformationConfirmationModal({
  submitting,
  confirm,
  customerType,
  showBusinessInformationConfirmationModal,
  setShowBusinessInformationConfirmationModal,
}: Props) {
  const renderHeader = () => {
    return <div className="pl-4 text-base">Acknowledgment</div>;
  };

  const renderFooter = () => {
    return (
      <div className="flex justify-end">
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.SecondaryOutline}
          disabled={submitting}
          className="w-full"
          onClick={() => setShowBusinessInformationConfirmationModal(false)}
        >
          Close
        </Button>
        <Button
          variant={ButtonVariant.Orange}
          disabled={submitting}
          loading={submitting}
          className="w-full ml-4"
          onClick={() => confirm()}
        >
          Confirm
        </Button>
      </div>
    );
  };

  return (
    <Dialog
      header={renderHeader}
      footer={renderFooter}
      visible={showBusinessInformationConfirmationModal}
      className="w-11/12 md:container md:w-1/2 xl:w-1/3"
      style={{ fontFamily: "Inter" }}
      closable={false}
      onHide={() => setShowBusinessInformationConfirmationModal(false)}
    >
      <div className="inline-block px-4 overflow-hidden text-left align-bottom transition-all transform bg-white sm:align-middle sm:w-full">
        <div className="text-left md:py-2">
          {customerType === "PREPAID" ? (
            <p className="text-sm">
              You are submitting an application for RoadFlex Unlimited (Prepaid
              card). If you are approved for the prepaid card, there is a $25.00
              non-refundable set-up fee. Confirm to acknowledge.
            </p>
          ) : (
            <p className="text-sm">
              You are submitting an application for RoadFlex Prestige (credit
              product). If you are approved, you will be extended a credit line.
              Confirm to acknowledge.
            </p>
          )}
        </div>
      </div>
    </Dialog>
  );
}
