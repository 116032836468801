/** @format */

import { CogIcon } from "@heroicons/react/24/outline";
import { BlobProvider, pdf } from "@react-pdf/renderer";
import { useGetDefaultedUsersExportData } from "@roadflex/react-hooks";
import { UserDetailsForAdmin } from "@roadflex/types";
import { humanizeSpace } from "@roadflex/utils";
import { isEmpty } from "lodash";
import getConfig from "next/config";
import { useEffect, useState } from "react";
import { Button, ButtonVariant } from "../../buttons";
import {
  CompanyDetailsPdf,
  CreditApplicationPdf,
  InvoicesPdf,
  StatementListPdf,
  StatementOfAccountPdf,
  UnderwritingStatusPdf,
} from "../../load-pdf";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const JSZip = require("jszip");

export const DefaultedUsersDataExportModal = ({
  modal,
  toggle,
  email,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [info, setinfo] = useState<any>({});
  const [disable, setdisable] = useState(false);
  const { getDefaultedUsersExportData } = useGetDefaultedUsersExportData();
  let pdfFiles: File[] = [];
  let imageFiles: Blob[] = [];

  async function getDefaultedUsersData() {
    try {
      const newData = { email: email };
      setdisable(true);
      const response = await getDefaultedUsersExportData({
        variables: { data: newData },
      });
      if (response) {
        setinfo(response?.data?.getDefaultedUsersExportData);
      }
      setdisable(false);
    } catch (err) {
      Toast({ type: "error", message: "There was an error fetching the data" });
      setdisable(false);
      toggle();
    }
  }

  const downloadFile = async (fileUrl: string) => {
    const isPDF = fileUrl.slice(-5).includes(".pdf");
    await fetch(`${NEXT_PUBLIC_API_URL}/api/files/${fileUrl}`, {
      method: "GET",
      credentials: "include",
    })
      .then(async (response) => {
        if (!response.ok) {
          // make the promise be rejected if we didn't get a 2xx response
          throw new Error("Failed to download the file, please try again.");
        }
        await response
          .clone()
          .blob()
          .then((res) => {
            if (isPDF) {
              const file = new File([res], "BankStatement.pdf");
              pdfFiles.push(file);
            } else {
              imageFiles.push(res);
            }
          });
        await response.text();
        // .then((a) => console.log("downloadDocument ", a.length));
      })
      .catch((error) => {
        // toast.error("Something went wrong. Please try again.");
        // console.log("error", error);
        throw error;
      });
  };

  const DebtorContactInformationPdf = () => <CompanyDetailsPdf {...info} />;
  const StatementsPdf = () => <StatementListPdf {...info} />;
  const FailedInvoicesPdf = () => <InvoicesPdf {...info} />;
  const UnderwritingResultsPdf = () => <UnderwritingStatusPdf {...info} />;
  const CreditAppPdf = () => <CreditApplicationPdf {...info} />;
  const StmtOfAccountPdf = () => <StatementOfAccountPdf {...info} />;

  useEffect(() => {
    getDefaultedUsersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(info)) {
      (async () => {
        try {
          const creditApplication: UserDetailsForAdmin =
            info?.creditApplication;
          const { business: business2 } = creditApplication || {};
          // const { business: business2 } =
          //   (businessMembers && businessMembers[0]) || {};
          const { document } = business2 || {};
          const { fleetLicense = "", fleetLicenseBack = "" } = document || {};
          let { statements = "" } = document || {};
          if (isEmpty(statements)) {
            statements = ["", "", ""];
          }

          const statement1 = statements[0];
          const statement2 = statements[1];
          const statement3 = statements[2];

          const businessName = humanizeSpace(info?.company?.businessName);
          const zip = new JSZip();
          const blob1 = await pdf(<DebtorContactInformationPdf />).toBlob();
          const blob2 = await pdf(<StatementsPdf />).toBlob();
          const blob3 = await pdf(<FailedInvoicesPdf />).toBlob();
          const blob4 = await pdf(<UnderwritingResultsPdf />).toBlob();
          const blob5 = await pdf(<CreditAppPdf />).toBlob();
          const blob6 = await pdf(<StmtOfAccountPdf />).toBlob();

          zip.file(`Debtor Contact Information with Amounts Due.pdf`, blob1);
          zip.file(`Statement ${businessName} from RoadFlex.pdf`, blob2);
          zip.file(`Invoices ${businessName} from RoadFlex.pdf`, blob3);
          zip.file(
            `Credit Underwriting Results ${businessName} with RoadFlex.pdf`,
            blob4,
          );
          zip.file(`Credit Application.pdf`, blob5);
          zip.file(
            `Statement of Account ${businessName} from RoadFlex.pdf`,
            blob6,
          );

          if (fleetLicense) {
            await downloadFile(fleetLicense);
          }
          if (fleetLicenseBack) {
            await downloadFile(fleetLicenseBack);
          }
          for (let i = 0; i < imageFiles.length; i++) {
            zip.file(`License ${i + 1} ${businessName}.jpg`, imageFiles[i]);
          }
          for (let i = 0; i < pdfFiles.length; i++) {
            zip.file(`License ${i + 1} ${businessName}.pdf`, pdfFiles[i]);
          }

          //Resetting the arrays
          imageFiles = [];
          pdfFiles = [];
          if (statement1) {
            await downloadFile(statement1);
          }
          if (statement2) {
            await downloadFile(statement2);
          }
          if (statement3) {
            await downloadFile(statement3);
          }
          for (let i = 0; i < imageFiles.length; i++) {
            zip.file(
              `Bank Statement ${i + 1} ${businessName}.jpg`,
              imageFiles[i],
            );
          }
          for (let i = 0; i < pdfFiles.length; i++) {
            zip.file(
              `Bank Statement ${i + 1} ${businessName}.pdf`,
              pdfFiles[i],
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          zip.generateAsync({ type: "blob" }).then((blobdata: any) => {
            const zipblob = new Blob([blobdata]);
            const elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(zipblob);
            elem.download = `${businessName} Default.zip`;
            elem.click();
            toggle();
          });
        } catch (err) {
          Toast({
            type: "error",
            message: "There was some error processing the data",
          });
          setdisable(false);
          toggle();
        }
      })();
    }
  }, [info]);

  return (
    <Button variant={ButtonVariant.Transparent} className="!p-0 ml-4">
      <CogIcon className="w-5 h-5 text-scarpa-flow-500 animate-spin"></CogIcon>
      <div className="hidden">
        <BlobProvider document={<DebtorContactInformationPdf />}>
          {({ blob, url, loading, error }) => {
            return loading ? "Loading Doc 1..." : "Download Doc 1";
          }}
        </BlobProvider>
        <BlobProvider document={<StatementsPdf />}>
          {({ blob, url, loading, error }) => {
            return loading ? "Loading Doc 2..." : "Download Doc 2";
          }}
        </BlobProvider>
        <BlobProvider document={<FailedInvoicesPdf />}>
          {({ blob, url, loading, error }) => {
            return loading ? "Loading Doc 3..." : "Download Doc 3";
          }}
        </BlobProvider>
        <BlobProvider document={<UnderwritingResultsPdf />}>
          {({ blob, url, loading, error }) => {
            return loading ? "Loading Doc 4..." : "Download Doc 4";
          }}
        </BlobProvider>
        <BlobProvider document={<CreditAppPdf />}>
          {({ blob, url, loading, error }) => {
            return loading ? "Loading Doc 5..." : "Download Doc 5";
          }}
        </BlobProvider>
        <BlobProvider document={<StmtOfAccountPdf />}>
          {({ blob, url, loading, error }) => {
            return loading ? "Loading Doc 6..." : "Download Doc 6";
          }}
        </BlobProvider>
      </div>
    </Button>
  );
};
