/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import { ENUM_CUSTOMER_TYPE, ENUM_JOB_TITLE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import {
  PersonalInformationType,
  PersonalInformationValues,
} from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import {
  dateOfBirthFormatter,
  phoneFormatter,
  ssnFormatter,
} from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";
import { Persona } from "../persona";
import { OnboardBannerComponent } from "./common-banner-component";

interface PersonalInformationUIProps
  extends FormikProps<PersonalInformationValues> {
  isVerifyEnabled: boolean;
  setVerifyEnabled: (isVerifyEnabled: boolean) => void;
  isSubmittingOnboardStatus: boolean;
  id: string;
  email: string;
  personaInquiryId: string;
  personaInquirySessionToken: string;
  handleStorePersonaInquiryId: (inquiryId: string) => void;
  customerType: string;
  personalInfo: PersonalInformationType;
  onPreviousStep: () => void;
}

const PersonalInformationUI = ({
  isVerifyEnabled,
  setVerifyEnabled,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  isSubmittingOnboardStatus,
  id,
  email,
  personaInquiryId,
  personaInquirySessionToken,
  handleStorePersonaInquiryId,
  customerType,
  personalInfo,
  dirty,
  onPreviousStep,
  ...rest
}: PersonalInformationUIProps) => {
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent
        header="Application"
        subText="Tell us about yourself (1 of 2). Due to regulatory guidelines, we
              are required to collect information on anyone who has significant
              ownership (&#62;25&#37;) of your business."
      />

      <div className="flex flex-col w-full px-4 py-8 md:p-16">
        <form onSubmit={handleSubmit} className="flex flex-col w-full ">
          <div className="relative w-full">
            <TextInput
              label="Legal First (and Middle) Name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              touched={touched.firstName}
              variant="responsive"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Legal Last Name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              touched={touched.lastName}
              variant="responsive"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Email Address"
              name="email"
              value={email}
              variant="responsive"
              disabled
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Mobile"
              type="tel"
              name="phoneNumber"
              value={values.phoneNumber}
              onChange={(event) =>
                setFieldValue(
                  "phoneNumber",
                  phoneFormatter(event?.target?.value),
                )
              }
              onBlur={handleBlur}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              errorMessage={errors.phoneNumber}
              touched={touched.phoneNumber}
              variant="responsive"
              required
              disabled
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label={
                <div className="flex flex-row mb-1 md:mb-2">
                  <div className="flex items-center ml-2 text-center whitespace-nowrap">
                    Date Of Birth*
                  </div>
                </div>
              }
              name="dateOfBirth"
              value={values.dateOfBirth}
              type="tel"
              onChange={(event) =>
                setFieldValue(
                  "dateOfBirth",
                  dateOfBirthFormatter(event?.target?.value),
                )
              }
              onBlur={handleBlur}
              autoComplete="dateOfBirth"
              error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
              errorMessage={errors.dateOfBirth}
              touched={touched.dateOfBirth}
              variant="responsive"
              placeholder="MM/DD/YYYY"
              required
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <Select
              label="Executive Job Title"
              value={values.executiveJobTitle}
              name="executiveJobTitle"
              options={[
                {
                  label: "-- Select an option --",
                  value: "",
                  disabled: true,
                },
              ].concat(
                Object.keys(ENUM_JOB_TITLE).map((key) => {
                  return {
                    label: humanizeEnum(key),
                    value: key,
                    disabled: false,
                  };
                }),
              )}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => setFieldValue("executiveJobTitle", e.value)}
              onBlur={() => setFieldTouched("executiveJobTitle", true)}
              placeholder="-- Select an option --"
              touched={touched.executiveJobTitle}
              error={
                touched.executiveJobTitle && Boolean(errors.executiveJobTitle)
              }
              errorMessage={errors.executiveJobTitle}
              required
              variant="responsive"
            ></Select>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label={
                <div className="mb-1 md:mb-2">
                  <div className="flex flex-row ">
                    <div className="text-center">
                      <LockClosedIcon
                        width={25}
                        height={25}
                        className="pointer-events-none"
                      />
                    </div>
                    <div className="flex items-center ml-2 text-center whitespace-nowrap">
                      Executive's Social Security Number*
                    </div>
                  </div>
                </div>
              }
              name="ssn"
              value={values.ssn}
              type="tel"
              onChange={(event) =>
                setFieldValue("ssn", ssnFormatter(event?.target?.value))
              }
              onBlur={handleBlur}
              autoComplete="ssn"
              error={touched.ssn && Boolean(errors.ssn)}
              errorMessage={errors.ssn}
              touched={touched.ssn}
              variant="responsive"
              placeholder="###-##-####"
              required
            ></TextInput>
          </div>
          <div className="mt-2 text-sm italic">
            To verify your identity, we'll need to know your full Social
            Security Number. We do not conduct credit checks. Your credit score
            won't be affected.
          </div>

          <div className="flex items-center justify-center px-4 py-1 mt-4 text-sm text-center max-w-[500px] w-full self-center bg-green-100 rounded-full">
            {" "}
            <CheckCircleIcon className="w-4 h-4 mr-1 text-green-900"></CheckCircleIcon>
            Applying won't affect your personal credit score
          </div>
          {customerType !== ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE &&
            customerType !== ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE && (
              <div className="mt-4 space-y-4" role="group">
                <div className="flex items-center">
                  <input
                    id="isVerifyEnabled"
                    name="isVerifyEnabled"
                    type="checkbox"
                    disabled={Boolean(
                      personalInfo?.fleetLicense &&
                        personalInfo?.fleetLicenseBack,
                    )}
                    checked={
                      isVerifyEnabled ||
                      Boolean(
                        personalInfo?.fleetLicense &&
                          personalInfo?.fleetLicenseBack,
                      )
                    }
                    onChange={(e) => {
                      setVerifyEnabled(e.target.checked);
                    }}
                    className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
                  />
                  <label
                    htmlFor="isVerifyEnabled"
                    className="block ml-3 text-xs md:text-sm"
                  >
                    We will be collecting your business information. You confirm
                    that you have significant control and management of your
                    business.
                  </label>
                </div>
              </div>
            )}

          <div className="mt-6">
            {personalInfo?.fleetLicense && personalInfo?.fleetLicenseBack ? (
              <Button
                variant={ButtonVariant.Orange}
                className="w-full"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting || isSubmittingOnboardStatus}
                loading={isSubmitting || isSubmittingOnboardStatus}
              >
                <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
                {dirty ? `Save` : `Next`}
              </Button>
            ) : (
              <Persona
                {...{
                  isVerifyEnabled,
                  handleBlur,
                  values,
                  setFieldValue,
                  isSubmitting,
                  isSubmittingOnboardStatus,
                  handleSubmit,
                  errors,
                  touched,
                  handleChange,
                  setFieldTouched,
                  dirty,
                  id,
                  personaInquiryId,
                  personaInquirySessionToken,
                  handleStorePersonaInquiryId,
                  customerType,
                  ...rest,
                }}
              />
            )}
          </div>
          <div>
            <div className="flex items-center justify-center mt-1 text-xs text-center md:text-sm">
              <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
              We securely store and encrypt your personal information
            </div>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Transparent}
              className="pl-0"
              size={ButtonSize.Small}
              onClick={onPreviousStep}
            >
              &#60;&#60; Go back
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInformationUI;
