import { PencilIcon } from "@heroicons/react/24/outline";
import {
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES,
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES,
} from "@prisma/client";
import { Toast } from "@roadflex/react-components-www";
import { DynamicPurchaseControlsFormikValues } from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { FuelCardComponentPermissionGuard } from "../../permission-guard";

type DynamicPurchaseControlsUIProps =
  FormikProps<DynamicPurchaseControlsFormikValues> & {
    access: boolean;
    enableEdit: boolean;
    createMode: boolean;
    createNewDynamicPurchaseControls: () => void;
  };

export const DynamicPurchaseControlsUI = ({
  handleChange,
  handleSubmit,
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
  resetForm,
  validateForm,
  setFieldTouched,
  isSubmitting,
  handleReset,
  access,
  enableEdit,
  createMode,
  createNewDynamicPurchaseControls,
  ...rest
}: DynamicPurchaseControlsUIProps) => {
  const [editDynamicPurchaseControls, setEditDynamicPurchaseControls] =
    useState(false);

  useEffect(() => {
    if (createMode) {
      setEditDynamicPurchaseControls(true);
    }
  }, [createMode]);

  return (
    <>
      <div className="py-4 mt-4 text-sm bg-white rounded-md shadow md:py-8 md:mt-8 md:text-base">
        <div className="flex flex-col overflow-hidden">
          <div className="px-4 mb-4 md:mb-8">
            <div className="font-semibold text-brown-500 md:text-lg">
              Dynamic purchase controls{" "}
              <span className="font-normal text-yellow-600"> (Enterprise)</span>
            </div>
            <div className="mt-2 text-sm">
              Dynamic purchase controls help you restrict purchase types. Unlike
              merchant categories, which is broader, dynamic purchase controls
              is more granular. For this feature, RoadFlex communicates to the
              merchant what specific purchase items are allowed or not allowed.
              Adherence to these restrictions is at the seller's discretion,
              particularly for non-fuel purchases.
            </div>
          </div>
          <div className="h-0.5 border-0 bg-brown-500"></div>
          <div className="px-4">
            <div className="flex w-full">
              <div className={classNames("w-full border-b divide-gray-300")}>
                <dt className="flex items-center justify-between py-6 pr-4 text-md">
                  <div className="relative flex items-center ">
                    <div className="mr-3 text-left">
                      <label className="">
                        Enable Dynamic Purchase Controls
                      </label>
                    </div>
                  </div>
                  <div className="flex items-center h-6">
                    <FuelCardComponentPermissionGuard
                      type="inputswitch"
                      featurePermission={access}
                      messageType={"enterprise"}
                    >
                      <InputSwitch
                        id={"dynamicPurchaseControlsSetting"}
                        name={"dynamicPurchaseControlsSetting"}
                        checked={values.dynamicPurchaseControlsSetting}
                        disabled={!editDynamicPurchaseControls}
                        onChange={(e) =>
                          setFieldValue(
                            "dynamicPurchaseControlsSetting",
                            e.target.value,
                          )
                        }
                        className="border-0 input-switch"
                      />
                    </FuelCardComponentPermissionGuard>
                  </div>
                </dt>
              </div>
            </div>
            <fieldset className="space-y-0">
              <div className="pt-4 text-left md:pt-8">
                Dynamic Purchase Controls Fuel Categories Allowed
              </div>
              {Object.keys(
                ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES,
              ).map((category) => (
                <div key={category}>
                  <div className="flex w-full">
                    <div
                      className={classNames("w-full border-b divide-gray-300")}
                    >
                      <dt className="flex items-center justify-between px-4 py-6 text-md">
                        <div className="relative flex items-center ">
                          <div className="mr-3 text-left">
                            <label
                              htmlFor={category}
                              className={
                                !values.dynamicPurchaseControlsSetting
                                  ? "opacity-50"
                                  : ""
                              }
                            >
                              {humanizeEnum(category)}
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center h-6">
                          <InputSwitch
                            id={"dynamicPurchaseControlsAllowedFuelCategories"}
                            name={
                              "dynamicPurchaseControlsAllowedFuelCategories"
                            }
                            checked={values?.dynamicPurchaseControlsAllowedFuelCategories?.some(
                              (value) => value === category,
                            )}
                            disabled={
                              !values.dynamicPurchaseControlsSetting ||
                              !editDynamicPurchaseControls
                            }
                            // disabled={!editLimits}
                            onChange={(e) =>
                              setFieldValue(
                                "dynamicPurchaseControlsAllowedFuelCategories",
                                e.target.value
                                  ? [
                                      ...values.dynamicPurchaseControlsAllowedFuelCategories,
                                      category,
                                    ]
                                  : values.dynamicPurchaseControlsAllowedFuelCategories.filter(
                                      (item) => item !== category,
                                    ),
                              )
                            }
                            className="border-0 input-switch disabled:opacity-50"
                          />
                        </div>
                      </dt>
                    </div>
                  </div>
                </div>
              ))}
            </fieldset>
            <fieldset className="space-y-0">
              <div className="pt-4 text-left md:pt-8">
                Dynamic Purchase Controls Non-Fuel Categories Allowed
              </div>
              {Object.keys(
                ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES,
              ).map((category) => (
                <div key={category}>
                  <div className="flex w-full">
                    <div
                      className={classNames("w-full border-b divide-gray-300")}
                    >
                      <dt className="flex items-center justify-between px-4 py-6 text-md">
                        <div className="relative flex items-center ">
                          <div className="mr-3 text-left">
                            <label
                              htmlFor={category}
                              className={
                                !values.dynamicPurchaseControlsSetting
                                  ? "opacity-50"
                                  : ""
                              }
                            >
                              {humanizeEnum(category)}
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center h-6">
                          <InputSwitch
                            id={
                              "dynamicPurchaseControlsAllowedNonFuelCategories"
                            }
                            name={
                              "dynamicPurchaseControlsAllowedNonFuelCategories"
                            }
                            checked={values?.dynamicPurchaseControlsAllowedNonFuelCategories?.some(
                              (value) => category === value,
                            )}
                            disabled={
                              !values.dynamicPurchaseControlsSetting ||
                              !editDynamicPurchaseControls
                            }
                            // disabled={!editLimits}
                            onChange={(e) =>
                              setFieldValue(
                                "dynamicPurchaseControlsAllowedNonFuelCategories",
                                e.target.value
                                  ? [
                                      ...values.dynamicPurchaseControlsAllowedNonFuelCategories,
                                      category,
                                    ]
                                  : values.dynamicPurchaseControlsAllowedNonFuelCategories.filter(
                                      (item) => item !== category,
                                    ),
                              )
                            }
                            className="border-0 input-switch"
                          />
                        </div>
                      </dt>
                    </div>
                  </div>
                </div>
              ))}
            </fieldset>
          </div>
        </div>
      </div>
      <div>
        {createMode && (
          <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-2">
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Black}
              size={ButtonSize.AppSize}
              onClick={() => {
                createNewDynamicPurchaseControls();
              }}
            >
              Save
            </Button>
          </div>
        )}
        {!createMode && !editDynamicPurchaseControls && (
          <div className="fixed flex flex-row justify-end space-y-2 bottom-5 right-5">
            <Button
              type={ButtonType.Button}
              size={ButtonSize.AppSize}
              variant={ButtonVariant.Green}
              disabled={!enableEdit}
              onClick={() => {
                setEditDynamicPurchaseControls(true);
              }}
            >
              <PencilIcon className="w-5 h-5" />
              <span className="pl-2 text-base">
                {enableEdit ? "Edit" : "Read Permission Only"}
              </span>
            </Button>
          </div>
        )}
        {!createMode && editDynamicPurchaseControls && (
          <div className="fixed flex flex-row justify-end gap-2 bottom-5 right-5">
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Red}
              size={ButtonSize.AppSize}
              disabled={isSubmitting}
              onClick={() => {
                setEditDynamicPurchaseControls(false);
                handleReset();
              }}
            >
              Cancel
            </Button>
            <FuelCardComponentPermissionGuard
              type="button"
              featurePermission={access}
              messageType={"enterprise"}
            >
              <Button
                type={ButtonType.Button}
                variant={ButtonVariant.Black}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={() => {
                  if (
                    errors &&
                    (errors?.dynamicPurchaseControlsAllowedFuelCategories ||
                      errors?.dynamicPurchaseControlsAllowedNonFuelCategories)
                  ) {
                    Toast({
                      type: "error",
                      message:
                        (errors?.dynamicPurchaseControlsAllowedFuelCategories ||
                          errors?.dynamicPurchaseControlsAllowedNonFuelCategories) as string,
                    });
                    return;
                  }
                  handleSubmit();
                  setEditDynamicPurchaseControls(false);
                }}
              >
                Save
              </Button>
            </FuelCardComponentPermissionGuard>
          </div>
        )}
      </div>
    </>
  );
};
