/* eslint-disable dot-notation */
/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES,
  ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES,
} from "@prisma/client";
import { dynamicPurchaseControlsSchema } from "@roadflex/constants";
import {
  READ_CURRENT_USER_AUTH,
  READ_CURRENT_USER_BASIC,
  READ_DYNAMIC_PURCHASE_CONTROLS,
  READ_FEATURE_BY_PAGE,
  READ_USER_DETAILS,
  UPDATE_DYNAMIC_PURCHASE_CONTROLS,
} from "@roadflex/graphql";
import {
  DynamicPurchaseControlsFormikValues,
  FeaturePermissionsType,
  UserAuthType,
  UserBasicType,
  UserDetailsType,
} from "@roadflex/types";
import { useFormik } from "formik";

export function useReadPermissions({ pageName }: { pageName: string }) {
  const { data, refetch, ...rest } = useQuery(READ_FEATURE_BY_PAGE, {
    // fetchPolicy: "no-cache",
    variables: { data: { pageName: pageName } },
  });
  const {
    readFeatureByPage = null,
  }: { readFeatureByPage: FeaturePermissionsType | null } = data || {};
  return { readFeatureByPage, refetch, ...rest };
}

export function useReadCurrentUserAuth() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_AUTH, {
    fetchPolicy: "no-cache",
  });
  const {
    readCurrentUserAuth = null,
  }: { readCurrentUserAuth: UserAuthType | null } = data || {};
  return { readCurrentUserAuth, refetch, ...rest };
}

export function useReadCurrentUserBasicInformation() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_BASIC, {
    // fetchPolicy: "no-cache",
  });
  const { readCurrentUserBasic }: { readCurrentUserBasic: UserBasicType } =
    data || {};

  return { readCurrentUserBasic, refetch, ...rest };
}

export const useReadUserDetails = (
  email: string,
  phoneNumber: string,
  businessName: string,
  queryType: string,
  objArray: string[],
) => {
  const {
    data: readUserDetailsData,
    refetch: readUserDetailsRefetch,
    loading: readUserDetailsLoading,
    ...rest
  } = useQuery<{
    readUserDetails: {
      code: string;
      message: string;
      details: UserDetailsType;
    };
  }>(READ_USER_DETAILS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        email: email,
        phoneNumber: phoneNumber,
        businessName: businessName,
        queryType: queryType,
        objArray: objArray,
      },
    },
  });

  return {
    readUserDetailsData,
    readUserDetailsRefetch,
    readUserDetailsLoading,
    ...rest,
  };
};

export const useReadDynamicPurchaseControls = (
  object: "card" | "vehicle" | "driver" | "policy",
  id: string,
) => {
  const {
    data: readDynamicPurchaseControlsData,
    refetch: readDynamicPurchaseControlsRefetch,
    loading: readDynamicPurchaseControlsRefetchLoading,
    ...rest
  } = useQuery<{
    readDynamicPurchaseControls: {
      code: string;
      message: string;
      dynamicPurchaseControlsSetting: boolean;
      dynamicPurchaseControlsAllowedFuelCategories: ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_FUEL_CATEGORIES[];
      dynamicPurchaseControlsAllowedNonFuelCategories: ENUM_DYNAMIC_PURCHASE_CONTROL_ALLOWED_NON_FUEL_CATEGORIES[];
      id: string;
      object: "card" | "vehicle" | "driver" | "policy";
    };
  }>(READ_DYNAMIC_PURCHASE_CONTROLS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        id,
        object,
      },
    },
  });

  return {
    readDynamicPurchaseControlsData,
    readDynamicPurchaseControlsRefetch,
    readDynamicPurchaseControlsRefetchLoading,
    ...rest,
  };
};

type UseUpdateDynamicPurchaseControlsProps = {
  initialValues: DynamicPurchaseControlsFormikValues;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};
export const useUpdateDynamicPurchaseControls = ({
  onSubmit,
  initialValues,
}: UseUpdateDynamicPurchaseControlsProps) => {
  const [updateDynamicPurchaseControlsFn] = useMutation<{
    updateDynamicPurchaseControls: {
      code: string;
      message: string;
    };
  }>(UPDATE_DYNAMIC_PURCHASE_CONTROLS, {
    refetchQueries: [READ_DYNAMIC_PURCHASE_CONTROLS],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: dynamicPurchaseControlsSchema,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await updateDynamicPurchaseControlsFn({
          variables: { data },
        });
        if (res?.data?.updateDynamicPurchaseControls) {
          onSubmit(res?.data?.updateDynamicPurchaseControls, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
