/** @format */

import { ENUM_CLASSIFICATION, ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { CATEGORIES_MASTER_MAP } from "@roadflex/constants";
import { useReadAdminCardSetting } from "@roadflex/react-hooks";
import { CardMerchants, ChildFeatureType, UserAuthType } from "@roadflex/types";
import { useState } from "react";
import { Loader } from "../../../loader";
import { PolicyUI } from "./policy.ui";

interface PolicyNewProps {
  readCurrentUserAuth: UserAuthType;
  childFeatures: ChildFeatureType[];
}
export const PolicyNew = ({
  readCurrentUserAuth,
  childFeatures,
}: PolicyNewProps) => {
  const { adminCardSettingData, adminCardSettingLoading } =
    useReadAdminCardSetting();
  const [submittingRequest, setsubmittingRequest] = useState(false);
  const [editCardRules, setEditCardRules] = useState(false);
  const [editLimits, setEditLimits] = useState(false);

  const tabs = [
    { id: 1, name: "Card Rules", current: true },
    { id: 2, name: "Spend Limits", current: false },
    { id: 3, name: "Dynamic Purchase Controls", current: false },
  ];
  const merchantOriginal: CardMerchants[] = [];
  adminCardSettingData?.readAdminCardSetting?.data[0]?.categories
    .split(",")
    .forEach((key: string) => {
      merchantOriginal.push({
        name: key,
        authorized: true,
        toggleOn: false,
        classification: CATEGORIES_MASTER_MAP[
          key as keyof typeof CATEGORIES_MASTER_MAP
        ] as ENUM_CLASSIFICATION,
        requireMemo: false,
      });
    });

  if (adminCardSettingLoading) {
    <Loader />;
  }

  return (
    <PolicyUI
      {...{
        cardRuleData: null,
        tabs,
        adminCardSettingData: adminCardSettingData?.readAdminCardSetting
          ?.data[0] || {
          dailyLimit: 0,
          transactionLimit: 0,
          categories: "",
          customerType: ENUM_CUSTOMER_TYPE.PREPAID_PLUS,
          restrictedMerchants: "",
        },
        submittingRequest,
        editLimits,
        setEditLimits,
        editCardRules,
        setEditCardRules,
        setsubmittingRequest,
        merchantOriginal,
        childFeatures,
      }}
    />
  );
};
