/** @format */
import { useRouter } from "next/router";
import { useEffect } from "react";

export const Redirect = ({ path }: { path: string } = { path: "/" }) => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      router.push(path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.isReady]);
  return null;
};

export default Redirect;
