/** @format */
import { useMutation } from "@apollo/client";
import { onboardBusinessInformationSchema } from "@roadflex/constants";
import { UPDATE_BUSINESS_INFORMATION } from "@roadflex/graphql";
import { BusinessInformationValues } from "@roadflex/types";
import { uploadFile } from "@roadflex/web-api";
import { useFormik } from "formik";

type UseBusinessUpdateAltProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: BusinessInformationValues;
};

export const useBusinessUpdateAlt = ({
  onSubmit,
  initialValues,
}: UseBusinessUpdateAltProps) => {
  const [updateBusinessInformationFn] = useMutation<{
    updateBusinessInformation: {
      code: string;
      message: string;
    };
  }>(UPDATE_BUSINESS_INFORMATION, {});

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: onboardBusinessInformationSchema,
    onSubmit: async (value) => {
      const { irsLetterReupload, uploadedIRSLetter } = value;
      const irsLetterUrl =
        uploadedIRSLetter && !irsLetterReupload
          ? uploadedIRSLetter
          : await uploadFile(value.irsLetter as File, "irs_letter");
      const data = {
        title: value.title,
        cardName: value.cardName,
        companyType: value.companyType,
        customerType: value.customerType,
        type: value.type,
        description: value.description,
        // fuel: value.fuel,
        irsLetter: irsLetterUrl,
        yearsOfOperation: Number(value.yearsOfOperation),
        ein: value.ein,
      };
      // const formattedFuelVal = (data.fuel as string).replace("$", "");
      // data.fuel = parseFloat(formattedFuelVal) * 100;

      try {
        const res = await updateBusinessInformationFn({
          variables: { data: data },
        });
        if (res?.data?.updateBusinessInformation) {
          onSubmit(res?.data?.updateBusinessInformation, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
