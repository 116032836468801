import { dollarFormatter } from "@roadflex/constants";

interface CreditLimitBarProps {
  creditLimit: number;
  creditRemaining: number;
}

const CreditLimitBar: React.FC<CreditLimitBarProps> = ({
  creditLimit,
  creditRemaining,
}) => {
  const limitUtilized = Math.max(creditLimit - creditRemaining, 0);
  const limitUtilizedPercentage = (limitUtilized / creditLimit) * 100;

  return (
    <div className="flex flex-col w-full gap-2">
      <div className="flex flex-row justify-between ">
        <div>{dollarFormatter(limitUtilized / 100)}</div>
        <div>{dollarFormatter(creditLimit / 100)}</div>
      </div>
      <div className="flex w-full h-3 overflow-hidden rounded-md bg-brown-200">
        <div
          className="flex items-center justify-center h-full text-xs font-medium "
          style={{
            width: `${limitUtilizedPercentage}%`,
            backgroundColor: "#e6b68b",
          }}
        ></div>
      </div>
      <div className="flex flex-row justify-between ">
        <div>Limit Utlized</div>
        <div>Total Credit Limit</div>
      </div>
      <div>Available Credit: {dollarFormatter(creditRemaining / 100)}</div>
    </div>
  );
};

export default CreditLimitBar;
