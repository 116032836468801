/** @format */
import { useQuery } from "@apollo/client";
import { READ_CURRENT_USER_ONBOARD } from "@roadflex/graphql";
import { UserOnboardType } from "@roadflex/types";

export function useReadCurrentUserOnboard() {
  const { data, refetch, ...rest } = useQuery(READ_CURRENT_USER_ONBOARD, {
    fetchPolicy: "no-cache",
  });
  const {
    readCurrentUserOnboard = null,
  }: { readCurrentUserOnboard: UserOnboardType | null } = data || {};
  return { readCurrentUserOnboard, refetch, ...rest };
}
