/** @format */
import { useQuery } from "@apollo/client";
import { READ_ADDRESSES, READ_HOME_ADDRESS } from "@roadflex/graphql";
import { AddressType } from "@roadflex/types";

type useReadAddressesProps = {
  businessId: string | null | undefined;
};

type useReadHomeAddressProps = {
  id: string | null | undefined;
};

export const useReadAddresses = ({ businessId }: useReadAddressesProps) => {
  const { data, ...rest } = useQuery<{
    readAddresses: {
      addresses: AddressType[];
      isSameAddress: boolean;
    };
  }>(READ_ADDRESSES, {
    variables: {
      where: {
        businessId,
      },
    },
    fetchPolicy: "no-cache",
    // skip: !!businessId !== true,
  });

  return { data: data?.readAddresses, ...rest };
};

export const useReadHomeAddress = ({ id }: useReadHomeAddressProps) => {
  const { data, ...rest } = useQuery<{
    readHomeAddress: {
      address: AddressType;
      id: string;
    };
  }>(READ_HOME_ADDRESS, {
    variables: {
      data: {
        id,
      },
    },
    fetchPolicy: "no-cache",
    // skip: !!businessId !== true,
  });

  return { data: data?.readHomeAddress, ...rest };
};
