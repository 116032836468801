/** @format */

import { useUpdatePassword } from "@roadflex/react-hooks";
import { Toast } from "../../../../src/toast-message/toast";
import UpdatePasswordUI from "./update-password.ui";

const UpdatePassword = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    ...rest
  } = useUpdatePassword({
    initialValues: {
      currentPassword: "",
      password: "",
      retypePassword: "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      }
      resetForm();
    },
  });

  return (
    <UpdatePasswordUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        resetForm,
        ...rest,
      }}
    />
  );
};
export default UpdatePassword;
