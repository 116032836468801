/** @format */
import { ENUM_PRODUCT_NAMES } from "@prisma/client";
import { GEOLOCATION_URL } from "@roadflex/constants";
import {
  useReadOwnersAndExecutives,
  useSubmitOnboardApplication,
  useUpdateOnboardStatus,
} from "@roadflex/react-hooks";
import { UserOnboardType } from "@roadflex/types";
import { isIPAddress } from "@roadflex/utils";
import axios from "axios";
import { useRouter } from "next/router";
import { useState } from "react";
import { Toast } from "../toast-message/toast";
import FuelcardOnboardUI from "./fuel-card-onboard.ui";

export const FuelcardOnboard = ({
  readCurrentUserOnboard,
}: {
  readCurrentUserOnboard: UserOnboardType;
}) => {
  const [onBoardStatus, setOnBoardStatus] = useState(
    readCurrentUserOnboard.fuelCard.onboardStatus || 0,
  );
  const router = useRouter();
  const { onSubmitOnboardApplicationFn } = useSubmitOnboardApplication();
  const { data, loading } = useReadOwnersAndExecutives();

  const [confirming, setconfirming] = useState(false);

  const { isSubmitting, submitForm, setFieldValue } = useUpdateOnboardStatus({
    initialValues: {
      step: onBoardStatus,
      productName: ENUM_PRODUCT_NAMES.FUELCARD,
    },
    onSubmit(result, err) {
      if (err) {
        Toast({ type: "error", message: "Something went wrong" });
        return;
      }
      const status = result.step;
      setOnBoardStatus(status);
      setFieldValue("step", status);
    },
  });

  const nextStep = (jumpStepBy?: number) => {
    const currentStatus = readCurrentUserOnboard?.fuelCard?.onboardStatus || 0;
    if (currentStatus <= onBoardStatus) {
      if (jumpStepBy) {
        setFieldValue("step", jumpStepBy + currentStatus).then(() => {
          submitForm();
        });
        return;
      }
      submitForm();
    } else {
      if (jumpStepBy) {
        setOnBoardStatus(onBoardStatus + 1 + jumpStepBy);
        return;
      }
      setOnBoardStatus(onBoardStatus + 1);
    }
  };
  const onPreviousStep = () => {
    if (
      data?.ownersAndExecutives.some(
        (oAE) => oAE.representative && !oAE.owner,
      ) &&
      onBoardStatus === 7
    ) {
      setOnBoardStatus(onBoardStatus - 2);
    } else {
      setOnBoardStatus(onBoardStatus - 1);
    }
  };

  const onJumpToStep = (step: number) => {
    setOnBoardStatus(step);
  };

  const onSubmitApplication = async () => {
    setconfirming(true);
    let ipAddress = "";
    try {
      const ipResponse = await axios.get(GEOLOCATION_URL);
      if (ipResponse?.data?.IPv4 && isIPAddress(ipResponse.data.IPv4)) {
        ipAddress = ipResponse.data.IPv4;
      }
    } catch (err) {
      console.error("Error fetching IP Address => ", err);
    }

    try {
      await onSubmitOnboardApplicationFn({
        variables: {
          data: { ipAddress, productName: ENUM_PRODUCT_NAMES.FUELCARD },
        },
      });
      router.push("/no-access");
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      }
    } finally {
      setconfirming(false);
    }
  };
  if (loading) {
    return null;
  }

  return (
    <FuelcardOnboardUI
      {...{
        readCurrentUserOnboard,
        nextStep,
        onBoardStatus,
        onSubmitApplication,
        isSubmittingApplication: confirming,
        onPreviousStep,
        onJumpToStep,
        isSubmittingOnboardStatus: isSubmitting,
      }}
    />
  );
};

export default FuelcardOnboard;
