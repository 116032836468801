/** @format */
import { useMutation } from "@apollo/client";
import { ENUM_PRODUCT_NAMES } from "@prisma/client";
import {
  READ_CURRENT_USER_ONBOARD,
  READ_USER_DETAILS,
  UPDATE_ONBOARD_STATUS,
} from "@roadflex/graphql";
import { useFormik } from "formik";

type Values = {
  step: number;
  productName: ENUM_PRODUCT_NAMES;
};

type UseUpdateOnboardStatusProps = {
  onSubmit: (val: any, error: any) => void;
  initialValues: Values;
};

export const useUpdateOnboardStatus = ({
  onSubmit,
  initialValues,
}: UseUpdateOnboardStatusProps) => {
  const [updateOnboardStatusFn] = useMutation(UPDATE_ONBOARD_STATUS, {
    refetchQueries: [READ_USER_DETAILS, READ_CURRENT_USER_ONBOARD],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateOnboardStatusFn({
          variables: {
            data: { step: value.step + 1, productName: value.productName },
          },
        });
        onSubmit({ step: value.step + 1, ...res }, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
