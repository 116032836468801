/** @format */
import { useQuery } from "@apollo/client";
import {
  READ_AUTHORIZATION_DETAIL,
  READ_TRANSACTION_DETAIL,
} from "@roadflex/graphql";

export const useReadTransactionDetail = (transactionId: string) => {
  const { data, ...rest } = useQuery(READ_TRANSACTION_DETAIL, {
    fetchPolicy: "no-cache",
    variables: { data: { transactionId: transactionId } },
  });
  const { transaction = null }: { transaction: any | null } =
    data?.readTransactionDetail || {};

  return { transaction, ...rest };
};

export const useReadAuthorizationDetail = (authorizationId: string) => {
  const { data, ...rest } = useQuery(READ_AUTHORIZATION_DETAIL, {
    fetchPolicy: "no-cache",
    variables: { data: { authorizationId: authorizationId } },
  });
  const { authorization = null }: { authorization: any | null } =
    data?.readAuthorizationDetail || {};

  return { authorization, ...rest };
};
