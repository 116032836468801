/** @format */

import { FLEET_TYPES } from "@roadflex/constants";
import { UpdateFleetInfoValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { SyntheticEvent, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateFleetInfoUIProps extends FormikProps<UpdateFleetInfoValues> {}

const UpdateFleetInfoUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  handleReset,
  setFieldTouched,
  ...rest
}: UpdateFleetInfoUIProps) => {
  const [update, setupdate] = useState(false);
  const toggle = () => setupdate((t) => !t);
  const submitAction = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
    toggle();
  };

  const discardChanges = () => {
    handleReset();
    setupdate(false);
  };

  return (
    <form
      onSubmit={submitAction}
      className="flex flex-col w-full text-sm md:text-base"
    >
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Fleet Information
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Fleet Type
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <Select
                disabled={!update}
                onChange={(e) => setFieldValue("fleetType", e.value)}
                onBlur={() => setFieldTouched("fleetType", true)}
                value={values.fleetType}
                name="fleetType"
                options={[
                  {
                    label: "-- Select an option --",
                    value: "",
                    disabled: true,
                  },
                ].concat(
                  FLEET_TYPES.map((fT) => {
                    return {
                      label: fT.label,
                      value: fT.value,
                      disabled: false,
                    };
                  }),
                )}
                optionLabel="label"
                optionValue="value"
                placeholder="Select"
                touched={touched.fleetType}
                error={touched.fleetType && Boolean(errors.fleetType)}
                errorMessage={errors.fleetType}
              ></Select>
            </div>
          </div>
          <div className="flex justify-end">
            {!update && (
              <Button
                onClick={toggle}
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
              >
                Update
              </Button>
            )}
            {update && (
              <div className="flex gap-x-2">
                <Button
                  onClick={discardChanges}
                  variant={ButtonVariant.Red}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type={ButtonType.Submit}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.AppSize}
                  loading={isSubmitting}
                  disabled={
                    isSubmitting ||
                    Object.keys(errors).length > 0 ||
                    values.fleetType === ""
                  }
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
export default UpdateFleetInfoUI;
