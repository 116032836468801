/** @format */
import { BAR_GRAPH_CONFIG } from "@roadflex/constants";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

/* eslint-disable-next-line */
export interface WeeklyFraudDeclinedTransactionProps {
  fraudDeclinedDailyTransactions: Array<[number, number]>;
  fraudDeclinedWeeklyTransactions: Array<[number, number]>;
  loading: boolean;
  title: string;
  selectedDisplayMode: string;
}
const NoDataToShowMessage = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
      <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
        No Data to Show
      </div>
    </div>
  );
};
export function WeeklyFraudDeclinedTransaction({
  fraudDeclinedDailyTransactions,
  fraudDeclinedWeeklyTransactions,
  title,
  selectedDisplayMode,
}: WeeklyFraudDeclinedTransactionProps) {
  const finalGraphData =
    selectedDisplayMode === "day"
      ? fraudDeclinedDailyTransactions
      : fraudDeclinedWeeklyTransactions;
  const highestValue = Math.max(...finalGraphData.map((item) => item[1]));

  const graphOfFraudDeclinedTransactionsOptions = {
    ...BAR_GRAPH_CONFIG,
    title: {
      text: title,
      style: {
        textAlign: "left",
        color: "#893f2b",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "600",
      },
      align: "left",
    },
    yAxis: [
      {
        title: {
          text: "Total Fraud Declined Transactions",
        },
      },
      {
        title: {
          text: "Total Fraud Declined Transactions",
        },
        linkedTo: 0,
        opposite: true,
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    series: [
      {
        name: "Total Fraud Declined Transactions",
        data: finalGraphData.map((item) => ({
          x: item[0],
          y: item[1],
          color: item[1] === highestValue ? "#ff5100" : "#893f2b",
        })),
      },
    ],
  };

  return (
    <div className="relative p-5 overflow-hidden bg-white border border-gray-200 rounded-md shadow sm:pt-6 sm:px-6">
      <HighchartsReact
        highcharts={Highcharts}
        options={graphOfFraudDeclinedTransactionsOptions}
      />
      {finalGraphData.some((value) => value[1] > 0) ? null : (
        <NoDataToShowMessage />
      )}
    </div>
  );
}

export default WeeklyFraudDeclinedTransaction;
