/** @format */

import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { onboardBankStatementsSchema } from "@roadflex/constants";
import {
  useBankVerification,
  useLinkToken,
  useReadBusinessInformation,
  useReadDocuments,
  useReadUserDetails,
} from "@roadflex/react-hooks";
import { BusinessType, DocumentsType } from "@roadflex/types";
import * as Yup from "yup";
import { Toast } from "../toast-message/toast";
import BankVerificationUI from "./bank-verification.ui";

interface BankVerificationProps {
  nextStep: (jumpStepBy?: number) => void;
  business: BusinessType;
  documents: DocumentsType;
  isSubmittingOnboardStatus: boolean;
  customerType: string;
  onPreviousStep: () => void;
}

const BankVerification = ({
  customerType,
  business,
  nextStep,
  documents,
  isSubmittingOnboardStatus,
  onPreviousStep,
}: BankVerificationProps) => {
  let validationSchema = Yup.object().shape({});

  const uploadedStatements: string[] = documents.statements || [];
  if (customerType.includes("CREDIT")) {
    validationSchema = onboardBankStatementsSchema(uploadedStatements.length);
  }
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    institution,
    publicToken,
    status,
    setStatus,
    ...rest
  } = useBankVerification({
    initialValues: {
      businessId: business.id,
      statements: [],
      plaidConnect: business?.plaid?.length > 0 || false,
      docsReupload: false,
    },
    validationSchema,
    onSubmit(res, err) {
      if (!err) {
        nextStep();
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    },
  });
  const { linkToken: plaidToken, loading: plaidLoading } = useLinkToken({
    id: business?.id,
    isUpdateMode: false,
    isWithTransaction: true,
  });

  if (plaidLoading) {
    return <div className="my-6 text-center text-black">Loading...</div>;
  }

  const handleFiles = (files: FileList) => {
    const filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files[i]);
    }
    setFieldValue(
      "statements",
      (values.statements as File[]).concat(filesArray),
    );
  };

  const handleRemoveFile = (file: File) => {
    const newFilesArray = (values.statements as File[]).filter(
      (statement) => statement.name !== file.name,
    );
    setFieldValue("statements", newFilesArray);
  };

  return (
    <BankVerificationUI
      {...{
        values,
        customerType,
        handleChange,
        handleFiles,
        handleRemoveFile,
        handleSubmit,
        isSubmitting,
        isSubmittingOnboardStatus,
        setFieldValue,
        handleBlur,
        errors,
        touched,
        linkToken: plaidToken?.linkToken,
        plaidData: business.plaid || [],
        institution,
        publicToken,
        status,
        setStatus,
        uploadedStatements,
        onPreviousStep,
        ...rest,
      }}
    />
  );
};

function FleetInfo(props: {
  nextStep: (jumpStepBy?: number) => void;
  business: BusinessType;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}) {
  const { documents, loading: docsLoading } = useReadDocuments({
    businessId: props.business.id,
  });
  const {
    readUserDetailsData,
    readUserDetailsLoading: loading,
    readUserDetailsRefetch,
  } = useReadUserDetails("", "", "", "", ["Setting"]);
  const data = readUserDetailsData?.readUserDetails?.details;

  if (docsLoading) {
    return <div className="my-6 text-center text-white">Loading...</div>;
  }

  let customer = "PREPAID";
  if (
    data?.setting?.customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
    data?.setting?.customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
    data?.setting?.customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE
  ) {
    customer = "CREDIT";
  }

  return (
    <BankVerification {...{ ...props, customerType: customer, documents }} />
  );
}

function Business(props: {
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}) {
  const { data, loading } = useReadBusinessInformation();

  if (loading) {
    return <div className="my-6 text-center text-white">Loading...</div>;
  }

  const { readBusinessInformation: business } = data;

  return <FleetInfo {...{ ...props, business }} />;
}

export default Business;
