import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const NoDataToShowMessage = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
      <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
        No Data to Show
      </div>
    </div>
  );
};
function FraudDeclinedTransactionReasonType({
  categoryBreakdown,
  title,
}: {
  categoryBreakdown: { [key: string]: string };
  title: string;
}) {
  const data = Object.keys(categoryBreakdown).map((key) => ({
    name: key,
    y: categoryBreakdown[key],
  }));

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    title: {
      text: title,
      style: {
        textAlign: "left",
        color: "#893f2b",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "600",
      },
      align: "left",
    },
    tooltip: {
      formatter: function () {
        return `Reason: <b>${this.key}</b><br/><b>${this.y}</b>`;
      },
      shared: true,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    colors: [
      "#823920",
      "#ff5100",
      "#de8a26",
      "#6b9848",
      "#2670de",
      "#9f9f9f",
      "#893f2b",
      "#3a3939s",
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 300,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
              },
            },
          },
        },
      ],
    },

    series: [
      {
        name: "Share",
        colorByPoint: true,
        data: data,
        type: "pie" as never,
      },
    ],
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
        innerSize: "60%",
        point: {
          events: {
            legendItemClick: function () {
              return false; // <== returning false will cancel the default action
            },
          },
        },
      },
    },
  };

  return (
    <div className="relative p-5 overflow-hidden bg-white border border-gray-200 rounded-md shadow sm:pt-6 sm:px-6">
      <HighchartsReact highcharts={Highcharts} options={options} />
      {Object.keys(categoryBreakdown).length > 0 ? null : (
        <NoDataToShowMessage />
      )}
    </div>
  );
}

export default FraudDeclinedTransactionReasonType;
