/** @format */

import { useLazyQuery, useMutation } from "@apollo/client";
import { ENUM_USER_ACCOUNT_STATUS } from "@prisma/client";
import {
  RESEND_DEVICE_VERIFICATION_FOR_SIGN_IN,
  VERIFY_DEVICE_AND_SIGN_IN,
} from "@roadflex/graphql";
import { useReadCurrentUserAuth, useSignin } from "@roadflex/react-hooks-www";
import { setCookie } from "@roadflex/web-lib";
import { useFormik } from "formik";
import getConfig from "next/config";
import { useState } from "react";
import { Loader } from "../loader";
import Redirect from "../redirect/redirect";
import { Toast } from "../toast-message/toast";
import SignInUI from "./signin.ui";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_APP_URL, NEXT_PUBLIC_FACTOR_URL } = publicRuntimeConfig;

export function SignIn() {
  const { readCurrentUserAuth, refetch, loading } = useReadCurrentUserAuth();
  const [dataLoading, setDataLoading] = useState(false);
  const [showDeviceVerificationModal, setShowDeviceVerificationModal] =
    useState(false);
  const [otpSubmitting, setOtpSubmitting] = useState<boolean>(false);
  const formikStateAndHelpers = useFormik({
    initialValues: { otp: "" },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const [verifyDeviceAndSignIn] = useMutation(VERIFY_DEVICE_AND_SIGN_IN, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: async (res) => {
      const { authToken, deviceToken } = res?.verifyDeviceAndSignIn || {};
      setDataLoading(true);
      setCookie("token", authToken, 60);
      setCookie(`deviceToken`, deviceToken, 60);
      await refetch();
      setDataLoading(false);
    },
    onError: () => {
      formikStateAndHelpers.resetForm();
      Toast({ type: "error", message: "Device verification failed" });
      setOtpSubmitting(false);
      setShowDeviceVerificationModal(true);
    },
  });

  const [resendDeviceVerificationForSignIn] = useLazyQuery(
    RESEND_DEVICE_VERIFICATION_FOR_SIGN_IN,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setShowDeviceVerificationModal(true);
      },
      onError: (err) => {
        Toast({ type: "error", message: `Failed to send verification sms` });
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    handleBlur,
    ...rest
  } = useSignin({
    initialValues: {
      email: "",
      password: "",
    },
    async onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      const { authToken, isDeviceAuthorized } = res as {
        authToken: string;
        isDeviceAuthorized: boolean;
      };
      if (authToken) {
        setDataLoading(true);
        setCookie("token", authToken, 60);
        await refetch();
        setDataLoading(false);
      } else if (!isDeviceAuthorized) {
        //console.log(values);
        setShowDeviceVerificationModal(true);
      }
    },
  });

  const onOtpSubmit = (value: { otp: string }) => {
    verifyDeviceAndSignIn({
      variables: {
        data: {
          otp: value.otp,
          email: values.email,
          password: values.password,
        },
      },
    });
  };

  const onResendCode = () => {
    resendDeviceVerificationForSignIn({
      variables: {
        data: {
          email: values.email,
          //password: values.password,
        },
      },
    });
  };

  if (loading || dataLoading) {
    return <Loader />;
  }

  if (readCurrentUserAuth) {
    // if (!currentUser.isPhoneVerified) {
    //   return <Redirect path="phone-verification"></Redirect>;
    // }
    // if (!currentUser.ssn || !data.business.ein) {
    //   return <Redirect path="details-update"></Redirect>;
    // }
    if (readCurrentUserAuth?.factor?.isFactor) {
      return <Redirect path={`${NEXT_PUBLIC_FACTOR_URL}`}></Redirect>;
    }
    if (readCurrentUserAuth?.fuelCard?.isFuelCard) {
      if (
        readCurrentUserAuth.fuelCard.status !== ENUM_USER_ACCOUNT_STATUS.ONBOARD
      ) {
        if (!readCurrentUserAuth.isSSNSet || !readCurrentUserAuth.isEINSet) {
          return <Redirect path="details-update"></Redirect>;
        }
      }
      return <Redirect path={`${NEXT_PUBLIC_APP_URL}`}></Redirect>;
    }
    return <Redirect path={`${NEXT_PUBLIC_APP_URL}`}></Redirect>;
  }

  return (
    <SignInUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        handleBlur,
        ...rest,
        showDeviceVerificationModal,
        setShowDeviceVerificationModal,
        formikStateAndHelpers,
        onOtpSubmit,
        otpSubmitting,
        onResendCode,
      }}
    />
  );
}

export default SignIn;
