/** @format */
import { useMutation } from "@apollo/client";
import {
  EXECUTE_ADMIN_PRESET_QUERY,
  EXECUTE_ADMIN_PRESET_QUERY_FOR_ALL_USERS_DATA,
} from "@roadflex/graphql";

type UseAdminQueryProps = {
  queryType: string;
};

export const useExecuteAdminPresetMutation = ({
  queryType,
}: UseAdminQueryProps) => {
  const options = {
    fetchPolicy: "no-cache" as const,
    variables: {
      data: {
        queryType,
      },
    },
  };

  const mutation =
    queryType === "AllUsersBasicInfo"
      ? EXECUTE_ADMIN_PRESET_QUERY_FOR_ALL_USERS_DATA
      : EXECUTE_ADMIN_PRESET_QUERY;

  const [executeMutation, { data, loading }] = useMutation(mutation, options);

  return {
    loadFn: executeMutation,
    data,
    loading,
  };
};
