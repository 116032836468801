/** @format */

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { AccountTagType, TabsType } from "@roadflex/types";
import { FormikProps } from "formik";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { TextInput } from "../../../inputs";

interface AccountTagsUIProps extends FormikProps<AccountTagType> {
  tabs: TabsType[];
  tagId?: string;
  update: boolean;
  setUpdate: (value: boolean) => void;
}

export const AccountTagsUI = ({
  tagId,
  handleChange,
  isSubmitting,
  values,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  update,
  setFieldValue,
  setFieldTouched,
  setUpdate,
}: AccountTagsUIProps) => {
  const router = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const backToAccountTags = () => {
    router.push("/account-tags");
  };

  return (
    <div className="flex-col flex flex-1 md:px-4 md:pb-4 mt-0 m-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="">
        <div className="flex flex-col w-full rounded-md md:my-12">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-row items-center">
              <div className="">
                <Button
                  size={ButtonSize.Small}
                  variant={ButtonVariant.GrayOutline}
                  onClick={backToAccountTags}
                  className="pl-0 ml-[-6px] border-0"
                >
                  <span className="flex items-center h-5">
                    <ChevronRightIcon
                      className={
                        "-rotate-180 h-5 w-5 transform ease-in duration-300"
                      }
                      aria-hidden="true"
                    />
                  </span>
                  Back
                </Button>
              </div>
              <div className="ml-4 text-sm font-medium md:text-lg ">
                {tagId ? `Tag - ${values?.title}` : "Add New Tag"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mt-5 md:mt-0">
          {/* <div className="border-b border-gray-200">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-brown-500 text-brown-500"
                      : "border-transparent hover:border-brown-600 hover:text-brown-600",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div> */}
          <div className={`${tabOpen === 1 ? `block` : `hidden`} mt-6`}>
            <form
              className="flex flex-col h-full p-8 overflow-y-auto bg-white rounded-md shadow"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col flex-grow h-full py-8 space-y-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label="Tag Name"
                      required
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.title && Boolean(errors.title)}
                      errorMessage={errors.title}
                      touched={touched.title}
                      variant="medium"
                      disabled={tagId !== undefined && !update}
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <TextInput
                      label="Tag Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.description && Boolean(errors.description)}
                      errorMessage={errors.description}
                      touched={touched.description}
                      variant="medium"
                      disabled={tagId !== undefined && !update}
                      required
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 sm:col-span-12">
                    <Select
                      label="Tag Type"
                      required
                      value={values.tagType}
                      onChange={(e) => {
                        setFieldValue("tagType", e.value);
                      }}
                      onBlur={() => setFieldTouched("tagType", true)}
                      name="tagType"
                      disabled={tagId !== undefined}
                      id="tagType"
                      placeholder="--Select Tag Type--"
                      optionLabel="label"
                      optionValue="value"
                      options={[
                        {
                          label: "--Select Tag Type--",
                          value: "",
                        },
                        {
                          label: "Transaction",
                          value: "TRANSACTION",
                        },
                        {
                          label: "Card",
                          value: "CARD",
                        },
                        {
                          label: "Driver",
                          value: "DRIVER",
                        },
                        {
                          label: "Vehicle",
                          value: "VEHICLE",
                        },
                      ]}
                    ></Select>
                  </div>
                </div>
                <div className="flex flex-row justify-end py-4">
                  {tagId ? (
                    <>
                      {!update && (
                        <div className="flex flex-row justify-end w-full mt-8">
                          <Button
                            variant={ButtonVariant.Primary}
                            size={ButtonSize.AppSize}
                            className=""
                            onClick={() => {
                              setUpdate(true);
                            }}
                          >
                            Update
                          </Button>
                        </div>
                      )}
                      {update && (
                        <div className="flex flex-row justify-end w-full mt-8">
                          <Button
                            variant={ButtonVariant.GrayOutline}
                            size={ButtonSize.AppSize}
                            onClick={() => {
                              setUpdate(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant={ButtonVariant.Primary}
                            size={ButtonSize.AppSize}
                            className="ml-4"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type={ButtonType.Submit}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </>
                  ) : (
                    <Button
                      variant={ButtonVariant.AppOrange}
                      size={ButtonSize.AppSize}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      type={ButtonType.Submit}
                      className="flex flex-row items-center w-full sm:w-auto"
                    >
                      Add New Tag
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
