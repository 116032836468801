/** @format */

import { ENUM_CHILD_USER_TYPE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { ParentCompanyUserType } from "@roadflex/types";
import { capitalizeFirstLetter } from "@roadflex/utils";
import { phoneFormatter } from "@roadflex/web-lib";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ListUsersUIProps {
  users: {
    id: string;
    email: string;
    phoneNumber: string;
    profilesAuthorized: ParentCompanyUserType[];
  }[];
  usersRefetch: () => void;
  usersLoading: boolean;
}

const ListUsersUI = ({
  users,
  usersRefetch,
  usersLoading,
}: ListUsersUIProps) => {
  const { isMobile } = usePlatformDetect();

  const authorizedProfilesTemplate = (rowData: {
    id: string;
    email: string;
    phoneNumber: string;
    profilesAuthorized: ParentCompanyUserType[];
  }) => {
    const profileOptions = [];

    const sortedProfiles = (rowData?.profilesAuthorized || [])
      .slice()
      .sort((a, b) => {
        const nameA = a.businessName?.toLowerCase() || "";
        const nameB = b.businessName?.toLowerCase() || "";
        return nameA.localeCompare(nameB);
      });

    for (const profile of sortedProfiles) {
      const roleName = profile?.roles?.[0]?.role?.name;

      if (!roleName || roleName === ENUM_CHILD_USER_TYPE.OWNER) continue;

      const formattedRoleName = capitalizeFirstLetter(
        roleName.toLowerCase().replace("_", " "),
      );
      const formattedBusinessName = capitalizeFirstLetter(profile.businessName);

      profileOptions.push(
        <div className="m-1 text-sm whitespace-nowrap text-ellipsis">
          {formattedRoleName
            ? `${formattedBusinessName} (${formattedRoleName})`
            : formattedBusinessName}
        </div>,
      );
    }

    return profileOptions;
  };

  return (
    <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8 ">
      <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
        Parent Company Users
      </div>
      <div className="flex items-center py-4">
        <div className="w-full">
          <div className="overflow-hidden">
            <DataTable
              pageLinkSize={3}
              size="small"
              stripedRows
              loading={usersLoading}
              value={users}
              paginator
              rows={10}
              dataKey="id"
              sortField="email"
              sortOrder={1}
              paginatorTemplate={newPaginationTemplate}
              responsiveLayout={isMobile() ? "stack" : "scroll"}
              emptyMessage="No available records"
              style={{ fontFamily: "Inter" }}
              className="custom-table"
            >
              <Column
                sortable
                field="email"
                header="Email"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
              <Column
                sortable
                field="phoneNumber"
                header="Phone Number"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={(r) => phoneFormatter(r.phoneNumber)}
              />
              <Column
                field="authorizedProfiles"
                header="Authorized Profiles"
                className="text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
                body={authorizedProfilesTemplate}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListUsersUI;
