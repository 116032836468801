/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArrowUpOnSquareIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { adminBlogSchema } from "@roadflex/constants";
import { BlogType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { adminUploadFile } from "@roadflex/web-api";
import { useFormik } from "formik";
import getConfig from "next/config";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";
import { TextAreaInput, TextInput } from "../../inputs";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;
/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const HtmlToReactParser = require("html-to-react").Parser;

export type BlogModalProps = {
  isBlogSaving: boolean;
  onSaveBlog: ({
    id,
    title,
    titleImage,
    supportImages,
    summary,
    publishDate,
    rank,
    blog,
  }: BlogType) => void;
  blogPopup: boolean;
  setBlogPopup: (blogPopup: boolean) => void;
  blogData: BlogType;
};
export const BlogModal = ({
  isBlogSaving,
  blogPopup,
  onSaveBlog,
  setBlogPopup,
  blogData,
}: BlogModalProps) => {
  const initValues = {
    id: blogData.id,
    titleImage: blogData.titleImage,
    supportImage1: blogData.supportImages[0] || null,
    supportImage2: blogData.supportImages[1] || null,
    supportImages: blogData.supportImages,
    uploadedTitleImage: new File([""], ""),
    uploadedSupportImage1: new File([""], ""),
    uploadedSupportImage2: new File([""], ""),
    title: blogData.title,
    summary: blogData.summary,
    publishDate: blogData.publishDate,
    rank: blogData?.rank || "",
    blog: blogData.blog,
  };

  const uploadedImageURLsInitValues = {
    uploadedTitleImage: null,
    uploadedSupportImage1: null,
    uploadedSupportImage2: null,
  };

  const [uploadedImageURLs, setUploadedImageURLs] = useState(
    uploadedImageURLsInitValues,
  );
  const [isPreview, setPreview] = useState<boolean>(false);
  const [previewHtml, setPreviewHtml] = useState("");
  const [showEditButton, setShowEditButton] = useState<boolean>(
    blogData.title.length > 0 || false,
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    submitForm,
    setValues,
    values,
    touched,
    isSubmitting,
    errors,
    setFieldValue,
    setFieldTouched,
    setSubmitting,
  } = useFormik({
    initialValues: initValues,
    validationSchema: adminBlogSchema,
    onSubmit: async (value) => {
      const finalValues = { ...value };
      if (value?.uploadedTitleImage.name !== "") {
        const uploadedImage = await adminUploadFile(
          value.uploadedTitleImage as File,
          "admin_blog_image",
        );
        finalValues.titleImage = uploadedImage;
      }
      if (value?.uploadedSupportImage1.name !== "") {
        const finalSupportImage1 = await adminUploadFile(
          value.uploadedSupportImage1 as File,
          "admin_blog_image",
        );
        finalValues.supportImages.push(finalSupportImage1);
        finalValues.supportImage1 = finalSupportImage1;
      }
      if (value?.uploadedSupportImage2.name !== "") {
        const finalSupportImage2 = await adminUploadFile(
          value.uploadedSupportImage2 as File,
          "admin_blog_image",
        );
        finalValues.supportImages.push(finalSupportImage2);
        finalValues.supportImage2 = finalSupportImage2;
      }
      finalValues.title = value.title.trim();
      setSubmitting(false);
      onSaveBlog(finalValues);
    },
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFieldValue(event.target.id, event.target.files[0]);
      setUploadedImageURLs({
        ...uploadedImageURLs,
        [event.target.id]: URL.createObjectURL(event.target.files[0]),
      });
      setFieldValue(event.target.name, null);
      event.target.value = ""; // eslint-disable-line no-param-reassign
    }
  };

  const handleUploadedFileDelete = (event: any) => {
    setFieldValue(event.target.id, null);
    if (String(event.target.id).includes("uploaded")) {
      setUploadedImageURLs({ ...uploadedImageURLs, [event.target.id]: null });
    }
  };

  const handleShowPreview = () => {
    const titleImageURL =
      uploadedImageURLs.uploadedTitleImage ||
      `${NEXT_PUBLIC_API_URL}/api/files/${values.titleImage}`;
    const supportImage1URL =
      uploadedImageURLs.uploadedSupportImage1 ||
      `${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage1}`;
    const supportImage2URL =
      uploadedImageURLs.uploadedSupportImage2 ||
      `${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage2}`;
    const variables = {
      titleImage: titleImageURL,
      supportImage1: supportImage1URL,
      supportImage2: supportImage2URL,
    };

    const finalPreviewOfFullBlog = `
                  <div className="w-full max-w-4xl px-4 text-white rounded-md">
                    <div>
                      <div className="text-2xl text-center md:text-5xl">${
                        values?.title
                      }</div>
                      <div className="my-8 text-base font-thin text-center md:text-xl">
                        ${convertISOStringToDate(
                          values?.publishDate
                            ? values?.publishDate.toString()
                            : new Date().toString(),
                          true,
                        )}
                      </div>
                      <div className="flex justify-center">
                        <img
                          src={titleImage}
                          alt="Blog title image"
                          className="w-auto h-auto rounded-md max-h-[500px]"
                      />
                      </div>
                      <div className="my-8 text-xl md:text-2xl">
                        ${values?.summary}
                      </div>
                    </div>
                    <div className="text-base text-gray-300">
                      ${values?.blog}
                    </div>
                  </div>`;

    try {
      const finalPreviewHtml = finalPreviewOfFullBlog.replace(
        /{([^{}]+)}/g,
        (keyExpr, key) => {
          return variables[key as keyof typeof variables] || "";
        },
      );
      const htmlToReactParser = new HtmlToReactParser();
      const parsedPreviewHtml = htmlToReactParser.parse(finalPreviewHtml);
      setPreviewHtml(parsedPreviewHtml);
      setPreview(true);
    } catch (parseError) {
      // console.error("parse error => ", e);
      Toast({
        type: "error",
        message: "Error in parsing the template",
      });
      setPreview(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className="text-base">{blogData?.title ? "Edit" : "Add"} Blog</div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="flex justify-between">
        <div className="flex flex-row mt-5">
          {!isPreview ? (
            <div>
              {showEditButton ? (
                <Button
                  className="inline-flex"
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={() => {
                    setShowEditButton(false);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <div className="flex w-full">
                  {blogData.title && (
                    <Button
                      className="inline-flex"
                      size={ButtonSize.AppSize}
                      variant={ButtonVariant.Secondary}
                      onClick={() => {
                        setShowEditButton(true);
                        setValues(initValues);
                        setUploadedImageURLs(uploadedImageURLsInitValues);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    className="inline-flex"
                    size={ButtonSize.AppSize}
                    variant={ButtonVariant.AppOrange}
                    onClick={submitForm}
                  >
                    {isBlogSaving || isSubmitting ? "Publishing..." : "Publish"}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col mt-3">
          <div className="flex items-center mt-2">
            {isPreview ? (
              <Button
                className="inline-flex"
                size={ButtonSize.AppSize}
                variant={ButtonVariant.AppOrange}
                onClick={(e) => {
                  setPreview(false);
                }}
              >
                Close Preview
              </Button>
            ) : (
              <Button
                className="inline-flex"
                size={ButtonSize.AppSize}
                variant={ButtonVariant.AppOrange}
                onClick={() => {
                  handleShowPreview();
                }}
              >
                Preview
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header={renderHeader}
      footer={renderFooter}
      visible={blogPopup}
      style={{ width: "70vw", fontFamily: "Inter" }}
      onHide={() => setBlogPopup(false)}
    >
      <form onSubmit={handleSubmit} className="w-full">
        <div className="inline-block px-4 overflow-hidden text-left align-bottom transition-all transform bg-white sm:align-middle sm:w-full">
          <div>
            {isPreview ? (
              <div className="w-full px-4 py-10 rounded-md bg-darkblue">
                <div className="container flex flex-col items-center justify-center min-h-[300px]">
                  {previewHtml || ""}
                </div>
              </div>
            ) : (
              <div>
                {showEditButton ? (
                  <div className="py-3 text-sm font-medium">
                    <div className="grid grid-cols-3 gap-1 mb-2 text-gray-900">
                      {values.titleImage ? (
                        <div className="relative flex justify-center">
                          <a
                            href={`${NEXT_PUBLIC_API_URL}/api/files/${values.titleImage}`}
                            rel="noreferrer"
                            target="_blank"
                            className="underline"
                          >
                            Title Image
                            <img
                              src={`${NEXT_PUBLIC_API_URL}/api/files/${values.titleImage}`}
                              alt="Blog title image"
                              className="w-auto h-auto pt-1 rounded-md max-h-40"
                            />
                          </a>
                        </div>
                      ) : null}
                      {values.supportImage1 ? (
                        <div className="relative flex justify-center">
                          <a
                            href={`${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage1}`}
                            rel="noreferrer"
                            target="_blank"
                            className="underline"
                          >
                            Support Image 1
                            <img
                              src={`${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage1}`}
                              alt="Blog supporting image 1"
                              className="w-auto h-auto pt-1 rounded-md max-h-40"
                            />
                          </a>
                        </div>
                      ) : null}
                      {values.supportImage2 ? (
                        <div className="relative flex justify-center">
                          <a
                            href={`${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage2}`}
                            rel="noreferrer"
                            target="_blank"
                            className="underline"
                          >
                            Support Image 2
                            <img
                              src={`${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage2}`}
                              alt="Blog supporting image 2"
                              className="w-auto h-auto pt-1 rounded-md max-h-40"
                            />
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="grid grid-cols-3 gap-2">
                      <div>
                        <div className="flex flex-row items-start justify-center w-full mx-auto mb-3 bg-white rounded-md cursor-pointer">
                          <label className="relative flex flex-row items-center justify-center w-full h-24 p-4 border-2 border-gray-200 border-dashed rounded-md cursor-pointer">
                            <div className="flex flex-col items-center justify-center w-full">
                              <div className="flex flex-row">
                                <ArrowUpOnSquareIcon
                                  className="w-10 h-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm tracking-wider group-hover:text-gray-600 uploadedTitle">
                                  Title Image*
                                </span>
                                {errors?.uploadedTitleImage && (
                                  <>
                                    <Tooltip
                                      target=".uploadedTitle-error"
                                      position="right"
                                      className="red-tooltip"
                                      content={
                                        errors.uploadedTitleImage as string
                                      }
                                    ></Tooltip>

                                    <div className="absolute right-0 flex items-center pr-8 cursor-pointer">
                                      <ExclamationCircleIcon
                                        className="w-5 h-5 text-red-500 uploadedTitle-error"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="w-full h-10 pt-1 overflow-hidden text-sm italic text-center">
                                {values.uploadedTitleImage?.name}
                              </div>
                            </div>
                            <input
                              type="file"
                              id="uploadedTitleImage"
                              name="titleImage"
                              onChange={handleFileUpload}
                              className="absolute hidden opacity-0"
                              onBlur={handleBlur}
                              accept=".png, .jpeg, .jpg"
                            />
                          </label>
                        </div>
                        {uploadedImageURLs.uploadedTitleImage ? (
                          <div className="relative flex justify-center">
                            <img
                              src={uploadedImageURLs.uploadedTitleImage}
                              alt="Blog title image"
                              className="w-auto h-auto rounded-md max-h-40"
                            />
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.Normal}
                              className="absolute top-0 right-0"
                              id="uploadedTitleImage"
                              onClick={handleUploadedFileDelete}
                            >
                              <TrashIcon
                                width={20}
                                height={20}
                                className="pointer-events-none"
                              />
                            </Button>
                          </div>
                        ) : null}
                        {!uploadedImageURLs.uploadedTitleImage &&
                        values.titleImage ? (
                          <div className="relative flex justify-center">
                            <img
                              src={`${NEXT_PUBLIC_API_URL}/api/files/${values.titleImage}`}
                              alt="Blog title image"
                              className="w-auto h-auto rounded-md max-h-40"
                            />
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.Normal}
                              className="absolute top-0 right-0"
                              id="titleImage"
                              onClick={handleUploadedFileDelete}
                            >
                              <TrashIcon
                                width={20}
                                height={20}
                                className="pointer-events-none"
                              />
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <div className="flex flex-row items-start justify-center w-full mx-auto mb-3 bg-white rounded-md cursor-pointer">
                          <label className="relative flex flex-row items-center justify-center w-full h-24 p-4 border-2 border-gray-200 border-dashed rounded-md cursor-pointer">
                            <div className="flex flex-col items-center justify-center">
                              <div className="flex flex-row">
                                <ArrowUpOnSquareIcon
                                  className="w-10 h-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm tracking-wider group-hover:text-gray-600">
                                  Support Image 1
                                </span>
                                {errors?.uploadedSupportImage1 && (
                                  <>
                                    <Tooltip
                                      target=".uploadedSupportImage1-error"
                                      position="right"
                                      className="red-tooltip"
                                      content={
                                        errors.uploadedSupportImage1 as string
                                      }
                                    ></Tooltip>

                                    <div className="absolute right-0 flex items-center pr-8 cursor-pointer">
                                      <ExclamationCircleIcon
                                        className="w-5 h-5 text-red-500 uploadedSupportImage1-error"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="w-full h-10 pt-1 overflow-hidden text-sm italic text-center">
                                {values.uploadedSupportImage1?.name}
                              </div>
                            </div>
                            <input
                              type="file"
                              id="uploadedSupportImage1"
                              name="supportImage1"
                              onChange={handleFileUpload}
                              className="absolute hidden opacity-0"
                              onBlur={handleBlur}
                              accept=".png, .jpeg, .jpg"
                            />
                          </label>
                        </div>
                        {uploadedImageURLs.uploadedSupportImage1 ? (
                          <div className="relative flex justify-center">
                            <img
                              src={uploadedImageURLs.uploadedSupportImage1}
                              alt="Blog supporting image 1"
                              className="w-auto h-auto rounded-md max-h-40"
                            />
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.Normal}
                              className="absolute top-0 right-0"
                              id="uploadedSupportImage1"
                              onClick={handleUploadedFileDelete}
                            >
                              <TrashIcon
                                width={20}
                                height={20}
                                className="pointer-events-none"
                              />
                            </Button>
                          </div>
                        ) : null}
                        {!uploadedImageURLs.uploadedSupportImage1 &&
                        values.supportImage1 ? (
                          <div className="relative flex justify-center">
                            <img
                              src={`${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage1}`}
                              alt="Blog supporting image 1"
                              className="w-auto h-auto rounded-md max-h-40"
                            />
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.Normal}
                              className="absolute top-0 right-0"
                              id="supportImage1"
                              onClick={handleUploadedFileDelete}
                            >
                              <TrashIcon
                                width={20}
                                height={20}
                                className="pointer-events-none"
                              />
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <div className="flex flex-row items-start justify-center w-full mx-auto mb-3 bg-white rounded-md cursor-pointer">
                          <label className="relative flex flex-row items-center justify-center w-full h-24 p-4 border-2 border-gray-200 border-dashed rounded-md cursor-pointer">
                            <div className="flex flex-col items-center justify-center">
                              <div className="flex flex-row">
                                <ArrowUpOnSquareIcon
                                  className="w-10 h-5 text-gray-500"
                                  aria-hidden="true"
                                />
                                <span className="text-sm tracking-wider group-hover:text-gray-600">
                                  Support Image 2
                                </span>
                                {errors?.uploadedSupportImage2 && (
                                  <>
                                    <Tooltip
                                      target=".uploadedSupportImage2-error"
                                      position="right"
                                      className="red-tooltip"
                                      content={
                                        errors.uploadedSupportImage2 as string
                                      }
                                    ></Tooltip>

                                    <div className="absolute right-0 flex items-center pr-8 cursor-pointer">
                                      <ExclamationCircleIcon
                                        className="w-5 h-5 text-red-500 uploadedSupportImage2-error"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="w-full h-10 pt-1 overflow-hidden text-sm italic text-center">
                                {values.uploadedSupportImage2?.name}
                              </div>
                            </div>
                            <input
                              type="file"
                              id="uploadedSupportImage2"
                              name="supportImage2"
                              onChange={handleFileUpload}
                              className="absolute hidden opacity-0"
                              onBlur={handleBlur}
                              accept=".png, .jpeg, .jpg"
                            />
                          </label>
                        </div>
                        {uploadedImageURLs.uploadedSupportImage2 ? (
                          <div className="relative flex justify-center">
                            <img
                              src={uploadedImageURLs.uploadedSupportImage2}
                              alt="Blog supporting image 2"
                              className="w-auto h-auto rounded-md max-h-40"
                            />
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.Normal}
                              className="absolute top-0 right-0"
                              id="uploadedSupportImage2"
                              onClick={handleUploadedFileDelete}
                            >
                              <TrashIcon
                                width={20}
                                height={20}
                                className="pointer-events-none"
                              />
                            </Button>
                          </div>
                        ) : null}
                        {values.supportImage2 ? (
                          <div className="relative flex justify-center">
                            <img
                              src={`${NEXT_PUBLIC_API_URL}/api/files/${values.supportImage2}`}
                              alt="Blog supporting image 2"
                              className="w-auto h-auto rounded-md max-h-40"
                            />
                            <Button
                              variant={ButtonVariant.Red}
                              size={ButtonSize.Normal}
                              className="absolute top-0 right-0"
                              id="supportImage2"
                              onClick={handleUploadedFileDelete}
                            >
                              <TrashIcon
                                width={20}
                                height={20}
                                className="pointer-events-none"
                              />
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                <div className="relative w-full">
                  <TextInput
                    label="Blog Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.title && Boolean(errors.title)}
                    errorMessage={errors.title}
                    touched={touched.title}
                    variant="medium"
                    disabled={showEditButton}
                    required
                  ></TextInput>
                </div>
                <div className="relative w-full mt-4">
                  <TextInput
                    label="Summary"
                    name="summary"
                    value={values.summary}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.summary && Boolean(errors.summary)}
                    errorMessage={errors.summary}
                    touched={touched.summary}
                    variant="medium"
                    disabled={showEditButton}
                    required
                  ></TextInput>
                </div>

                <div className="relative w-full mt-4">
                  <Select
                    label="Priority"
                    required
                    options={[
                      {
                        label: "  -- Select Priority --",
                        value: "",
                        disabled: true,
                      },
                      {
                        label: "1 (Highest)",
                        value: 1,
                        disabled: false,
                      },
                      {
                        label: "2",
                        value: 2,
                        disabled: false,
                      },
                      {
                        label: "3",
                        value: 3,
                        disabled: false,
                      },
                      {
                        label: "4",
                        value: 4,
                        disabled: false,
                      },
                      {
                        label: "5 (Lowest)",
                        value: 5,
                        disabled: false,
                      },
                    ]}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => {
                      setFieldValue("rank", e.value);
                    }}
                    onBlur={() => setFieldTouched("rank", true)}
                    name="rank"
                    id="rank"
                    value={values.rank}
                    placeholder="-- Select Priority --"
                    disabled={showEditButton}
                    touched={touched?.rank as boolean}
                    error={touched?.rank && Boolean(errors?.rank)}
                    errorMessage={errors?.rank as string}
                  ></Select>
                </div>

                <div className="relative w-full mt-4">
                  <div className="mb-2">
                    Publish Date &mdash; If this is not provided, the publish
                    date will be the date this blog post was created
                  </div>
                  <Calendar
                    name="publishDate"
                    value={values.publishDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={showEditButton}
                    className="block w-full custom-calendar h-[37.6px]"
                    dateFormat="mm/dd/yy"
                    placeholder="mm/dd/yyyy"
                    mask="99/99/9999"
                  />
                </div>

                <div className="relative w-full mt-4">
                  <TextAreaInput
                    label="Blog Body"
                    required
                    name="blog"
                    value={values.blog}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={showEditButton}
                    rows={8}
                    variant="medium"
                    touched={touched?.blog as boolean}
                    error={touched?.blog && Boolean(errors?.blog)}
                    errorMessage={errors?.blog as string}
                  ></TextAreaInput>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </Dialog>
  );
};
