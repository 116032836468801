/** @format */

import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { SignInValues } from "@roadflex/types";
import { FormikProps } from "formik";
import getConfig from "next/config";
import Link from "next/link";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, ButtonType, ButtonVariant } from "../buttons";
import { TextInput } from "../inputs";
import { OTPModal } from "../modals/index-www";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_WWW_URL, RECAPTCHA_SITE_KEY, NEXT_PUBLIC_CDN_URL } =
  publicRuntimeConfig;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignInUIProps extends FormikProps<SignInValues> {
  showDeviceVerificationModal: boolean;
  setShowDeviceVerificationModal: (param: boolean) => void;
  formikStateAndHelpers: FormikProps<{ otp: string }>;
  onOtpSubmit: (value: { otp: string }) => void;
  otpSubmitting: boolean;
  onResendCode: () => void;
}

export default function SignInUI({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  touched,
  errors,
  showDeviceVerificationModal,
  setShowDeviceVerificationModal,
  formikStateAndHelpers,
  onOtpSubmit,
  otpSubmitting,
  onResendCode,
}: SignInUIProps) {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const { isMobile } = usePlatformDetect();

  return (
    <>
      <div className="flex flex-col justify-center w-full text-center rounded-md">
        <form
          className="flex flex-col px-4 py-8 md:p-16"
          onSubmit={handleSubmit}
        >
          <div className="grid w-full grid-cols-12 gap-2 text-black md:gap-4">
            <div className="relative col-span-12">
              <TextInput
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                errorMessage={errors.email}
                touched={touched.email}
                variant="responsive"
                required
              ></TextInput>
            </div>
            <div className="relative col-span-12">
              <TextInput
                label="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                errorMessage={errors.password}
                touched={touched.password}
                variant="responsive"
                required
                type={"password"}
              ></TextInput>
            </div>
          </div>
          <div className="mt-4 text-sm text-right md:text-base">
            <Link href="/forgot-password">
              <a className="font-medium underline underline-offset-1">
                Forgot your password?
              </a>
            </Link>
          </div>
          <ReCAPTCHA
            className="invisible recaptcha"
            sitekey={RECAPTCHA_SITE_KEY}
            ref={captchaRef}
            size="invisible"
          />
          <div className="mt-8">
            <Button
              type={ButtonType.Submit}
              variant={ButtonVariant.Orange}
              className="w-full"
            >
              Sign In
            </Button>
          </div>
        </form>
        <div className="mt-4 text-sm text-center text-white md:text-base">
          Do not have an account?&nbsp;
          <Link href={`${NEXT_PUBLIC_WWW_URL}/signup`}>
            <a
              href={`${NEXT_PUBLIC_WWW_URL}/signup`}
              className="font-medium underline text-white-600 hover:text-white-500 underline-offset-1"
            >
              Sign Up
            </a>
          </Link>
        </div>
      </div>
      {showDeviceVerificationModal && (
        <OTPModal
          {...{
            ...formikStateAndHelpers,
          }}
          show={showDeviceVerificationModal}
          setShow={setShowDeviceVerificationModal}
          onOtpSubmit={onOtpSubmit}
          otpSubmitting={otpSubmitting}
          resendCode={onResendCode}
          heading={`Verify your Device`}
          hideCrossButton={true}
          message={"Enter the 6-digit verification code sent via SMS"}
        />
      )}
    </>
  );
}
