/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { useReadCurrentUserBasicInformation } from "@roadflex/react-hooks-www";
import getConfig from "next/config";
import { Fragment, useRef, useState } from "react";
import { Button, ButtonVariant } from "../../buttons";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

export const QuickbooksModal = ({
  modal,
  toggle,
}: {
  modal: boolean;
  toggle: () => void;
}) => {
  const cancelButtonRef = useRef(null);

  const [submitting, setsubmitting] = useState(false);
  const [realmId, setRealmId] = useState("");
  const { readCurrentUserBasic } = useReadCurrentUserBasicInformation();

  async function connectToQuickbooks() {
    const data = { userId: readCurrentUserBasic?.id, realmId };
    setsubmitting(true);
    const response = await fetch(
      `${NEXT_PUBLIC_API_URL}/api/quickbooks/authUri`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    const res = await response.json();
    setsubmitting(false);
    toggle();
    window.open(
      res,
      "_blank", // <- This is what makes it open in a new window.
    );
  }

  return (
    <Transition.Root show={modal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={toggle}
      >
        <div className="w-full">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 z-1" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block px-4 pt-5 pb-4 text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <Dialog.Title>
                  <div className="pb-4 text-lg font-bold text-center">
                    Quickbooks Integrations
                  </div>
                </Dialog.Title>

                <div className="flex flex-col gap-4">
                  <div className="w-full text-sm">
                    After entering the Realm ID, you will be brought to the
                    quickbooks authorization page. On that page, you may be
                    asked to select the company that you will like to link.
                    Please choose the company that is linked to the Realm ID
                    that you provide.
                  </div>
                  {/* <div className="w-full">
                    <label
                      htmlFor="dailyLimit"
                      className="block text-sm font-medium text-left text-blue-gray-900"
                    >
                      Client ID
                    </label>
                    <input
                      type="number"
                      name="dailyLimit"
                      id="dailyLimit"
                      // placeholder="100"
                      placeholder="xyz121"
                      className="block w-full mt-1 rounded-md shadow-sm border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div> */}
                  {/* {quickbooksData?.getQuickBookDetails?.realmId && (
                    <div className="w-full">
                      <label className="block text-sm font-medium text-left text-blue-gray-900 opacity-70">
                        Connected Company
                      </label>
                      <input
                        disabled
                        type="text"
                        name="connectedRealmID"
                        value={
                          qbDetailsLoading
                            ? "Loading"
                            : quickbooksData?.getQuickBookDetails?.realmId
                        }
                        className="block w-full mt-1 rounded-md shadow-sm disabled:opacity-60 border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>
                  )} */}
                  <div className="w-full">
                    <label
                      htmlFor="realmId"
                      className="flex items-center gap-2 text-sm font-medium text-left text-blue-gray-900"
                    >
                      Realm ID (Company ID)
                      {/* <InformationCircleIcon className="w-4 h-4" /> */}
                    </label>
                    <input
                      required
                      type="text"
                      name="realmId"
                      id="realmId"
                      value={realmId}
                      onChange={(e) => setRealmId(e.target.value)}
                      placeholder="123456789"
                      className="block w-full mt-1 rounded-md shadow-sm border-blue-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="text-xs italic">
                      At a time only one company can be connected.
                    </div>
                  </div>
                  <Button
                    loading={submitting}
                    disabled={realmId.length < 5}
                    onClick={connectToQuickbooks}
                    variant={ButtonVariant.Green}
                  >
                    Connect to Quickbooks
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
