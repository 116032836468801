import {
  ArrowRightCircleIcon,
  CheckIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CardShipping, CardShortened, CardsInDelivery } from "@roadflex/types";
import router from "next/router";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";

export const cardsAlreadyDeliveredActionTemplate = (rowData: CardShortened) => {
  return (
    <Button
      variant={ButtonVariant.Transparent}
      size={ButtonSize.Small}
      className=""
      onClick={() => {
        router.push(`/card/${rowData?.id}`);
      }}
    >
      <ArrowRightCircleIcon className="w-5 h-5" aria-hidden="true" />
    </Button>
  );
};

export const cardNumberTemplate = (
  rowData: CardShortened | CardsInDelivery,
  setShowDeliveryDetails: (value: boolean) => void,
  setDeliveryDetails: (obj: CardShipping) => void,
  isMobile = false,
) => {
  const shipping = rowData?.cardShipping?.[0];
  return (
    <span className="flex items-center justify-start cursor-pointer">
      <span className="pr-2">{rowData.lastFour}</span>
      {isMobile && (
        <>
          {rowData?.status === "active" && (
            <span className="inline-flex items-center p-1 text-green-800 bg-green-100 rounded-full">
              <CheckIcon className="w-4 h-4"></CheckIcon>
            </span>
          )}
          {rowData?.status !== "active" && (
            <span className="inline-flex items-center p-1 text-red-800 bg-red-100 rounded-full">
              <XMarkIcon className="w-4 h-4"></XMarkIcon>
            </span>
          )}
        </>
      )}
      {shipping?.trackingNumber && (
        <span>
          <Button
            variant={ButtonVariant.Transparent}
            size={ButtonSize.Wrap}
            onClick={() => {
              setShowDeliveryDetails(true);
              setDeliveryDetails({
                deliveryService: shipping?.deliveryService,
                deliveryPartner: shipping?.deliveryPartner,
                deliveryStatus: shipping?.deliveryStatus,
                deliveryType: shipping?.deliveryType,
                eta: shipping?.eta,
                trackingNumber: shipping?.trackingNumber,
                trackingUrl: shipping?.trackingUrl,
              });
            }}
          >
            <InformationCircleIcon
              className={`w-5 h-5 text-brown-500`}
              aria-hidden="true"
            />
          </Button>
        </span>
      )}
    </span>
  );
};

export const statusTemplate = (rowData: CardsInDelivery) => {
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm 5xl:text-base font-medium capitalize
      ${
        rowData?.status === "active"
          ? "bg-green-100 text-green-800"
          : "bg-orange-100 text-orange-800"
      }
      `}
    >
      {rowData?.status || "-"}
    </span>
  );
};

export const smsSecurityTemplate = (rowData: CardShortened) => {
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm 5xl:text-base font-medium capitalize
      ${
        rowData?.smsSecurity
          ? "bg-green-100 text-green-800"
          : "bg-orange-100 text-orange-800"
      }
      `}
    >
      {rowData?.smsSecurity ? "Active" : "Inactive"}
    </span>
  );
};
