export const humanize = (str: string | undefined) => {
  if (str) {
    const frags = str.split("_");
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
  return "";
};

export const deHumanize = (str: string | undefined) => {
  if (str) {
    const frags = str.toLowerCase().split(" ");
    return frags.join("_");
  }
  return "";
};

export const humanizeEnum = (str: string | undefined) => {
  if (str) {
    const frags = str.split("_");
    for (let i = 0; i < frags.length; i++) {
      frags[i] =
        frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
    return frags.join(" ");
  }
  return "";
};

export const humanizeSpace = (str: string | undefined) => {
  if (str) {
    const frags = str.split(" ");
    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
  return "";
};

export const humanizeCamelCase = (inputString: string) => {
  let formattedString = inputString
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z][a-z])/g, " $1")
    .trim();
  formattedString = formattedString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return formattedString;
};
