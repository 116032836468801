/** @format */

import { useMutation } from "@apollo/client";
import { SAVE_PLAID_INFORMATION, UPLOAD_DOCUMENTS } from "@roadflex/graphql";
import { BankVerificationValues } from "@roadflex/types";
import { uploadFile } from "@roadflex/web-api";
import { useFormik } from "formik";
import { useRef } from "react";
import * as Yup from "yup";

type UseBankVerificationProps = {
  onSubmit: (
    res: { success: boolean } | null | boolean,
    err: Error | null,
  ) => void;
  initialValues: BankVerificationValues;
  validationSchema: Yup.AnyObjectSchema;
};

export const useBankVerification = ({
  onSubmit,
  initialValues,
  validationSchema,
}: UseBankVerificationProps) => {
  const [saveDocuments] = useMutation(UPLOAD_DOCUMENTS);
  const [savePlaidInfo] = useMutation(SAVE_PLAID_INFORMATION);
  const institution = useRef("");
  const publicToken = useRef("");

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    status,
    setStatus,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      let res;
      try {
        if (institution.current && publicToken.current) {
          const data = {
            businessId: value.businessId,
            institution: institution.current,
            publicToken: publicToken.current,
          };
          res = await savePlaidInfo({
            variables: { input: data },
          });
          if (!res.data.savePlaidInformation.success) {
            throw new Error(res.data.savePlaidInformation.message);
          }
        }
        if (value.statements.length === 3) {
          const statement1 = await uploadFile(
            value.statements[0] as File,
            "statement_1",
          );
          const statement2 = await uploadFile(
            value.statements[1] as File,
            "statement_2",
          );
          const statement3 = await uploadFile(
            value.statements[2] as File,
            "statement_3",
          );
          const data = {
            businessId: value.businessId,
            statements: [statement1, statement2, statement3],
            connectedBoth: value.plaidConnect && value.statements.length === 3,
          };
          res = await saveDocuments({
            variables: { data },
          });
        }

        onSubmit(
          res?.data?.uploadDocuments || res?.data?.savePlaidInformation,
          null,
        );
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    institution,
    publicToken,
    status,
    setStatus,
    ...rest,
  };
};
