/** @format */

import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import {
  useReadBusinessInformation,
  useReadCustomizedSettings,
  useReadPaymentSettings,
} from "@roadflex/react-hooks";
import { UserAuthType, UserBasicType } from "@roadflex/types";
import { Loader } from "../../loader";
import { PaymentsUI } from "./payment-methods.ui";

type PaymentsProps = {
  readCurrentUserBasic: UserBasicType;
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};
export const Payments = ({
  readCurrentUserBasic,
  readCurrentUserAuth,
  loading,
}: PaymentsProps) => {
  const { data: business } = useReadBusinessInformation();
  const { readPaymentSettings } = useReadPaymentSettings();

  const { readUserCustomizedSettings, readUserCustomizedSettingsLoading } =
    useReadCustomizedSettings(readCurrentUserBasic?.email);

  if (loading || readUserCustomizedSettingsLoading) {
    return <Loader />;
  }

  const customerType = readCurrentUserAuth?.fuelCard?.customerType;
  let allowACHPaymentMethod = false;
  let allowDebitCardPaymentMethod = true;
  let allowCreditCardPaymentMethod = false;
  if (
    customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE ||
    customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE
  ) {
    allowACHPaymentMethod =
      readUserCustomizedSettings?.readUserCustomizedSettings
        ?.allowACHPaymentMethod;
    allowDebitCardPaymentMethod =
      readUserCustomizedSettings?.readUserCustomizedSettings
        ?.allowDebitCardPaymentMethod;
    allowCreditCardPaymentMethod =
      readUserCustomizedSettings?.readUserCustomizedSettings
        ?.allowCreditCardPaymentMethod;
  }

  return (
    <PaymentsUI
      {...{
        readCurrentUserAuth,
        userDepositCode:
          readPaymentSettings?.readPaymentSettings?.userDepositCode,
        business: business?.readBusinessInformation,
        showRepaymentButton:
          readPaymentSettings?.readPaymentSettings?.showRepaymentButton ||
          false,
        customerType,
        allowACHPaymentMethod,
        allowDebitCardPaymentMethod,
        allowCreditCardPaymentMethod,
      }}
    />
  );
};
