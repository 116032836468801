/* eslint-disable no-console */

import { PersonalInformationValues } from "@roadflex/types";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { memo, useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../buttons";
import { Toast } from "../toast-message/toast";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_API_URL, PERSONA_ENVIRONMENT } = publicRuntimeConfig;

interface PersonaVerificationProps
  extends FormikProps<PersonalInformationValues> {
  isVerifyEnabled: boolean;
  isSubmittingOnboardStatus: boolean;
  id: string;
  personaInquiryId: string;
  personaInquirySessionToken: string;
  handleStorePersonaInquiryId: (inquiryId: string) => void;
  customerType: string;
}

export default function PersonaVerification({
  isVerifyEnabled,
  handleBlur,
  values,
  setFieldValue,
  isSubmitting,
  isSubmittingOnboardStatus,
  handleSubmit,
  id,
  errors,
  personaInquiryId,
  personaInquirySessionToken,
  handleStorePersonaInquiryId,
  customerType,
}: PersonaVerificationProps) {
  const [client, setClient] = useState<null | { open: () => void }>(null);
  const [loading, setLoading] = useState<boolean>(true);

  async function downloadDocument(
    fileName: string,
    fileId: string,
    fieldValue: string,
  ): Promise<boolean> {
    const data = {
      fileName,
      fileId,
    };

    const fileDownloadResponse = await fetch(
      `${NEXT_PUBLIC_API_URL}/api/getPersonaDocument`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then(async (response) => {
        await response
          .clone()
          .blob()
          .then((res) => {
            const file = new File([res], fileName);
            setFieldValue(fieldValue, file ? file : "");
          });
        await response.text();
        return true;
        // .then((a) => console.log("downloadDocument ", a.length));
      })
      .catch((err) => {
        Toast({ type: "error", message: err.message });
        setLoading(false);
        return false;
      });
    return fileDownloadResponse;
  }

  async function downloadInquiry(inquiryId: string) {
    setLoading(true);
    const data = {
      inquiryId,
    };

    await fetch(`${NEXT_PUBLIC_API_URL}/api/getPersonaInquiry`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        let isSubmit = true;
        let licenseFrontDownloadSuccess = false;
        let licenseBackDownloadSuccess = false;
        // eslint-disable-next-line no-unused-expressions
        await response.json().then(async (inquiryResponse) => {
          const verificationResp = inquiryResponse?.included.filter(
            (includedObject: {
              type: string;
              attributes: { status: string };
            }) =>
              (includedObject?.type === "verification/government-id" &&
                includedObject?.attributes?.status) === "passed",
          )[0].attributes;
          if (verificationResp) {
            let licenseFrontUrl = verificationResp?.frontPhotoUrl;
            if (!licenseFrontUrl) {
              licenseFrontUrl = verificationResp["front-photo-url"];
            }
            if (licenseFrontUrl) {
              const licenseFrontSplitUrl = licenseFrontUrl.split("/");
              const licenseFrontFileName = licenseFrontSplitUrl.pop();
              const licenseFrontFileId = licenseFrontSplitUrl.pop();
              licenseFrontDownloadSuccess = await downloadDocument(
                licenseFrontFileName,
                licenseFrontFileId,
                "fleetLicense",
              );
            } else {
              isSubmit = false;
              Toast({ type: "error", message: "No front license photo found" });
            }

            let licenseBackUrl = verificationResp?.backPhotoUrl;
            if (!licenseBackUrl) {
              licenseBackUrl = verificationResp["back-photo-url"];
            }
            if (licenseBackUrl) {
              const licenseBackSplitUrl = licenseBackUrl.split("/");
              const licenseBackFileName = licenseBackSplitUrl.pop();
              const licenseBackFileId = licenseBackSplitUrl.pop();
              licenseBackDownloadSuccess = await downloadDocument(
                licenseBackFileName,
                licenseBackFileId,
                "fleetLicenseBack",
              );
            } else {
              isSubmit = false;
              Toast({ type: "error", message: "No back license photo found" });
            }
          }
        });
        if (
          isSubmit &&
          licenseFrontDownloadSuccess &&
          licenseBackDownloadSuccess
        ) {
          handleSubmit();
        } else {
          Toast({
            type: "error",
            message:
              "Failed to fetch the license due to an error, please try verifying the license again",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        Toast({
          type: "error",
          message: "Something went wrong, please try again",
        });
        setLoading(false);
      });
  }

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    const Persona = require("persona");
    let clientInit;
    if (personaInquiryId) {
      clientInit = new Persona.Client({
        inquiryId: personaInquiryId, // Govt ID + Selfie
        referenceId: id,
        environment: PERSONA_ENVIRONMENT,
        sessionToken: personaInquirySessionToken,
        onReady: () => {
          // eslint-disable-next-line no-console
          console.log("ready");
          setLoading(false);
        },
        onEvent: (name: string, metadata: { [x: string]: never }) => {
          if (name === "start") {
            handleStorePersonaInquiryId(metadata.inquiryId);
          }
        },
        onComplete: ({
          inquiryId,
          status,
          fields,
        }: {
          inquiryId: string;
          status: string;
          fields: unknown;
        }) => {
          // eslint-disable-next-line no-console
          // console.log("onComplete", inquiryId, status, fields);
          setFieldValue("personaInquiryId", inquiryId);
          setFieldValue("personaFields", fields);
          downloadInquiry(inquiryId);
        },
        onCancel: ({
          inquiryId,
          sessionToken,
        }: {
          inquiryId: string;
          sessionToken: string;
        }) => {
          // eslint-disable-next-line no-console
          // console.log("onCancel", inquiryId, sessionToken);
        },
        onError: (error: unknown) => {
          Toast({
            type: "error",
            message: "Something went wrong, please try again",
          });
        },
      });
    } else {
      const GOVT_ID_TEMPLATE_ID = "itmpl_uVMZsa47AjP3BLvTsKYVJJ6h";
      const GOVT_ID_AND_SELFIE_TEMPLATE_ID = "itmpl_yzTYVhCRmxEvZzH9UEU2zerU";

      const finalTemplateId = customerType.includes("ENTERPRISE")
        ? GOVT_ID_TEMPLATE_ID
        : GOVT_ID_AND_SELFIE_TEMPLATE_ID;

      clientInit = new Persona.Client({
        templateId: finalTemplateId,
        referenceId: id,
        environment: PERSONA_ENVIRONMENT,
        onReady: () => {
          // eslint-disable-next-line no-console
          console.log("ready");
          setLoading(false);
        },
        onEvent: (name: string, metadata: { [x: string]: never }) => {
          if (name === "start") {
            handleStorePersonaInquiryId(metadata.inquiryId);
          }
        },
        onComplete: ({
          inquiryId,
          status,
          fields,
        }: {
          inquiryId: string;
          status: string;
          fields: unknown;
        }) => {
          // eslint-disable-next-line no-console
          // console.log("onComplete", inquiryId, status, fields);
          setFieldValue("personaInquiryId", inquiryId);
          setFieldValue("personaFields", fields);
          downloadInquiry(inquiryId);
        },
        onCancel: ({
          inquiryId,
          sessionToken,
        }: {
          inquiryId: string;
          sessionToken: string;
        }) => {
          // eslint-disable-next-line no-console
          // console.log("onCancel", inquiryId, sessionToken);
        },
        onError: (error: unknown) => {
          Toast({
            type: "error",
            message: "Something went wrong, please try again",
          });
        },
      });
    }

    setClient(clientInit);
  }, []);

  return (
    <div>
      <Button
        onClick={() => {
          client?.open();
        }}
        onBlur={handleBlur}
        className="w-full"
        size={ButtonSize.Normal}
        variant={ButtonVariant.Orange}
        loading={loading || isSubmitting || isSubmittingOnboardStatus}
        disabled={
          loading ||
          isSubmitting ||
          isSubmittingOnboardStatus ||
          !isVerifyEnabled ||
          errors?.firstName !== undefined ||
          errors?.lastName !== undefined ||
          errors?.dateOfBirth !== undefined ||
          errors?.executiveJobTitle !== undefined ||
          errors?.ssn !== undefined
        }
      >
        Verify Identity
      </Button>
    </div>
  );
}

export const Persona = memo(PersonaVerification);
