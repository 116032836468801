/** @format */

import { BUSINESS_TYPE } from "@roadflex/constants";
import { UpdateBusinessInformationValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { SyntheticEvent, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { TextInput } from "../../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UpdateBusinessUIProps
  extends FormikProps<UpdateBusinessInformationValues> {}

const UpdateBusinessUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleReset,
  ...rest
}: UpdateBusinessUIProps) => {
  const [update, setupdate] = useState(false);
  const toggle = () => setupdate((t) => !t);
  const submitAction = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
    toggle();
  };

  const discardChanges = () => {
    handleReset();
    setupdate(false);
  };

  return (
    <form
      onSubmit={submitAction}
      className="flex flex-col w-full text-sm md:text-base"
    >
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Business Information
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="business-name" className="block">
              <div className="font-medium">Business Name on Physical Cards</div>
              <div className="text-sm italic">
                {" "}
                (Only 1 business name per account. This name would appear on all
                cards.)
              </div>
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <TextInput
                disabled={!update}
                type="text"
                name="cardName"
                value={values.cardName}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.cardName}
                error={touched.cardName && Boolean(errors.cardName)}
                errorMessage={errors.cardName}
              ></TextInput>
            </div>
          </div>
          <div className="flex justify-end">
            {!update && (
              <Button
                onClick={toggle}
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
              >
                Update
              </Button>
            )}
            {update && (
              <div className="flex gap-x-2">
                <Button
                  onClick={discardChanges}
                  variant={ButtonVariant.Red}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type={ButtonType.Submit}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.AppSize}
                  loading={isSubmitting}
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          <div>
            <hr className="my-4 border-brown-500" />
            <p className="pb-3 text-sm text-gray-500">
              The following information was provided during onboarding and is
              not updatable.
            </p>

            <div className="flex flex-col gap-4 sm:flex-row flex-grow-1">
              <div className="w-full">
                <Select
                  label="Type of Business"
                  labelClass="font-medium"
                  value={values.type}
                  options={[
                    {
                      label: "-- Select --",
                      value: "",
                      disabled: true,
                    },
                  ].concat(
                    Object.keys(BUSINESS_TYPE).map((key) => {
                      return {
                        label: BUSINESS_TYPE[key],
                        value: key,
                        disabled: false,
                      };
                    }),
                  )}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => setFieldValue("type", e.value)}
                  onBlur={() => setFieldTouched("type", true)}
                  placeholder="Select"
                  touched={touched.type}
                  error={touched.type && Boolean(errors.type)}
                  errorMessage={errors.type}
                  disabled
                ></Select>
              </div>

              <div className="w-full">
                <TextInput
                  label="Business Name"
                  labelClass="font-medium"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="title"
                  error={touched.title && Boolean(errors.title)}
                  errorMessage={errors.title}
                  touched={touched.title}
                  variant="medium"
                  disabled
                ></TextInput>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-2 sm:flex-row flex-grow-1">
              <div className="w-full">
                <TextInput
                  label="EIN"
                  labelClass="font-medium"
                  name="ein"
                  value={values.ein}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="ein"
                  error={touched.ein && Boolean(errors.ein)}
                  errorMessage={errors.ein}
                  touched={touched.ein}
                  variant="medium"
                  disabled
                ></TextInput>
              </div>

              <div className="w-full">
                <TextInput
                  label="MC Number"
                  labelClass="font-medium"
                  name="mc"
                  value={values.mc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="mc"
                  error={touched.mc && Boolean(errors.mc)}
                  errorMessage={errors.mc}
                  touched={touched.mc}
                  variant="medium"
                  placeholder="MC Number"
                  disabled
                ></TextInput>
              </div>

              <div className="w-full">
                <TextInput
                  label="USDOT Number"
                  labelClass="font-medium"
                  name="dot"
                  value={values.dot}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="dot"
                  error={touched.dot && Boolean(errors.dot)}
                  errorMessage={errors.dot}
                  touched={touched.dot}
                  variant="medium"
                  placeholder="USDOT Number"
                  disabled
                ></TextInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
export default UpdateBusinessUI;
