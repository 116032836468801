/** @format */

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  CardsFilterOptions,
  DriversFilterOptions,
  TransactionType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { useRouter } from "next/router";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import {
  DataTable,
  DataTablePFSEvent,
  DataTableRowToggleParams,
} from "primereact/datatable";
import { useRef, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { dateFilterTemplate } from "../../../datatable";
import {
  dateTemplate,
  driverFilterTemplate,
  vehicleFilterTemplate,
} from "../../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import {
  cardFilterTemplate,
  cardLastFourTemplate,
  driverTemplate,
  flaggedTransactionReason,
  merchantCategory,
  stateTemplate,
  transactionAmountTemplate,
  transactionsActionTemplate,
  vehicleTemplate,
} from "../../../datatable/transaction-table-templates/table-templates";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

interface TransactionsFlaggedUIProps {
  flaggedTransactionData: {
    transactions: TransactionType[];
    totalCount: number;
  };
  transactionFilterOptions: {
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  };
  flaggedTransactionsLazyParams: DataTablePFSEvent;
  setFlaggedTransactionsLazyParams: (value: DataTablePFSEvent) => void;
  transactionLoading: boolean;
  readCurrentUserAuth: UserAuthType;
}

const dateFilterApplied = (e: unknown) => {
  const event = e as {
    field: string;
    constraints: {
      operator: FilterOperator;
      constraints: [
        { value: null | string | Date; matchMode: FilterMatchMode },
      ];
    };
  };
};

const TransactionsFlaggedUI = ({
  flaggedTransactionData,
  transactionFilterOptions,
  flaggedTransactionsLazyParams,
  setFlaggedTransactionsLazyParams,
  transactionLoading,
  readCurrentUserAuth,
}: TransactionsFlaggedUIProps) => {
  const transactionRef = useRef<DataTable>(null);
  const { isMobile } = usePlatformDetect();
  const [expandedRows, setExpandedRows] = useState<TransactionType[]>([]);
  const router = useRouter();
  const backToTransactionsPanel = () => {
    router.push("/transactions");
  };

  const onPageAndSortTransactions = (event: DataTablePFSEvent) => {
    setFlaggedTransactionsLazyParams(event);
  };

  const onFilterTransactions = (event: DataTablePFSEvent) => {
    event.first = 0; // eslint-disable-line no-param-reassign
    setFlaggedTransactionsLazyParams(event);
  };

  const rowExpansionTransactionTemplate = (data: TransactionType) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="mb-4 font-semibold text-gray-800">
          {data.merchantName} {/* Merchant Name */}
        </div>
        <div className="flex flex-col gap-2 text-sm text-gray-600">
          <div>
            Card: {cardLastFourTemplate(data)}
            {/* Last four digits of the card used */}
          </div>
          <div>Merchant Category: {merchantCategory(data)}</div>
          <div>
            Driver: {driverTemplate(data)} {/* Driver name */}
          </div>
          <div>
            Vehicle: {vehicleTemplate(data)} {/* Vehicle detail */}
          </div>
          <div>
            Location: {stateTemplate(data)} {/* Merchant location */}
          </div>
          <div>
            Flagged Reason:{" "}
            <span className="font-semibold">
              {flaggedTransactionReason(data)}
            </span>{" "}
          </div>
          <div className="flex items-center">
            More Details:{transactionsActionTemplate(data)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex flex-col w-full rounded-md md:my-12">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row">
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.GrayOutline}
              onClick={backToTransactionsPanel}
              className="pl-0 ml-[-6px] border-0"
            >
              <span className="flex items-center h-5">
                <ChevronRightIcon
                  className={
                    "-rotate-180 h-5 w-5 transform  ease-in duration-300"
                  }
                  aria-hidden="true"
                />
              </span>
              Back
            </Button>
            <div className="ml-4 text-lg font-semibold">
              Flagged Transactions
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 mt-5 overflow-hidden bg-white rounded-md shadow md:p-8 md:mt-0 step-4">
        <div className="font-semibold text-brown-500 md:text-lg">
          Flagged Transactions
        </div>
        <div className="flex-col h-full mt-4 lex">
          {/* <Table data={transactionList} rowsPerPage={10} /> */}
          <DataTable
            pageLinkSize={isMobile() ? 2 : 3}
            size="small"
            ref={transactionRef}
            stripedRows
            value={flaggedTransactionData.transactions}
            loading={transactionLoading}
            paginator
            dataKey="id"
            paginatorTemplate={newPaginationTemplate}
            responsiveLayout={isMobile() ? "scroll" : "scroll"}
            emptyMessage="No available records"
            className="w-auto min-w-full custom-table"
            style={{
              fontFamily: "Inter",
            }}
            lazy
            rows={flaggedTransactionsLazyParams.rows}
            filters={flaggedTransactionsLazyParams.filters}
            sortField={flaggedTransactionsLazyParams.sortField}
            sortOrder={flaggedTransactionsLazyParams.sortOrder}
            sortMode={"single"}
            first={flaggedTransactionsLazyParams.first}
            totalRecords={flaggedTransactionData.totalCount}
            onPage={onPageAndSortTransactions}
            onSort={onPageAndSortTransactions}
            onFilter={onFilterTransactions}
            rowExpansionTemplate={rowExpansionTransactionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e: DataTableRowToggleParams) =>
              setExpandedRows(e.data as TransactionType[])
            }
          >
            <Column
              filter
              // filterElement={(options) => (
              //   <CustomDateFilter options={options} />
              // )}
              filterElement={dateFilterTemplate}
              filterMenuClassName="custom-filter"
              sortable
              dataType="date"
              filterField="date"
              field="date"
              header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              body={(r) =>
                dateTemplate(
                  r.authorizationDate,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                )
              }
              showFilterOperator={false}
              showFilterMatchModes={true}
              showAddButton={false}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              onFilterApplyClick={dateFilterApplied}
            />
            {!isMobile() && (
              <Column
                // filter
                sortable
                filterPlaceholder="Search by Name"
                field="merchantName"
                header="Merchant"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
              />
            )}
            {!isMobile() && (
              <Column
                sortable
                filterPlaceholder="Search by State"
                sortField="merchantState"
                header="Merchant Category"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={merchantCategory}
              />
            )}
            {!isMobile() && (
              <Column
                // filter
                sortable
                filterPlaceholder="Search by State"
                sortField="merchantState"
                header="Location"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={stateTemplate}
              />
            )}

            <Column
              showFilterMatchModes={false}
              filterMenuStyle={{
                width: "14rem",
              }}
              filter
              sortable
              header="Card"
              field="lastFour"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              filterMenuClassName="custom-filter"
              filterElement={(e) =>
                cardFilterTemplate(
                  e,
                  transactionFilterOptions.cardsFilterOptions,
                )
              }
              body={cardLastFourTemplate}
            />
            <Column
              // filter
              sortable
              field="transactionAmount"
              header="Amount"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={transactionAmountTemplate}
            />

            <Column
              showFilterMatchModes={false}
              filterMenuStyle={{
                width: "14rem",
              }}
              filter
              sortable
              body={driverTemplate}
              header="Driver"
              field="driver"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              filterMenuClassName="custom-filter"
              filterElement={(e) =>
                driverFilterTemplate(
                  e,
                  transactionFilterOptions.driversFilterOptions,
                )
              }
            />

            {!isMobile() && (
              <Column
                showFilterMatchModes={false}
                filterMenuStyle={{
                  width: "14rem",
                }}
                filter
                sortable
                body={vehicleTemplate}
                header="Vehicle"
                field="vehicle"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                filterMenuClassName="custom-filter"
                filterElement={(e) =>
                  vehicleFilterTemplate(
                    e,
                    transactionFilterOptions.vehiclesFilterOptions,
                  )
                }
              />
            )}

            {!isMobile() && (
              <Column
                // filter
                sortable
                field="flagReason"
                header="Flagged Reason"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={flaggedTransactionReason}
              />
            )}
            {!isMobile() && (
              <Column
                // filter
                field="details"
                header="Details"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={transactionsActionTemplate}
              />
            )}
            {isMobile() && (
              <Column
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                expander
                style={{ width: "3em" }}
              />
            )}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default TransactionsFlaggedUI;
