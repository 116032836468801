/** @format */

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Tooltip } from "primereact/tooltip";
import { forwardRef, ReactNode, useEffect, useState } from "react";

interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  error?: boolean;
  errorMessage?: string;
  touched?: boolean;
  variant?: "small" | "medium" | "large" | "responsive";
  className?: string;
  required?: boolean;
  labelClass?: string;
}

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  (
    {
      label,
      name,
      value,
      onChange,
      onBlur,
      checked,
      error = false,
      errorMessage = "",
      touched = false,
      variant = "medium",
      className,
      required = false,
      labelClass = "",
      ...props
    },
    ref,
  ) => {
    const [variantValue, setVariantValue] = useState(variant);

    useEffect(() => {
      const updateVariant = () => {
        if (window.innerWidth >= 768) {
          setVariantValue("large");
        } else {
          setVariantValue("medium");
        }
      };
      if (variant === "responsive") {
        // Initial check
        updateVariant();

        // Add event listener
        window.addEventListener("resize", updateVariant);

        // Cleanup event listener on component unmount
      }
      return () => window.removeEventListener("resize", updateVariant);
    }, [variant]);

    const radioClass = classNames(
      touched && error
        ? "border-red-500 focus:border-red-500 border-2"
        : "border-brown-500",
      "border placeholder-brown-700 placeholder-opacity-50 disabled:opacity-50 disabled:hover:cursor-not-allowed custom-radio-button rounded-full focus:outline-none bg-white ",
      {
        "w-3 h-3": variantValue === "small",
        "w-4 h-4": variantValue === "medium",
        "w-5 h-5": variantValue === "large",
      },
      className,
    );

    const labelClassName = classNames(
      "inline-flex items-center",
      {
        "text-sm": variantValue === "small",
        "text-base": variantValue === "medium" || variantValue === "large",
      },
      labelClass,
    );

    const errorIconClass = classNames("flex items-center cursor-pointer", {
      "w-3.5 h-3.5 ml-2.5": variantValue === "small",
      "w-4 h-4 ml-3": variantValue === "medium",
      "w-5 h-5 ml-4": variantValue === "large",
    });

    return (
      <div className="relative flex items-center">
        <label className={labelClassName}>
          <input
            type="radio"
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            checked={checked}
            className={radioClass}
            ref={ref}
            {...props}
          />
          {label &&
            (typeof label === "string" ? (
              <span className="ml-3">{label}</span>
            ) : (
              <span className="ml-3">{label}</span>
            ))}
          {required && "*"}
        </label>
        {touched && error && (
          <div className={errorIconClass}>
            <Tooltip
              target={`.${name}-error`}
              position="top"
              className={classNames("red-tooltip", {
                "text-sm": variantValue === "small",
                "text-base":
                  variantValue === "medium" || variantValue === "large",
              })}
              content={errorMessage}
            ></Tooltip>
            <ExclamationCircleIcon
              className={`text-red-500 ${name}-error`}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    );
  },
);

export default RadioInput;
