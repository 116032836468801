import { useMutation, useQuery } from "@apollo/client";
import { appDriverValidationSchema } from "@roadflex/constants";
import {
  CREATE_DRIVER,
  DELETE_DRIVERS,
  INVITE_DRIVER_TO_APP,
  READ_DRIVERS,
  UPDATE_DRIVER,
} from "@roadflex/graphql";
import {
  DriverType,
  FleetDriverExtended,
  IdListInputType,
} from "@roadflex/types";
/* eslint-disable dot-notation */
/** @format */
import { useFormik } from "formik";

type useCreateDriverProps = {
  initialValues: DriverType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useInviteDriverToAppProps = {
  initialValues: IdListInputType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useUpdateDriverProps = {
  initialValues: DriverType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useDeleteDriversProps = {
  initialValues: IdListInputType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useCreateDriver = ({
  onSubmit,
  initialValues,
}: useCreateDriverProps) => {
  const [createDriverFn] = useMutation<{
    createDriver: {
      code: string;
      message: string;
    };
  }>(CREATE_DRIVER);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appDriverValidationSchema,
    onSubmit: async (value) => {
      const { status, ...rest } = value;
      const data = {
        ...rest,
        phoneNumber: rest.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
      };
      try {
        const res = await createDriverFn({
          variables: { data },
        });
        if (res?.data?.createDriver) {
          onSubmit(res?.data?.createDriver, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
export const useInviteDriverToApp = ({
  onSubmit,
  initialValues,
}: useInviteDriverToAppProps) => {
  const [inviteDriverToAppFn] = useMutation<{
    inviteDriverToApp: {
      code: string;
      message: string;
    };
  }>(INVITE_DRIVER_TO_APP, {
    refetchQueries: [READ_DRIVERS],
  });

  const {
    handleSubmit: inviteDriverToAppHandleSubmit,
    isSubmitting: inviteDriverToAppIsSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm: inviteDriverToAppResetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await inviteDriverToAppFn({
          variables: {
            data: { idList: value.idList },
          },
        });
        if (res?.data?.inviteDriverToApp) {
          onSubmit(res?.data?.inviteDriverToApp, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    inviteDriverToAppHandleSubmit,
    inviteDriverToAppIsSubmitting,
    inviteDriverToAppResetForm,
    ...restFormikValues,
  };
};
export function useReadDrivers(idList: string[]) {
  const {
    data: getDriversData,
    refetch: getDriversRefetch,
    loading: getDriversLoading,
    ...rest
  } = useQuery<{
    readDrivers: {
      code: string;
      message: string;
      drivers: FleetDriverExtended[];
    };
  }>(READ_DRIVERS, {
    fetchPolicy: "no-cache",
    variables: {
      data: { idList: idList },
    },
    notifyOnNetworkStatusChange: true,
  });
  return {
    getDriversData,
    getDriversRefetch,
    getDriversLoading,
    ...rest,
  };
}
export const useUpdateDriver = ({
  onSubmit,
  initialValues,
}: useUpdateDriverProps) => {
  const [updateDriversFn] = useMutation<{
    updateDriver: {
      code: string;
      message: string;
    };
  }>(UPDATE_DRIVER, {
    refetchQueries: [READ_DRIVERS],
  });

  const { ...restFormikValues } = useFormik({
    initialValues,
    validationSchema: appDriverValidationSchema,
    onSubmit: async (value) => {
      try {
        const data: DriverType = {
          id: value.id,
          name: value.name,
          email: value.email,
          phoneNumber: value.phoneNumber?.trim().match(/\d+/g)?.join("") || "",
          notes: value.notes,
          inviteDriver: value.inviteDriver,
        };
        const res = await updateDriversFn({
          variables: {
            data,
          },
        });
        if (res?.data?.updateDriver) {
          onSubmit(res?.data?.updateDriver, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    ...restFormikValues,
  };
};
export const useDeleteDrivers = ({
  onSubmit,
  initialValues,
}: useDeleteDriversProps) => {
  const [deleteDriversFn] = useMutation<{
    deleteDrivers: {
      code: string;
      message: string;
    };
  }>(DELETE_DRIVERS, {
    refetchQueries: [READ_DRIVERS],
  });

  const {
    handleSubmit: deleteDriverHandleSubmit,
    values: deleteDriverValues,
    setValues: deleteDriverSetValues,
    isSubmitting: deleteDriverIsSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteDriversFn({
          variables: {
            data: { idList: value.idList },
          },
        });
        if (res?.data?.deleteDrivers) {
          onSubmit(res?.data?.deleteDrivers, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    deleteDriverHandleSubmit,
    deleteDriverValues,
    deleteDriverIsSubmitting,
    deleteDriverSetValues,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
