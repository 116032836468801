/** @format */
import { NetworkStatus } from "@apollo/client";
import { useReadRepaymentPlan } from "@roadflex/react-hooks";
import { Loader } from "../../../loader";
import RepaymentPlanUI from "./repayment-plan.ui";

export function RepaymentPlan() {
  const { repaymentplanDetails, repaymentplanDetailsLoading, networkStatus } =
    useReadRepaymentPlan();

  if (repaymentplanDetailsLoading && networkStatus === NetworkStatus.loading) {
    // This is the initial loading state
    return <Loader></Loader>;
  }
  return (
    <RepaymentPlanUI
      repaymentplanDetails={repaymentplanDetails?.getRepayment}
    ></RepaymentPlanUI>
  );
}
