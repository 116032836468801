import {
  useCreateAuthorizationBearer,
  useDeleteAuthorizationBearer,
  useReadAuthorizationBearer,
} from "@roadflex/react-hooks";
import Toast from "../../../../../../libs/react-components/src/toast-message/toast";
import DeveloperControlCenterUI from "./developer-control-center.ui";

const DeveloperControlCenter = () => {
  const { values, handleChange, handleSubmit, isSubmitting } =
    useCreateAuthorizationBearer({
      onSubmit(res, err) {
        if (err) {
          Toast({ type: "error", message: err?.message });
          return;
        }
        if (res?.code === "200") {
          Toast({ type: "success", message: res?.message });
        }
      },
    });

  const {
    values: deleteValues,
    handleChange: deleteHandleChange,
    handleSubmit: deleteHandleSubmit,
    isSubmitting: deleteIsSubmitting,
  } = useDeleteAuthorizationBearer({
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      }
    },
  });

  const { data, refetch, loading } = useReadAuthorizationBearer();
  return (
    <DeveloperControlCenterUI
      {...{
        handleSubmit,
        deleteHandleSubmit,
        isSubmitting,
        deleteIsSubmitting,
        authorizationBearer: data?.readAuthorizationBearer?.authorizationBearer,
      }}
    />
  );
};
export default DeveloperControlCenter;
