/** @format */

import { useMutation } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { PLAID_REAUTHORIZE_REQUIRED } from "@roadflex/constants";
import {
  READ_ACCOUNT_STATUS,
  READ_BUSINESS_INFORMATION,
  SAVE_PLAID_INFO_PAYMENT,
} from "@roadflex/graphql";
import { useLinkToken } from "@roadflex/react-hooks";
import { Tooltip } from "primereact/tooltip";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";

interface PlaidLinkUpdateUIProps {
  plaidToken?: {
    linkToken: string;
  };
  plaidTokenLoading: boolean;
  plaidId: string;
  businessId?: string;
  plaidError: string;
  setModal?: (value: boolean) => void;
}

const PlaidLinkUpdateUI = ({
  plaidToken,
  plaidId,
  businessId,
  plaidError,
  setModal,
  plaidTokenLoading,
}: PlaidLinkUpdateUIProps) => {
  const [savePlaidInfo, { loading }] = useMutation(SAVE_PLAID_INFO_PAYMENT, {
    refetchQueries: [READ_BUSINESS_INFORMATION, READ_ACCOUNT_STATUS],
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const config: PlaidLinkOptions = {
    token: plaidToken?.linkToken || "",
    onSuccess: async (token, res) => {
      // onSuccess
      try {
        const response = await savePlaidInfo({
          variables: {
            input: {
              isUpdateMode: true,
              plaidId: plaidId,
              publicToken: token || "",
              institution: res?.institution?.name || "",
              businessId: businessId,
            },
          },
        });
        if (response?.data?.savePlaidInformationFromPayment?.code === "200") {
          Toast({ type: "success", message: "Re-authentication complete" });
          if (setModal) {
            setModal(false);
          }
        } else {
          Toast({
            type: "error",
            message:
              response?.data?.savePlaidInformationFromPayment?.message ||
              "Something went wrong with re-authentication!",
          });
        }
      } catch (err) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
      }
    },
    // onExit
    // onEvent
  };

  const { open, ready, error, exit } = usePlaidLink(config);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {PLAID_REAUTHORIZE_REQUIRED.includes(plaidError) ? (
        <div className="flex flex-row">
          <Tooltip
            target=".error-tooltip"
            position="top"
            className="red-tooltip"
            content="**Re-authentication required!"
          ></Tooltip>
          <Tooltip
            target=".warning-tooltip"
            position="top"
            className="yellow-tooltip"
            content="Refresh the page if button remains disabled"
          ></Tooltip>
          <Button
            variant={ButtonVariant.Black}
            size={ButtonSize.Normal}
            className="flex flex-row items-center w-auto"
            onClick={() => open()}
            disabled={!ready || plaidTokenLoading}
            loading={loading || plaidTokenLoading}
          >
            {loading ? <>Authenticating...</> : <>Re-authenticate Account</>}
          </Button>
          <div className="flex items-center ml-3 cursor-pointer">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-700 error-tooltip"
              aria-hidden="true"
            />
          </div>
          {!ready && (
            <div className="flex items-center ml-3 cursor-pointer">
              <ExclamationCircleIcon
                className="w-5 h-5 text-yellow-400 warning-tooltip"
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      ) : (
        <Button
          variant={ButtonVariant.GrayOutline}
          size={ButtonSize.Small}
          className="flex flex-row items-center w-auto italic"
          onClick={() => open()}
          disabled={!ready || plaidTokenLoading}
          loading={loading || plaidTokenLoading}
        >
          {loading ? <>Authenticating...</> : <>Sync accounts</>}
        </Button>
      )}
    </>
  );
};

const PlaidLinkUpdate = ({
  plaidId,
  businessId,
  plaidError,
  setModal,
}: {
  plaidId: string;
  businessId?: string;
  plaidError: string;
  setModal?: (value: boolean) => void;
}) => {
  const { linkToken: plaidToken, loading: plaidLoading } = useLinkToken({
    id: plaidId,
    isUpdateMode: true,
    isWithTransaction: false,
  });

  return (
    <PlaidLinkUpdateUI
      plaidToken={plaidToken}
      plaidTokenLoading={plaidLoading}
      plaidId={plaidId}
      businessId={businessId}
      plaidError={plaidError}
      setModal={setModal}
    />
  );
};

export default PlaidLinkUpdate;
