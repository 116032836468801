/** @format */

import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { PREPAID_DEPOSIT_SETUP_FEE } from "@roadflex/constants";
import { useReadBusinessInformation } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { DepositUI } from "./deposits.ui";

type DepositProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};

export const Deposit = ({ readCurrentUserAuth, loading }: DepositProps) => {
  const {
    data: business,
    refetch: refetchBusiness,
    loading: businessDataLoading,
  } = useReadBusinessInformation();

  const [payAmount, setpayAmount] = useState("");

  let depositAmount =
    (readCurrentUserAuth?.fuelCard?.creditLimit || 2500) / 100;

  let phrasing = "";

  const roles = readCurrentUserAuth?.fuelCard?.customerType
    ? [readCurrentUserAuth?.fuelCard?.customerType]
    : [];

  const isEnterpriseUser =
    roles.includes(ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE) ||
    roles.includes(ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE);

  if (isEnterpriseUser) {
    depositAmount =
      (readCurrentUserAuth?.fuelCard?.metadata?.setupFeeOrDeposit || 0) / 100;
    const allowDebitCard =
      readCurrentUserAuth?.fuelCard?.metadata?.paymentMethods?.allowDebitCard;
    const allowCreditCard =
      readCurrentUserAuth?.fuelCard?.metadata?.paymentMethods?.allowCreditCard;
    if (allowCreditCard && allowDebitCard) {
      phrasing = "Debit/Credit Card";
    } else if (allowCreditCard && !allowDebitCard) {
      phrasing = "Credit Card";
    } else if (!allowCreditCard && allowDebitCard) {
      phrasing = "Debit Card";
    }
  }

  const isPrepaidUser =
    roles.includes(ENUM_CUSTOMER_TYPE.PREPAID) ||
    roles.includes(ENUM_CUSTOMER_TYPE.PREPAID_PLUS);

  if (isPrepaidUser) {
    depositAmount = PREPAID_DEPOSIT_SETUP_FEE / 100;
  }

  const validateValue = (value: string | undefined): void => {
    const rawValue = value === undefined ? "undefined" : value;
    setpayAmount(rawValue || " ");
    if (Number.isNaN(Number(value)) && value !== undefined) {
      Toast({ type: "error", message: "Enter a valid number" });
    }
  };

  useEffect(() => {
    const depositAmountVal =
      depositAmount === undefined ? "undefined" : depositAmount;
    setpayAmount(String(depositAmountVal) || " ");
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <DepositUI
      {...{
        depositAmount,
        business: business?.readBusinessInformation,
        validateValue,
        payAmount,
        customerType: readCurrentUserAuth?.fuelCard?.customerType,
        phrasing,
      }}
    />
  );
};
