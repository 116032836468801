/** @format */
import { isEmpty, truncateString } from "@roadflex/constants";
import { AdminDataAnalysisType } from "@roadflex/types";
import { convertISOStringToDate, humanize } from "@roadflex/utils";
import { dollarFormatter, phoneFormatter } from "./inputFormatter";

export const applicantMapper = (applicant: any, panelNumber: number) => {
  const newApplicant = { ...applicant };
  newApplicant.email = applicant?.email;
  newApplicant.id = applicant?.userProfileId || applicant?.userLeadId;
  newApplicant.firstName = applicant?.firstName;
  newApplicant.lastName = applicant?.lastName;
  newApplicant.phoneNumber = applicant?.phoneNumber;
  newApplicant.isEmailVerified = applicant?.userProfile?.isEmailVerified;
  newApplicant.fullName = `${applicant?.firstName} ${applicant?.lastName}`;
  newApplicant.companyName = applicant?.title;
  newApplicant.businessType = applicant?.business?.type;
  newApplicant.createdAt = applicant.createdAt
    ? new Date(applicant.createdAt)
    : null;
  newApplicant.updatedAt = applicant.updatedAt
    ? new Date(applicant.updatedAt)
    : null;
  newApplicant.lastActionDate = applicant.statusUpdatedAt
    ? new Date(applicant.statusUpdatedAt)
    : null;
  newApplicant.submittedAt = applicant.submittedAt
    ? new Date(applicant.submittedAt)
    : newApplicant.lastActionDate;
  newApplicant.statusUpdatedAt = applicant.statusUpdatedAt
    ? new Date(applicant.statusUpdatedAt)
    : null;
  newApplicant.panelNumber = panelNumber;
  newApplicant.lendflowStatus =
    applicant?.business?.userLendFlowData?.status === "COMPLETED";
  newApplicant.lendflowStatusString =
    applicant?.business?.userLendFlowData?.status || "-";
  newApplicant.underwritingState =
    applicant?.business?.businessUnderwritingProcess?.state || "-";
  newApplicant.underwritingStatus = applicant?.status || "-";
  newApplicant.cardHoldersMembershipStatusString =
    applicant?.cardHolderStatus || "";
  newApplicant.cardHoldersMembershipId = applicant?.cardHoldersMembership?.id;
  newApplicant.formattedPhoneNumber = phoneFormatter(applicant?.phoneNumber);
  newApplicant.creditLimit = applicant?.creditLimit
    ? applicant?.creditLimit / 100
    : null;
  newApplicant.formattedCreditLimit = newApplicant.creditLimit
    ? dollarFormatter(newApplicant.creditLimit)
    : null;
  newApplicant.message = applicant?.userProductStatusMessage;
  newApplicant.underwritingErrorMessage = applicant?.underwritingMessage;
  newApplicant.userLeadId = applicant?.userLeadId;
  newApplicant.customerSource = applicant?.customerSource;
  newApplicant.salesAdminFullName = `${applicant?.salesAdminFirstName || "-"} ${
    applicant?.salesAdminLastName || ""
  }`;
  return newApplicant;
};

export const customerDashboardExportsMapper = (
  applicant: AdminDataAnalysisType,
) => {
  const newApplicant = { ...applicant };

  newApplicant.approvedDate = convertISOStringToDate(
    new Date(applicant?.approvedDate).toString(),
  );

  const outstandingAmt = applicant?.outstandingAmount || 0;
  newApplicant.convertedOutstandingAmount = dollarFormatter(
    Number(outstandingAmt) / 100,
  );
  return newApplicant;
};

export const activityLogMapper = (log: any) => {
  const newLogObj = {
    id: log.id,
    activity: log.activity,
    activityDetails: log.activityDetails,
    activityType: log.activityType,
    activityTypeString: log.activityType,
    createdAt: log.createdAt,
    fullName: "",
    email: log.email || log?.user?.email || "",
    parentEmail: log.parentEmail || log?.parent?.email || "",
  };

  if (!isEmpty(log.admin)) {
    newLogObj.fullName = `${truncateString(
      log?.admin?.firstName,
      36,
    )} ${truncateString(log?.admin?.lastName, 36)}`;
  }
  if (isEmpty(log.admin)) {
    if (log?.parentFirstName) {
      newLogObj.fullName = `${truncateString(
        log?.parentFirstName,
        36,
      )} ${truncateString(log?.parentLastName, 36)}`;
    } else {
      newLogObj.fullName = `${log?.parent.firstName} ${log?.parent.lastName}`;
    }
  }
  const activityType = log?.activityType || "";
  newLogObj.activityTypeString = humanize(activityType).toLowerCase();
  return newLogObj;
};
