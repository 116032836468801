/** @format */

import { useLazyQuery } from "@apollo/client";
import { TAG_ANALYSIS_DATA } from "@roadflex/graphql";
import { useReadAccountTag } from "@roadflex/react-hooks";
import { TransactionType, UserAuthType } from "@roadflex/types";
import {
  appChartsGraphSorter,
  getEndOfWeek,
  getMinDate,
  getStartOfWeek,
  getTimeZoneOffset,
} from "@roadflex/utils";
import { addMilliseconds } from "date-fns";
import { CalendarChangeParams } from "primereact/calendar";
import { DropdownChangeParams } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { Loader } from "../../../loader";
import { Toast } from "../../../toast-message/toast";
import TagAnalysisUI from "./tag-analysis.ui";

export const TagAnalysis = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }
  const [dates, setDates] = useState<Date[]>([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  const displayModes = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
  ];
  const [selectedDisplayMode, setSelectedDisplayMode] = useState(
    displayModes[0].value,
  );
  const [transactionTags, setTransactionTags] = useState([]);
  const [cardTags, setCardTags] = useState([]);
  const [driverTags, setDriverTags] = useState([]);
  const [vehicleTags, setVehicleTags] = useState([]);
  const [selectedTransactionTags, setSelectedTransactionTags] = useState<
    string[]
  >([]);
  const [selectedCardTags, setSelectedCardTags] = useState<string[]>([]);
  const [selectedDriverTags, setSelectedDriverTags] = useState<string[]>([]);
  const [selectedVehicleTags, setSelectedVehicleTags] = useState<string[]>([]);

  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);
  const [startDate, setStartDate] = useState<Date>(minDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [gmvGraphData, setGmvGraphData] = useState<Array<[string, string]>>([]);
  const [transactionCountGraphData, setTransactionCountGraphData] = useState<
    Array<[string, string]>
  >([]);
  const [transactionList, setTransactionList] = useState<TransactionType[]>([]);
  const [tagAnalysisDataFn, { loading: tagAnalysisDataLoading }] = useLazyQuery(
    TAG_ANALYSIS_DATA,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data: {
        readTagAnalysisData: {
          code: string;
          transactions: TransactionType[];
          gmvGraphData: Array<[string, string]>;
          transactionCountGraphData: Array<[string, string]>;
          message: string;
        };
      }) => {
        if (data?.readTagAnalysisData?.code === "200") {
          setTransactionList(data?.readTagAnalysisData?.transactions || []);
          setGmvGraphData(data?.readTagAnalysisData?.gmvGraphData || []);
          setTransactionCountGraphData(
            data?.readTagAnalysisData?.transactionCountGraphData || [],
          );
        } else {
          Toast({
            type: "error",
            message: data?.readTagAnalysisData?.message || "",
          });
          setTransactionList([]);
        }
      },
      onError: (err: Error) => {
        Toast({
          type: "error",
          message: `Tag analysis: ${err.message}`,
        });
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: accountTagsData, loading: accountTagsDataLoading } =
    useReadAccountTag({});

  const fetchTagAnalysisData = (
    startDt: Date,
    endDt: Date,
    displayMode: string,
  ) => {
    if (startDt && endDt) {
      let finalStartDate = startDt;
      let finalEndDate = endDt;

      if (displayMode === "week") {
        finalStartDate = getStartOfWeek(startDt);
        finalEndDate = getEndOfWeek(endDt);
      }

      setDates([finalStartDate, finalEndDate]);
      tagAnalysisDataFn({
        variables: {
          data: {
            transactionTags: selectedTransactionTags,
            cardTags: selectedCardTags,
            driverTags: selectedDriverTags,
            vehicleTags: selectedVehicleTags,
            dateFilter: {
              startDate: addMilliseconds(
                finalStartDate,
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
              endDate: addMilliseconds(
                finalEndDate,
                (-getTimeZoneOffset(
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                ) +
                  getTimeZoneOffset(tz)) *
                  60000,
              ),
            },
          },
        },
      });
    }
  };

  const onDisplayModeChange = (e: DropdownChangeParams) => {
    const displayMode = e.value;
    setSelectedDisplayMode(displayMode);
    if (displayMode === "week") {
      fetchTagAnalysisData(dates[0], dates[1], displayMode);
    }
  };

  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    if (dateArray[0] && !dateArray[1]) {
      const startDateTemp = new Date(dateArray[0]);
      const endDateTemp = new Date(dateArray[0]);
      startDateTemp.setDate(startDateTemp.getDate() - 30);
      endDateTemp.setDate(endDateTemp.getDate() + 30);
      const calculatedEndDate = getMinDate(endDateTemp, new Date());
      setStartDate(startDateTemp);
      setEndDate(calculatedEndDate);
    }
    if (dateArray[1]) {
      setStartDate(minDate);
      setEndDate(new Date());
    }
    setDates(dateArray);
    fetchTagAnalysisData(dateArray[0], dateArray[1], selectedDisplayMode);
  };

  const transactionVolumeData: [number, number][] = [];
  const merchantByDollarSpentData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[] = [];

  const merchantByTransactionCountData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[] = [];

  const transactionDict: Record<string, number> = {};
  const merchantByDollarDict: Record<string, number> = {};
  const merchantByTransactionCountDict: Record<string, number> = {};

  let totalTransactionAmount = 0;
  let totalTransactionCount = 0;
  transactionList?.forEach((item) => {
    if (
      item.transactionStatus === "CAPTURE" &&
      item.merchantName !== "RoadFlex" &&
      new Date(item.authorizationDate) >= (dates as Date[])[0] &&
      new Date(item.authorizationDate) <= (dates as Date[])[1]
    ) {
      const transAmount = item.transactionAmount;
      const authDate = `${
        new Date(item.authorizationDate).toISOString().split("T")[0]
      }T00:00:00.000Z`;
      if (authDate in transactionDict) {
        transactionDict[authDate] += transAmount;
      } else {
        transactionDict[authDate] = transAmount;
      }
      const merchant = item.merchantName;
      totalTransactionAmount += transAmount;
      totalTransactionCount += 1;
      if (merchant in merchantByDollarDict) {
        merchantByDollarDict[merchant] += transAmount;
      } else {
        merchantByDollarDict[merchant] = transAmount;
      }
      if (merchant in merchantByTransactionCountDict) {
        merchantByTransactionCountDict[merchant] += 1;
      } else {
        merchantByTransactionCountDict[merchant] = 1;
      }
    }
  });
  for (const [key, value] of Object.entries(transactionDict)) {
    const date = new Date(key);
    transactionVolumeData.push([date.getTime(), value / 100]);
  }
  transactionVolumeData.sort(appChartsGraphSorter);
  const dailyTransactionVolumeData = [
    { name: "Daily Transaction Volume", data: transactionVolumeData },
  ];
  const otherPercentage = 0;
  for (const [key, value] of Object.entries(merchantByDollarDict)) {
    if (totalTransactionAmount !== 0) {
      const percentage =
        Math.round((10000 * value) / totalTransactionAmount) / 100;
      // if (percentage > 10) {
      merchantByDollarSpentData.push({
        name: key,
        y: percentage,
        sliced: true,
        selected: false,
      });
      // } else {
      //   otherPercentage += percentage;
      // }
    }
  }
  // merchantByDollarSpentData.push({
  //   name: "others",
  //   y: Math.round(otherPercentage * 100) / 100,
  //   sliced: true,
  //   selected: false,
  // });

  for (const [key, value] of Object.entries(merchantByTransactionCountDict)) {
    if (totalTransactionCount !== 0) {
      const percentage =
        Math.round((10000 * value) / totalTransactionCount) / 100;
      merchantByTransactionCountData.push({
        name: key,
        y: percentage,
        sliced: true,
        selected: false,
      });
    }
  }

  useEffect(() => {
    fetchTagAnalysisData(dates[0], dates[1], selectedDisplayMode);
  }, [
    selectedTransactionTags,
    selectedCardTags,
    selectedDriverTags,
    selectedVehicleTags,
  ]);

  useEffect(() => {
    if (accountTagsData?.readAccountTags?.accountTagList) {
      const accountTagList =
        accountTagsData?.readAccountTags?.accountTagList || {};

      setTransactionTags(accountTagList?.TRANSACTION?.active || []);
      setCardTags(accountTagList?.CARD?.active || []);
      setDriverTags(accountTagList?.DRIVER?.active || []);
      setVehicleTags(accountTagList?.VEHICLE?.active || []);
    }
  }, [accountTagsData]);

  if (accountTagsDataLoading) {
    return <Loader />;
  }
  return (
    <TagAnalysisUI
      {...{
        tagAnalysisDataLoading,
        merchantByDollarSpentData,
        merchantByTransactionCountData,
        dates,
        onDateRangeChange,
        gmvGraphData,
        transactionCountGraphData,
        startDate,
        endDate,
        displayModes,
        selectedDisplayMode,
        onDisplayModeChange,
        readCurrentUserAuth,
        transactionTags,
        cardTags,
        driverTags,
        vehicleTags,
        accountTagsDataLoading,
        selectedTransactionTags,
        setSelectedTransactionTags,
        selectedCardTags,
        setSelectedCardTags,
        selectedDriverTags,
        setSelectedDriverTags,
        selectedVehicleTags,
        setSelectedVehicleTags,
      }}
    />
  );
};
