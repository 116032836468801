/** @format */

import {
  SendVerificationEmailOrSmsType,
  UpdateEmailOrSmsType,
  UserDetailsType,
} from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { phoneFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { FocusEventHandler, SyntheticEvent } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { TextInput } from "../../../inputs";
import { OTPModal } from "../../../modals";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type UpdateBasicUIProps = FormikProps<UpdateEmailOrSmsType> & {
  readUserDetailsData: UserDetailsType;
  showOtpModal: boolean;
  setShowOtpModal: (value: boolean) => void;
  updatedEmail: boolean;
  updatedPhone: boolean;
  setUpdatedEmail: (value: boolean) => void;
  setUpdatedPhone: (value: boolean) => void;
  SendVerificationSetFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<SendVerificationEmailOrSmsType>>;
  SendVerificationHandleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  SendVerificationResetForm: () => void;
  SendVerificationHandleBlur: FocusEventHandler<HTMLInputElement>;
  SendVerificationIsSubmitting: boolean;
  SendVerificationHandleSubmit: () => void;
  SendVerificationValues: SendVerificationEmailOrSmsType;
  SendVerificationErrors: FormikErrors<SendVerificationEmailOrSmsType>;
  SendVerificationTouched: FormikTouched<SendVerificationEmailOrSmsType>;
};

const UpdateBasicUI = ({
  showOtpModal,
  setShowOtpModal,
  updatedEmail,
  updatedPhone,
  setUpdatedEmail,
  setUpdatedPhone,
  SendVerificationValues,
  SendVerificationHandleChange,
  SendVerificationHandleSubmit,
  SendVerificationResetForm,
  SendVerificationIsSubmitting,
  SendVerificationHandleBlur,
  SendVerificationErrors,
  SendVerificationTouched,
  SendVerificationSetFieldValue,
  readUserDetailsData,
  setFieldValue,
  handleSubmit,
  values,
  isSubmitting,
  ...rest
}: UpdateBasicUIProps) => {
  const submitAction = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    SendVerificationHandleSubmit();
  };

  const discardEmailChanges = () => {
    SendVerificationResetForm();
    setUpdatedEmail(false);
  };

  const discardPhoneChanges = () => {
    SendVerificationResetForm();
    setUpdatedPhone(false);
  };

  function phoneLastUpdateCheck() {
    if (!readUserDetailsData?.phoneLastDateOfEdit) {
      return false;
    }
    const diffDays = differenceInDays(
      new Date(),
      new Date(readUserDetailsData?.phoneLastDateOfEdit),
    );
    if (diffDays < 60) {
      return true;
    }
    return false;
  }

  function emailLastUpdateCheck() {
    if (!readUserDetailsData?.emailLastDateOfEdit) {
      return false;
    }
    const diffDays = differenceInDays(
      new Date(),
      new Date(readUserDetailsData?.emailLastDateOfEdit),
    );
    if (diffDays < 60) {
      return true;
    }
    return false;
  }

  const onPhoneSubmit = async (value: UpdateEmailOrSmsType) => {
    setFieldValue("phoneNumber", SendVerificationValues.newPhoneNumber);
    handleSubmit();
  };

  const onEmailSubmit = async (value: UpdateEmailOrSmsType) => {
    setFieldValue("email", SendVerificationValues.newEmail);
    handleSubmit();
  };

  return (
    <>
      <form onSubmit={submitAction} className="w-full text-sm md:text-base">
        <div>
          <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
            <div className="flex flex-col justify-between sm:flex-row sm:items-center">
              <div className="flex flex-col">
                <label htmlFor="Phone-Number" className="block font-medium">
                  Phone Number
                </label>
                <div className="max-w-xl text-xs">
                  {phoneLastUpdateCheck() &&
                    `Phone number last updated on ${convertISOStringToDate(
                      (
                        readUserDetailsData?.phoneLastDateOfEdit || ""
                      ).toString(),
                      true,
                    )}. It can only be updated once every 60 days.`}
                </div>
              </div>
              <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
                <TextInput
                  type="tel"
                  value={phoneFormatter(
                    SendVerificationValues.newPhoneNumber || "",
                  )}
                  onChange={(event) => {
                    SendVerificationSetFieldValue(
                      "newPhoneNumber",
                      phoneFormatter(event?.target?.value),
                    );
                  }}
                  name="newPhoneNumber"
                  id="newPhoneNumber"
                  disabled={!updatedPhone || phoneLastUpdateCheck()}
                  autoComplete="newPhoneNumber"
                  onBlur={SendVerificationHandleBlur}
                  touched={SendVerificationTouched.newPhoneNumber}
                  error={
                    Boolean(SendVerificationErrors?.newPhoneNumber) &&
                    SendVerificationTouched.newPhoneNumber
                  }
                  errorMessage={SendVerificationErrors?.newPhoneNumber}
                ></TextInput>
              </div>
            </div>

            <div className="flex justify-end">
              {!updatedPhone && (
                <Button
                  onClick={() => setUpdatedPhone(!updatedPhone)}
                  variant={ButtonVariant.AppOrange}
                  size={ButtonSize.AppSize}
                  disabled={
                    SendVerificationIsSubmitting || phoneLastUpdateCheck()
                  }
                >
                  Update
                </Button>
              )}
              {updatedPhone && (
                <div className="flex gap-x-2">
                  <Button
                    onClick={(e) => discardPhoneChanges()}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={SendVerificationIsSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    disabled={
                      SendVerificationIsSubmitting ||
                      !!SendVerificationErrors?.newPhoneNumber ||
                      phoneLastUpdateCheck() ||
                      SendVerificationValues.newPhoneNumber ===
                        SendVerificationValues.currentPhoneNumber
                    }
                  >
                    Verify
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4" id="email">
          <div
            className={classNames(
              !readUserDetailsData?.isEmailVerified ? "bg-red-100" : "",
              "p-4 md:p-8 space-y-4 rounded-md shadow bg-white",
            )}
          >
            <div className="flex flex-col justify-between sm:flex-row sm:items-center">
              <div className="flex flex-col">
                <label htmlFor="Email-Address" className="block font-medium">
                  Email Address
                </label>
                <div className="max-w-xl text-xs italic">
                  {emailLastUpdateCheck() &&
                    `Email last updated on ${convertISOStringToDate(
                      (
                        readUserDetailsData?.emailLastDateOfEdit || ""
                      ).toString(),
                      true,
                    )}. It can only be updated once every 60 days.`}
                </div>
              </div>
              <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
                <TextInput
                  type="email"
                  name="newEmail"
                  value={SendVerificationValues.newEmail}
                  disabled={!updatedEmail}
                  onChange={SendVerificationHandleChange}
                  onBlur={SendVerificationHandleBlur}
                  touched={SendVerificationTouched.newEmail}
                  error={
                    Boolean(SendVerificationErrors?.newEmail) &&
                    SendVerificationTouched.newEmail
                  }
                  errorMessage={SendVerificationErrors?.newEmail}
                ></TextInput>
              </div>
            </div>

            <div className="flex justify-end">
              {!updatedEmail && (
                <Button
                  onClick={() => setUpdatedEmail(!updatedEmail)}
                  variant={ButtonVariant.AppOrange}
                  size={ButtonSize.AppSize}
                  disabled={
                    SendVerificationIsSubmitting || emailLastUpdateCheck()
                  }
                >
                  Update
                </Button>
              )}
              {updatedEmail && (
                <div className="flex gap-x-2">
                  <Button
                    onClick={(e) => discardEmailChanges()}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={SendVerificationIsSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    disabled={
                      SendVerificationIsSubmitting ||
                      !!SendVerificationErrors?.newEmail ||
                      emailLastUpdateCheck() ||
                      SendVerificationValues.newEmail ===
                        SendVerificationValues.currentEmail
                    }
                  >
                    Verify
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      {showOtpModal && (
        <OTPModal
          {...{
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            ...rest,
          }}
          show={showOtpModal}
          setShow={setShowOtpModal}
          onOtpSubmit={
            SendVerificationValues.newEmail !==
            SendVerificationValues.currentEmail
              ? onEmailSubmit
              : onPhoneSubmit
          }
          resendCode={SendVerificationHandleSubmit}
          otpSubmitting={isSubmitting}
          message={
            SendVerificationValues.newEmail !==
            SendVerificationValues.currentEmail
              ? "Enter the 6-digit verification code sent via email"
              : "Enter the 6-digit verification code sent via SMS"
          }
        />
      )}
    </>
  );
};
export default UpdateBasicUI;
