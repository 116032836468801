/** @format */
import { useMutation } from "@apollo/client";
import {
  CREATE_AND_UPDATE_OWNERS,
  READ_OWNERS_AND_EXECUTIVES,
} from "@roadflex/graphql";
import { OwnersAndExecutivesValues } from "@roadflex/types";
import { capitalizeFirstLetters } from "@roadflex/utils";
import { useFormik } from "formik";
import * as Yup from "yup";

type useCreateAndUpdateOwnersProps = {
  onSubmit: (
    val: { code: string; message: string } | null,
    err: Error | null,
  ) => void;
  initialValues: OwnersAndExecutivesValues;
  validationSchema: Yup.AnyObjectSchema;
};

export const useCreateAndUpdateOwners = ({
  onSubmit,
  initialValues,
  validationSchema,
}: useCreateAndUpdateOwnersProps) => {
  const [createAndUpdateOwnersFn] = useMutation(CREATE_AND_UPDATE_OWNERS, {
    refetchQueries: [READ_OWNERS_AND_EXECUTIVES],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      const data = {
        firstName: capitalizeFirstLetters(value.firstName),
        lastName: capitalizeFirstLetters(value.lastName),
        phoneNumber: value.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
        ssn: value.ssn ? value.ssn.replace(/\D/g, "") : "",
        dateOfBirth: value?.dateOfBirth,
        executiveJobTitle: value?.executiveJobTitle,
        id: value?.id,
        address: value.homeAddress,
        email: value.email,
      };
      try {
        const res = await createAndUpdateOwnersFn({
          variables: { data },
        });
        onSubmit(res?.data?.createAndUpdateOwners, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
