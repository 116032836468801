/** @format */
import { useSubscription } from "@apollo/client";
import { CARDS_SUBSCRIPTION } from "@roadflex/graphql";

export const useCardListSubscription = (userId?: string) => {
  const { data, ...rest } = useSubscription(CARDS_SUBSCRIPTION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        userId,
      },
    },
  });

  return { data, ...rest };
};
