import { CogIcon } from "@heroicons/react/24/outline";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MapType } from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import getConfig from "next/config";
import { useCallback, useEffect, useState } from "react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

const containerStyle = {
  height: "60vh",
  width: "100%",
};

export function LiveLocationMap({ mapValues }: { mapValues: MapType }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
  });

  const [map, setMap] = useState<null>(null);
  const [position, setPosition] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 0, lng: 0 });
  const [showLiveLocationInfo, setShowLiveLocationInfo] =
    useState<boolean>(true);

  const onLoad = useCallback((mapObj) => {
    // const latLngBounds = new google.maps.LatLngBounds();
    // latLngBounds.extend(location);
    // const bounds = new window.google.maps.LatLngBounds(latLngBounds);
    // mapObj.fitBounds(bounds);
    const location = {
      lat: Number(mapValues.latitude),
      lng: Number(mapValues.longitude),
    };
    setPosition(location);
    setMap(map);
  }, []);

  const onUnmount = useCallback((mapObj) => {
    setMap(null);
  }, []);

  useEffect(() => {
    const location = {
      lat: Number(mapValues.latitude),
      lng: Number(mapValues.longitude),
    };
    setPosition(location);
  }, [mapValues]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={position}
        title={"Current location"}
        // icon={{
        //   url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        // }}
        onClick={() => {
          setShowLiveLocationInfo(true);
        }}
        key={"currentLocationKey"}
      >
        {showLiveLocationInfo && (
          <InfoWindow
            onCloseClick={() => {
              setShowLiveLocationInfo(false);
            }}
          >
            <div>
              <div>
                <b>Device Name: </b>
                {mapValues?.deviceName}
              </div>
              {mapValues.showAdditionalInfo ? (
                <>
                  <div>
                    <b>Driver: </b>
                    {mapValues?.driver}
                  </div>
                  <div>
                    <b>Is Driving: </b>
                    {mapValues?.isDriving === "true" ? "Yes" : "No"}
                  </div>
                  <div>
                    <b>Current State Duration: </b>
                    {mapValues?.currentStateDuration}
                  </div>
                </>
              ) : null}
              <div>
                <b>Last Updated:</b>{" "}
                {mapValues?.dateTime
                  ? convertISOStringToDate(mapValues.dateTime)
                  : "-"}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  ) : (
    <div className="flex justify-center h-full align-middle">
      <span className="inline-flex items-center">
        <CogIcon
          className="w-6 h-6 mr-2 -ml-1 animate-spin"
          aria-hidden="true"
        />
        {"Loading Map..."}
      </span>
    </div>
  );
}
