/** @format */

import { dollarFormatter } from "@roadflex/constants";
import { useReadReferrals, useShareReferral } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import getConfig from "next/config";
import { useState } from "react";
import { Step } from "react-joyride";
import { Toast } from "../../../src/toast-message/toast";
import { Loader } from "../../loader";
import { ReferralUI } from "./referral.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

interface ReferralProps {
  readCurrentUserAuth: UserAuthType;
  loading?: boolean;
}

export const Referral = ({ readCurrentUserAuth, loading }: ReferralProps) => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const { data, loading: loadingAllReferrals } = useReadReferrals();
  const { handleReset, ...rest } = useShareReferral({
    initialValues: {
      email: "",
      phoneNumber: "",
    },
    onSubmit(res) {
      if (res?.code === "200") {
        handleReset({});
        Toast({ type: "success", message: res.message });
      } else {
        Toast({ type: "info", message: res?.message || "" });
      }
    },
  });

  const referralEarnings = data?.readReferrals.referralEarnings || 0;
  const referralSetting = data?.readReferrals.referralSetting || null;
  const referralCode = data?.readReferrals?.referralCode || "";
  const referralLink = `${NEXT_PUBLIC_WWW_URL}/signup?referral=${referralCode}`;

  const steps: Step[] = [
    {
      title: <h1 className="text-lg font-semibold text-red-900">Welcome</h1>,
      content: (
        <p className="text-base text-gray-700">
          Welcome to the Refer & Earn page.
        </p>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      placement: "center",
      target: "body",
    },
    {
      title: (
        <h1 className="text-lg font-semibold text-red-900">Referral Code</h1>
      ),
      content: (
        <p className="text-base text-gray-700">This is your referral code.</p>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-2",
    },
    {
      title: (
        <h1 className="text-lg font-semibold text-red-900">How to share</h1>
      ),
      content: (
        <p className="text-base text-gray-700">
          Invite via text, email or other platforms.
        </p>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-3",
    },
    {
      target: ".step-4",
      title: (
        <h1 className="text-lg font-semibold text-red-900">
          Referral Earnings
        </h1>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      content: (
        <p className="text-base text-gray-700">
          When the referred fleet account purchases{" "}
          <strong>{referralSetting?.fuelQuantity || "XX"}</strong> gallons of
          fuel using RoadFlex cards, a{" "}
          <strong>
            {dollarFormatter((referralSetting?.bonusAmount || 0) / 100)}
          </strong>{" "}
          credit will be added to your account.Track your earnings here.
        </p>
      ),
    },
    {
      target: ".step-5",
      title: (
        <h1 className="text-lg font-semibold text-red-900">Help Center</h1>
      ),
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
        last: "Close",
      },
      spotlightPadding: 10,
      content: (
        <p className="text-base text-gray-700">
          Need assistance or want to revisit this guide? Click on{" "}
          <em>Help Center</em> anytime.
        </p>
      ),
    },
  ];

  const [joyride, setJoyride] = useState(false);

  if (loading || loadingAllReferrals) {
    return <Loader />;
  }

  return (
    <ReferralUI
      {...{
        readCurrentUserAuth,
        referralCode,
        referralLink,
        isLinkCopied,
        setLinkCopied,
        handleReset,
        joyride,
        setJoyride,
        steps,
        allReferrals: data?.readReferrals?.referred || [],
        loadingAllReferrals: loadingAllReferrals,
        referralEarnings,
        referralSetting,
        ...rest,
      }}
    ></ReferralUI>
  );
};
