/** @format */

import { ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING } from "@prisma/client";
import { AccountSettingControlType, FeatureType } from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { SyntheticEvent, useState } from "react";
import { FuelCardComponentPermissionGuard } from "../../../../../../../libs/react-components/src/permission-guard";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../../buttons";
import { Select } from "../../../../dropdowns";
import { Toast } from "../../../../toast-message/toast";

type AccountControlsUIProps = FormikProps<AccountSettingControlType> & {
  controlCenterFeatureAccess: FeatureType[];
};

const AccountControlsUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  handleReset,
  controlCenterFeatureAccess,
  ...rest
}: AccountControlsUIProps) => {
  const [update, setupdate] = useState(false);
  const toggle = () => setupdate((t) => !t);
  const discardChanges = () => {
    handleReset();
    setupdate(false);
  };
  const submitAction = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isEmpty(errors)) {
      Toast({
        type: "error",
        message: "Validation failed, please check your inputs",
      });
      return;
    }
    handleSubmit();
    toggle();
  };

  const hasAccess =
    controlCenterFeatureAccess.find(
      (item) =>
        item.name === "ACCOUNT_SETTING_PAGE_CONTROL_CENTER_ACCOUNT_SETTINGS",
    ) !== undefined;
  return (
    <form
      onSubmit={submitAction}
      className="flex flex-col w-full text-sm md:text-base"
    >
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Account Controls{" "}
            <span className="font-normal text-yellow-600">(Enterprise)</span>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Dynamic Purchase Controls Settings{" "}
            </label>

            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <FuelCardComponentPermissionGuard
                type="dropdown"
                featurePermission={hasAccess}
                messageType="enterprise"
              >
                <Select
                  disabled={!update}
                  onChange={(e) =>
                    setFieldValue("dynamicPurchaseControlsLinkedTo", e.value)
                  }
                  value={values.dynamicPurchaseControlsLinkedTo}
                  name="dynamicPurchaseControlsLinkedTo"
                  options={Object.keys(
                    ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
                  ).map((key) => {
                    return {
                      label: humanizeEnum(key),
                      value: key,
                      disabled:
                        key === ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING.CARD
                          ? false
                          : true,
                    };
                  })}
                ></Select>
              </FuelCardComponentPermissionGuard>
            </div>
          </div>
          <div className="flex justify-end">
            {!update && (
              <Button
                onClick={toggle}
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
              >
                Update
              </Button>
            )}
            {update && (
              <div className="flex gap-x-2">
                <Button
                  onClick={discardChanges}
                  variant={ButtonVariant.Red}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type={ButtonType.Submit}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.AppSize}
                  loading={isSubmitting}
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
export default AccountControlsUI;
