/** @format */

import { ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING } from "@prisma/client";
import { AccountSettingDisplayType, FeatureType } from "@roadflex/types";
import AccountControls from "./account-controls/account-controls";
import DisplaySettings from "./display-settings/display-settings";

type ControlCenterUIProps = {
  readAccountDisplaySetting: AccountSettingDisplayType;
  dynamicPurchaseControlsLinkedTo: ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING;
  controlCenterFeatureAccess: FeatureType[];
};

const ControlCenterUI = ({
  readAccountDisplaySetting,
  dynamicPurchaseControlsLinkedTo,
  controlCenterFeatureAccess,
}: ControlCenterUIProps) => {
  return (
    <div className="space-y-4">
      <DisplaySettings readAccountDisplaySetting={readAccountDisplaySetting} />
      <AccountControls
        dynamicPurchaseControlsLinkedTo={dynamicPurchaseControlsLinkedTo}
        controlCenterFeatureAccess={controlCenterFeatureAccess}
      ></AccountControls>
    </div>
  );
};
export default ControlCenterUI;
