/** @format */
import { ENUM_SUPER_USER_USER_TYPE } from "@prisma/client";
import {
  useImpersonatorSignIn,
  useReadParentCompanyUser,
} from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { setCookie } from "@roadflex/web-lib";
import { useState } from "react";
import { Toast } from "../../../src/toast-message/toast";
import { Loader } from "../../loader";
import { ParentCompanyUserUI } from "./parent-company-user.ui";

interface ParentCompanyUserProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

export const ParentCompanyUser = ({
  readCurrentUserAuth,
  loading,
}: ParentCompanyUserProps) => {
  const { data, loading: loadingReadParentCompanyUser } =
    useReadParentCompanyUser();
  const [showImpersonatorMode, setShowImpersonatorMode] = useState(false);

  const {
    values: valuesImpersonatorSignIn,
    handleSubmit: handleSubmitImpersonatorSignIn,
    isSubmitting: isSubmittingImpersonatorSignIn,
    setFieldValue: setFieldValueImpersonatorSignIn,
  } = useImpersonatorSignIn({
    initialValues: {
      email: "",
    },
    async onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      const { authToken } = res as { authToken: string };
      setCookie("impersonatorToken", authToken, 30);
      setShowImpersonatorMode(true);
    },
  });

  if (loading || loadingReadParentCompanyUser) {
    return <Loader />;
  }

  return (
    <ParentCompanyUserUI
      {...{
        role:
          data?.readParentCompanyUser?.role ||
          ENUM_SUPER_USER_USER_TYPE.CONTRIBUTOR,
        profilesAuthorized:
          data?.readParentCompanyUser?.profilesAuthorized || [],
      }}
      handleSubmitImpersonatorSignIn={handleSubmitImpersonatorSignIn}
      isSubmittingImpersonatorSignIn={isSubmittingImpersonatorSignIn}
      setFieldValueImpersonatorSignIn={setFieldValueImpersonatorSignIn}
      valuesImpersonatorSignIn={valuesImpersonatorSignIn}
      setShowImpersonatorMode={setShowImpersonatorMode}
      showImpersonatorMode={showImpersonatorMode}
    />
  );
};
