/** @format */
import { EnvelopeIcon, EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { useReadAlertGeotabIframe } from "@roadflex/react-hooks";
import { AlertType } from "@roadflex/types";
import { DataTablePFSEvent } from "primereact/datatable";
import { useState } from "react";
import { GeoTabIframeUI } from "./iframe.ui";

const typeTemplate = (rowData: AlertType) => {
  const result = rowData?.type?.split("_");
  return (
    <span className="whitespace-nowrap">
      {result[0].charAt(0) + result[0].slice(1).toLowerCase()}{" "}
      {result[1].charAt(0) + result[1].slice(1).toLowerCase()}{" "}
      {result.length === 3 && (
        <span>{result[2].charAt(0) + result[2].slice(1).toLowerCase()}</span>
      )}
    </span>
  );
};

const messageTemplate = (rowData: AlertType) => {
  return (
    <span className="flex">
      {rowData?.read && (
        <span className="pl-2">
          {" "}
          <EnvelopeOpenIcon className="hidden w-5 h-5 mr-2 md:block"></EnvelopeOpenIcon>{" "}
        </span>
      )}
      {!rowData?.read && (
        <span className="pl-2">
          {" "}
          <EnvelopeIcon className="hidden w-5 h-5 mr-2 md:block"></EnvelopeIcon>{" "}
        </span>
      )}
      {rowData?.message}
    </span>
  );
};

type GeoTabIframeProps = {
  info: {
    database: string;
    date: Date;
    sessionId: string;
    userName: string;
  };
};

const alertsInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: {},
  sortField: "createdAt",
  sortOrder: -1,
  multiSortMeta: null,
  deleted: false,
};

export const GeoTabIframe = ({ info }: GeoTabIframeProps) => {
  const [alertsLazyParams, setAlertsLazyParams] = useState<DataTablePFSEvent>({
    ...alertsInitialLazyParams,
  });

  const { data, loadingAlerts, refetchAlerts } = useReadAlertGeotabIframe({
    info,
  });

  if (loadingAlerts) {
    return <></>;
  }

  const onPageAndSortAlerts = (event: DataTablePFSEvent) => {
    setAlertsLazyParams({ ...alertsLazyParams, ...event });
  };

  return (
    <GeoTabIframeUI
      {...{
        alertsList: data?.readAlertsGeoTabIframe?.alerts || [],
        alertsLazyParams,
        loadingAlerts,
        totalCount: data?.readAlertsGeoTabIframe?.totalCount || 0,
        onPageAndSortAlerts,
        typeTemplate,
        messageTemplate,
      }}
    ></GeoTabIframeUI>
  );
};
