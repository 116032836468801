/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { ENUM_SUPER_USER_USER_TYPE } from "@prisma/client";
import { appParentCompanySchema } from "@roadflex/constants";
import {
  CREATE_PARENT_COMPANY_USER,
  READ_ALL_PARENT_COMPANY_USERS,
  READ_PARENT_COMPANY_USER,
} from "@roadflex/graphql";
import {
  ParentCompanyFormValues,
  ParentCompanyUserType,
} from "@roadflex/types";
import { useFormik } from "formik";

type useCreateParentCompanyUserProps = {
  initialValues: ParentCompanyFormValues;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useReadParentCompanyUser = () => {
  const { data, loading, refetch, ...rest } = useQuery<{
    readParentCompanyUser: {
      code: string;
      message: string;
      role: ENUM_SUPER_USER_USER_TYPE;
      profilesAuthorized: ParentCompanyUserType[];
    };
  }>(READ_PARENT_COMPANY_USER, {
    fetchPolicy: "no-cache",
  });
  return {
    data,
    loading,
    refetch,
    ...rest,
  };
};

export const useReadAllParentCompanyUsers = () => {
  const { data, loading, refetch, ...rest } = useQuery<{
    readAllParentCompanyUsers: {
      code: string;
      message: string;
      users: {
        id: string;
        email: string;
        phoneNumber: string;
        profilesAuthorized: ParentCompanyUserType[];
      }[];
    };
  }>(READ_ALL_PARENT_COMPANY_USERS, {
    fetchPolicy: "no-cache",
  });
  return {
    data,
    loading,
    refetch,
    ...rest,
  };
};

export const useCreateParentCompanyUser = ({
  onSubmit,
  initialValues,
}: useCreateParentCompanyUserProps) => {
  const [createParentCompanyUserFn] = useMutation<{
    createParentCompanyUser: {
      code: string;
      message: string;
    };
  }>(CREATE_PARENT_COMPANY_USER, {
    refetchQueries: [READ_ALL_PARENT_COMPANY_USERS],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appParentCompanySchema,
    onSubmit: async (value) => {
      try {
        const res = await createParentCompanyUserFn({
          variables: {
            data: {
              email: value.email,
              id: value.id,
              phoneNumber: value.phoneNumber.trim().match(/\d+/g)?.join(""),
              password: value.password,
            },
          },
        });
        if (res?.data?.createParentCompanyUser) {
          onSubmit(res?.data?.createParentCompanyUser, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
