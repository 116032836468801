/** @format */
import { FeatureType, UserAuthType } from "@roadflex/types";
import classNames from "classnames";
import { NoAccessFeatureUI } from "../no-access";
import { AccountAnalysis } from "./account-analysis/account-analysis";
import { CardAnalysis } from "./card-analysis/card-analysis";
import { FraudAnalysis } from "./fraud-analysis/fraud-analysis";
import { TagAnalysis } from "./tag-analysis/tag-analysis";

interface ReportsUIProps {
  tabOpen: number;
  setTabOpen: (value: number) => void;
  childFeatures: FeatureType[];
  readCurrentUserAuth: UserAuthType;
}

export const ReportsUI = ({
  tabOpen,
  setTabOpen,
  childFeatures,
  readCurrentUserAuth,
}: ReportsUIProps) => {
  const tabs = [
    { id: 1, name: "Account Analysis", href: "", current: true },
    { id: 2, name: "Fraud Analysis", href: "", current: false },
    { id: 3, name: "Card Analysis", href: "", current: false },
    { id: 4, name: "Tag Analysis", href: "", current: false },
  ];
  const AccountAnalysisAccess =
    childFeatures.find(
      (item) => item.name === "ANALYSIS_PAGE_ACCOUNT_ANALYSIS",
    ) !== undefined;
  const CardAnalysisAccess =
    childFeatures.find(
      (item) => item.name === "ANALYSIS_PAGE_CARD_ANALYSIS",
    ) !== undefined;
  const FraudAnalysisAccess =
    childFeatures.find(
      (item) => item.name === "ANALYSIS_PAGE_FRAUD_ANALYSIS",
    ) !== undefined;
  const TagAnalysisAccess =
    childFeatures.find((item) => item.name === "ANALYSIS_PAGE_TAG_ANALYSIS") !==
    undefined;
  const DriverAnalysisAccess =
    childFeatures.find(
      (item) => item.name === "ANALYSIS_PAGE_FLEET_DRIVER_ANALYSIS",
    ) !== undefined;

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Fleet Analysis`.toUpperCase()}
      </div>
      <div>
        <div className="mt-5 text-sm md:text-base md:mt-0">
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name === "Account Analysis" && <>{tab.name}</>}
                  {tab.name === "Fraud Analysis" && (
                    <>
                      {tab.name}{" "}
                      <span className="text-yellow-600">(Premium)</span>
                    </>
                  )}
                  {(tab.name === "Card Analysis" ||
                    tab.name === "Tag Analysis") && (
                    <>
                      {tab.name}{" "}
                      <span className="text-yellow-600">(Enterprise)</span>
                    </>
                  )}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className={`${tabOpen === 1 ? `` : `hidden`} mt-6`}>
        <div>
          {AccountAnalysisAccess && (
            <AccountAnalysis {...{ readCurrentUserAuth }}></AccountAnalysis>
          )}
          {!AccountAnalysisAccess && <NoAccessFeatureUI />}
        </div>
      </div>
      <div className={`${tabOpen === 2 ? `` : `hidden`} mt-6`}>
        {FraudAnalysisAccess && (
          <FraudAnalysis {...{ readCurrentUserAuth }}></FraudAnalysis>
        )}
        {!FraudAnalysisAccess && <NoAccessFeatureUI />}
      </div>
      <div className={`${tabOpen === 3 ? `` : `hidden`} mt-6`}>
        {CardAnalysisAccess && (
          <CardAnalysis {...{ readCurrentUserAuth }}></CardAnalysis>
        )}
        {!CardAnalysisAccess && <NoAccessFeatureUI />}
      </div>
      <div className={`${tabOpen === 4 ? `` : `hidden`} mt-6`}>
        {TagAnalysisAccess && (
          <TagAnalysis {...{ readCurrentUserAuth }}></TagAnalysis>
        )}
        {!TagAnalysisAccess && <NoAccessFeatureUI />}
      </div>
    </div>
  );
};
