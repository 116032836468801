/** @format */

import {
  COLOR_SCHEME_OPTIONS,
  DISPLAY_TIME_OPTIONS,
} from "@roadflex/constants";
import {
  AccountSettingDisplayType,
  UpdateAccountDisplaySettings,
} from "@roadflex/types";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { InputSwitch } from "primereact/inputswitch";
import { SyntheticEvent, useState } from "react";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../../buttons";
import { Select } from "../../../../dropdowns";
import { Toast } from "../../../../toast-message/toast";

type DisplaySettingsUIProps = FormikProps<UpdateAccountDisplaySettings> & {
  readAccountDisplaySetting: AccountSettingDisplayType;
};

const DisplaySettingsUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  handleReset,
  readAccountDisplaySetting,
  ...rest
}: DisplaySettingsUIProps) => {
  const [update, setupdate] = useState(false);
  const toggle = () => setupdate((t) => !t);
  const discardChanges = () => {
    handleReset();
    setupdate(false);
  };
  const submitAction = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isEmpty(errors)) {
      Toast({
        type: "error",
        message: "Validation failed, please check your inputs",
      });
      return;
    }
    handleSubmit();
    toggle();
  };
  return (
    <form
      onSubmit={submitAction}
      className="flex flex-col w-full text-sm md:text-base"
    >
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Display Options
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Color Scheme
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <Select
                disabled={!update}
                onChange={(e) => setFieldValue("colorScheme", e.value)}
                value={values.colorScheme}
                name="colorScheme"
                options={COLOR_SCHEME_OPTIONS}
              ></Select>
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Show Company Name in Navigation Bar
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <InputSwitch
                name={``}
                id="showCompanyName"
                checked={values?.showCompanyName}
                onChange={handleChange}
                className="border-0 input-switch"
                disabled={!update}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="fleet-type" className="block font-medium">
              Display Time
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <Select
                disabled={!update}
                onChange={(e) => setFieldValue("timeZone", e.value)}
                value={values.timeZone}
                name="timeZone"
                options={DISPLAY_TIME_OPTIONS}
              ></Select>
            </div>
          </div>
          <div className="flex justify-end">
            {!update && (
              <Button
                onClick={toggle}
                variant={ButtonVariant.AppOrange}
                size={ButtonSize.AppSize}
                disabled={isSubmitting}
              >
                Update
              </Button>
            )}
            {update && (
              <div className="flex gap-x-2">
                <Button
                  onClick={discardChanges}
                  variant={ButtonVariant.Red}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type={ButtonType.Submit}
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.AppSize}
                  loading={isSubmitting}
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
export default DisplaySettingsUI;
