import classNames from "classnames";
import {
  PaginatorCurrentPageReportOptions,
  PaginatorFirstPageLinkOptions,
  PaginatorJumpToPageInputOptions,
  PaginatorLastPageLinkOptions,
  PaginatorNextPageLinkOptions,
  PaginatorPageLinksOptions,
  PaginatorPrevPageLinkOptions,
  PaginatorRowsPerPageDropdownOptions,
} from "primereact/paginator";
import { Ripple } from "primereact/ripple";
import { Select } from "../../dropdowns";

export const newPaginationTemplate = {
  layout:
    "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown JumpToPageInput",
  RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
    const dropdownOptions = [
      { label: 10, value: 10 },
      { label: 20, value: 20 },
      { label: 50, value: 50 },
    ];

    return (
      <div className="items-center justify-center hidden w-full mt-8 sm:flex sm:mt-0 sm:w-auto sm:mb-0 md:text-base">
        <Select
          value={options.value}
          options={dropdownOptions}
          onChange={options.onChange}
          className="!rounded-sm !h-[32px] md:!h-[38px]"
        ></Select>
      </div>
    );
  },
  CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
    return (
      <div
        style={{
          color: "var(--text-color)",
          userSelect: "none",
          textAlign: "center",
        }}
        className="hidden w-full mb-3 text-sm sm:mr-3 sm:w-auto sm:mb-0 md:text-base sm:block"
      >
        Showing {options.first} to {options.last} of {options.totalRecords}
      </div>
    );
  },
  FirstPageLink: (options: PaginatorFirstPageLinkOptions) => {
    return (
      <div className="hidden md:block">
        <button
          type="button"
          className={classNames(
            options.className,
            "border !border-brown-500 !max-h-[32px] !min-w-[32px] md:!max-h-[38px] md:!min-w-[38px] justify-center flex items-center",
          )}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="flex text-sm p-paginator-icon pi pi-angle-double-left sm:mb-0 md:text-base"></span>
        </button>
      </div>
    );
  },
  PrevPageLink: (options: PaginatorPrevPageLinkOptions) => {
    return (
      <div className="">
        <button
          type="button"
          className={classNames(
            options.className,
            "border  !border-brown-500 !max-h-[32px] !min-w-[32px] md:!max-h-[38px] md:!min-w-[38px] justify-center flex items-center",
          )}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="text-sm p-paginator-icon pi pi-angle-left sm:mb-0 md:text-base"></span>
        </button>
      </div>
    );
  },
  NextPageLink: (options: PaginatorNextPageLinkOptions) => {
    return (
      <div className="">
        <button
          type="button"
          className={classNames(
            options.className,
            "border !border-brown-500 !max-h-[32px] !min-w-[32px] md:!max-h-[38px] md:!min-w-[38px] justify-center flex items-center",
          )}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="text-sm p-paginator-icon pi pi-angle-right sm:mb-0 md:text-base"></span>
        </button>
      </div>
    );
  },
  LastPageLink: (options: PaginatorLastPageLinkOptions) => {
    return (
      <div className="hidden md:block">
        <button
          type="button"
          className={classNames(
            options.className,
            "border !border-brown-500 !max-h-[32px] !min-w-[32px] md:!max-h-[38px] md:!min-w-[38px] justify-center flex items-center",
          )}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="text-sm p-paginator-icon pi pi-angle-double-right sm:mb-0 md:text-base"></span>
        </button>
      </div>
    );
  },
  PageLinks: (options: PaginatorPageLinksOptions) => {
    return (
      <span className="">
        <button
          type="button"
          className={classNames(
            options.className,
            options.className.split(" ").includes("p-highlight")
              ? "!bg-orange-50 !border-1 !text-brown-500"
              : "",
            "border  !border-brown-500 hover:!bg-orange-50 focus:!shadow-none !max-h-[32px] !min-w-[32px] md:!max-h-[38px] md:!min-w-[38px] justify-center flex items-center text-sm md:text-base mt-4",
          )}
          onClick={options.onClick}
        >
          {options.page + 1}
          <Ripple />
        </button>
      </span>
    );
  },
  JumpToPageInput: (options: PaginatorJumpToPageInputOptions) => {
    return null;
  },
};
