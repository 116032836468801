/** @format */
import { useReadFleetAnalysis } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import FleetPerformanceUI from "./fleet-performance.ui";

export const FleetPerformance = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  const {
    fleetAnalysisData,
    fleetAnalysisDataRefetch,
    fleetAnalysisDataLoading,
  } = useReadFleetAnalysis();

  const weeklySpendsData =
    fleetAnalysisData?.readFleetAnalysisData?.weeklySpends || [];
  const categoryBreakdownData =
    fleetAnalysisData?.readFleetAnalysisData?.categoryBreakdown || {};
  const blockedTransactionsList =
    fleetAnalysisData?.readFleetAnalysisData?.blockedTransactionsList || [];

  return (
    <FleetPerformanceUI
      {...{
        loading: fleetAnalysisDataLoading,
        weeklySpendsData,
        categoryBreakdownData,
        blockedTransactionsList,
      }}
    />
  );
};
