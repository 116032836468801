/** @format */
// https://www.w3schools.com/js/js_cookies.asp
import { SECONDS_IN_DAY } from "@roadflex/constants";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL, APP_ENV } = publicRuntimeConfig;

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const cookieName = APP_ENV === "production" ? cname : `${cname}-${APP_ENV}`;
  const domainName = NEXT_PUBLIC_API_URL.includes("localhost")
    ? "localhost"
    : "roadflex.com";
  const d = new Date();
  d.setTime(d.getTime() + exdays * SECONDS_IN_DAY);
  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `${cookieName}=${cvalue};${expires};domain=${domainName};path=/;NONE`;
}

export function getCookie(cname: string) {
  const cookieName = APP_ENV === "production" ? cname : `${cname}-${APP_ENV}`;
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts && parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
  return null;
}

export function deleteCookie(cname: string) {
  const cookieName = APP_ENV === "production" ? cname : `${cname}-${APP_ENV}`;
  const domainName = NEXT_PUBLIC_API_URL.includes("localhost")
    ? "localhost"
    : "roadflex.com";
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domainName};path=/;NONE`;
}

export const checkIfUserIsInImpersonatorMode = () => {
  const impersonatorCookie = getCookie("impersonatorToken");
  if (impersonatorCookie) {
    return true;
  }
  return false;
};
