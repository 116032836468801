import { useQuery } from "@apollo/client";
import {
  DRIVER_ANALYSIS_DATA,
  FLEET_ANALYSIS_DATA,
  TECHNICAL_ANALYSIS_DATA,
} from "@roadflex/graphql";
import {
  Card,
  DriverAnalysisPerformanceType,
  TechnicalAnalysisDataDriverVehicleType,
} from "@roadflex/types";

export function useReadDriversAnalysis(timePeriod: number) {
  const {
    data: driverAnalysisData,
    refetch: driverAnalysisDataRefetch,
    loading: driverAnalysisDataLoading,
    ...rest
  } = useQuery<{
    readDriverAnalysisData: {
      code: string;
      message: string;
      data: DriverAnalysisPerformanceType[];
    };
  }>(DRIVER_ANALYSIS_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: { timePeriod },
    },
    notifyOnNetworkStatusChange: true,
  });
  return {
    driverAnalysisData,
    driverAnalysisDataRefetch,
    driverAnalysisDataLoading,
    ...rest,
  };
}

export function useReadFleetAnalysis() {
  const {
    data: fleetAnalysisData,
    refetch: fleetAnalysisDataRefetch,
    loading: fleetAnalysisDataLoading,
    ...rest
  } = useQuery<{
    readFleetAnalysisData: {
      code: string;
      message: string;
      weeklySpends: Array<[number, number]>;
      categoryBreakdown: { [key: string]: string };
      blockedTransactionsList: {
        declineReason: string;
        numberOfOccurrences: number;
        numberOfUniqueDrivers: number;
      }[];
    };
  }>(FLEET_ANALYSIS_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    },
    notifyOnNetworkStatusChange: true,
  });
  return {
    fleetAnalysisData,
    fleetAnalysisDataRefetch,
    fleetAnalysisDataLoading,
    ...rest,
  };
}

export function useReadTechnicalAnalysis(queryTable: string) {
  const {
    data: technicalAnalysisData,
    refetch: technicalAnalysisDataRefetch,
    loading: technicalAnalysisDataLoading,
    ...rest
  } = useQuery<{
    readTechnicalAnalysisData: {
      code: string;
      message: string;
      cards: Card[];
      drivers: TechnicalAnalysisDataDriverVehicleType[];
      vehicles: TechnicalAnalysisDataDriverVehicleType[];
    };
  }>(TECHNICAL_ANALYSIS_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: { queryTable },
    },
    notifyOnNetworkStatusChange: true,
  });
  return {
    technicalAnalysisData,
    technicalAnalysisDataRefetch,
    technicalAnalysisDataLoading,
    ...rest,
  };
}
