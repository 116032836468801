/** @format */

import { dollarFormatter } from "@roadflex/constants";

type ReferralStatsUIProps = {
  referralCode: string;
  referralEarnings: number;
  totalReferrals: number;
};
export const ReferralStatsUI = ({
  referralCode,
  referralEarnings,
  totalReferrals,
}: ReferralStatsUIProps) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col items-center h-full p-4 bg-white divide-y rounded-md shadow md:divide-y md:divide-x-0 lg:divide-y-0 lg:divide-x md:flex-col lg:flex-row sm:divide-y-0 sm:divide-x sm:flex-row text-brown-500 divide-brown-500">
        <div className="flex flex-col justify-center w-full h-full gap-3 py-4 sm:px-6 md:px-0 lg:px-6 sm:items-center md:items-start lg:items-center">
          <div className="text-lg font-semibold">Your unique referral code</div>
          <div className="text-2xl font-semibold md:text-3xl step-2">
            {" "}
            {referralCode}
          </div>
        </div>
        <div className="flex flex-col justify-center w-full h-full py-4 divide-y sm:px-6 md:px-0 lg:px-6 divide-brown-500">
          <div className="flex flex-col gap-3 pb-6 step-4">
            <div className="text-lg font-semibold">Referral Earnings</div>
            <div className="flex flex-row items-end font-bold">
              {" "}
              <div className="mr-3 text-2xl font-semibold md:text-3xl">
                {dollarFormatter(referralEarnings / 100 || 0)}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 pt-6">
            <div className="text-lg font-semibold">Number of Referrals</div>
            <div className="flex flex-row items-end font-bold">
              {" "}
              <div className="mr-3 text-2xl font-semibold md:text-3xl">
                {totalReferrals}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
