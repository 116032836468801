/** @format */

import { usePlatformDetect } from "@roadflex/react-hooks";
import { formatJson } from "@roadflex/utils";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";

type JsonDataViewerModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: JSON[];
  header: string;
  mode: string;
  description?: string;
};

export const JsonDataViewerModal = ({
  open,
  data,
  setOpen,
  header,
  description,
  mode,
}: JsonDataViewerModalProps) => {
  const { isMobile } = usePlatformDetect();
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end pt-2">
        <Button
          size={ButtonSize.AppSize}
          variant={ButtonVariant.SecondaryOutline}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </div>
    );
  };
  return (
    <Dialog
      header={<div className="text-base lg:text-lg">{header}</div>}
      visible={open}
      style={{
        width: isMobile() ? "90vw" : "70vw",
        fontFamily: "Inter",
      }}
      footer={renderFooter()}
      onHide={() => setOpen(false)}
      closable={false}
    >
      {!data && <div>No Logs</div>}
      {data && (
        <>
          {description && <div className="mb-2 text-sm">{description}</div>}
          {(data || []).length > 0 &&
            data.reverse().map((log, index) => (
              <div key={index} className="mt-2">
                <Fieldset
                  className="!border-1 !border-brown-500 mr-2"
                  legend={
                    formatJson(log)?.Created
                      ? new Date(formatJson(log)?.Created * 1000).toDateString()
                      : null
                  }
                >
                  <pre className="leading-3">
                    <code className="text-xs">
                      {mode === "shipping"
                        ? JSON.stringify(
                            JSON.parse(JSON.stringify(log)).data.object
                              .shipping,
                            null,
                            4,
                          )
                        : mode === "geotab"
                        ? JSON.stringify(
                            JSON.parse(JSON.stringify(log)),
                            null,
                            4,
                          )
                        : JSON.stringify(formatJson(log), null, 2).replace(
                            /"([^"]+)":/g,
                            "$1:",
                          )}
                    </code>
                  </pre>
                </Fieldset>
              </div>
            ))}
        </>
      )}
    </Dialog>
  );
};
