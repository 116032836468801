/** @format */

import getConfig from "next/config";
import { Ref } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import SwiperCore, { EffectFlip, Navigation, Pagination } from "swiper";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

SwiperCore.use([Navigation, Pagination, EffectFlip]);
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

type FuelStationFinderAutoCompleteUIProps = {
  handleSearch: () => void;
  loading: boolean;
  parameters: {
    latitude: number;
    longitude: number;
    address: string;
  };
  setParameters: (value: {
    latitude: number;
    longitude: number;
    address: string;
  }) => void;
};

const FuelStationFinderAutoCompleteUI = ({
  handleSearch,
  loading,
  parameters,
  setParameters,
}: FuelStationFinderAutoCompleteUIProps) => {
  const { ref: searchAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (
        place?.geometry &&
        place?.geometry?.location?.lat &&
        place?.geometry?.location?.lng &&
        place?.formatted_address
      ) {
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        setParameters({
          address: place?.formatted_address,
          latitude: lat,
          longitude: lng,
        });
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["formatted_address", "geometry.location"],
      types: ["address"],
    },
  });

  return (
    <div className="flex flex-row justify-between my-4">
      <form className="flex flex-col md:flex-row items-end gap-4 w-full">
        <TextInput
          ref={searchAutoCompleteRef as unknown as Ref<HTMLInputElement>}
          value={parameters.address}
          onChange={(e) =>
            setParameters({ ...parameters, address: e.target.value })
          }
          variant="medium"
          placeholder="Location Address"
        ></TextInput>
        <Button
          size={ButtonSize.AppSize}
          variant={ButtonVariant.Secondary}
          onClick={handleSearch}
          disabled={loading}
          className="w-fit"
        >
          Search
        </Button>
      </form>
    </div>
  );
};

export default FuelStationFinderAutoCompleteUI;
