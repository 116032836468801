/** @format */
import { useQuery } from "@apollo/client";
import {
  READ_ACCOUNT_STATUS,
  READ_APP_DASHBOARD_DATA,
  READ_USER_WALLET_ITEMS,
  USER_WALLET_ITEMS_AND_STATISTICS_FOR_PREPAID,
} from "@roadflex/graphql";
import {
  DashboardDataType,
  UserAccountStatusData,
  WalletItem,
} from "@roadflex/types";
import { DataTablePFSEvent } from "primereact/datatable";

export function useReadAccountStatus() {
  const { data, refetch, ...rest } = useQuery<{
    readAccountStatus: {
      code: string;
      message: string;
      userAccount: UserAccountStatusData;
    };
  }>(READ_ACCOUNT_STATUS, {
    fetchPolicy: "no-cache",
  });
  return { data, refetch, ...rest };
}

export const useReadAppDashboardData = () => {
  const { data, refetch, ...rest } = useQuery<{
    readAppDashboardData: {
      code: string;
      message: string;
      appDashboardData: DashboardDataType;
    };
  }>(READ_APP_DASHBOARD_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    },
  });
  return { data, refetch, ...rest };
};

export const useUserWalletItemsAndStatisticsForPrepaid = () => {
  const { data, refetch, loading, ...rest } = useQuery(
    USER_WALLET_ITEMS_AND_STATISTICS_FOR_PREPAID,
    {
      fetchPolicy: "no-cache",
    },
  );

  return {
    data:
      typeof data === "object" && data.userWalletItemsAndStatisticsForPrepaid
        ? data.userWalletItemsAndStatisticsForPrepaid
        : {},
    refetch,
    loading,
    ...rest,
  };
};

export const useReadUserWalletItems = ({
  userWalletLazyParams,
}: {
  userWalletLazyParams: DataTablePFSEvent;
}) => {
  const { data, loading, refetch, ...rest } = useQuery<{
    readUserWalletItem: {
      code: string;
      message: string;
      walletItems: WalletItem[];
      totalCount: number;
    };
  }>(READ_USER_WALLET_ITEMS, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...userWalletLazyParams,
      },
    },
  });
  return {
    data,
    loading,
    refetch,
  };
};
