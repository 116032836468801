/** @format */

import { useSetPassword } from "@roadflex/react-hooks";
import { UserOnboardType } from "@roadflex/types";
import { useEffect } from "react";
import { Toast } from "../toast-message/toast";
import SetPasswordUI from "./set-password.ui";

export interface SetPasswordProps {
  nextStep: (jumpStepBy?: number) => void;
  readCurrentUserOnboard: UserOnboardType;
  isSubmittingOnboardStatus: boolean;
}

const SetPassword = ({
  nextStep,
  readCurrentUserOnboard,
  isSubmittingOnboardStatus,
}: SetPasswordProps) => {
  useEffect(() => {
    const { isPasswordSet } = readCurrentUserOnboard;

    if (isPasswordSet) {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readCurrentUserOnboard]);

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    ...rest
  } = useSetPassword({
    initialValues: {
      password: "",
      retypePassword: "",
    },
    onSubmit(val, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (val?.code !== "200") {
        Toast({ type: "error", message: "Something went wrong" });
        return;
      }
      nextStep();
    },
  });

  return (
    <SetPasswordUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isSubmittingOnboardStatus,
        handleBlur,
        errors,
        touched,
        ...rest,
      }}
    />
  );
};

export default SetPassword;
