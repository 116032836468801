/** @format */
import { useQuery } from "@apollo/client";
import { READ_ADDITIONAL_BUSINESS_INFORMATION } from "@roadflex/graphql";

export const useReadAdditionalBusinessInformation = () => {
  const { data, refetch, ...rest } = useQuery(
    READ_ADDITIONAL_BUSINESS_INFORMATION,
    {
      variables: {
        where: {},
      },
      fetchPolicy: "no-cache",
    },
  );
  return { data, refetch, ...rest };
};
