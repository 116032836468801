/** @format */
import { useQuery } from "@apollo/client";
import { READ_DOCUMENTS } from "@roadflex/graphql";

type UseReadDocumentsProps = {
  businessId: string;
};

export const useReadDocuments = ({ businessId }: UseReadDocumentsProps) => {
  const { data, refetch, ...rest } = useQuery(READ_DOCUMENTS, {
    variables: {
      where: {
        businessId,
      },
    },
    fetchPolicy: "no-cache",
  });
  const documents = data?.readDocuments;
  return { documents, refetch, ...rest };
};
