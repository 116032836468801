/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { ENUM_JOB_TITLE } from "@prisma/client";
import { STATE_CODES } from "@roadflex/constants";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import {
  OwnerAndExecutiveType,
  OwnersAndExecutivesValues,
} from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import {
  dateOfBirthFormatter,
  phoneFormatter,
  ssnFormatter,
} from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { forwardRef, LegacyRef, Ref } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";
import { OnboardBannerComponent } from "./common-banner-component";

interface BeneficialOwnersUIProps
  extends FormikProps<OwnersAndExecutivesValues> {
  isSubmittingOnboardStatus: boolean;
  ownersAndExecutives: OwnerAndExecutiveType[];
  onEdit: (ownerAndExecutive: OwnerAndExecutiveType) => void;
  showInputs: boolean;
  onAddNewOwner: () => void;
  nextStep: (jumpStepBy?: number) => void;
  onPreviousStep: () => void;
  setShowInputs: (value: boolean) => void;
  removingOwnerAndExecutive: boolean;
  onRemoveOwner: (id: string) => void;
}

const BeneficialOwnersUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
      handleBlur,
      errors,
      touched,
      isSubmittingOnboardStatus,
      dirty,
      ownersAndExecutives,
      onEdit,
      showInputs,
      onAddNewOwner,
      nextStep,
      onPreviousStep,
      setShowInputs,
      removingOwnerAndExecutive,
      onRemoveOwner,
      ...rest
    }: BeneficialOwnersUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { homeAddressAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      homeAddressAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };
    const { isMobile } = usePlatformDetect();
    return (
      <div
        className={classNames(
          isMobile() ? "flex-col" : "flex-row",
          "flex w-full h-full min-h-screen",
        )}
      >
        <OnboardBannerComponent
          header="Application"
          subText={
            !showInputs
              ? "Review your business owners"
              : values.id
              ? "Edit business owner details"
              : "Add business owner details"
          }
        />

        <div className="flex flex-col w-full px-4 py-8 md:p-16">
          <form onSubmit={handleSubmit} className="flex flex-col w-full">
            <div className={classNames(showInputs ? "hidden" : "")}>
              <div className="w-full space-y-2">
                {ownersAndExecutives.map((ownerAndExecutive, idx) => (
                  <div key={ownerAndExecutive.id}>
                    <div className="flex flex-row items-center justify-between">
                      <div className="">Owner {idx + 1}</div>
                      <div className="flex items-center space-x-3">
                        <Button
                          variant={ButtonVariant.Transparent}
                          className="underline-offset-2 underline !px-0"
                          onClick={() => {
                            onEdit(ownerAndExecutive);
                          }}
                        >
                          Edit
                        </Button>
                        {!ownerAndExecutive.representative && (
                          <Button
                            variant={ButtonVariant.Transparent}
                            className="underline-offset-2 underline !px-0"
                            disabled={removingOwnerAndExecutive}
                            onClick={() => {
                              onRemoveOwner(ownerAndExecutive.id);
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className="w-full p-4 text-sm bg-white rounded-md ">
                      <span>
                        {ownerAndExecutive.firstName}{" "}
                        {ownerAndExecutive.lastName}
                      </span>{" "}
                      <br />
                      {ownerAndExecutive.address?.streetAddress}, <br />
                      {ownerAndExecutive.address?.streetDetails && (
                        <div>{ownerAndExecutive.address?.streetDetails},</div>
                      )}
                      {ownerAndExecutive.address?.city},{" "}
                      {ownerAndExecutive.address?.state}, <br />
                      {ownerAndExecutive.address?.zip}
                    </div>
                  </div>
                ))}
              </div>
              <div>
                <Button
                  variant={ButtonVariant.Transparent}
                  className="underline-offset-2 underline !px-0"
                  onClick={() => {
                    //   onJumpToStep(2);
                    onAddNewOwner();
                  }}
                >
                  Add another owner
                </Button>
              </div>
              <div className="mt-6">
                <Button
                  variant={ButtonVariant.Orange}
                  className="w-full"
                  onClick={() => {
                    nextStep();
                  }}
                  disabled={isSubmittingOnboardStatus}
                  loading={isSubmittingOnboardStatus}
                >
                  <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
                  Next
                </Button>
                <div>
                  <div className="flex items-center justify-center mt-1 text-sm text-center">
                    <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
                    We securely store and encrypt your personal information
                  </div>
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Transparent}
                    className="pl-0"
                    size={ButtonSize.Small}
                    onClick={onPreviousStep}
                  >
                    &#60;&#60; Go back
                  </Button>
                </div>
              </div>
            </div>
            <div className={classNames(showInputs ? "" : "hidden")}>
              <div className="relative w-full">
                <TextInput
                  label="Legal First (and Middle) Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  errorMessage={errors.firstName}
                  touched={touched.firstName}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label="Legal Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  errorMessage={errors.lastName}
                  touched={touched.lastName}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label="Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  errorMessage={errors.email}
                  touched={touched.email}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label="Mobile"
                  type="tel"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  onChange={(event) =>
                    setFieldValue(
                      "phoneNumber",
                      phoneFormatter(event?.target?.value),
                    )
                  }
                  onBlur={handleBlur}
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  errorMessage={errors.phoneNumber}
                  touched={touched.phoneNumber}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label={
                    <div className="flex flex-row mb-1 md:mb-2">
                      <div className="flex items-center ml-2 text-center whitespace-nowrap">
                        Date Of Birth*
                      </div>
                    </div>
                  }
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                  type="tel"
                  onChange={(event) =>
                    setFieldValue(
                      "dateOfBirth",
                      dateOfBirthFormatter(event?.target?.value),
                    )
                  }
                  onBlur={handleBlur}
                  autoComplete="dateOfBirth"
                  error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                  errorMessage={errors.dateOfBirth}
                  touched={touched.dateOfBirth}
                  variant="responsive"
                  placeholder="MM/DD/YYYY"
                  required
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <Select
                  label="Executive Job Title"
                  value={values.executiveJobTitle}
                  name="executiveJobTitle"
                  options={[
                    {
                      label: "-- Select an option --",
                      value: "",
                      disabled: true,
                    },
                  ].concat(
                    Object.keys(ENUM_JOB_TITLE).map((key) => {
                      return {
                        label: humanizeEnum(key),
                        value: key,
                        disabled: false,
                      };
                    }),
                  )}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => setFieldValue("executiveJobTitle", e.value)}
                  onBlur={() => setFieldTouched("executiveJobTitle", true)}
                  placeholder="-- Select an option --"
                  touched={touched.executiveJobTitle}
                  error={
                    touched.executiveJobTitle &&
                    Boolean(errors.executiveJobTitle)
                  }
                  errorMessage={errors.executiveJobTitle}
                  required
                  variant="responsive"
                ></Select>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label={
                    <div className="mb-1 md:mb-2">
                      <div className="flex flex-row ">
                        <div className="text-center">
                          <LockClosedIcon
                            width={25}
                            height={25}
                            className="pointer-events-none"
                          />
                        </div>
                        <div className="flex items-center ml-2 text-center whitespace-nowrap">
                          Executive's Social Security Number*
                        </div>
                      </div>
                    </div>
                  }
                  name="ssn"
                  value={values.ssn}
                  type="tel"
                  onChange={(event) =>
                    setFieldValue("ssn", ssnFormatter(event?.target?.value))
                  }
                  onBlur={handleBlur}
                  autoComplete="ssn"
                  error={touched.ssn && Boolean(errors.ssn)}
                  errorMessage={errors.ssn}
                  touched={touched.ssn}
                  variant="responsive"
                  placeholder="###-##-####"
                  required
                ></TextInput>
              </div>
              <div className="mt-2 text-sm">
                To verify your identity, we'll need to know your full Social
                Security Number. We do not conduct credit checks. Your credit
                score won't be affected.
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label="Home Address* (PO Box not allowed)"
                  name="homeAddress.streetAddress"
                  ref={
                    homeAddressAutoCompleteRef as unknown as Ref<HTMLInputElement>
                  }
                  value={values?.homeAddress?.streetAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={30}
                  error={
                    touched?.homeAddress?.streetAddress &&
                    Boolean(errors?.homeAddress?.streetAddress)
                  }
                  errorMessage={errors?.homeAddress?.streetAddress}
                  touched={touched?.homeAddress?.streetAddress}
                  variant="responsive"
                  informationToolTip="A valid business address is required. Any typos or wrong address can lead to lengthened review times."
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label="Suite # (Optional)"
                  name="homeAddress.streetDetails"
                  value={values?.homeAddress?.streetDetails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={200}
                  error={
                    touched?.homeAddress?.streetDetails &&
                    Boolean(errors?.homeAddress?.streetDetails)
                  }
                  errorMessage={errors?.homeAddress?.streetDetails}
                  touched={touched?.homeAddress?.streetDetails}
                  variant="responsive"
                ></TextInput>
              </div>
              <div className="relative w-full mt-4">
                <TextInput
                  label="City"
                  name="homeAddress.city"
                  value={values?.homeAddress?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={15}
                  error={
                    touched?.homeAddress?.city &&
                    Boolean(errors?.homeAddress?.city)
                  }
                  errorMessage={errors?.homeAddress?.city}
                  touched={touched?.homeAddress?.city}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
              <div className="flex flex-col sm:flex-row">
                <div className="relative w-full mt-4">
                  <Select
                    label="State"
                    value={values?.homeAddress?.state}
                    options={[
                      {
                        label: "-- Select a State--",
                        value: "",
                        disabled: true,
                      },
                    ].concat(
                      STATE_CODES.map((m) => {
                        return {
                          label: `${m.label} (${m.value})`,
                          value: m.value,
                          disabled: false,
                        };
                      }),
                    )}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) =>
                      setFieldValue("homeAddress.state", e.value)
                    }
                    onBlur={() => setFieldTouched("homeAddress.state", true)}
                    placeholder="Select"
                    touched={touched?.homeAddress?.state}
                    error={
                      touched?.homeAddress?.state &&
                      Boolean(errors?.homeAddress?.state)
                    }
                    errorMessage={errors?.homeAddress?.state}
                    required
                    variant="responsive"
                  ></Select>
                </div>

                <div className="relative w-full mt-4 sm:ml-4">
                  <TextInput
                    label="Zip"
                    name="homeAddress.zip"
                    value={values?.homeAddress?.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={5}
                    error={
                      touched?.homeAddress?.zip &&
                      Boolean(errors?.homeAddress?.zip)
                    }
                    errorMessage={errors?.homeAddress?.zip}
                    touched={touched?.homeAddress?.zip}
                    variant="responsive"
                    required
                  ></TextInput>
                </div>
              </div>
              <div className="mt-6">
                <Button
                  variant={ButtonVariant.Orange}
                  className="w-full"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
                  {dirty ? `Save` : `Next`}
                </Button>
              </div>
              <div>
                <div className="flex items-center justify-center mt-1 text-xs text-center md:text-sm">
                  <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
                  We securely store and encrypt your personal information
                </div>
                <Button
                  type={ButtonType.Button}
                  variant={ButtonVariant.Transparent}
                  className="pl-0"
                  size={ButtonSize.Small}
                  onClick={() => setShowInputs(false)}
                >
                  &#60;&#60; Go back
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  },
);

export default BeneficialOwnersUI;
