import { useQuery } from "@apollo/client";
import { EyeIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import { renderFields } from "@highnoteplatform/card-viewer";
import { HIGHNOTE_GET_VIRTUAL_PAYMENT_CARD_TOKEN } from "@roadflex/graphql";
import { Card } from "@roadflex/types";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function CardInformationHighNoteUI({
  cardDetailsObj,
  handlePhysicalCardOrder,
  submittingCardOrderRequest,
}: {
  cardDetailsObj: Card;
  handlePhysicalCardOrder: () => void;
  submittingCardOrderRequest: boolean;
}) {
  const [cardRevealing, setCardRevealing] = useState(true);
  const [cardRevealed, setCardRevealed] = useState(true);
  const [unmountFn, setUnmountFn] = useState<() => Promise<void>>();
  const [toggleCardNumberMaskFn, setToggleCardNumberMaskFn] =
    useState<() => void | null>();
  const [token, setToken] = useState("");
  useQuery<{
    getVirtualPaymentCardToken: {
      code: string;
      success: boolean;
      token: string;
    };
  }>(HIGHNOTE_GET_VIRTUAL_PAYMENT_CARD_TOKEN, {
    onCompleted: async (data) => {
      if (data.getVirtualPaymentCardToken?.success) {
        setToken(data.getVirtualPaymentCardToken.token);
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
      setTimeout(() => {
        setCardRevealing(false);
      }, 2000);
    },
    variables: {
      data: {
        highnoteCardId: cardDetailsObj.cardId,
      },
    },
  });

  useEffect(() => {
    const fetchDetails = async () => {
      const { unmount, toggleCardNumberMask } = await renderFields({
        clientToken: token,
        // This is the same paymentCardId used to generate the token
        paymentCardId: cardDetailsObj.cardId as string,

        onError: (error) => {
          // Handle errors
          console.error(error);
        },
        environment: "test",

        // Specify the individual fields to render data into
        elements: {
          cardNumber: {
            selector: "#cardNumber",
            styles: {
              color: "#fff",
              fontSize: "24px",
              fontFamily: "Inter",
            },
          },

          cvv: {
            selector: "#cvv",
            styles: {
              color: "#fff",
              fontSize: "24px",
              fontFamily: "Inter",
            },
          },

          expirationDate: {
            selector: "#expirationDate",
            styles: {
              color: "#fff",
              fontSize: "24px",
              fontFamily: "Inter",
            },
          },
        },
      });
      // unmount();
      setUnmountFn(() => unmount);
      setToggleCardNumberMaskFn(() => toggleCardNumberMask);
    };
    if (token) {
      fetchDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const onToggle = () => {
    if (toggleCardNumberMaskFn) {
      toggleCardNumberMaskFn();
      setCardRevealed(!cardRevealed);
    }
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
      <div className="py-4 text-sm bg-white rounded-md shadow md:py-8 md:text-base">
        <div className="flex flex-col gap-4 overflow-hidden md:gap-8 ">
          <div className="flex flex-row justify-between">
            <div className="flex gap-4 px-4 font-medium">
              <div className="flex items-center font-semibold text-brown-500 md:text-lg">
                Virtual Card
              </div>
              <Button
                variant={ButtonVariant.Transparent}
                className="!p-0 text-orange-500 flex gap-1"
                onClick={onToggle}
              >
                {cardRevealed ? (
                  <LockClosedIcon className="w-4 h-4"></LockClosedIcon>
                ) : (
                  <EyeIcon className="w-4 h-4"></EyeIcon>
                )}
                Toggle
              </Button>
            </div>
            <div className="px-4">
              {cardDetailsObj.cardType === "VIRTUAL" ? (
                <Button
                  size={ButtonSize.AppSize}
                  variant={ButtonVariant.AppOrange}
                  onClick={handlePhysicalCardOrder}
                  className={"text-sm md:text-base"}
                  disabled={submittingCardOrderRequest}
                  loading={submittingCardOrderRequest}
                >
                  Issue Physical Card
                </Button>
              ) : null}
            </div>
          </div>
          <div className="h-0.5 border-0 bg-brown-500"></div>

          <div className="flex flex-row items-center w-full px-4">
            <div className="w-full">
              <div
                style={{
                  background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
                  transform: "translate3d(0, 0, 0)",
                }}
                className=" rounded-xl backdrop-blur-md w-full  max-w-[400px] h-60 py-4 px-4 md:px-8 text-white"
              >
                <div className="flex justify-between w-full">
                  <p className="text-bold">
                    <b>ROADFLEX</b>
                  </p>
                </div>
                <div className="my-2 max-w-[50px]">
                  <Image
                    layout="responsive"
                    width={"50"}
                    height={"50"}
                    src={`${NEXT_PUBLIC_CDN_URL}/svg/card-chip.svg`}
                    alt="card-chip"
                  ></Image>
                </div>
                <label className="text-xs uppercase">Card Number</label>
                <div className="flex gap-4">
                  <div
                    id="cardNumber"
                    className={
                      cardRevealing ? "hidden" : "h-[24px] w-[240px] ml-[-4px]"
                    }
                  ></div>
                </div>
                {cardRevealing && (
                  <div className="flex space-x-4 animate-pulse">
                    <div className="flex-1 py-1 space-y-6">
                      <div className="space-y-3">
                        <div className="grid grid-cols-6 gap-4">
                          <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                          <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                          <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                          <div className="h-4 col-span-1 bg-slate-200 rounded-xl"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex justify-between mt-2">
                  <div className="flex gap-4 md:gap-8">
                    <div>
                      <label className="text-xs uppercase">Valid Till</label>
                      <div
                        id="expirationDate"
                        className={
                          cardRevealing ? "hidden" : "w-[75px] ml-[-4px]"
                        }
                      ></div>
                      {cardRevealing && (
                        <div className="flex space-x-4 animate-pulse">
                          <div className="flex-1 py-1 space-y-6">
                            <div className="space-y-3">
                              <div className="">
                                <div className="h-4 bg-slate-200 rounded-xl w-[65px] col-span-1"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <label className="text-xs uppercase">CVV</label>
                      <div
                        id="cvv"
                        className={classNames(
                          cardRevealing ? "hidden" : "min-w-[45px] ml-[-4px]",
                        )}
                      ></div>
                      {cardRevealing && (
                        <div className="flex space-x-4 animate-pulse">
                          <div className="flex-1 py-1 space-y-6">
                            <div className="space-y-3">
                              <div className="grid grid-cols-6 gap-4">
                                <div className="h-4 bg-slate-200  w-[45px] rounded-xl col-span-1"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex absolute right-[32px] bottom-4">
                    <Image
                      layout="fixed"
                      width={"70"}
                      height={"50"}
                      src={`${NEXT_PUBLIC_CDN_URL}/svg/visa-signature.svg`}
                      alt="card-chip"
                    ></Image>
                    {/* <div className="absolute z-10 w-8 h-8 bg-red-500 rounded-full right-5 top-1"></div>
                            <div className="absolute right-0 w-8 h-8 rounded-full bg-amber-500 top-1"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
