import { CreditCardIcon } from "@heroicons/react/24/solid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import getConfig from "next/config";

import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import StripeSetupIntentForm from "./stripe-setup-intent-form";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = publicRuntimeConfig;

const stripeResponse = loadStripe(`${NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

type StripeAddNewCardButtonUIProps = {
  addPaymentMethod: () => void;
  clientSecret: string;
  addingPaymentMethod: boolean;
  setShowSetupIntentForm: (value: boolean) => void;
  showSetupIntentForm: boolean;
  onRepaymentActivate?: () => void;
  onDeposit?: () => void;
  disabled?: boolean;
  loading?: boolean;
  phrasing: string;
};
export const StripeAddNewCardButtonUI = (
  props: StripeAddNewCardButtonUIProps,
) => {
  const {
    clientSecret,
    addingPaymentMethod,
    addPaymentMethod,
    setShowSetupIntentForm,
    showSetupIntentForm,
    onRepaymentActivate,
    onDeposit,
    disabled,
    loading,
    phrasing,
  } = props;

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "stripe",
      variables: {
        focusBoxShadow: "none",
        colorPrimary: "#893f2b",
      },
    },
  };

  return (
    <div className="">
      {onRepaymentActivate ? (
        <Button
          variant={ButtonVariant.Gray}
          loading={addingPaymentMethod}
          onClick={() => addPaymentMethod()}
          size={ButtonSize.Small}
          className="flex flex-row items-center w-full sm:w-auto"
        >
          Activate
        </Button>
      ) : onDeposit ? (
        <Button
          size={ButtonSize.Large}
          variant={ButtonVariant.Brown}
          loading={addingPaymentMethod || loading}
          onClick={() => addPaymentMethod()}
          disabled={disabled}
          className="flex flex-row items-center w-full !rounded-xl sm:!py-3 sm:w-auto"
        >
          <CreditCardIcon className="w-5 h-5 mr-2" aria-hidden="true" />
          <span>Pay Now</span>
        </Button>
      ) : (
        <Button
          variant={
            phrasing.includes("Connect")
              ? ButtonVariant.AppOrange
              : ButtonVariant.Gray
          }
          size={ButtonSize.AppSize}
          loading={addingPaymentMethod}
          onClick={() => addPaymentMethod()}
          className="flex flex-row items-center w-full step-2 sm:w-auto"
        >
          {phrasing}
        </Button>
      )}

      {clientSecret && showSetupIntentForm && (
        <Elements options={options} stripe={stripeResponse}>
          <StripeSetupIntentForm
            onRepaymentActivate={onRepaymentActivate}
            onDeposit={onDeposit}
            showSetupIntentForm={showSetupIntentForm}
            setShowSetupIntentForm={setShowSetupIntentForm}
            phrasing={phrasing}
          ></StripeSetupIntentForm>
        </Elements>
      )}
    </div>
  );
};
