/** @format */

import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { useReadAutoReload } from "@roadflex/react-hooks";
import { useReadCurrentUserAuth } from "@roadflex/react-hooks-www";
import { Loader } from "../../../loader";
import { AutoReloadUI } from "./auto-reload.ui";

export function AutoReload() {
  const { autoReloadDetails, autoReloadDetailsLoading } = useReadAutoReload();
  const { readCurrentUserAuth, loading, refetch } = useReadCurrentUserAuth();
  if (autoReloadDetailsLoading || loading) {
    return <Loader />;
  }
  return (
    <AutoReloadUI
      autoReloadDetails={autoReloadDetails?.getAutoReloadDetails}
      customerType={
        readCurrentUserAuth?.fuelCard?.customerType ||
        ENUM_CUSTOMER_TYPE.PREPAID
      }
    ></AutoReloadUI>
  );
}
