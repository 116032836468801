/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  CHECK_ACCOUNT_BALANCE,
  DELETE_BANK_ACCOUNT,
  GET_AUTO_RELOAD_DETAILS,
  GET_REPAYMENT_PLAN_DETAILS,
  MAKE_PRIMARY_BANK_ACCOUNT,
  READ_ACCOUNT_STATUS,
  READ_BUSINESS_INFORMATION,
  READ_PAYMENT_SETTINGS,
  READ_USER_CUSTOMIZED_SETTING,
} from "@roadflex/graphql";
import {
  AutoReloadDetails,
  PaymentSettings,
  RepaymentPlanDetails,
} from "@roadflex/types";

export function useReadPaymentSettings() {
  const { data: readPaymentSettings } = useQuery<{
    readPaymentSettings: PaymentSettings;
  }>(READ_PAYMENT_SETTINGS, {
    fetchPolicy: "no-cache",
  });
  return { readPaymentSettings };
}

export function useReadCustomizedSettings(email: string) {
  const {
    data: readUserCustomizedSettings,
    loading: readUserCustomizedSettingsLoading,
  } = useQuery(READ_USER_CUSTOMIZED_SETTING, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { data: { email: email } },
  });
  return { readUserCustomizedSettings, readUserCustomizedSettingsLoading };
}

export function useReadAutoReload() {
  const { data: autoReloadDetails, loading: autoReloadDetailsLoading } =
    useQuery<{
      getAutoReloadDetails: AutoReloadDetails;
    }>(GET_AUTO_RELOAD_DETAILS, {
      fetchPolicy: "no-cache",
    });
  return { autoReloadDetails, autoReloadDetailsLoading };
}
export function useReadRepaymentPlan() {
  const {
    data: repaymentplanDetails,
    loading: repaymentplanDetailsLoading,
    networkStatus,
  } = useQuery<{
    getRepayment: RepaymentPlanDetails;
  }>(GET_REPAYMENT_PLAN_DETAILS);
  return { repaymentplanDetails, repaymentplanDetailsLoading, networkStatus };
}

export const useDeleteBankAccount = () => {
  const [deleteBankAccount, { loading }] = useMutation(DELETE_BANK_ACCOUNT, {
    refetchQueries: [READ_BUSINESS_INFORMATION],
  });

  return { deleteBankAccount, loading };
};

export const useBankAccountMakePrimary = () => {
  const [makeAccountPrimary, { loading }] = useMutation(
    MAKE_PRIMARY_BANK_ACCOUNT,
    {
      refetchQueries: [READ_BUSINESS_INFORMATION, READ_ACCOUNT_STATUS],
      fetchPolicy: "no-cache",
    },
  );

  return { makeAccountPrimary, loading };
};

export const usePlaidAccountBalance = () => {
  const [checkPlaidBalanceFn, { loading }] = useMutation<{
    checkAccountBalance: {
      bankBalance: number;
    };
  }>(CHECK_ACCOUNT_BALANCE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return { checkPlaidBalanceFn, loading };
};
