/** @format */
import { usePlatformDetect } from "@roadflex/react-hooks";
import { AlertType } from "@roadflex/types";
import { Column } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { dateTemplate } from "../../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";

type GeoTabIframeUIProps = {
  alertsList: AlertType[];
  onPageAndSortAlerts: (value: DataTablePFSEvent) => void;
  totalCount: number;
  alertsLazyParams: DataTablePFSEvent;
  loadingAlerts: boolean;
  typeTemplate: (rowData: AlertType) => React.ReactNode;
  messageTemplate: (rowData: AlertType) => React.ReactNode;
};

export const GeoTabIframeUI = ({
  alertsList,
  alertsLazyParams,
  loadingAlerts,
  onPageAndSortAlerts,
  totalCount,
  typeTemplate,
  messageTemplate,
}: GeoTabIframeUIProps) => {
  const { isMobile } = usePlatformDetect();
  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`RoadFlex Fraud Prevention Alerts`.toUpperCase()}
      </div>
      <div className="mt-5 md:mt-0">
        <div className="overflow-hidden">
          <div className={`block mt-6`}>
            <div className="flex flex-col w-full p-8 mt-4 mr-4 bg-white rounded-md shadow">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={alertsList}
                loading={loadingAlerts}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "Inter",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                lazy
                rows={alertsLazyParams.rows}
                filters={alertsLazyParams.filters}
                sortField={alertsLazyParams.sortField}
                sortOrder={alertsLazyParams.sortOrder}
                sortMode={"single"}
                first={alertsLazyParams.first}
                totalRecords={totalCount}
                onPage={onPageAndSortAlerts}
                onSort={onPageAndSortAlerts}
              >
                <Column
                  sortable
                  field="createdAt"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  header={`Date`}
                  body={(r) => dateTemplate(r.createdAt, "EST")}
                />

                <Column
                  sortable
                  field="type"
                  header="Notification Type"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={typeTemplate}
                />
                <Column
                  sortable
                  field="message"
                  header=""
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={messageTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
