/** @format */

import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

export const uploadFile = async (file: File, fileType: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileType", fileType);

  const response = await fetch(`${NEXT_PUBLIC_API_URL}/api/files/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  const { fileName } = await response.json();

  return fileName;
};
