/** @format */
import { centToDollarFormatter } from "@roadflex/constants";
import styles from "./prepaid-statistics.module.css";

const Dot = ({ color }: { color: string }) => (
  <span
    className="dot"
    style={{
      height: "15px",
      width: "15px",
      borderRadius: `50%`,
      display: `inline-block`,
      background: color || "gray",
      position: "relative",
      top: `4px`,
      aspectRatio: "1",
    }}
  ></span>
);

export const Colors = {
  transaction: `#f8ebdc`,
  pendingAuthorization: `#e6b68b`,
  walletBalance: `#893f2b`,
  total: ` #522214`,
};

export interface PrepaidUsersStatisticsProps {
  totalAmountSpentInPendingAuthorizations: number;
  totalAmountSpentInTransactions: number;
  totalAmountUsedForWalletRecharges: number;
  walletBalance: number;
  amountCorrection: number;
  businessName?: string;
}

export const PrepaidUsersStatistics = ({
  businessName,
  totalAmountSpentInPendingAuthorizations,
  totalAmountSpentInTransactions,
  totalAmountUsedForWalletRecharges,
  walletBalance,
  amountCorrection,
}: PrepaidUsersStatisticsProps) => {
  const transactionWidthPercent =
    (totalAmountSpentInTransactions * 100) / totalAmountUsedForWalletRecharges;
  const pendingAuthorizationWidthPercent =
    (totalAmountSpentInPendingAuthorizations * 100) /
    totalAmountUsedForWalletRecharges;
  const availableWidthPercent =
    (walletBalance * 100) / totalAmountUsedForWalletRecharges;

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const stylesParsed = styles as any;

  const userStatistics = [
    {
      label: "Lifetime Amount Topped Up",
      value: totalAmountUsedForWalletRecharges,
      color: Colors.total,
    },
    {
      label: "Transactions",
      value: totalAmountSpentInTransactions,
      color: Colors.transaction,
    },
    {
      label: "Pending Transactions",
      value: totalAmountSpentInPendingAuthorizations,
      color: Colors.pendingAuthorization,
    },
    {
      label: "Available to Spend",
      value: walletBalance,
      color: Colors.walletBalance,
    },
    {
      label: "Disputed / Past Due",
      value: amountCorrection,
      color: Colors.walletBalance,
    },
  ];

  return (
    <>
      {/* <div className="text-sm font-medium md:text-base">{`${centToDollarFormatter(
        totalAmountUsedForWalletRecharges,
      )}`}</div>
      <div className={classNames(styles["progress-bar"])}>
        <div
          className={classNames(
            stylesParsed["progress-bar-part"],
            stylesParsed.transaction,
            "cursor-pointer",
          )}
          style={{
            left: 0,
            width: `${transactionWidthPercent}%`,
            backgroundColor: `${Colors.transaction}`,
          }}
        ></div>
        <div
          className={classNames(
            stylesParsed["progress-bar-part"],
            stylesParsed.pendingAuthorization,
            "cursor-pointer",
          )}
          style={{
            left: `${transactionWidthPercent}%`,
            width: `${pendingAuthorizationWidthPercent}%`,
            backgroundColor: `${Colors.pendingAuthorization}`,
          }}
        ></div>
        <div
          className={classNames(
            stylesParsed["progress-bar-part"],
            stylesParsed.walletBalance,
            "cursor-pointer",
          )}
          style={{
            left: `${
              transactionWidthPercent + pendingAuthorizationWidthPercent
            }%`,
            width: `${availableWidthPercent}%`,
            backgroundColor: `${Colors.walletBalance}`,
          }}
        ></div>
      </div> */}
      <div className="flex flex-col w-full p-4 mr-4 bg-white rounded-md shadow gap-y-2">
        <div className="py-2 font-semibold text-brown-500 md:text-lg">
          Account Details
        </div>
        <div className="flex flex-row justify-between w-full">
          <div>{userStatistics[0].label}</div>
          <div className="text-sm md:text-base">
            {`+ ${centToDollarFormatter(userStatistics[0].value)}`}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full">
          <div>{userStatistics[1].label}</div>
          <div className="text-sm md:text-base">
            {`- ${centToDollarFormatter(userStatistics[1].value)}`}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full">
          <div>{userStatistics[2].label}</div>
          <div className="text-sm md:text-base">
            {`- ${centToDollarFormatter(userStatistics[2].value)}`}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full">
          <div>{userStatistics[3].label}</div>
          <div className="text-sm md:text-base">
            {`${centToDollarFormatter(userStatistics[3].value)}`}
          </div>
        </div>
        {userStatistics[4].value > 0 && (
          <div className="flex flex-row justify-between w-full">
            <div>{userStatistics[4].label}</div>
            <div className="text-sm md:text-base">
              {`${centToDollarFormatter(userStatistics[4].value)}`}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
