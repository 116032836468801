/** @format */

export class customError extends Error {
  code: string;
  override message: string;
  meta: Record<string, unknown> | undefined;
  cause: string | undefined;

  constructor({
    code,
    message,
    cause,
    meta,
  }: {
    code: string;
    message: string;
    meta?: Record<string, unknown>;
    cause?: string;
  }) {
    super();
    this.code = code;
    this.cause = cause;
    this.meta = meta;
    this.message = message;
  }
}

export const codeOk = "200";
export const codeBadRequest = "400";
export const codeInternalServer = "500";
export const codeResourceNotFound = "404";
export const codeUnauthorized = "401";
export const codeForbidden = "403";

export const UserObjectUnavailable = "User object cannot be found";
export const SettingUnavailable = "Setting cannot be found";
export const CardHolderMembershipUnavailable =
  "CardholderMembership object cannot be found";
export const FormInvalidInput = "Form input invalid";
export const MaxCardOrdered = "The maximum number of cards have been ordered";
export const AccountInactive = "Account is inactive";
export const ErrorFromStripe = "There is an error from Stripe.";
export const ActivityLoggingFailure = "Logging user activity failed.";
export const WalletInsufficient =
  "Insufficient funds in your RoadFlex account.";

export const UserAuthentication = {
  code: "401",
  message: "User is unauthorized",
};

export const AdminAuthentication = {
  code: "401",
  message: "Admin is unauthorized",
};

export const NotLoggedIn = {
  code: "403",
  message: "Not logged in",
};

export const UserDetailsUnavailable = {
  code: "400",
  message: "User details unavailable",
  meta: { description: "user details unavailable from token" },
};

export const GenericError = {
  code: "404",
  message: "Generic error",
};

export const AdditionalUserObjectUnavailable = {
  code: "404",
  message: "Additional user details object cannot be found",
};

export const ReadDriverError = {
  code: "404",
  message: "Error reading driver(s)",
};

export const DeleteDriverError = {
  code: "404",
  message: "Error deleting driver(s)",
};

export const StripeError = {
  code: "404",
  message: "Error updating information on stripe",
};

export const Failure = {
  code: "400",
  message: "Request unsuccessful",
};

export const Success = {
  code: "200",
  message: "Request successful",
};

// Error Code -> RF0001, RF0002, RF0003, RF0004 ........
export const ERROR_CODES = {
  unSpecifiedError: "UN-SP",
  noError: "NO-ERR",
  userNotFound: "US-NF",
  userNotAvailable: "US-NA",
  userNotVerified: "US-NV",
  cardHolderIdNotCreated: "CHID-NC",
  userSettingNotFound: "US-ST-NF",
  maxCardOrdered: "MX-CO",
  cardHolderSettingNotFound: "CH-ST-NF",
  reachedMaxTransactionLimit: "MX-TL",

  stripeCardIssueError: "STRIPE-CI",
  stripeCardUpdateError: "STRIPE-CU",

  globalSettingNotFound: "GS-NF",
  cardDetailsNotFound: "CD-NF",
};

export const getErrorCode = (errorMsg: string) => {
  if (!errorMsg?.length || typeof errorMsg !== "string") {
    return ERROR_CODES.noError;
  }
  const arr = errorMsg.split(":");
  let code = arr.pop()?.trim();
  code = code?.replace("[", "");
  code = code?.replace("]", "");
  if (Object.values(ERROR_CODES).includes(code as string)) {
    return code;
  }
  return ERROR_CODES.unSpecifiedError;
};

export const UnauthorizedAccess = "Unauthorized Access";

// ROADFLEX ERRORS

// Please follow the naming convention to define the object -> Error<NameOfTheError>
// export const ErrorName = {
//   code: "RFXXXX",
//   type: "ERROR TYPE",
//   message: "error message", -> The message that will be returned from backend and shown to the user on ui side
//   success: false,
// };

export const ErrorUnSpecifiedError = {
  code: "RF0000",
  type: "UnSpecified",
  message: "Unspecified Error",
  success: false,
};
export const ErrorUserNotFound = {
  code: "RF0001",
  type: "userNotFound",
  message: "Unauthorized access: user not found",
  success: false,
};
export const ErrorUserNotAvailable = {
  code: "RF0002",
  type: "userNotAvailable",
  message: "User information cannot be retrieved",
  success: false,
};
export const ErrorUserNotVerified = {
  code: "RF0003",
  type: "userNotVerified",
  message: "User not verified!",
  success: false,
};
export const ErrorCardHolderIdNotCreated = {
  code: "RF0004",
  type: "cardHolderIdNotCreated",
  message: "CardHolder Id not created",
  success: false,
};
export const ErrorUserSettingNotFound = {
  code: "RF0005",
  type: "userSettingNotFound",
  message: "User settings are not found",
  success: false,
};
export const ErrorMaxCardOrdered = {
  code: "RF0006",
  type: "maxCardOrdered",
  message: "You have crossed Maximum Card Limit",
  success: false,
};
export const ErrorCardHolderSettingNotFound = {
  code: "RF0007",
  type: "",
  message: "User's cardholder setting is not found",
  success: false,
};
export const ErrorReachedMaxTransactionLimit = {
  code: "RF0008",
  type: "",
  message:
    "You have reached maximum transaction limit! Please clear your outstanding.",
  success: false,
};
export const ErrorStripeCardIssueError = {
  code: "RF0009",
  type: "stripeCardIssueError",
  message: "Something went wrong while issuing card on stripe!",
  success: false,
};
export const ErrorStripeCardUpdateError = {
  code: "RF0010",
  type: "",
  message: "Something went wrong while updating card info on stripe!",
  success: false,
};
export const ErrorGlobalSettingNotFound = {
  code: "RF0011",
  type: "",
  message: "Global settings are not found!",
  success: false,
};
export const ErrorCardDetailsNotFound = {
  code: "RF0012",
  type: "",
  message: "Card details are not found!",
  success: false,
};
export const ErrorLogUserActivity = {
  code: "RF0013",
  type: "",
  message: "Logging user activity failed!",
  success: false,
};
export const ErrorFileNotFound = {
  code: "RF0014",
  type: "",
  message: "File not found!",
  success: false,
};
export const ErrorAccountInactive = {
  code: "RF0015",
  type: "",
  message:
    "Account is inactive. Please activate the account before ordering cards.",
  success: false,
};
export const ErrorCardRuleNotFound = {
  code: "RF0016",
  type: "",
  message: "Card rule not found!",
  success: false,
};
export const SuccessfulRequest = {
  code: "200",
  type: "OK",
  message: "Successful request",
  success: true,
};

export const BadRequest = {
  code: "400",
  type: "Bad Request",
  message: "",
  success: false,
};

export const Unauthorized = {
  code: "401",
  type: "Unauthorized",
  message: "",
  success: false,
};

export const Forbidden = {
  code: "403",
  type: "Forbidden",
  message: "",
  success: false,
};

export const NotFound = {
  code: "404",
  type: "Not Found",
  message: "",
  success: false,
};
export const PreconditionFailed = {
  code: "412",
  type: "Precondition Failed",
  message: "",
  success: false,
};
export const InternalServerError = {
  code: "500",
  type: "Internal Server Error",
  message: "",
  success: false,
};
export const ServiceUnavailable = {
  code: "503",
  type: "Service Unavailable",
  message: "",
  success: false,
};

export const InvalidSignIn = {
  code: "600",
  type: "Unauthorized",
  message: "",
  success: false,
};

export const UserAccountNotFound = {
  code: "602",
  type: "",
  message: "User account with this email cannot be found",
  success: false,
};

export const PasswordInvalid = {
  code: "601",
  type: "",
  message: "Invalid password",
  success: false,
};

export const PasswordExpired = {
  code: "601",
  type: "",
  message: "password Expired",
  success: false,
};

export const PasswordNotSet = {
  code: "602",
  type: "",
  message: "",
  success: false,
};

export const PasswordAlreadySet = {
  code: "602",
  type: "",
  message: "Password is already set, please reset your password to update.",
  success: false,
};

export const EmailAlreadyExist = {
  code: "602",
  type: "",
  message: "Email Address already exists. Please use another email.",
  success: false,
};

export const EmailNotVerified = {
  code: "602",
  type: "",
  message: "Email not verified",
  success: false,
};

export const EmailVerificationPending = {
  code: "602",
  type: "",
  message: "Please verify email address.",
  success: false,
};

export const AuthTokenInvalid = {
  code: "602",
  type: "",
  message: "Invalid Token.",
  success: false,
};

export const AuthTokenExpired = {
  code: "602",
  type: "",
  message: "Token is Expired.",
  success: false,
};

export const TransactionError = {
  code: "602",
  type: "",
  message: "",
  success: false,
};

export const DatabaseUnavailableError = {
  code: "610",
  type: "",
  message: "Database Unavailable",
  success: false,
};

export const xyz = {
  code: "611",
  type: "Unknown Error",
  message: "Database Unavailable",
  success: false,
};
