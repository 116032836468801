import { useQuery } from "@apollo/client";
import { READ_ACCOUNT_DOCUMENTS } from "@roadflex/graphql";
import { AccountDocumentType } from "@roadflex/types";

type UseReadAccountDocumentsProps = {
  email?: string;
  showAll?: boolean;
};

export const useReadAccountDocuments = ({
  email,
  showAll,
}: UseReadAccountDocumentsProps) => {
  const { data, refetch, ...rest } = useQuery<{
    readAccountDocuments: {
      code: string;
      message: string;
      accountDocuments: AccountDocumentType[];
    };
  }>(READ_ACCOUNT_DOCUMENTS, {
    fetchPolicy: "no-cache",
    variables: { data: { email, showAll } },
  });
  return { data, refetch, ...rest };
};
