/** @format */

import {
  ENUM_WALLET_ACTIVITY_TYPE,
  ENUM_WALLET_ITEMS_TYPE,
  ENUM_WALLET_TYPE,
} from "@prisma/client";
import { UserAccountStatusData, UserAuthType } from "@roadflex/types";
import { DataTablePFSEvent } from "primereact/datatable";
import MerchantCategoryBreakdownGraph from "../graphs/merchant-category-breakdown-graph.ui";
import WeeklySpendGraph from "../graphs/weekly-spend-graph.ui";
import { AccountActivityUI } from "./panels/account-activity-panel.ui";
import { AccountAttentionUI } from "./panels/account-attention-panel.ui";
import { AccountBalanceUI } from "./panels/account-balance-panel.ui";
import { FraudActivityDetails } from "./panels/fraud-panel.ui";
import { PrepaidUsersStatistics } from "./statistics/prepaid-statistics/prepaid-statistics";

type PrepaidDashboardUIProps = {
  businessName?: string;
  currentData: {
    creditBalance: number;
    bonusAmount: number;
    totalFuelQuantity: number;
    bonusType: string;
    referralBalance: number;
    signOnBalance: number;
    targetFuelQuantity: number;
    amountCorrection: number;
  };
  prepaidUsersStatistics?: {
    totalAmountSpentInPendingAuthorizations: number;
    totalAmountSpentInTransactions: number;
    totalAmountUsedForWalletRecharges: number;
  };
  weeklySpendsData: Array<[number, number]>;
  categoryBreakdown: { [key: string]: string };
  userAccountStatusData?: UserAccountStatusData;
  totalFraudDeclinedTransaction: number;
  totalFraudSavings: number;
  totalLifeTimeSavings: number;
  readCurrentUserAuth: UserAuthType;
  walletItemsData: {
    walletItemsList: {
      type: ENUM_WALLET_TYPE;
      ItemType: ENUM_WALLET_ITEMS_TYPE;
      amount: number;
      classification: ENUM_WALLET_ACTIVITY_TYPE;
      fuelDiscountAmount: number;
      createdAt: Date;
    }[];
    totalCount: number;
  };
  walletItemsLoading: boolean;
  walletItemsLazyParams: DataTablePFSEvent;
  setWalletItemsLazyParams: (value: DataTablePFSEvent) => void;
};

export const PrepaidDashboardUI = ({
  businessName,
  currentData,
  prepaidUsersStatistics,
  weeklySpendsData,
  categoryBreakdown,
  userAccountStatusData,
  totalFraudDeclinedTransaction,
  totalFraudSavings,
  totalLifeTimeSavings,
  readCurrentUserAuth,
  walletItemsData,
  walletItemsLoading,
  walletItemsLazyParams,
  setWalletItemsLazyParams,
}: PrepaidDashboardUIProps) => {
  return (
    <div className="mt-5 text-sm md:mt-0 md:text-base ">
      <FraudActivityDetails
        {...{
          totalFraudDeclinedTransaction,
          totalFraudSavings,
          totalLifeTimeSavings,
        }}
      ></FraudActivityDetails>
      <div className="flex flex-col gap-4 mt-4 2xl:flex-row ">
        <div className="2xl:w-5/12">
          <div className="flex flex-col h-full">
            <div className="">
              <PrepaidUsersStatistics
                businessName={businessName}
                totalAmountSpentInPendingAuthorizations={
                  prepaidUsersStatistics?.totalAmountSpentInPendingAuthorizations ||
                  0
                }
                totalAmountSpentInTransactions={
                  prepaidUsersStatistics?.totalAmountSpentInTransactions || 0
                }
                totalAmountUsedForWalletRecharges={
                  prepaidUsersStatistics?.totalAmountUsedForWalletRecharges || 0
                }
                walletBalance={
                  currentData?.referralBalance +
                  currentData?.signOnBalance +
                  currentData?.creditBalance
                }
                amountCorrection={currentData?.amountCorrection}
              ></PrepaidUsersStatistics>
            </div>
            <div className="">
              <AccountBalanceUI {...{ currentData }}></AccountBalanceUI>
            </div>
          </div>
        </div>
        <div className="2xl:w-7/12">
          <AccountAttentionUI
            {...{ userAccountStatusData }}
          ></AccountAttentionUI>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 xl:grid-cols-2 ">
        <div className="">
          <WeeklySpendGraph
            weeklySpendsData={weeklySpendsData}
            dashboardLoading={false}
          ></WeeklySpendGraph>
        </div>
        <div className="">
          <MerchantCategoryBreakdownGraph
            categoryBreakdown={categoryBreakdown}
            dashboardLoading={false}
          ></MerchantCategoryBreakdownGraph>
        </div>
      </div>
      <div className="">
        <AccountActivityUI
          readCurrentUserAuth={readCurrentUserAuth}
          walletItemsData={walletItemsData}
          walletItemsLoading={walletItemsLoading}
          walletItemsLazyParams={walletItemsLazyParams}
          setWalletItemsLazyParams={setWalletItemsLazyParams}
        ></AccountActivityUI>
      </div>
    </div>
  );
};
