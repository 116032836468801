/** @format */

import { ENUM_CHILD_USER_TYPE } from "@prisma/client";
import {
  ParentCompanyFormValues,
  ParentCompanyUserType,
} from "@roadflex/types";
import { capitalizeFirstLetter, phoneFormatter } from "@roadflex/utils";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { CustomMultiSelect } from "../../../dropdowns";
import { TextInput } from "../../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddUserUIProps extends FormikProps<ParentCompanyFormValues> {
  profilesAuthorized: ParentCompanyUserType[];
}

const AddUserUI = ({
  profilesAuthorized,
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  setFieldValue,
  touched,
  errors,
  ...rest
}: AddUserUIProps) => {
  const ProfileOptions = [];
  for (const profile of profilesAuthorized) {
    if (
      profile &&
      profile?.roles?.length &&
      profile?.roles?.length > 0 &&
      profile?.roles[0]?.role?.name &&
      profile?.roles[0]?.role?.name !== ENUM_CHILD_USER_TYPE.OWNER
    ) {
      ProfileOptions.push({
        value: profile.id,
        label: profile?.roles
          ? `${capitalizeFirstLetter(
              profile.businessName,
            )} (${capitalizeFirstLetter(
              profile?.roles[0]?.role?.name.toLowerCase().replace("_", " "),
            )})`
          : capitalizeFirstLetter(profile.businessName),
      });
    }
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full text-sm md:text-base"
    >
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8 ">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Create new parent company user
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="current-password" className="block font-medium">
              Email
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <TextInput
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                id="email"
                touched={touched.email}
                error={touched.email && Boolean(errors.email)}
                errorMessage={errors.email}
                placeholder="Email"
              ></TextInput>
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="current-password" className="block font-medium">
              Phone Number
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <TextInput
                value={values.phoneNumber}
                onChange={(event) =>
                  setFieldValue(
                    "phoneNumber",
                    phoneFormatter(event?.target?.value),
                  )
                }
                onBlur={handleBlur}
                name="phoneNumber"
                id="phoneNumber"
                touched={touched.phoneNumber}
                error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                errorMessage={errors.phoneNumber}
                placeholder="Phone Number"
                type="tel"
              ></TextInput>
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="current-password" className="block font-medium">
              Approved Profiles
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <CustomMultiSelect
                value={values.id}
                options={ProfileOptions}
                optionLabel="label"
                optionValue="value"
                placeholder="--Select Profiles--"
                className="w-full max-h-[38px] items-center "
                onChange={(e) => setFieldValue("id", e.value)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="new-password" className="block font-medium">
              Create Password
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
              <TextInput
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                name="password"
                id="password"
                touched={touched.password}
                error={touched.password && Boolean(errors.password)}
                errorMessage={errors.password}
                placeholder="Enter a Password"
                type="password"
              ></TextInput>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type={ButtonType.Submit}
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              disabled={
                !values.password ||
                !values.phoneNumber ||
                !values.email ||
                isSubmitting ||
                !isEmpty(errors)
              }
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddUserUI;
