import { useApolloClient } from "@apollo/client";
import {
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";
import { useReadAlert } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { deleteCookie, getCookie } from "@roadflex/web-lib";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { DataTablePFSEvent } from "primereact/datatable";
import { useState } from "react";
import {
  NavigationLinksFifth,
  NavigationLinksFirst,
  NavigationLinksFourth,
  NavigationLinksSecond,
  NavigationLinksSeventh,
  NavigationLinksSixth,
  NavigationLinksThird,
} from "../fuelcardRoutes";

interface NavigationLinksProps {
  readCurrentUserAuth?: UserAuthType;
}

export default function NavigationLinks(props: NavigationLinksProps) {
  const router = useRouter();
  const client = useApolloClient();

  const alertsInitialLazyParams: DataTablePFSEvent = {
    first: 0,
    rows: 10,
    page: 0,
    filters: {},
    sortField: "createdAt",
    sortOrder: -1,
    multiSortMeta: null,
    deleted: false,
  };

  const { data, loadingAlerts, refetchAlerts } = useReadAlert({
    alertsLazyParams: alertsInitialLazyParams,
    readCountsOnly: true,
  });

  const { readCurrentUserAuth } = props;
  const [page, setPage] = useState(1);

  const handleLogout = async () => {
    if (getCookie("impersonatorToken")) {
      deleteCookie("impersonatorToken");
    } else {
      deleteCookie("token");
      deleteCookie("firstLogin");
    }
    await client.clearStore();
    router.push("/");
  };

  const customerTypes = readCurrentUserAuth?.fuelCard?.customerType
    ? [readCurrentUserAuth?.fuelCard?.customerType]
    : [];
  const roles = readCurrentUserAuth?.userRole
    ? [readCurrentUserAuth?.userRole]
    : [];

  const isParentCompanyUser = readCurrentUserAuth?.isParentCompanyUser;

  const NavFirst = NavigationLinksFirst.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );
  const NavSecond = NavigationLinksSecond.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );

  const NavThird = NavigationLinksThird.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );

  const NavFourth = NavigationLinksFourth.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );

  const NavFifth = NavigationLinksFifth.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );

  const NavSixth = NavigationLinksSixth.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );
  const NavSeventh = NavigationLinksSeventh.filter((navItem) =>
    !isParentCompanyUser
      ? !navItem.showForParentCompanyUser
        ? readCurrentUserAuth?.isDefaultUser
          ? navItem.canAccess.some(
              (role) =>
                customerTypes.includes(role) && !navItem.hideForDefaultUser,
            ) && navItem.canAccessRole.some((role) => roles.includes(role))
          : navItem.canAccess.some((role) => customerTypes.includes(role)) &&
            navItem.canAccessRole.some((role) => roles.includes(role))
        : false
      : navItem.showForParentCompanyUser,
  );

  return (
    <div className="flex flex-col flex-grow mt-5">
      <nav className="flex flex-col justify-between flex-1 h-full pb-4 space-y-2">
        <div className="flex flex-col flex-1 h-full space-y-2">
          {NavFirst.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name}
              </a>
            </Link>
          ))}

          {NavSecond.length > 0 && (
            <div className="pt-8 pl-6 text-sm font-medium text-white md:text-base">
              FLEET SETUP
            </div>
          )}
          {NavSecond.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name}
              </a>
            </Link>
          ))}
          {!readCurrentUserAuth?.isDefaultUser && NavThird.length > 0 && (
            <div className="pt-8 pl-6 text-sm font-medium text-white md:text-base">
              BILLING & PAYMENTS
            </div>
          )}
          {NavThird.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name}
              </a>
            </Link>
          ))}
          {!readCurrentUserAuth?.isDefaultUser && NavFourth.length > 0 && (
            <div className="pt-8 pl-6 text-sm font-medium text-white md:text-base">
              NOTIFICATIONS
            </div>
          )}
          {NavFourth.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name === "Notifications" ? (
                  <span>
                    {item.name}{" "}
                    <span>
                      {(data?.readAlerts?.totalUnreadCount || 0) > 0 ? (
                        <span className="text-white animate-pulse">
                          ({data?.readAlerts?.totalUnreadCount} unread)
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                ) : (
                  `${item.name}`
                )}
              </a>
            </Link>
          ))}
          {!readCurrentUserAuth?.isDefaultUser && NavFifth.length > 0 && (
            <div className="pt-8 pl-6 text-sm font-medium text-white md:text-base">
              TELEMATICS
            </div>
          )}
          {NavFifth.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name}
              </a>
            </Link>
          ))}
          {!readCurrentUserAuth?.isDefaultUser && NavSixth.length > 0 && (
            <div className="pt-8 pl-6 text-sm font-medium text-white md:text-base">
              REPORTS
            </div>
          )}
          {NavSixth.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name}
              </a>
            </Link>
          ))}
          {NavSeventh.length > 0 && (
            <div className="pt-8 pl-6 text-sm font-medium text-white md:text-base">
              SETTINGS
            </div>
          )}
          {NavSeventh.map((item) => (
            <Link href={item.href} key={item.name} prefetch={false}>
              <a
                href={item.href}
                key={item.name}
                onClick={(e) => setPage(item.current)}
                className={classNames(
                  item.href === router?.route || item?.hrefAlt === router?.route
                    ? "font-semibold"
                    : "hover:animate-pulse",
                  item.premiumIndicator ? "text-yellow-400" : "text-white",
                  `text-white group flex items-center px-6 py-1 leading-6 md:text-base text-sm`,
                )}
                aria-current={page === item.current ? "page" : undefined}
              >
                <item.icon
                  aria-hidden="true"
                  className={classNames(
                    item.premiumIndicator ? "text-yellow-400" : "text-white",
                    `flex-shrink-0 w-6 h-6 mr-2 text-white`,
                  )}
                />
                {item.name}
              </a>
            </Link>
          ))}
        </div>

        <div className="flex flex-row pt-8 pl-6 md:!mt-auto overflow-x-hidden ">
          <Link href="https://roadflex.zendesk.com/hc/en-us" prefetch={false}>
            <a
              href="https://roadflex.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
              // onClick={(e) => setPage(10)} // 10 is picked from fuelcardRoutes.ts which is value for help page
              className="flex items-center w-full text-sm leading-6 text-white group md:text-base"
            >
              <LifebuoyIcon
                className="flex-shrink-0 w-6 h-6 mr-2 text-white"
                aria-hidden="true"
              />
              Help Center
            </a>
          </Link>
          <button
            className={classNames(
              "text-white bg-brown-800 hover:animate-pulse cursor-pointer",
              "group flex items-center px-3 py-2 text-sm md:text-base leading-6 w-[50px] hover:w-full transition-all duration-500",
            )}
            onClick={handleLogout}
          >
            <ArrowRightOnRectangleIcon
              className="flex-shrink-0 w-6 h-6 text-white"
              aria-hidden="true"
            />
            <span className="ml-4 text-sm md:text-base">Logout</span>
          </button>
        </div>
      </nav>
    </div>
  );
}
