/** @format */

import {
  EnvelopeIcon,
  EnvelopeOpenIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ENUM_CHILD_USER_TYPE } from "@prisma/client";
import {
  useDeleteAlert,
  useReadAlert,
  useUpdateAlert,
} from "@roadflex/react-hooks";
import { AlertType, ChildFeatureType, UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";
import { AlertsUI } from "./alerts.ui";

const typeTemplate = (rowData: AlertType) => {
  const result = rowData?.type?.split("_");
  return (
    <span className="whitespace-nowrap">
      {result[0].charAt(0) + result[0].slice(1).toLowerCase()}{" "}
      {result[1].charAt(0) + result[1].slice(1).toLowerCase()}{" "}
      {result.length === 3 && (
        <span>{result[2].charAt(0) + result[2].slice(1).toLowerCase()}</span>
      )}
    </span>
  );
};

interface AlertProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  childFeatures: ChildFeatureType[];
}

const alertsInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: {},
  sortField: "createdAt",
  sortOrder: -1,
  multiSortMeta: null,
  deleted: false,
};

const deletedAlertsInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: {},
  sortField: "createdAt",
  sortOrder: -1,
  multiSortMeta: null,
  deleted: true,
};

export const Alert = (props: AlertProps) => {
  const accountDisplaySetting =
    props?.readCurrentUserAuth.accountDisplaySetting;
  const customerType = props?.readCurrentUserAuth?.fuelCard?.customerType;
  const isPrimaryOwner =
    props?.readCurrentUserAuth?.userRole === ENUM_CHILD_USER_TYPE.OWNER &&
    props?.readCurrentUserAuth?.loggedInId ===
      props?.readCurrentUserAuth?.primaryOwnerId;

  const router = useRouter();
  const [tabOpen, setTabOpen] = useState(1);
  useEffect(() => {
    if (!router.isReady) return;
    const { tab } = router.query;

    let element = null;

    if (tab === "1") {
      setTabOpen(1);
      return;
    }
    if (tab === "2") {
      setTabOpen(2);
      return;
    }
    if (tab === "3") {
      setTabOpen(3);
      return;
    }
    if (tab) {
      element = document.querySelector(`#${tab}`);
    }
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 80,
        left: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const tabs = [
    { id: 1, name: "Notifications", href: "", current: true },
    { id: 2, name: "Archived Notifications", href: "", current: false },
    ...(isPrimaryOwner
      ? [
          {
            id: 3,
            name: "Personal Notifications Settings",
            href: "",
            current: false,
          },
        ]
      : []),
  ];

  const [alertsLazyParams, setAlertsLazyParams] = useState<DataTablePFSEvent>({
    ...alertsInitialLazyParams,
  });
  const [deletedAlertsLazyParams, setDeletedAlertsLazyParams] =
    useState<DataTablePFSEvent>({
      ...deletedAlertsInitialLazyParams,
    });

  const { data, loadingAlerts, refetchAlerts } = useReadAlert({
    alertsLazyParams:
      tabOpen === 1 ? alertsLazyParams : deletedAlertsLazyParams,
    readCountsOnly: false,
  });

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const { updateAlerthandleSubmit, updateAlerthandleValues } = useUpdateAlert({
    initialValues: {
      id: "",
      markAsRead: true,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
      }
    },
  });

  const markMessageAsRead = (id: string) => {
    updateAlerthandleValues.id = id;
    updateAlerthandleSubmit();
  };

  const {
    archiveAlerthandleValues,
    archiveAlerthandleSubmit,
    archiveAlertSubmitting,
    ...rest
  } = useDeleteAlert({
    initialValues: {
      id: "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      } else {
        Toast({ type: "error", message: res?.message || "" });
      }
    },
  });

  useEffect(() => {
    refetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsLazyParams || deletedAlertsLazyParams]);

  const readTemplate = (rowData: AlertType) => {
    return (
      <span>
        <Button
          variant={ButtonVariant.Transparent}
          size={ButtonSize.Wrap}
          className="w-full mt-2 sm:w-auto sm:mt-0 sm:ml-3 md:ml-0 whitespace-nowrap"
          onClick={() => {
            setShowMessage(true);
            setMessage(rowData?.message);
            if (!rowData?.read && rowData?.id) {
              updateAlerthandleValues.id = rowData?.id;
              updateAlerthandleSubmit();
            }
          }}
        >
          {rowData?.read ? (
            <span>
              <EnvelopeOpenIcon
                className="w-5 h-5 text-brown-500"
                aria-hidden="true"
              />
            </span>
          ) : (
            <EnvelopeIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
          )}
        </Button>
      </span>
    );
  };

  const archiveTemplate = (rowData: AlertType) => {
    return (
      <span>
        <Button
          variant={ButtonVariant.Transparent}
          size={ButtonSize.Wrap}
          className="w-full mt-2 sm:w-auto sm:mt-0 sm:ml-3 md:ml-0 whitespace-nowrap"
          disabled={!rowData?.read}
          onClick={() => {
            if (!rowData.deleted && rowData?.id) {
              if (rowData?.read) {
                archiveAlerthandleValues.id = rowData.id;
                setConfirmModal(true);
              } else {
                Toast({
                  type: "error",
                  message: "You can only archive a message that is read",
                });
              }
            }
          }}
        >
          <span>
            <TrashIcon className="w-5 h-5 text-brown-500" aria-hidden="true" />
          </span>
        </Button>
      </span>
    );
  };

  const onTabChange = (value: number) => {
    if (value === 1) {
      setAlertsLazyParams({
        ...alertsInitialLazyParams,
        deleted: false,
      });
    } else {
      setDeletedAlertsLazyParams({
        ...deletedAlertsInitialLazyParams,
        deleted: true,
      });
    }
    setTabOpen(value);
  };
  const onPageAndSortAlerts = (event: DataTablePFSEvent) => {
    setAlertsLazyParams({ ...alertsLazyParams, ...event });
  };

  const onPageAndSortDeletedAlerts = (event: DataTablePFSEvent) => {
    setDeletedAlertsLazyParams({ ...deletedAlertsLazyParams, ...event });
  };

  return (
    <AlertsUI
      {...{
        alertsList: data?.readAlerts?.alerts || [],
        alertsListFirstThree: data?.readAlerts?.latestAlerts || [],
        totalCount: data?.readAlerts.totalCount || 0,
        accountDisplaySetting: accountDisplaySetting,
        markMessageAsRead,
        archiveTemplate,
        readTemplate,
        typeTemplate,
        loadingAlerts,
        message,
        setMessage,
        showMessage,
        setShowMessage,
        confirmModal,
        setConfirmModal,
        values: archiveAlerthandleValues,
        handleSubmit: archiveAlerthandleSubmit,
        isSubmitting: archiveAlertSubmitting,
        tabs,
        tabOpen,
        setTabOpen: onTabChange,
        alertsLazyParams,
        onPageAndSortAlerts,
        deletedAlertsLazyParams,
        onPageAndSortDeletedAlerts,
        ...rest,
        customerType,
        childFeatures: props?.childFeatures,
      }}
    ></AlertsUI>
  );
};
