/** @format */

import { CogIcon } from "@heroicons/react/24/outline";
import { BlobProvider, pdf } from "@react-pdf/renderer";
import { useBillingSummary } from "@roadflex/react-hooks";
import { BillingSummaryType } from "@roadflex/types";
import { convertPSTToDateOnlyDashFormat } from "@roadflex/utils";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { StatementPdf } from "../../load-pdf";
import { Toast } from "../../toast-message/toast";

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const JSZip = require("jszip");

export const StatementMultipleDownloads = ({
  multipleDownloadData,
  setMultipleDownloadData,
  setShowExportModal,
  setShowLoading,
  setShowComplete,
}: {
  setMultipleDownloadData: (value: string[]) => void;
  multipleDownloadData: string[];
  setShowExportModal: (showExportModal: boolean) => void;
  setShowLoading: (showLoading: boolean) => void;
  setShowComplete: (showComplete: boolean) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [info, setinfo] = useState<BillingSummaryType[]>([]);
  const [disable, setdisable] = useState(false);

  const { makeBillingSummary } = useBillingSummary();

  async function getStatements() {
    try {
      const newData = {
        billingCycleIds: multipleDownloadData,
      };
      setdisable(true);
      const response = await makeBillingSummary({
        variables: { data: newData },
      });

      if (
        response.data?.billingSummary &&
        response.data.billingSummary.length > 0
      ) {
        setinfo(response.data?.billingSummary);
      }
      if (
        !response?.data?.billingSummary[0]?.transactionDetails?.some(
          (transactionDetail) => transactionDetail?.transactions?.length > 0,
        )
      ) {
        Toast({
          type: "info",
          message: "There are no transactions in this duraction",
        });
        setMultipleDownloadData([]);
      }
      setdisable(false);
    } catch (error) {
      console.error(error);
      Toast({
        type: "error",
        message: "There is a problem fetching transaction data",
      });
      setdisable(false);
      setMultipleDownloadData([]);
    }
  }

  useEffect(() => {
    getStatements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(info)) {
      (async () => {
        try {
          setShowLoading(true);
          setShowComplete(false);
          setShowExportModal(true);
          const zip = new JSZip();

          for (let i = 0; i < info.length; i++) {
            const billingSummary = info[i];
            const blob = await pdf(
              <StatementPdf {...billingSummary} />,
            ).toBlob();
            zip.file(
              `${convertPSTToDateOnlyDashFormat(
                billingSummary?.startingDate.toString(),
              )}-${convertPSTToDateOnlyDashFormat(
                billingSummary?.endingDate.toString(),
              )}.pdf`,
              blob,
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          zip.generateAsync({ type: "blob" }).then((blobdata: any) => {
            const zipblob = new Blob([blobdata]);
            const elem = window.document.createElement("a");
            elem.href = window.URL.createObjectURL(zipblob);
            elem.download = `statements.zip`;
            setShowLoading(false);
            setShowComplete(true);
            elem.click();
            setMultipleDownloadData([]);
          });
        } catch (e) {
          setShowExportModal(false);
          Toast({
            type: "error",
            message: "There was some problem processing the data",
          });
          setdisable(false);
          setMultipleDownloadData([]);
        }
      })();
    }
  }, [info]);

  return (
    <Button
      variant={ButtonVariant.AppOrange}
      size={ButtonSize.AppSize}
      disabled={true}
      className="!h-fit ml-3"
    >
      <CogIcon className="w-5 h-5 mr-3 text-scarpa-flow-500 animate-spin"></CogIcon>
      Download
      <div className="hidden">
        {info.map((billingSummary) => (
          <BlobProvider
            key={billingSummary.billingCycleInvoiceId}
            document={<StatementPdf {...billingSummary} />}
          >
            {({ blob, url, loading, error }) => {
              return loading ? "Loading Doc 1..." : "Download Doc 1";
            }}
          </BlobProvider>
        ))}
      </div>
    </Button>
  );
};
