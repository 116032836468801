/** @format */

import { TrashIcon } from "@heroicons/react/24/outline";
import { STATE_CODES } from "@roadflex/constants";
import { ExtendedAddressType } from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import { forwardRef, LegacyRef, Ref } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";
import { RadioInput, TextInput } from "../../inputs";
import { Toast } from "../../toast-message/toast";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ShippingAddressUIProps
  extends FormikProps<{
    businessId: string;
    deliveryAddress: ExtendedAddressType;
  }> {
  addresses: ExtendedAddressType[];
  onAddressSelect: (id: string) => void;
  onAddressRemove: (id: string) => void;
  setShowAddressInputs: (value: boolean) => void;
  showAddressInputs: boolean;
}

const ShippingAddressUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      errors,
      touched,
      handleReset,
      setFieldValue,
      setFieldTouched,
      addresses,
      onAddressSelect,
      onAddressRemove,
      setShowAddressInputs,
      showAddressInputs,
      ...rest
    }: ShippingAddressUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { shippingAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      shippingAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };

    return (
      <div className="shipping-address">
        <h2 className="mb-2">Shipping Address</h2>

        {/* Existing Addresses */}
        <div className="space-y-4 address-list">
          {addresses.map((address) => (
            <div
              key={address.id}
              className="flex items-center justify-between rounded-md"
            >
              <div className="flex items-center gap-2">
                <RadioInput
                  type="radio"
                  className="!rounded"
                  onChange={(e) => {
                    onAddressSelect(address.id);
                  }}
                  checked={address.isPrimary}
                  onBlur={handleBlur}
                  placeholder=""
                  label={
                    <label className="">
                      {`${address.streetAddress}${
                        address.streetDetails
                          ? `, ${address.streetDetails}`
                          : ""
                      }, ${address.city}, ${address.state} ${address.zip}`}
                    </label>
                  }
                ></RadioInput>
              </div>
              {!address.isPrimary && (
                <div className="flex gap-4 actions">
                  <Button
                    variant={ButtonVariant.Transparent}
                    size={ButtonSize.Small}
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddressRemove(address.id);
                    }}
                  >
                    <TrashIcon className="w-4 h-4 text-red-500"></TrashIcon>
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Add/Edit Address Form */}
        {!showAddressInputs && (
          <Button
            onClick={() => {
              if (addresses.length === 2) {
                Toast({
                  type: "error",
                  message:
                    "A maximum of two addresses is allowed. Please delete one if you wish to add a new address.",
                });
              } else {
                setShowAddressInputs(true);
              }
            }}
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.AppSize}
            disabled={isSubmitting}
            className="mt-2"
          >
            Add New Address
          </Button>
        )}
        <form
          className={classNames(
            showAddressInputs ? "" : "hidden",
            "mt-6 new-address-form",
          )}
        >
          <h3 className="mb-4 font-medium">Add New Address</h3>
          <div>
            <div className="flex flex-col gap-4 mt-4 sm:flex-row flex-grow-1">
              <div className="relative w-full">
                <TextInput
                  label="Street Address"
                  labelClass="font-medium"
                  name="deliveryAddress.streetAddress"
                  ref={shippingAutoCompleteRef as Ref<HTMLInputElement>}
                  value={values?.deliveryAddress?.streetAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={30}
                  error={
                    touched?.deliveryAddress?.streetAddress &&
                    Boolean(errors?.deliveryAddress?.streetAddress)
                  }
                  errorMessage={errors?.deliveryAddress?.streetAddress}
                  touched={touched?.deliveryAddress?.streetAddress}
                  variant="medium"
                  placeholder="715 Townsend Street"
                  required
                ></TextInput>
              </div>

              <div className="relative w-full">
                <TextInput
                  label="Additional Info"
                  labelClass="font-medium"
                  name="deliveryAddress.streetDetails"
                  value={values?.deliveryAddress?.streetDetails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={200}
                  error={
                    touched?.deliveryAddress?.streetDetails &&
                    Boolean(errors?.deliveryAddress?.streetDetails)
                  }
                  errorMessage={errors?.deliveryAddress?.streetDetails}
                  touched={touched?.deliveryAddress?.streetDetails}
                  variant="medium"
                  placeholder="Apt, Floor, Suite, etc (optional)"
                  required
                ></TextInput>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-4 sm:flex-row flex-grow-1">
              <div className="relative w-full">
                <TextInput
                  label="City"
                  labelClass="font-medium"
                  name="deliveryAddress.city"
                  value={values?.deliveryAddress?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={15}
                  error={
                    touched?.deliveryAddress?.city &&
                    Boolean(errors?.deliveryAddress?.city)
                  }
                  errorMessage={errors?.deliveryAddress?.city}
                  touched={touched?.deliveryAddress?.city}
                  variant="medium"
                  required
                  placeholder="San Francisco"
                ></TextInput>
              </div>

              <div className="relative w-full">
                <Select
                  label="State"
                  labelClass="font-medium"
                  value={values?.deliveryAddress?.state}
                  options={[
                    {
                      label: "-- Select a State--",
                      value: "",
                      disabled: true,
                    },
                  ].concat(
                    STATE_CODES.map((m) => {
                      return {
                        label: `${m.label} (${m.value})`,
                        value: m.value,
                        disabled: false,
                      };
                    }),
                  )}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) =>
                    setFieldValue("deliveryAddress.state", e.value)
                  }
                  onBlur={() => setFieldTouched("deliveryAddress.state", true)}
                  placeholder="Select"
                  touched={touched?.deliveryAddress?.state}
                  error={
                    touched?.deliveryAddress?.state &&
                    Boolean(errors?.deliveryAddress?.state)
                  }
                  errorMessage={errors?.deliveryAddress?.state}
                  required
                ></Select>
              </div>

              <div className="relative w-full">
                <TextInput
                  label="Zip / Postal"
                  labelClass="font-medium"
                  name="deliveryAddress.zip"
                  value={values?.deliveryAddress?.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={5}
                  error={
                    touched?.deliveryAddress?.zip &&
                    Boolean(errors?.deliveryAddress?.zip)
                  }
                  errorMessage={errors?.deliveryAddress?.zip}
                  touched={touched?.deliveryAddress?.zip}
                  variant="medium"
                  required
                  placeholder="94305"
                ></TextInput>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            {showAddressInputs && (
              <div className="flex gap-2">
                <Button
                  onClick={() => {
                    setShowAddressInputs(false);
                  }}
                  variant={ButtonVariant.Red}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant={ButtonVariant.Primary}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  },
);

export default ShippingAddressUI;
