import {
  BuildingLibraryIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import { PlaidType, UserAuthType } from "@roadflex/types";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import getConfig from "next/config";
import { useCallback, useState } from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import BankConnectionModal from "../../modals/bank-connection-modal/bank-connection-modal";
import PlaidPendingConnection from "./PlaidPendingConnection";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = publicRuntimeConfig;

const stripeResponse = loadStripe(`${NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

interface PlaidPaymentMethodsUIProps {
  savePlaidLoading: boolean;
  plaidData: PlaidType[];
  businessId?: string;
  plaidToken?: { linkToken: string };
  handleSubmit: (data: {
    isUpdateMode?: boolean;
    plaidId?: string;
    publicToken: string;
    institution: string;
    businessId?: string;
    pendingConnection?: boolean;
  }) => void;
  plaidTokenLoading: boolean;
  primaryPaymentMethodType: ENUM_PAYMENT_METHOD_TYPE;
  plaidPendingConnectionLoading: boolean;
  plaidPendingConnections: {
    linkToken: string;
    institution: string;
    plaidId: string;
  }[];
  onDeletePlaidPendingConnection: (plaidId: string) => void;
  readCurrentUserAuth: UserAuthType;
}
export const PlaidPaymentMethodsUI = (props: PlaidPaymentMethodsUIProps) => {
  const {
    savePlaidLoading,
    plaidData,
    businessId,
    plaidToken,
    handleSubmit,
    plaidTokenLoading,
    primaryPaymentMethodType,
    plaidPendingConnections,
    onDeletePlaidPendingConnection,
    readCurrentUserAuth,
  } = props;
  const [info, setInfo] = useState<{
    plaidId: string;
    accountType?: string;
    businessId?: string;
    accountId?: string;
    inputChanged: boolean;
  }>();
  const [modal, setmodal] = useState(false);
  const [selectedData, setSelectedData] = useState<PlaidType | null>(null);
  const onSuccess = useCallback<PlaidLinkOnSuccess>((token, metadata) => {
    const data = {
      institution: metadata?.institution?.name || "",
      publicToken: token || "",
      pendingConnection:
        metadata?.accounts[0]?.verification_status ===
        "pending_manual_verification"
          ? true
          : false,
    };
    handleSubmit(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const config: PlaidLinkOptions = {
    token: plaidToken?.linkToken || "",
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <div className="xl:flex xl:items-stretch">
      <div className="w-full">
        <div className="flex flex-col items-end justify-center w-full">
          <div className="flex flex-col items-center mb-2">
            <Button
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              className="flex flex-row items-center w-full step-3 sm:w-auto"
              onClick={() => open()}
              loading={plaidTokenLoading}
              disabled={!ready || plaidData.length === 5 || plaidTokenLoading}
            >
              Connect New Bank
            </Button>
            <span className="mt-2 text-sm italic">(Max 5 connections)</span>
          </div>
        </div>
        <div className="flex flex-col justify-between w-full">
          {savePlaidLoading && (
            <div className="w-full mb-4 text-center">Updating...</div>
          )}
          <div className="flex flex-row flex-wrap w-full gap-2">
            {plaidData.length > 0 &&
              plaidData.map((plaid, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      const selectedAccount = plaid.authInfo?.accounts.find(
                        (account) =>
                          plaid?.accountInfo?.subtype?.toUpperCase() ===
                            account?.subtype?.toUpperCase() &&
                          plaid.accountInfo.account_id === account.account_id,
                      );
                      setSelectedData(plaid);
                      setInfo({
                        plaidId: plaid?.id,
                        accountType: selectedAccount?.subtype,
                        businessId,
                        accountId: selectedAccount?.account_id,
                        inputChanged: false,
                      });
                      setmodal(true);
                    }}
                    className={classNames(
                      plaid.isPrimary && plaid.plaidError
                        ? "bg-red-100 border-red-100"
                        : "bg-white border-brown-500",
                      "p-3 flex-grow min-h-[95px] sm:min-h-[125px] text-brown-500 sm:min-w-[220px] sm:max-w-[250px] border rounded-md shadow flex flex-col justify-between hover:shadow-lg hover:cursor-pointer",
                    )}
                  >
                    <div className="flex flex-row items-center justify-between">
                      <div
                        className={classNames(
                          plaid.isPrimary && plaid.plaidError
                            ? "text-red-500"
                            : "",
                          "text-sm mr-3",
                        )}
                      >
                        Bank Connection
                      </div>
                      <ChevronRightIcon className="w-4 h-4"></ChevronRightIcon>
                    </div>

                    <div className="flex justify-between">
                      <div className="mr-3 text-sm font-semibold text-gray-900">
                        {plaid?.institution}{" "}
                      </div>
                      {plaid.isPrimary &&
                        primaryPaymentMethodType ===
                          ENUM_PAYMENT_METHOD_TYPE.ACH && (
                          <span className="text-sm">Primary</span>
                        )}
                    </div>
                  </div>
                );
              })}
          </div>
          {plaidData.length === 0 && (
            <div className="h-[125px] flex justify-center items-center flex-col">
              <div>
                <BuildingLibraryIcon className="w-20 h-12"></BuildingLibraryIcon>
              </div>
              <div className="max-w-[200px] text-center md:text-xl">
                No bank account connected
              </div>
            </div>
          )}
          {plaidPendingConnections.length > 0 && (
            <div className="mt-4">
              <div className="mb-2">
                <span className="font-medium">Pending Verification </span>
                <span>
                  (Complete the verification by clicking on the bank connection)
                </span>
              </div>
              <div className="flex flex-row flex-wrap w-full gap-2">
                {plaidPendingConnections.map((data, idx) => {
                  return (
                    <PlaidPendingConnection
                      key={data.linkToken}
                      institution={data.institution}
                      linkToken={data.linkToken}
                      plaidId={data.plaidId}
                      handleSubmit={handleSubmit}
                      onDeletePlaidPendingConnection={
                        onDeletePlaidPendingConnection
                      }
                    ></PlaidPendingConnection>
                  );
                })}
              </div>
            </div>
          )}

          {modal && selectedData && businessId && (
            <BankConnectionModal
              open={modal}
              setOpen={setmodal}
              selectedData={selectedData}
              info={info}
              setInfo={setInfo}
              businessId={businessId}
              primaryPaymentMethodType={primaryPaymentMethodType}
              readCurrentUserAuth={readCurrentUserAuth}
            ></BankConnectionModal>
          )}
        </div>
      </div>
    </div>
  );
};
