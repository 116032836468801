/** @format */

import classNames from "classnames";
import Link from "next/link";
import { forwardRef } from "react";
import { ButtonSize, ButtonVariant, classes } from "../constants";

/* eslint-disable-next-line */
export interface ButtonLinkProps
  extends React.HTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  className?: string;
  variant: ButtonVariant;
  size?: ButtonSize;
  pill?: boolean;
  disabled?: boolean;
  href: string;
}

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      className,
      variant = ButtonVariant.Primary,
      size = ButtonSize.Normal,
      pill,
      href = "",
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const btnClass = classNames(
      classes.base,
      classes.size[size],
      classes.variant[variant],
      {
        [classes.pill]: pill,
      },
      className,
    );

    return !disabled ? (
      <Link href={href}>
        <a ref={ref} className={btnClass} {...props}>
          {children}
        </a>
      </Link>
    ) : (
      <div className={btnClass}>{children}</div>
    );
  },
);

export default ButtonLink;
