/** @format */

import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  LifebuoyIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { ENUM_TIME_ZONE } from "@prisma/client";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  BonusType,
  ReferralValues,
  ReferredType,
  UserAuthType,
} from "@roadflex/types";
import {
  dollarFormatter,
  getCookie,
  phoneFormatter,
  setCookie,
} from "@roadflex/web-lib";
import { FormikProps } from "formik";
import getConfig from "next/config";
import Image from "next/image";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { dateTemplate } from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { TextInput } from "../../inputs";
import { ReferralStatsUI } from "./referral-stats.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;
interface ReferralUIProps extends FormikProps<ReferralValues> {
  readCurrentUserAuth: UserAuthType;
  referralCode: string;
  referralLink: string;
  isLinkCopied: boolean;
  setLinkCopied: (val: boolean) => void;
  joyride: boolean;
  setJoyride: (value: boolean) => void;
  steps: Step[];
  allReferrals: ReferredType[];
  loadingAllReferrals: boolean;
  referralEarnings: number;
  referralSetting: BonusType | null;
}

const nameTemplate = (rowData: ReferredType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData?.firstName} {rowData?.lastName}
    </span>
  );
};

const gallonTemplate = (rowData: ReferredType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData?.totalFuelQuantity > 0
        ? +rowData?.totalFuelQuantity.toFixed(2)
        : 0}
    </span>
  );
};

const bonusAmountTemplate = (rowData: ReferredType) => {
  return (
    <span className="whitespace-nowrap">
      {dollarFormatter((rowData?.bonusAmount ? +rowData.bonusAmount : 0) / 100)}
    </span>
  );
};

const statusTemplate = (rowData: ReferredType) => {
  return (
    <>
      {rowData.status === "APPLIED" && (
        <span className="whitespace-nowrap">Applied</span>
      )}
      {rowData.status === "EXPIRED" && (
        <span className="whitespace-nowrap">Expired</span>
      )}
      {rowData.status === "NOT_APPLIED" && (
        <span className="whitespace-nowrap">Not Eligible</span>
      )}
    </>
  );
};

export const ReferralUI = ({
  readCurrentUserAuth,
  referralCode,
  referralLink,
  isLinkCopied,
  setLinkCopied,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
  setFieldValue,
  handleBlur,
  handleChange,
  values,
  joyride,
  setJoyride,
  steps,
  allReferrals,
  loadingAllReferrals,
  referralEarnings,
  referralSetting,
}: ReferralUIProps) => {
  const platform = usePlatformDetect();

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setJoyride(true);
  };

  useEffect(() => {
    const referralGuide = getCookie("referralGuide");
    if (!referralGuide) {
      setJoyride(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleJoyrideCallback = (callbackData: CallBackProps) => {
    const { status, type } = callbackData;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyride(false);
    }
    if (status === STATUS.READY) {
      setCookie("referralGuide", "true", 30);
    }
  };
  const userDefaultFilterConfig = {
    email: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    fullName: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    companyName: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    lastActionDate: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  };

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex gap-3 text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Refer And Earn`.toUpperCase()}
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={joyride}
        scrollToFirstStep
        showProgress
        scrollOffset={76}
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#000000",
          },
        }}
      />
      <div className="flex flex-col h-full gap-8 mt-5 md:mt-0 2xl:flex-row">
        <div className="flex flex-col items-center justify-between w-full h-auto gap-8 p-12 bg-white rounded-md shadow lg:flex-row 2xl:w-2/3 text-brown-500">
          <div className="flex flex-col h-full gap-4 max-w-[400px] 5xl:max-w-[700px] justify-center text-sm sm:text-base md:text-xl">
            <div className="">
              Do you know of a Business Owner who would benefit from the
              convenience, controls, and low fees of a RoadFlex Card?
            </div>
            <div className="mt-4">
              Earn {dollarFormatter((referralSetting?.bonusAmount || 0) / 100)}{" "}
              for every Business Owner that joins RoadFlex through your
              referral!{" "}
            </div>
            <div className="mt-8 text-black">
              <Button
                onClick={handleClickStart}
                variant={ButtonVariant.Transparent}
                size={ButtonSize.Wrap}
                className="step-5"
              >
                <LifebuoyIcon
                  className="flex-shrink-0 w-4 h-4"
                  aria-hidden="true"
                />
                <span className="px-1 text-xs italic">Help Center</span>
              </Button>
            </div>
          </div>
          <div className="min-w-[200px] xs:min-w-[300px] lg:min-w-[200px] xl:min-w-[300px] 2xl:min-w-[300px] 4xl:min-w-[400px]">
            <Image
              layout="responsive"
              width={183}
              height={171}
              loading="eager"
              className=""
              src={`${NEXT_PUBLIC_CDN_URL}/images/referral-image.png`}
              alt="Referral image"
            ></Image>
          </div>
        </div>
        <div className="2xl:w-1/3 2xl:h-auto">
          <ReferralStatsUI
            {...{
              referralCode,
              referralEarnings,
              totalReferrals: allReferrals?.length || 0,
            }}
          ></ReferralStatsUI>
        </div>
      </div>
      <div className="flex flex-col h-full gap-8 mt-8 2xl:flex-row">
        <div className="flex flex-col h-auto xl:min-w-[400px] 5xl:min-w-[700px]">
          <div className="flex flex-col h-full bg-white rounded-md shadow">
            <div className="p-8 font-semibold border-b-2 text-brown-500 border-brown-500 md:text-lg">
              How to Qualify
            </div>
            <div className="flex flex-col gap-8 p-8">
              <div className="flex flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex items-center justify-center w-12 h-12 px-4 py-2 text-white rounded-full md:w-16 md:h-16 bg-brown-500 md:text-lg">
                  1
                </div>
                <div className="flex flex-col md:w-5/6">
                  <div className="font-semibold text-brown-500">Invite</div>
                  <div className="2xl:max-w-[250px] 5xl:max-w-[700px]">
                    Share your unique referral link with your friends, or send
                    them through SMS.
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex items-center justify-center w-12 h-12 px-4 py-2 text-white rounded-full md:w-16 md:h-16 bg-brown-500 md:text-lg">
                  2
                </div>
                <div className="flex flex-col md:w-5/6">
                  <div className="font-semibold text-brown-500">Apply</div>
                  <div className="2xl:max-w-[250px] 5xl:max-w-[700px]">
                    Your referral clicks on your link and applies for a RoadFlex
                    account.
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex items-center justify-center w-12 h-12 px-4 py-2 text-white rounded-full md:w-16 md:h-16 bg-brown-500 md:text-lg">
                  3
                </div>
                <div className="flex flex-col md:w-5/6">
                  <div className="font-semibold text-brown-500">Buy Fuel</div>
                  <div className="2xl:max-w-[250px] 5xl:max-w-[700px]">
                    Referral is approved and purchases{" "}
                    {referralSetting?.fuelQuantity} gallons within the first{" "}
                    {(Number(referralSetting?.days) || 90) / 30} months.
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex items-center justify-center w-12 h-12 px-4 py-2 text-white rounded-full md:w-16 md:h-16 bg-brown-500 md:text-lg">
                  4
                </div>
                <div className="flex flex-col md:w-5/6">
                  <div className="font-semibold text-brown-500">Reward</div>
                  <div className="2xl:max-w-[250px] 5xl:max-w-[700px]">
                    You receive a{" "}
                    {dollarFormatter((referralSetting?.bonusAmount || 0) / 100)}{" "}
                    statement credit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full h-auto">
          <div className="flex flex-col h-full bg-white rounded-md shadow step-3">
            <div className="p-8 font-semibold border-b-2 text-brown-500 border-brown-500 md:text-lg">
              Refer RoadFlex to a friend and earn discounts
            </div>
            <div className="flex flex-col gap-8 p-8">
              <div className="pb-8 border-b border-brown-500">
                {" "}
                <div className="mb-1">
                  You can share your personal referral link
                </div>
                <div className="flex w-full gap-3 flex-nowrap">
                  <TextInput
                    type="text"
                    placeholder="Referral link"
                    value={referralLink}
                    name="referralLink"
                    disabled
                  ></TextInput>

                  <div className="flex">
                    <CopyToClipboard
                      text={referralLink}
                      onCopy={() => setLinkCopied(true)}
                    >
                      <Button
                        variant={ButtonVariant.Transparent}
                        size={ButtonSize.Wrap}
                        className="w-full mt-2 sm:w-auto sm:mt-0 sm:ml-3 md:ml-0 whitespace-nowrap"
                      >
                        {isLinkCopied ? (
                          <span>
                            <ClipboardDocumentCheckIcon className="text-green-700 w-7 h-7"></ClipboardDocumentCheckIcon>
                          </span>
                        ) : (
                          <span>
                            <ClipboardDocumentIcon className="w-7 h-7"></ClipboardDocumentIcon>
                          </span>
                        )}
                      </Button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center w-full gap-4">
                <FacebookShareButton
                  className="items-center p-0 overflow-hidden text-white"
                  url={referralLink}
                >
                  <Button
                    variant={ButtonVariant.Square}
                    size={ButtonSize.Normal}
                    className="whitespace-nowrap"
                  >
                    <ShareIcon className="hidden w-5 h-5 mr-2 md:block"></ShareIcon>
                    Facebook
                  </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  className="items-center p-0 mx-1 overflow-hidden text-white"
                  url={referralLink}
                  title="Apply for a RoadFlex Card: "
                >
                  <Button
                    variant={ButtonVariant.Square}
                    size={ButtonSize.Normal}
                    className="whitespace-nowrap"
                  >
                    <ShareIcon className="hidden w-5 h-5 mr-2 md:block"></ShareIcon>
                    Twitter
                  </Button>
                </TwitterShareButton>
                <WhatsappShareButton
                  className="items-center p-0 overflow-hidden text-white"
                  url={referralLink}
                  title="Apply for a RoadFlex Card: "
                >
                  <Button
                    variant={ButtonVariant.Square}
                    size={ButtonSize.Normal}
                    className="whitespace-nowrap"
                  >
                    <ShareIcon className="hidden w-5 h-5 mr-2 md:block"></ShareIcon>
                    WhatsApp
                  </Button>
                </WhatsappShareButton>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="">Or send an email invite</div>
                <div className="flex flex-wrap sm:flex-nowrap">
                  <div className="relative w-full mr-4">
                    <TextInput
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      id="email"
                      onKeyUp={() => setFieldValue("phoneNumber", "")}
                      touched={touched.email}
                      error={touched.email && Boolean(errors.email)}
                      errorMessage={errors.email}
                      placeholder="Email"
                    ></TextInput>
                  </div>
                  <Button
                    variant={ButtonVariant.AppOrange}
                    type={ButtonType.Submit}
                    size={ButtonSize.AppSize}
                    className="w-full mt-2 sm:w-auto sm:mt-0 sm:ml-3 whitespace-nowrap"
                    disabled={isSubmitting}
                  >
                    Send Invite
                  </Button>
                </div>
                <div className="mt-3">Or send a text invite</div>
                <div className="flex flex-wrap sm:flex-nowrap">
                  <div className="relative w-full mr-4">
                    <TextInput
                      value={values.phoneNumber}
                      onChange={(event) =>
                        setFieldValue(
                          "phoneNumber",
                          phoneFormatter(event?.target?.value),
                        )
                      }
                      onKeyUp={() => setFieldValue("email", "")}
                      onBlur={handleBlur}
                      name="phoneNumber"
                      id="phoneNumber"
                      touched={touched.phoneNumber}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      errorMessage={errors.phoneNumber}
                      placeholder="Phone Number"
                      type="tel"
                    ></TextInput>
                  </div>
                  <Button
                    variant={ButtonVariant.AppOrange}
                    type={ButtonType.Submit}
                    size={ButtonSize.AppSize}
                    className="w-full mt-2 sm:w-auto sm:mt-0 sm:ml-3 whitespace-nowrap"
                    disabled={isSubmitting}
                  >
                    Send invite
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full my-first-step"></div>

      <div className="flex flex-col w-full p-8 mt-8 bg-white rounded-md shadow">
        <div className="flex flex-row justify-between font-semibold text-brown-500 md:text-lg">
          Your Referrals
        </div>
        <div className="mt-8 overflow-hidden rounded-sm">
          <DataTable
            pageLinkSize={3}
            size="small"
            loading={loadingAllReferrals}
            value={allReferrals}
            stripedRows
            responsiveLayout={platform.isMobile() ? "stack" : "scroll"}
            style={{
              fontFamily: "Inter",
            }}
            paginatorClassName="!bg-white"
            paginator
            paginatorTemplate={newPaginationTemplate}
            rows={10}
            dataKey="id"
            filters={userDefaultFilterConfig}
            sortField="createdAt"
            sortOrder={-1}
            emptyMessage="No available records"
            className="min-w-full w-auto custom-table !border-brown-500"
          >
            <Column
              // filter
              sortable
              // filterPlaceholder="Search by name"
              field="firstName"
              header="Name"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={nameTemplate}
            />
            <Column
              // filter
              sortable
              // filterPlaceholder="Search by Email"
              field="email"
              header="Email"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              // filter
              // filterElement={dateFilterTemplate}
              // sortable
              dataType="date"
              filterField="createdAt"
              field="createdAt"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              body={(r) =>
                dateTemplate(
                  r.createdAt,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone ||
                    ENUM_TIME_ZONE.USER,
                )
              }
            />
            <Column
              // filter
              // sortable
              filterPlaceholder="Search by Galloon"
              field="gallPur"
              header="Gallon"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={gallonTemplate}
            />
            <Column
              // filter
              // sortable
              // filterPlaceholder="Search by Bonus Amount"
              field="bonusAmount"
              header="Bonus Amount"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={bonusAmountTemplate}
            />
            <Column
              // filter
              // sortable
              // filterPlaceholder="Search by Bonus Amount"
              field="status"
              header="Status"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={statusTemplate}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
