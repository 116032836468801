import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CATEGORY_LABELS } from "@roadflex/constants";
import { CardMerchants, PolicyRestrictionFormikValues } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import classNames from "classnames";
import { FormikProps } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { useState } from "react";
import { FuelCardComponentPermissionGuard } from "../../../../src/permission-guard";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { Select } from "../../../dropdowns";
import { CheckboxInput, TextInput } from "../../../inputs";
interface PolicyMerchantosureUIProps
  extends FormikProps<PolicyRestrictionFormikValues> {
  merchantType: CardMerchants;
  editLimits: boolean;
  access: boolean;
}

const PolicyMerchantDisclosureUI: React.FC<PolicyMerchantosureUIProps> = ({
  merchantType,
  values,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  editLimits,
  access,
  // ... other Formik props if needed ...
}) => {
  const handleMerchantChange = <K extends keyof CardMerchants>(
    merchant: CardMerchants,
    value: CardMerchants[K],
    updateField: K,
  ) => {
    const newObject = values.merchants;
    if (newObject) {
      let index = newObject?.findIndex((val) => val.name === merchant.name);
      newObject[index][updateField] = value;
      if (
        updateField === "numberOfPurchaseLimit" &&
        !merchant.numberOfPurchaseFrequency
      ) {
        newObject[index].numberOfPurchaseFrequency = "daily";
      }
      if (updateField === "totalSpendLimit" && !merchant.totalSpendFrequency) {
        newObject[index].totalSpendFrequency = "daily";
      }
      if (merchant.name === "auto_body_repair_shops") {
        index = newObject?.findIndex(
          (val) => val.name === "auto_service_shops",
        );
        newObject[index][updateField] = value;
        if (
          updateField === "numberOfPurchaseLimit" &&
          !merchant.numberOfPurchaseFrequency
        ) {
          newObject[index].numberOfPurchaseFrequency = "daily";
        }

        if (
          updateField === "totalSpendLimit" &&
          !merchant.totalSpendFrequency
        ) {
          newObject[index].totalSpendFrequency = "daily";
        }
      }
      setFieldValue("merchants", newObject);
    }
  };
  const merchantIndex =
    values?.merchants?.findIndex((value) => value.name === merchantType.name) ||
    0;

  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="flex w-full">
      <div className={classNames("w-full border-b divide-gray-300")}>
        <dt className="flex items-center justify-between p-4 ml-4 text-md">
          <Button
            size={ButtonSize.Small}
            variant={ButtonVariant.GrayOutline}
            className="!pl-0 ml-[-6px] border-0"
            onClick={() => setOpen(!open)}
          >
            <span className="flex items-center mr-3 h-7">
              <ChevronDownIcon
                className={classNames(
                  open ? "-rotate-180" : "rotate-0",
                  "h-5 w-5 transform text-orange-500",
                )}
                aria-hidden="true"
              />
            </span>
            <div className="relative flex items-center" key={merchantType.name}>
              <div className="mr-3 text-left">
                <label htmlFor={merchantType.name} className="">
                  {(CATEGORY_LABELS[merchantType.name] &&
                    CATEGORY_LABELS[merchantType.name]?.label) ||
                    humanize(merchantType.name)}
                </label>
              </div>
            </div>
          </Button>
          <div className="flex items-center h-6">
            <InputSwitch
              id={merchantType.name}
              name={merchantType.name}
              checked={
                values?.merchants?.filter(
                  (value) => value.name === merchantType.name,
                )[0].toggleOn
              }
              disabled={!editLimits}
              onChange={(e) =>
                handleMerchantChange(merchantType, e.target.value, "toggleOn")
              }
              className="input-switch"
            />
          </div>
        </dt>
        {open && (
          <div className="pr-12 ml-[64px]">
            <div className="flex flex-col gap-4 pb-4 border-gray-300">
              <div
                className={classNames(
                  "flex flex-col gap-3 text-black sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between max-w-[900px] ",
                )}
              >
                <div className="flex flex-row items-center">
                  <FuelCardComponentPermissionGuard
                    type="checkbox"
                    featurePermission={access}
                    messageType="premium"
                  >
                    <CheckboxInput
                      type="checkbox"
                      checked={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].numberOfPurchaseLimitToggleOn
                      }
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].toggleOn || !editLimits
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          e.target.checked,
                          "numberOfPurchaseLimitToggleOn",
                        )
                      }
                      label={
                        <label className="block capitalize">
                          Limit Number of Purchases{" "}
                          <span className="font-normal text-yellow-600">
                            {" "}
                            (Premium)
                          </span>
                        </label>
                      }
                    ></CheckboxInput>
                  </FuelCardComponentPermissionGuard>
                </div>
                <div className="w-[320px]">
                  <div className="flex flex-row gap-3">
                    <TextInput
                      type="number"
                      value={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].numberOfPurchaseLimit || ""
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          Number(e.target.value),
                          "numberOfPurchaseLimit",
                        )
                      }
                      className="max-w-[150px]"
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].numberOfPurchaseLimitToggleOn || !editLimits
                      }
                      onBlur={() =>
                        setFieldTouched(
                          `merchants[${merchantIndex}].numberOfPurchaseLimit`,
                          true,
                        )
                      }
                      placeholder="5"
                      error={
                        Boolean(touched.merchants) &&
                        Boolean(
                          (
                            errors.merchants?.[
                              merchantIndex
                            ] as unknown as CardMerchants
                          )?.numberOfPurchaseLimit,
                        )
                      }
                      touched={Boolean(touched.merchants)}
                      errorMessage={
                        (
                          errors.merchants?.[
                            merchantIndex
                          ] as unknown as CardMerchants
                        )?.numberOfPurchaseLimit as unknown as string
                      }
                    ></TextInput>
                    <Select
                      value={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].numberOfPurchaseFrequency || "daily"
                      }
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].numberOfPurchaseLimitToggleOn || !editLimits
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          e.target.value,
                          "numberOfPurchaseFrequency",
                        )
                      }
                      className="w-[150px]"
                      options={[
                        { label: "Daily", value: "daily" },
                        { label: "Weekly", value: "weekly" },
                      ]}
                      optionLabel="label"
                      optionValue="value"
                    ></Select>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "flex flex-col gap-3 text-black sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between max-w-[900px] ",
                )}
              >
                <div className="flex flex-row items-center">
                  <FuelCardComponentPermissionGuard
                    type="checkbox"
                    featurePermission={access}
                    messageType="premium"
                  >
                    <CheckboxInput
                      type="checkbox"
                      checked={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].totalSpendLimitToggleOn
                      }
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].toggleOn || !editLimits
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          e.target.checked,
                          "totalSpendLimitToggleOn",
                        )
                      }
                      label={
                        <label className="block capitalize">
                          Limit Purchase Amount{" "}
                          <span className="font-normal text-yellow-600">
                            {" "}
                            (Premium)
                          </span>
                        </label>
                      }
                    ></CheckboxInput>
                  </FuelCardComponentPermissionGuard>
                </div>
                <div className="w-[320px]">
                  <div className="flex flex-row gap-3">
                    <TextInput
                      type="number"
                      value={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].totalSpendLimit || ""
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          Number(e.target.value),
                          "totalSpendLimit",
                        )
                      }
                      className="max-w-[150px]"
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].totalSpendLimitToggleOn || !editLimits
                      }
                      onBlur={() =>
                        setFieldTouched(
                          `merchants[${merchantIndex}].totalSpendLimit`,
                          true,
                        )
                      }
                      placeholder="$5"
                      error={
                        Boolean(touched.merchants) &&
                        Boolean(
                          (
                            errors.merchants?.[
                              merchantIndex
                            ] as unknown as CardMerchants
                          )?.totalSpendLimit,
                        )
                      }
                      touched={Boolean(touched.merchants)}
                      errorMessage={
                        (
                          errors.merchants?.[
                            merchantIndex
                          ] as unknown as CardMerchants
                        )?.totalSpendLimit as unknown as string
                      }
                    ></TextInput>
                    <Select
                      value={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].totalSpendFrequency || "daily"
                      }
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].totalSpendLimitToggleOn || !editLimits
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          e.target.value,
                          "totalSpendFrequency",
                        )
                      }
                      className="w-[150px]"
                      options={[
                        { label: "Daily", value: "daily" },
                        { label: "Weekly", value: "weekly" },
                      ]}
                      optionLabel="label"
                      optionValue="value"
                    ></Select>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "flex flex-col gap-3 text-black sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between max-w-[900px] ",
                )}
              >
                <div className="flex flex-row items-center">
                  <FuelCardComponentPermissionGuard
                    type="checkbox"
                    featurePermission={access}
                    messageType="premium"
                  >
                    <CheckboxInput
                      type="checkbox"
                      checked={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].requireMemo
                      }
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].toggleOn || !editLimits
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          e.target.checked,
                          "requireMemo",
                        )
                      }
                      label={
                        <label className="block capitalize">
                          {" "}
                          Prompt for memo after purchase{" "}
                          <span className="font-normal text-yellow-600">
                            {" "}
                            (Premium)
                          </span>
                        </label>
                      }
                    ></CheckboxInput>
                  </FuelCardComponentPermissionGuard>
                </div>
              </div>
              <div
                className={classNames(
                  "flex flex-col gap-3 text-black sm:flex-row md:flex-col lg:flex-row sm:items-center md:items-start lg:items-center justify-between max-w-[900px]",
                )}
              >
                <div className="flex flex-row items-center">
                  <FuelCardComponentPermissionGuard
                    type="checkbox"
                    featurePermission={access}
                    messageType="premium"
                  >
                    <CheckboxInput
                      type="checkbox"
                      checked={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].requireReceiptToggleOn
                      }
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].toggleOn || !editLimits
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          e.target.checked,
                          "requireReceiptToggleOn",
                        )
                      }
                      label={
                        <label className="block capitalize">
                          {" "}
                          Require receipt for purchase amount above{" "}
                          <span className="font-normal text-yellow-600">
                            {" "}
                            (Premium)
                          </span>
                        </label>
                      }
                    ></CheckboxInput>
                  </FuelCardComponentPermissionGuard>
                </div>
                <div className="w-[320px]">
                  <div className="flex flex-row gap-3">
                    <TextInput
                      type="number"
                      value={
                        values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].requireReceiptAmount || ""
                      }
                      onChange={(e) =>
                        handleMerchantChange(
                          merchantType,
                          Number(e.target.value),
                          "requireReceiptAmount",
                        )
                      }
                      className="max-w-[150px]"
                      disabled={
                        !values?.merchants?.filter(
                          (value) => value.name === merchantType.name,
                        )[0].requireReceiptToggleOn || !editLimits
                      }
                      onBlur={() =>
                        setFieldTouched(
                          `merchants[${merchantIndex}].requireReceiptAmount`,
                          true,
                        )
                      }
                      placeholder="$5"
                      error={
                        Boolean(touched.merchants) &&
                        Boolean(
                          (
                            errors.merchants?.[
                              merchantIndex
                            ] as unknown as CardMerchants
                          )?.requireReceiptAmount,
                        )
                      }
                      touched={Boolean(touched.merchants)}
                      errorMessage={
                        (
                          errors.merchants?.[
                            merchantIndex
                          ] as unknown as CardMerchants
                        )?.requireReceiptAmount as unknown as string
                      }
                    ></TextInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyMerchantDisclosureUI;
