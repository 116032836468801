/** @format */
import { ENUM_ADDRESS_TYPE } from "@prisma/client";
import { useAddressCreate, useReadHomeAddress } from "@roadflex/react-hooks";
import { AddressType } from "@roadflex/types";
import getConfig from "next/config";
import { useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Toast } from "../toast-message/toast";
import HomeAddressUI from "./home-address.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;
interface AddressProps {
  nextStep: (jumpStepBy?: number) => void;
  personData: { address: AddressType; id: string; owner: boolean };
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}

const HomeAddress = ({
  nextStep,
  personData,
  isSubmittingOnboardStatus,
  onPreviousStep,
}: AddressProps) => {
  const homeAddress = personData.address;

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    ...rest
  } = useAddressCreate({
    initialValues: {
      id: personData.id,
      homeAddress: {
        id: homeAddress?.id || "",
        streetAddress: homeAddress?.streetAddress || "",
        streetDetails: homeAddress?.streetDetails || "",
        state: homeAddress?.state || "",
        city: homeAddress?.city || "",
        zip: homeAddress?.zip || "",
        type: ENUM_ADDRESS_TYPE.HOME,
      },
      owner: personData.owner || false,
    },
    onSubmit(res, err) {
      if (!err) {
        if (!values.owner) {
          nextStep(1);
        } else {
          nextStep();
        }
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
    },
  });

  const { ref: homeAddressAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      if (place.address_components) {
        let streetAddress = "";
        let zip = "";
        let state = "";
        let city = "";
        for (const component of place.address_components) {
          const componentType = component.types;
          if (componentType.includes("street_number")) {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType.includes("route")) {
            streetAddress += component.long_name;
          }
          if (componentType.includes("postal_code")) {
            zip = component.long_name;
          }
          if (componentType.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (
            componentType.includes("locality") ||
            componentType.includes("sublocality")
          ) {
            city = component.long_name;
          }
        }
        setFieldValue("homeAddress.zip", zip);
        setFieldValue("homeAddress.state", state);
        setFieldValue("homeAddress.city", city);
        setFieldValue("homeAddress.streetAddress", streetAddress);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  const autoCompleteRef = useRef({
    homeAddressAutoCompleteRef,
  });

  return (
    <HomeAddressUI
      {...{
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        isSubmittingOnboardStatus,
        touched,
        errors,
        setFieldValue,
        ...rest,
        onPreviousStep,
      }}
      ref={autoCompleteRef}
    />
  );
};

function Address(props: {
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  onPreviousStep: () => void;
}) {
  const { data, loading } = useReadHomeAddress({ id: undefined });

  if (loading) {
    return <div className="mt-6 text-center text-black">Loading...</div>;
  }
  return (
    <HomeAddress
      {...{
        ...props,
        personData: {
          ...data,
        } as {
          id: string;
          address: AddressType;
          owner: boolean;
        },
      }}
    />
  );
}

export default Address;
