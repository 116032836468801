/** @format */

import { gql } from "@apollo/client";

export const CARDS_SUBSCRIPTION = gql`
  subscription CARDS_SUBSCRIPTION($data: CardSubscribeInput) {
    cards(data: $data) {
      id
      lastFour
      allowedCategory
      blockCategory
      type
      currency
      status
      userId
      smsSecurity
      fleetDriver {
        id
        email
        name
      }
      fleet {
        id
        vin
        fleetName
      }
      cardSpending {
        id
        interval
        amount
      }
      cardShipping {
        deliveryStatus
        deliveryPartner
        deliveryService
        deliveryType
        trackingNumber
        trackingUrl
        eta
      }
    }
  }
`;

export const AUTHORIZATION_SUBSCRIPTION = gql`
  subscription AUTHORIZATION_SUBSCRIPTION($data: AuthorizationSubscribeInput!) {
    authorization(data: $data) {
      id
    }
  }
`;

export const TRANSACTION_SUBSCRIPTION = gql`
  subscription TRANSACTION_SUBSCRIPTION($data: TransactionSubscribeInput!) {
    transaction(data: $data) {
      id
    }
  }
`;
