/**
 * /* eslint-disable-next-line
 *
 * @format
 */

import { UserAuthType } from "@roadflex/types";
import classNames from "classnames";
import { useRouter } from "next/router";
import Header from "../headers/header/header";

export interface OnboardingLayoutProps {
  children: JSX.Element;
  readCurrentUserAuth: UserAuthType;
}

export function OnboardingLayout(props: OnboardingLayoutProps) {
  const router = useRouter();
  const { children, readCurrentUserAuth } = props;
  return (
    <>
      <Header readCurrentUserAuth={readCurrentUserAuth} />

      <main
        className={classNames(
          router.pathname === "/" ? "" : "pt-[64px]",
          router.pathname === "/deposit" ? "" : "bg-onboard-texture bg-cover",
          "flex flex-col items-center min-h-[calc(100vh-64px)] overflow-x-hidden",
        )}
      >
        {children}
      </main>
    </>
  );
}

export default OnboardingLayout;
