/** @format */

import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

export const uploadTransactionReceipt = async (
  file: File,
  fileType: string,
  transactionId: string,
  type: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileType", fileType);
  formData.append("transactionId", transactionId);
  formData.append("type", type);

  const response = await fetch(`${NEXT_PUBLIC_API_URL}/api/files/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  const { fileName } = await response.json();

  return fileName;
};
