/** @format */

import { useMutation } from "@apollo/client";
import {
  READ_APP_CARD_DATA,
  UPDATE_MULTIPLE_CARDS_STATUS,
} from "@roadflex/graphql";
import {
  useCardListSubscription,
  useReadAppCardData,
} from "@roadflex/react-hooks";
import {
  CardShortened,
  DriversFilterOptions,
  ParameterFilterType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef, useState } from "react";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { VirtualCardsUI } from "./virtual-cards.ui";

type VirtualCardsProps = {
  readCurrentUserAuth: UserAuthType;
  cardTags: ParameterFilterType[];
  loading: boolean;
  refetch: () => void;
};
export const VirtualCards = ({
  readCurrentUserAuth,
  cardTags,
  loading,
  refetch,
}: VirtualCardsProps) => {
  const [cardPopup, setcardPopup] = useState(false);
  const [showDeliveryDetails, setShowDeliveryDetails] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({
    deliveryService: "",
    deliveryPartner: "",
    deliveryStatus: "",
    deliveryType: "",
    eta: new Date(),
    trackingNumber: "",
    trackingUrl: "",
  });

  const [selectedCards, setSelectedCards] = useState<CardShortened[]>([]);
  const [showConfirmation, setConfirmation] = useState(false);
  const [variables, setVariables] = useState({ var1: 0, var2: 0, var3: 0 });
  const op = useRef<OverlayPanel>(null);

  const {
    readAppCardData,
    loading: readAppCardDataLoading,
    refetch: readAppCardDataRefetch,
  } = useReadAppCardData(
    false,
    false,
    true,
    "Shortened",
    undefined,
    cardTags.length > 0 ? cardTags.map((x) => x.tag) : undefined,
  );

  const [updateCardsStatusRequest] = useMutation<{
    updateMultipleCardsStatus: {
      message: string;
      code: string;
      cardUpdated: boolean;
    };
  }>(UPDATE_MULTIPLE_CARDS_STATUS, {
    refetchQueries: [READ_APP_CARD_DATA],
  });

  const { data: cardsListSubscribed } = useCardListSubscription(
    readCurrentUserAuth.primaryOwnerId,
  );

  const [submittingRequest, setsubmittingRequest] = useState(false);
  if (loading) {
    return <Loader />;
  }

  const handleMultipleCardsStatusUpdate = (
    cards: CardShortened[],
    cardStatus: string,
  ) => {
    setsubmittingRequest(true);
    const cardIdList = [
      ...new Set(
        cards.map((cardObj) => {
          return cardObj.id;
        }),
      ),
    ];
    updateCardsStatusRequest({
      variables: {
        data: { cardIdList, status: cardStatus },
      },
    })
      .then(({ data }) => {
        if (
          data?.updateMultipleCardsStatus?.code === "200" &&
          data?.updateMultipleCardsStatus?.cardUpdated
        ) {
          Toast({
            type: "success",
            message: "Cards updated successfully",
          });
          refetch();
          setSelectedCards([]);
        } else {
          Toast({
            type: "error",
            message: data?.updateMultipleCardsStatus?.message || "",
          });
        }
        setsubmittingRequest(false);
      })
      .catch((err) => {
        console.error(err);
        setsubmittingRequest(false);
        Toast({
          type: "error",
          message: err.message || "",
        });
      });
  };

  const deliveredCards: CardShortened[] = [];

  const currentVehiclesSet = new Set<string>();
  const currentDriversSet = new Set<string>();

  if (cardsListSubscribed?.cards?.length) {
    cardsListSubscribed?.cards?.forEach((card: CardShortened) => {
      const newCardObj: CardShortened = {
        ...card,
        driverName:
          (card?.fleetDriver &&
            card?.fleetDriver?.length > 0 &&
            card?.fleetDriver[0]?.name) ||
          "-",
        driverEmail:
          (card?.fleetDriver &&
            card?.fleetDriver?.length > 0 &&
            card?.fleetDriver[0]?.email) ||
          "-",
        vehicleName:
          (card?.fleet &&
            card?.fleet?.length > 0 &&
            card?.fleet[0]?.fleetName) ||
          "-",
      };
      if (newCardObj.fleet) {
        currentVehiclesSet.add(newCardObj?.fleet[0]?.fleetName);
      }
      if (newCardObj.fleetDriver) {
        currentDriversSet.add(newCardObj?.fleetDriver[0]?.name);
      }
      deliveredCards.push(newCardObj);
    });
  } else {
    readAppCardData?.cards?.forEach((card: CardShortened) => {
      const newCardObj: CardShortened = {
        ...card,
        driverName:
          (card?.fleetDriver &&
            card?.fleetDriver?.length > 0 &&
            card?.fleetDriver[0]?.name) ||
          "-",
        driverEmail:
          (card?.fleetDriver &&
            card?.fleetDriver?.length > 0 &&
            card?.fleetDriver[0]?.email) ||
          "-",
        vehicleName:
          (card?.fleet &&
            card?.fleet?.length > 0 &&
            card?.fleet[0]?.fleetName) ||
          "-",
      };
      if (newCardObj.fleet) {
        currentVehiclesSet.add(newCardObj?.fleet[0]?.fleetName);
      }
      if (newCardObj.fleetDriver) {
        currentDriversSet.add(newCardObj?.fleetDriver[0]?.name);
      }
      deliveredCards.push(newCardObj);
    });
  }
  const proceedToHandle = (cardStatus: string, cards: CardShortened[]) => {
    const cardsCharged = readAppCardData?.cardsCharged;
    let makingActive = 0;
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].status === "inactive") {
        makingActive += 1;
      }
    }
    let currentNumberOfActive = 0;
    for (let i = 0; i < deliveredCards.length; i++) {
      if (deliveredCards[i].status === "active") {
        currentNumberOfActive += 1;
      }
    }
    if (makingActive + currentNumberOfActive <= cardsCharged) {
      setConfirmation(false);
      return false;
    }
    setVariables({
      var1: cardsCharged,
      var2: makingActive,
      var3: makingActive + currentNumberOfActive - cardsCharged,
    });
    setConfirmation(true);
    return true;
  };

  const currentVehiclesFilter: VehiclesFilterOptions[] = Array.from(
    currentVehiclesSet,
  )
    .sort()
    .map((elem) => {
      return { vehicleName: elem };
    });

  const currentDriversFilter: DriversFilterOptions[] = Array.from(
    currentDriversSet,
  )
    .sort()
    .map((elem) => {
      return { driverName: elem };
    });

  return (
    <VirtualCardsUI
      {...{
        readAppCardDataLoading,
        readAppCardDataRefetch,
        readAppCardData,
        cardPopup,
        setcardPopup,
        deliveredCards,
        op,
        submittingRequest,
        currentVehiclesFilter,
        currentDriversFilter,
        selectedCards,
        setSelectedCards,
        handleMultipleCardsStatusUpdate,
        proceedToHandle,
        deliveryDetails,
        setDeliveryDetails,
        showDeliveryDetails,
        setShowDeliveryDetails,
        showConfirmation,
        setConfirmation,
        variables,
        readCurrentUserAuth,
        cardTags,
      }}
    />
  );
};
