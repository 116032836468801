/** @format */

import { Dialog } from "primereact/dialog";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";

type ConfirmModalProps = {
  title: string;
  body: string | JSX.Element;
  show: boolean;
  setShow: (value: boolean) => void;
  confirm: () => void;
  submitting: boolean;
};
export function ConfirmModal({
  title,
  body,
  show,
  submitting,
  setShow,
  confirm,
}: ConfirmModalProps) {
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.SecondaryOutline}
          size={ButtonSize.AppSize}
          disabled={submitting}
          className=""
          onClick={() => setShow(false)}
        >
          Close
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.AppSize}
          disabled={submitting}
          loading={submitting}
          className="ml-3"
          onClick={() => confirm()}
        >
          Confirm
        </Button>
      </div>
    );
  };
  return (
    <Dialog
      header={<div className="text-base">{title}</div>}
      style={{ minWidth: "60vw", maxWidth: "80vw", fontFamily: "Inter" }}
      visible={show}
      onHide={() => setShow(false)}
      closeOnEscape={true}
      closable={false}
      footer={renderFooter}
    >
      <div className="text-lg text-gray-500 ">{body}</div>
    </Dialog>
  );
}
