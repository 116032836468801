/** @format */
import { useMutation } from "@apollo/client";
import { genericPasswordSchema } from "@roadflex/constants";
import { SET_PASSWORD } from "@roadflex/graphql";
import { PasswordFormValues } from "@roadflex/types";
import { useFormik } from "formik";

export type useSetPasswordProps = {
  onSubmit: (
    val: { code: string; message: string } | null,
    error: Error | null,
  ) => void;
  initialValues: PasswordFormValues;
};

export const useSetPassword = ({
  onSubmit,
  initialValues,
}: useSetPasswordProps) => {
  const [setPasswordFn] = useMutation<{
    setPassword: {
      code: string;
      message: string;
    };
  }>(SET_PASSWORD);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPasswordSchema,
    onSubmit: async (value) => {
      try {
        const res = await setPasswordFn({
          variables: { data: value },
        });
        if (res?.data?.setPassword) {
          onSubmit(res?.data?.setPassword, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, { message: "Something Went Wrong", name: "" });
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
