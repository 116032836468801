/** @format */
import { useMutation } from "@apollo/client";
import { ADMIN_IMPERSONATOR_SIGN_IN } from "@roadflex/graphql";
import { ImpersonatorSignInValues } from "@roadflex/types";
import { useFormik } from "formik";

interface UseImpersonatorSignInProps {
  onSubmit: (val: { authToken: string } | null, error: Error | null) => void;
  initialValues: ImpersonatorSignInValues;
}

export const useImpersonatorSignIn = ({
  onSubmit,
  initialValues,
}: UseImpersonatorSignInProps) => {
  const [impersonatorSignInFn] = useMutation(ADMIN_IMPERSONATOR_SIGN_IN, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const { handleSubmit, values, isSubmitting, setFieldValue, ...rest } =
    useFormik({
      initialValues,
      onSubmit: async (value) => {
        try {
          const res = await impersonatorSignInFn({
            variables: { data: value },
          });
          onSubmit(res?.data?.impersonatorSignIn, null);
        } catch (error) {
          if (error instanceof Error) {
            onSubmit(null, error);
          } else {
            onSubmit(null, { message: "Something Went Wrong", name: "" });
          }
        }
      },
    });

  return {
    values,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...rest,
  };
};
