/** @format */
import { useQuery } from "@apollo/client";
import { PLAID_LINK_TOKEN } from "@roadflex/graphql";

export const useLinkToken = (variableObj: any) => {
  const { data, ...rest } = useQuery(PLAID_LINK_TOKEN, {
    variables: {
      ...variableObj,
    },
    fetchPolicy: "no-cache",
  });
  const { linkToken } = data || {};
  return { linkToken, ...rest };
};
