/** @format */

import { UserOnboardType } from "@roadflex/types";
import { useEffect } from "react";
import EmailVerificationUI from "./email-verification.ui";

export interface EmailVerificationProps {
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
}

const EmailVerification = ({
  readCurrentUserOnboard,
  nextStep,
}: EmailVerificationProps) => {
  const { isEmailVerified } = readCurrentUserOnboard;

  // @NOTE: as we are already changing `onBoardStatus` in the backend after
  // email verification, keep this thing here for just in case.
  useEffect(() => {
    if (isEmailVerified) {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailVerified]);

  return <EmailVerificationUI {...{ isEmailVerified }} />;
};

export default EmailVerification;
