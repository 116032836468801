/** @format */

import React from "react";

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
  React.RefAttributes<SVGSVGElement>;
type IconProps = IconSVGProps & {
  title?: string;
  titleId?: string;
};

type AccountDetailsUIProps = {
  businessName?: string;
  overviewStats: {
    id: number;
    name: string;
    stat: string | number;
    icon: React.FC<IconProps>;
    subText?: () => JSX.Element;
  }[];
};

export const AccountDetailsUI = ({
  overviewStats,
  businessName,
}: AccountDetailsUIProps) => {
  return (
    <div className="flex flex-col w-full p-4 mt-4 mr-4 bg-white rounded-md shadow gap-y-2">
      <div className="py-2 font-semibold text-brown-500 md:text-lg">
        Account Details
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <div className="">{overviewStats[0].name}</div>
        </div>
        <div>
          <span>{overviewStats[0].stat} </span>
          <span>
            {overviewStats[0].subText
              ? React.createElement(overviewStats[0].subText)
              : null}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <span className="">{overviewStats[1].name}</span>{" "}
          <span>
            {overviewStats[1].subText
              ? React.createElement(overviewStats[1].subText)
              : null}
          </span>
        </div>
        <div>
          <span>{overviewStats[1].stat} </span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <span className="">{overviewStats[2].name}</span>{" "}
        </div>
        <div>
          <span>{overviewStats[2].stat} </span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div>
          <span className="">{overviewStats[3].name}</span>{" "}
        </div>
        <div>
          <span>{overviewStats[3].stat} </span>
        </div>
      </div>
    </div>
  );
};
