/** @format */

export enum ENUM_CARDHOLDER_INACTIVE_REASON {
  PLAID = "PLAID",
  FAILED_BILLING_CYCLE = "FAILED_BILLING_CYCLE",
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  PHONE_NUMBER_NOT_VERIFIED = "PHONE_NUMBER_NOT_VERIFIED",
}

export enum ENUM_BONUS_TYPE {
  AMAZON_GIFTCARD = "AMAZON_GIFTCARD",
  CREDIT = "CREDIT",
}
