/** @format */
import { centToDollarFormatter } from "@roadflex/constants";
import { UserAuthType } from "@roadflex/types";
import { convertISOStringToDateNew } from "@roadflex/utils";
import CreditLimitBar from "../credit-limit-bar/credit-limit-bar";

export interface CreditUsersUsageProps {
  creditLimit: number;
  spend: number;
  savings: number;
  creditRemaining: number;
  currentCycleData: {
    startDt: Date;
    endDt: Date;
  } | null;
  readCurrentUserAuth: UserAuthType;
}

export const CreditUsersUsage = ({
  creditLimit,
  spend,
  savings,
  creditRemaining,
  currentCycleData,
  readCurrentUserAuth,
}: CreditUsersUsageProps) => {
  const userStatistics =
    readCurrentUserAuth?.fuelCard?.platform === "STRIPE"
      ? [
          {
            label: "Credit Limit",
            value: creditLimit,
          },
          {
            label: "Total Spend",
            value: spend,
          },
          {
            label: "Total Savings",
            value: savings,
          },
          {
            label: "Available to Spend",
            value: creditRemaining,
          },
        ]
      : [
          {
            label: "Current Period Spend",
            value: spend,
          },
          {
            label: "Current Period Savings",
            value: savings,
          },
        ];
  let tz = "";
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }

  return (
    <div className="flex flex-col w-full p-4 mr-4 bg-white rounded-md shadow gap-y-2">
      {/* <div className="text-sm font-medium md:text-base">{`Credit Limit: ${centToDollarFormatter(
        creditLimit,
      )}`}</div> */}

      <div className="py-2 font-semibold lg:flex lg:gap-2 lg:whitespace-nowrap 2xl:block 2xl:gap-0 4xl:flex 4xl:gap-2 text-brown-500 md:text-lg">
        Current Period:{" "}
        <div className="lg:whitespace-nowrap">
          {currentCycleData
            ? `${convertISOStringToDateNew(
                currentCycleData?.startDt.toString(),
                false,
                false,
                true,
                tz,
              )} - ${convertISOStringToDateNew(
                currentCycleData?.endDt.toString(),
                false,
                false,
                true,
                tz,
              )} (${readCurrentUserAuth?.accountDisplaySetting.timeZone})`
            : "-"}
        </div>
      </div>
      <div className="sm:col-span-1 lg:col-span-1">
        <div className="flex flex-col gap-2">
          {userStatistics.map((userStatistic) => {
            return (
              <div className="flex justify-between" key={userStatistic.label}>
                <div className="flex justify-start">
                  <div className="pr-4 text-sm md:text-base">
                    {userStatistic.label}
                  </div>
                </div>
                <div className="text-sm md:text-base">{`${centToDollarFormatter(
                  userStatistic.value,
                )}`}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="my-2 border border-black"></div>
      <CreditLimitBar
        creditLimit={creditLimit}
        creditRemaining={creditRemaining}
      />
    </div>
  );
};
