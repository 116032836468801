/** @format */

import { ArrowRightCircleIcon, CpuChipIcon } from "@heroicons/react/24/outline";
import { ENUM_CUSTOMER_TYPE, ENUM_ELD_ORG_NAME } from "@prisma/client";
import { EldSystem, GeoTabAuthValues } from "@roadflex/types";
import { FormikProps } from "formik";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";
import AddEJWardModal from "./add-ejward.modal.ui";
import AddELDSystemModal from "./add-eld-system.modal.ui";
import AddFleetioModal from "./add-fleetio.modal.ui";

const { publicRuntimeConfig } = getConfig();
const {
  NEXT_PUBLIC_CDN_URL,
  MOTIVE_CLIENT_ID,
  MOTIVE_API_BASE_URL,
  NEXT_PUBLIC_API_URL,
} = publicRuntimeConfig;

type ELDSystemsUIProps = {
  openAddELDSystemModal: boolean;
  setOpenAddELDSystemModal: (value: boolean) => void;
  eldSystems: EldSystem[];
  selectedEldSystem: EldSystem;
  setSelectedEldSystem: (value: EldSystem) => void;
  geoTabAuthForm: FormikProps<GeoTabAuthValues>;
  openAddFleetioModal: boolean;
  setOpenAddFleetioModal: (value: boolean) => void;
  openAddEJWardModal: boolean;
  setOpenAddEJWardModal: (value: boolean) => void;
  isFleetioConnected: boolean;
  isFleetioDeleted: boolean;
  isEJWardConnected: boolean;
  disconnectFleetio: () => void;
  disconnectEJWard: () => void;
  disconnectEld: (orgName: ENUM_ELD_ORG_NAME) => void;
  eldConnected: { isConnected: boolean; orgName: ENUM_ELD_ORG_NAME } | null;
  customerType: ENUM_CUSTOMER_TYPE;
  loggedInId: string;
};

export const ELDSystemsUI = ({
  openAddELDSystemModal,
  setOpenAddELDSystemModal,
  eldSystems,
  selectedEldSystem,
  setSelectedEldSystem,
  geoTabAuthForm,
  setOpenAddFleetioModal,
  openAddFleetioModal,
  openAddEJWardModal,
  setOpenAddEJWardModal,
  isFleetioConnected,
  isFleetioDeleted,
  isEJWardConnected,
  disconnectFleetio,
  disconnectEJWard,
  disconnectEld,
  eldConnected,
  customerType,
  loggedInId,
}: ELDSystemsUIProps) => {
  const router = useRouter();

  const gotoDetailsPage = (name: string) => {
    router.push(`/eld-systems/${name.toLowerCase()}`);
  };

  const gotoVehiclesPage = () => {
    router.push("/vehicles");
  };

  const openMotiveLink = (url: string | URL | undefined) => {
    if (eldConnected && eldConnected.isConnected) {
      Toast({
        type: "error",
        message:
          "Eld device already connected. Please disconnect it first to add any other eld device",
      });
    } else {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <>
      <div className="m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
        <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
          {`Connect`.toUpperCase()}
        </div>
        <div className="flex flex-col mt-5 md:mt-0">
          <div className="p-4 bg-white rounded-md shadow sm:p-8">
            <div>
              <div className="flex flex-col w-full">
                <div className="flex flex-row justify-between py-2 text-sm font-semibold border-b-2 text-brown-500 md:text-base border-brown-500">
                  Telematics Integration
                </div>
              </div>
              <div className="flex flex-row flex-wrap gap-2 mt-4 font-medium">
                {eldSystems.length > 0 &&
                  eldSystems.map((eldSystem: EldSystem, idx: number) => (
                    <div
                      key={idx}
                      className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[320px] p-4  border border-brown-500 rounded-md bg-white text-AppOrange"
                    >
                      <div className=" min-w-[60px] md:min-w-[70px]">
                        <Image
                          src={eldSystem.imageLink}
                          layout="responsive"
                          width={100}
                          height={100}
                          alt="ELD Device Logo"
                        />
                      </div>
                      <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                        <div className="flex justify-between w-full">
                          <div className="px-2">{eldSystem.name}</div>
                          {eldConnected?.isConnected &&
                            eldConnected?.orgName === eldSystem.code && (
                              <Button
                                variant={ButtonVariant.Transparent}
                                size={ButtonSize.Small}
                                className="text-sm w-fit"
                                onClick={() => {
                                  gotoDetailsPage(eldSystem.name);
                                }}
                              >
                                Details
                                <ArrowRightCircleIcon
                                  className="w-5 h-5 ml-1"
                                  aria-hidden="true"
                                />
                              </Button>
                            )}
                        </div>

                        {!(
                          eldConnected?.isConnected &&
                          eldConnected?.orgName === eldSystem.code
                        ) ? (
                          eldSystem?.code !== ENUM_ELD_ORG_NAME.MOTIVE ? (
                            <Button
                              onClick={() => {
                                setSelectedEldSystem(eldSystem);

                                setOpenAddELDSystemModal(true);
                              }}
                              size={ButtonSize.AppSize}
                              variant={ButtonVariant.AppOrange}
                              className="flex flex-row items-center gap-2 p-2 w-fit"
                            >
                              <CpuChipIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                              Connect
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                openMotiveLink(
                                  `${MOTIVE_API_BASE_URL}/oauth/authorize?client_id=${MOTIVE_CLIENT_ID}&redirect_uri=${NEXT_PUBLIC_API_URL}/api/webhook/motive?userId=${loggedInId}&response_type=code&scope=locations.vehicle_locations_list`,
                                );
                              }}
                              size={ButtonSize.AppSize}
                              variant={ButtonVariant.AppOrange}
                              className="flex flex-row items-center gap-2 p-2 w-fit"
                            >
                              <CpuChipIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                              Connect
                            </Button>
                          )
                        ) : (
                          <Button
                            onClick={() => {
                              disconnectEld(eldSystem.code);
                            }}
                            size={ButtonSize.AppSize}
                            variant={ButtonVariant.Red}
                            className="flex flex-row items-center gap-2 p-2 w-fit"
                          >
                            <CpuChipIcon
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                            Disconnect
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className="flex flex-col w-full mt-8">
                <div className="flex flex-row justify-between py-2 text-sm font-semibold border-b-2 text-brown-500 md:text-base border-brown-500">
                  Other Integrations
                </div>
              </div>
              <div className="flex flex-row flex-wrap gap-4 mt-4 font-medium ">
                <div className="flex flex-row flex-wrap items-center w-full h-full gap-4 text-center md:text-left md:items-start">
                  <div className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[320px] p-4 rounded-md border border-brown-500 bg-white text-AppOrange">
                    <div className="p-2 min-w-[60px] md:min-w-[75px]">
                      <Image
                        src={`${NEXT_PUBLIC_CDN_URL}/eld-logos/fleetio.svg`}
                        layout="responsive"
                        width={100}
                        height={100}
                        alt="blockquote"
                      />
                    </div>
                    <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                      <div className="flex items-center justify-between w-full">
                        <div>Fleetio</div>
                        {isFleetioConnected && (
                          <Button
                            onClick={() => {
                              disconnectFleetio();
                            }}
                            size={ButtonSize.Normal}
                            variant={ButtonVariant.Red}
                            className="flex flex-row items-center gap-2 p-2 ml-2 text-sm"
                          >
                            Disconnect
                          </Button>
                        )}
                      </div>
                      {!isFleetioConnected ? (
                        <Button
                          onClick={() => {
                            setOpenAddFleetioModal(true);
                          }}
                          size={ButtonSize.AppSize}
                          variant={ButtonVariant.AppOrange}
                          className="flex flex-row items-center gap-2 p-2 w-fit"
                        >
                          <CpuChipIcon className="w-5 h-5" aria-hidden="true" />
                          Connect
                        </Button>
                      ) : (
                        <Button
                          variant={ButtonVariant.AppOrange}
                          size={ButtonSize.Small}
                          className="text-xs w-fit"
                          onClick={() => {
                            gotoVehiclesPage();
                          }}
                        >
                          Show Vehicles
                          <ArrowRightCircleIcon
                            className="w-5 h-5 ml-2"
                            aria-hidden="true"
                          />
                        </Button>
                      )}
                      {isFleetioDeleted && (
                        <p className="lg:text-xs xs w-fit">
                          Api key is expired. <br />
                          Please reconnect with the new one
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[320px] p-4 rounded-md border border-brown-500 bg-white text-AppOrange">
                    <div className="p-2 min-w-[60px] md:min-w-[75px]">
                      <Image
                        src={`${NEXT_PUBLIC_CDN_URL}/eld-logos/Ward-Logo.svg`}
                        layout="responsive"
                        width={100}
                        height={100}
                        alt="blockquote"
                      />
                    </div>
                    <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                      <div className="flex items-center justify-between w-full">
                        <div>EJ Ward</div>
                        {isEJWardConnected && (
                          <Button
                            onClick={() => {
                              disconnectEJWard();
                            }}
                            size={ButtonSize.Normal}
                            variant={ButtonVariant.Red}
                            className="flex flex-row items-center gap-2 p-2 ml-2 text-sm"
                          >
                            Disconnect
                          </Button>
                        )}
                      </div>
                      {!isEJWardConnected ? (
                        <Button
                          onClick={() => {
                            setOpenAddEJWardModal(true);
                          }}
                          size={ButtonSize.AppSize}
                          variant={ButtonVariant.AppOrange}
                          className="flex flex-row items-center gap-2 p-2 w-fit"
                        >
                          <CpuChipIcon className="w-5 h-5" aria-hidden="true" />
                          Connect
                        </Button>
                      ) : (
                        ""
                        /*<Button
                          variant={ButtonVariant.AppOrange}
                          size={ButtonSize.Small}
                          className="text-xs w-fit"
                          onClick={() => {
                            gotoVehiclesPage();
                          }}
                        >
                          Show Vehicles
                          <ArrowCircleRightIcon
                            className="w-5 h-5 ml-2"
                            aria-hidden="true"
                          />
                        </Button>*/
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
          <div className="mb-2 font-semibold">Telematics Integration</div>
          <div className="flex flex-row flex-wrap items-center h-full gap-4 text-center md:text-left md:items-start basis-1/3">
            {eldSystems.length > 0 &&
              eldSystems.map((eldSystem: EldSystem, idx: number) => (
                <div
                  key={idx}
                  className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[400px] p-4 rounded-sm shadow bg-white text-AppOrange"
                >
                  <div className="p-2 border min-w-[72px] md:min-w-[85px]">
                    <Image
                      src={eldSystem.imageLink}
                      layout="responsive"
                      width={100}
                      height={100}
                      alt="ELD Device Logo"
                    />
                  </div>
                  <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                    <div className="flex items-center justify-between w-full">
                      <div className="px-2 py-1">{eldSystem.name}</div>
                      {eldConnected?.isConnected &&
                        eldConnected?.orgName === eldSystem.code && (
                          <Button
                            variant={ButtonVariant.Transparent}
                            size={ButtonSize.Small}
                            className="text-sm w-fit"
                            onClick={() => {
                              gotoDetailsPage(eldSystem.name);
                            }}
                          >
                            Show Details
                            <ArrowCircleRightIcon
                              className="w-5 h-5 ml-2"
                              aria-hidden="true"
                            />
                          </Button>
                        )}
                    </div>

                    {!(
                      eldConnected?.isConnected &&
                      eldConnected?.orgName === eldSystem.code
                    ) ? (
                      <Button
                        onClick={() => {
                          setSelectedEldSystem(eldSystem);

                          setOpenAddELDSystemModal(true);
                        }}
                        size={ButtonSize.Normal}
                        variant={ButtonVariant.AppOrange}
                        className="flex flex-row items-center gap-2 p-2 w-fit"
                      >
                        <ChipIcon className="w-5 h-5" aria-hidden="true" />
                        Connect
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          disconnectEld(eldSystem.code);
                        }}
                        size={ButtonSize.Normal}
                        variant={ButtonVariant.Red}
                        className="flex flex-row items-center gap-2 p-2 w-fit"
                      >
                        <ChipIcon className="w-5 h-5" aria-hidden="true" />
                        Disconnect
                      </Button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-8">
          <div className="mb-2 font-semibold">Other Integrations</div>
          <div className="flex flex-row flex-wrap items-center h-full gap-4 text-center md:text-left md:items-start basis-1/3">
            <div className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[400px] p-4 rounded-sm shadow bg-white text-AppOrange">
              <div className="p-2 border min-w-[85px]">
                <Image
                  src={`${NEXT_PUBLIC_CDN_URL}/eld-logos/fleetio.svg`}
                  layout="responsive"
                  width={100}
                  height={100}
                  alt="blockquote"
                />
              </div>
              <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                <div className="flex items-center justify-between w-full">
                  <div>Fleetio</div>
                  {isFleetioConnected && (
                    <Button
                      onClick={() => {
                        disconnectFleetio();
                      }}
                      size={ButtonSize.Normal}
                      variant={ButtonVariant.Red}
                      className="flex flex-row items-center gap-2 p-2 ml-2 text-sm"
                    >
                      Disconnect
                    </Button>
                  )}
                </div>
                {!isFleetioConnected ? (
                  <Button
                    onClick={() => {
                      setOpenAddFleetioModal(true);
                    }}
                    size={ButtonSize.Normal}
                    variant={ButtonVariant.AppOrange}
                    className="flex flex-row items-center gap-2 p-2 w-fit"
                  >
                    <ChipIcon className="w-5 h-5" aria-hidden="true" />
                    Connect
                  </Button>
                ) : (
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.Small}
                    className="text-xs w-fit"
                    onClick={() => {
                      gotoVehiclesPage();
                    }}
                  >
                    Show Vehicles
                    <ArrowCircleRightIcon
                      className="w-5 h-5 ml-2"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>   {/* <div>
          <div className="mb-2 font-semibold">Telematics Integration</div>
          <div className="flex flex-row flex-wrap items-center h-full gap-4 text-center md:text-left md:items-start basis-1/3">
            {eldSystems.length > 0 &&
              eldSystems.map((eldSystem: EldSystem, idx: number) => (
                <div
                  key={idx}
                  className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[400px] p-4 rounded-sm shadow bg-white text-AppOrange"
                >
                  <div className="p-2 border min-w-[72px] md:min-w-[85px]">
                    <Image
                      src={eldSystem.imageLink}
                      layout="responsive"
                      width={100}
                      height={100}
                      alt="ELD Device Logo"
                    />
                  </div>
                  <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                    <div className="flex items-center justify-between w-full">
                      <div className="px-2 py-1">{eldSystem.name}</div>
                      {eldConnected?.isConnected &&
                        eldConnected?.orgName === eldSystem.code && (
                          <Button
                            variant={ButtonVariant.Transparent}
                            size={ButtonSize.Small}
                            className="text-sm w-fit"
                            onClick={() => {
                              gotoDetailsPage(eldSystem.name);
                            }}
                          >
                            Show Details
                            <ArrowCircleRightIcon
                              className="w-5 h-5 ml-2"
                              aria-hidden="true"
                            />
                          </Button>
                        )}
                    </div>

                    {!(
                      eldConnected?.isConnected &&
                      eldConnected?.orgName === eldSystem.code
                    ) ? (
                      <Button
                        onClick={() => {
                          setSelectedEldSystem(eldSystem);

                          setOpenAddELDSystemModal(true);
                        }}
                        size={ButtonSize.Normal}
                        variant={ButtonVariant.AppOrange}
                        className="flex flex-row items-center gap-2 p-2 w-fit"
                      >
                        <ChipIcon className="w-5 h-5" aria-hidden="true" />
                        Connect
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          disconnectEld(eldSystem.code);
                        }}
                        size={ButtonSize.Normal}
                        variant={ButtonVariant.Red}
                        className="flex flex-row items-center gap-2 p-2 w-fit"
                      >
                        <ChipIcon className="w-5 h-5" aria-hidden="true" />
                        Disconnect
                      </Button>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-8">
          <div className="mb-2 font-semibold">Other Integrations</div>
          <div className="flex flex-row flex-wrap items-center h-full gap-4 text-center md:text-left md:items-start basis-1/3">
            <div className="flex flex-row w-full gap-3 lg:w-auto lg:min-w-[400px] p-4 rounded-sm shadow bg-white text-AppOrange">
              <div className="p-2 border min-w-[85px]">
                <Image
                  src={`${NEXT_PUBLIC_CDN_URL}/eld-logos/fleetio.svg`}
                  layout="responsive"
                  width={100}
                  height={100}
                  alt="blockquote"
                />
              </div>
              <div className="flex flex-col justify-between w-full gap-3 font-semibold">
                <div className="flex items-center justify-between w-full">
                  <div>Fleetio</div>
                  {isFleetioConnected && (
                    <Button
                      onClick={() => {
                        disconnectFleetio();
                      }}
                      size={ButtonSize.Normal}
                      variant={ButtonVariant.Red}
                      className="flex flex-row items-center gap-2 p-2 ml-2 text-sm"
                    >
                      Disconnect
                    </Button>
                  )}
                </div>
                {!isFleetioConnected ? (
                  <Button
                    onClick={() => {
                      setOpenAddFleetioModal(true);
                    }}
                    size={ButtonSize.Normal}
                    variant={ButtonVariant.AppOrange}
                    className="flex flex-row items-center gap-2 p-2 w-fit"
                  >
                    <ChipIcon className="w-5 h-5" aria-hidden="true" />
                    Connect
                  </Button>
                ) : (
                  <Button
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.Small}
                    className="text-xs w-fit"
                    onClick={() => {
                      gotoVehiclesPage();
                    }}
                  >
                    Show Vehicles
                    <ArrowCircleRightIcon
                      className="w-5 h-5 ml-2"
                      aria-hidden="true"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>

      {openAddELDSystemModal && (
        <AddELDSystemModal
          {...{
            openAddELDSystemModal,
            setOpenAddELDSystemModal,
            eldSystems,
            selectedEldSystem,
            setSelectedEldSystem,
            ...geoTabAuthForm,
          }}
        />
      )}
      {openAddFleetioModal && (
        <AddFleetioModal
          {...{
            openAddFleetioModal,
            setOpenAddFleetioModal,
          }}
        />
      )}

      {openAddEJWardModal && (
        <AddEJWardModal
          {...{
            openAddEJWardModal,
            setOpenAddEJWardModal,
          }}
        />
      )}
    </>
  );
};
