/** @format */
import {
  BAR_GRAPH_CONFIG,
  REPORTS_PIE_GRAPH_CONFIG,
} from "@roadflex/constants";
import { AccountTagType, UserAuthType } from "@roadflex/types";
import {
  appChartsGraphSorter,
  getStartOfWeek,
  stringSorter,
} from "@roadflex/utils";
import classNames from "classnames";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { DropdownChangeParams } from "primereact/dropdown";
import { CustomMultiSelect, Select } from "../../../dropdowns";
import { InlineLoader } from "../../../loader";

type DisplayMode = {
  label: string;
  value: string;
};

interface TagAnalysisProps {
  tagAnalysisDataLoading: boolean;
  merchantByDollarSpentData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[];
  merchantByTransactionCountData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[];
  dates: Date[] | Date | undefined;
  onDateRangeChange: (e: CalendarChangeParams) => void;
  gmvGraphData: Array<[string, string]>;
  transactionCountGraphData: Array<[string, string]>;
  startDate: Date;
  endDate: Date;
  displayModes: DisplayMode[];
  selectedDisplayMode: string;
  onDisplayModeChange: (e: DropdownChangeParams) => void;
  readCurrentUserAuth: UserAuthType;
  transactionTags: AccountTagType[];
  cardTags: AccountTagType[];
  driverTags: AccountTagType[];
  vehicleTags: AccountTagType[];
  accountTagsDataLoading: boolean;
  selectedTransactionTags: string[];
  setSelectedTransactionTags: (val: string[]) => void;
  selectedCardTags: string[];
  setSelectedCardTags: (val: string[]) => void;
  selectedDriverTags: string[];
  setSelectedDriverTags: (val: string[]) => void;
  selectedVehicleTags: string[];
  setSelectedVehicleTags: (val: string[]) => void;
}

const TagAnalysisUI = ({
  tagAnalysisDataLoading,
  merchantByDollarSpentData,
  merchantByTransactionCountData,
  dates,
  onDateRangeChange,
  gmvGraphData,
  transactionCountGraphData,
  startDate,
  endDate,
  displayModes,
  selectedDisplayMode,
  onDisplayModeChange,
  readCurrentUserAuth,
  transactionTags,
  cardTags,
  driverTags,
  vehicleTags,
  accountTagsDataLoading,
  selectedTransactionTags,
  setSelectedTransactionTags,
  selectedCardTags,
  setSelectedCardTags,
  selectedDriverTags,
  setSelectedDriverTags,
  selectedVehicleTags,
  setSelectedVehicleTags,
}: TagAnalysisProps) => {
  const graphOfGmvData = [];
  const gmvWeeklyDataDict: Record<string, number> = {};
  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const accountTagItemTemplate = (accountTagItem: AccountTagType) => {
    const index = Number(accountTagItem?.colorCode) || 0;
    const length = ACCOUNT_TAGS_COLOR.length;
    const colorCode = ACCOUNT_TAGS_COLOR[index % length];
    return (
      <div>
        <span
          className={classNames(colorCode, "rounded-full text-sm px-2 mr-3")}
        ></span>
        <span>
          {accountTagItem.title} ({accountTagItem.tagId})
        </span>
      </div>
    );
  };

  for (let i = 0; i < gmvGraphData.length || 0; i++) {
    const parsedDate = parseInt(gmvGraphData[i][0], 10);
    const parsedAmount = parseFloat(gmvGraphData[i][1]) / 100;
    graphOfGmvData.push([parsedDate, parsedAmount]);
    const convertedDate = new Date(parsedDate);
    const firstDayOfWeek = getStartOfWeek(convertedDate).getTime();
    if (firstDayOfWeek in gmvWeeklyDataDict) {
      gmvWeeklyDataDict[firstDayOfWeek] += parsedAmount;
    } else {
      gmvWeeklyDataDict[firstDayOfWeek] = parsedAmount;
    }
  }

  const graphOfGmvWeeklyData = Object.keys(gmvWeeklyDataDict)
    .sort(stringSorter)
    .map((weekKey) => {
      const weekInfo = gmvWeeklyDataDict[weekKey];
      return [parseInt(weekKey, 10), weekInfo];
    });

  const graphOfTransactionsData = [];
  const transactionsWeeklyDataDict: Record<string, number> = {};

  for (let i = 0; i < transactionCountGraphData?.length || 0; i++) {
    const parsedDate = parseInt(transactionCountGraphData[i][0], 10);
    const parsedNumberOfTransactions = parseInt(
      transactionCountGraphData[i][1],
      10,
    );
    graphOfTransactionsData.push([parsedDate, parsedNumberOfTransactions]);
    const convertedDate = new Date(parsedDate);
    const firstDayOfWeek = getStartOfWeek(convertedDate).getTime();
    if (firstDayOfWeek in transactionsWeeklyDataDict) {
      transactionsWeeklyDataDict[firstDayOfWeek] += parsedNumberOfTransactions;
    } else {
      transactionsWeeklyDataDict[firstDayOfWeek] = parsedNumberOfTransactions;
    }
  }

  const graphOfTransactionsWeeklyData = Object.keys(transactionsWeeklyDataDict)
    .sort(stringSorter)
    .map((weekKey) => {
      const weekInfo = transactionsWeeklyDataDict[weekKey];
      return [parseInt(weekKey, 10), weekInfo];
    });

  graphOfGmvData.sort(appChartsGraphSorter);
  graphOfTransactionsData.sort(appChartsGraphSorter);

  const finalGraphOfGmvData =
    selectedDisplayMode === "day" ? graphOfGmvData : graphOfGmvWeeklyData;

  const graphOfGmvOptions = {
    ...BAR_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "Amount of GMV($)",
        },
      },
      {
        title: {
          text: "Amount of GMV($)",
        },
        linkedTo: 0,
        opposite: true,
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      pointFormat: "${point.y:.2f}",
    },
    series: [
      {
        name: "Transaction Volume",
        data: finalGraphOfGmvData,
      },
    ],
  };

  const finalGraphOfTransactionsData =
    selectedDisplayMode === "day"
      ? graphOfTransactionsData
      : graphOfTransactionsWeeklyData;

  const graphOfTransactionsOptions = {
    ...BAR_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "Number of Transactions",
        },
      },
      {
        title: {
          text: "Number of Transactions",
        },
        linkedTo: 0,
        opposite: true,
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    series: [
      {
        name: "Transactions",
        data: finalGraphOfTransactionsData,
      },
    ],
  };

  const merchantByDollarSpentBreakdown = {
    ...REPORTS_PIE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valueSuffix: "%",
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Merchant breakdown (amount)",
        data: merchantByDollarSpentData,
      },
    ],
  };

  const merchantByTransactionCountBreakdown = {
    ...REPORTS_PIE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valueSuffix: "%",
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Merchant breakdown (transaction count)",
        data: merchantByTransactionCountData,
      },
    ],
  };

  const NoDataToShowMessage = () => {
    return (
      <div className="absolute inset-0 flex justify-center w-full h-full bg-white md:items-center bg-opacity-70">
        <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
          No Data to Show
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 gap-3 text-sm">
      <div className="col-span-2">
        <div className="flex flex-col justify-start gap-10 md:items-center md:flex-row">
          <div className="w-full md:w-fit">
            <div className="flex flex-row md:items-center">
              <label className="pr-2 font-semibold" htmlFor="display-mode">
                Display Mode
              </label>
              <Select
                id="display-mode"
                value={selectedDisplayMode}
                options={displayModes}
                optionLabel="label"
                optionValue="value"
                onChange={onDisplayModeChange}
                placeholder="Select Mode"
                itemTemplate={(option) => {
                  return (
                    <span className={`mode-${option.label}`}>
                      {option.label}
                    </span>
                  );
                }}
              ></Select>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <label className="flex flex-col pr-2 font-semibold" htmlFor="range">
              <div>
                Date Range (
                {readCurrentUserAuth?.accountDisplaySetting?.timeZone})
              </div>
              <div className="italic font-normal">Max Range: 1 month</div>
            </label>
            <Calendar
              id="range"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              placeholder="Select Date Range"
              className="custom-calendar"
              panelClassName="custom-calendar-panel"
              minDate={startDate}
              maxDate={endDate}
              inputClassName="!py-2"
              readOnlyInput
            />
          </div>
          {tagAnalysisDataLoading && <InlineLoader />}
        </div>
        <div className="flex flex-col justify-start gap-6 mt-8 md:items-center md:flex-row">
          <div className="flex flex-row md:items-center">
            <label className="pr-2 font-semibold" htmlFor="transaction-tag">
              Transaction Tag
            </label>
            <CustomMultiSelect
              id="transaction-tag"
              value={selectedTransactionTags}
              options={transactionTags}
              onChange={(e) => {
                setSelectedTransactionTags(e.value);
              }}
              placeholder="Any"
              optionLabel="title"
              optionValue="id"
              maxSelectedLabels={1}
              itemTemplate={accountTagItemTemplate}
              disabled={accountTagsDataLoading}
            ></CustomMultiSelect>
          </div>
          <div className="flex flex-row md:items-center">
            <label className="pr-2 font-semibold" htmlFor="card-tag">
              Card Tag
            </label>
            <CustomMultiSelect
              id="card-tag"
              value={selectedCardTags}
              options={cardTags}
              onChange={(e) => {
                setSelectedCardTags(e.value);
              }}
              placeholder="Any"
              optionLabel="title"
              optionValue="id"
              maxSelectedLabels={1}
              itemTemplate={accountTagItemTemplate}
              disabled={accountTagsDataLoading}
            ></CustomMultiSelect>
          </div>
          <div className="flex flex-row md:items-center">
            <label className="pr-2 font-semibold" htmlFor="driver-tag">
              Driver Tag
            </label>
            <CustomMultiSelect
              id="driver-tag"
              value={selectedDriverTags}
              options={driverTags}
              onChange={(e) => {
                setSelectedDriverTags(e.value);
              }}
              placeholder="Any"
              optionLabel="title"
              optionValue="id"
              maxSelectedLabels={1}
              itemTemplate={accountTagItemTemplate}
              disabled={accountTagsDataLoading}
            />
          </div>
          <div className="flex flex-row md:items-center">
            <label className="pr-2 font-semibold" htmlFor="vehicle-tag">
              Vehicle Tag
            </label>
            <CustomMultiSelect
              id="vehicle-tag"
              value={selectedVehicleTags}
              options={vehicleTags}
              onChange={(e) => {
                setSelectedVehicleTags(e.value);
              }}
              style={{
                fontFamily: "Inter",
                fontSize: "0.75rem",
              }}
              placeholder="Any"
              optionLabel="title"
              optionValue="id"
              maxSelectedLabels={1}
              itemTemplate={accountTagItemTemplate}
              disabled={accountTagsDataLoading}
            />
          </div>
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Transaction Volume each {selectedDisplayMode}
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOfGmvOptions}
          />
          {finalGraphOfGmvData.length > 0 ? null : <NoDataToShowMessage />}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Number of Transactions each {selectedDisplayMode}
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={graphOfTransactionsOptions}
          />
          {finalGraphOfTransactionsData.length > 0 ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Merchant Breakdown (amount)
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={merchantByDollarSpentBreakdown}
          />
          {merchantByDollarSpentData.some(
            (merchantData) => merchantData.y !== 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Merchant Breakdown (transaction count)
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={merchantByTransactionCountBreakdown}
          />
          {merchantByTransactionCountData.some(
            (merchantData) => merchantData.y !== 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
    </div>
  );
};

export default TagAnalysisUI;
