/** @format */

import {
  ArrowPathIcon,
  BanknotesIcon,
  CreditCardIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  ENUM_WALLET_ACTIVITY_TYPE,
  ENUM_WALLET_ITEMS_TYPE,
  ENUM_WALLET_TYPE,
} from "@prisma/client";
import {
  useReadAccountStatus,
  useReadAppDashboardData,
  useReadUserWalletItems,
  useUserWalletItemsAndStatisticsForPrepaid,
} from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import {
  calculateDueDate,
  dollarFormatter,
  valueIsNullOrUndefined,
} from "@roadflex/web-lib";
import { addMilliseconds, differenceInDays } from "date-fns";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { DashboardUI } from "./main-dashboard.ui";

interface DashboardProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

const walletItemsInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: {},
  sortField: "",
  sortOrder: -1,
  multiSortMeta: null,
};

export const Dashboard = ({ readCurrentUserAuth, loading }: DashboardProps) => {
  const [walletItemsLazyParams, setWalletItemsLazyParams] =
    useState<DataTablePFSEvent>({
      ...walletItemsInitialLazyParams,
    });
  const { data, loading: appDashboardDataLoading } = useReadAppDashboardData();
  const { data: accountStatusData, loading: accountStatusDataLoading } =
    useReadAccountStatus();
  const [walletItemsData, setwalletItemsData] = useState<{
    walletItemsList: {
      type: ENUM_WALLET_TYPE;
      ItemType: ENUM_WALLET_ITEMS_TYPE;
      amount: number;
      classification: ENUM_WALLET_ACTIVITY_TYPE;
      fuelDiscountAmount: number;
      createdAt: Date;
    }[];
    totalCount: number;
  }>({
    walletItemsList: [],
    totalCount: 0,
  });
  const {
    data: prepaidStatistics,
    loading: userWalletItemsAndStatisticsForPrepaidDataLoading,
  } = useUserWalletItemsAndStatisticsForPrepaid();
  const prepaidUsersStatistics = {
    totalAmountSpentInPendingAuthorizations:
      prepaidStatistics?.totalAmountSpentInPendingAuthorizations,
    totalAmountSpentInTransactions:
      prepaidStatistics?.totalAmountSpentInTransactions,
    totalAmountUsedForWalletRecharges:
      prepaidStatistics?.totalAmountUsedForWalletRecharges,
  };

  const { data: UserWalletItems, loading: walletItemsLoading } =
    useReadUserWalletItems({
      userWalletLazyParams: walletItemsLazyParams,
    });

  useEffect(() => {
    if (
      !walletItemsLoading &&
      UserWalletItems &&
      UserWalletItems?.readUserWalletItem?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: UserWalletItems.readUserWalletItem.message || "",
      });
    }
    if (UserWalletItems && UserWalletItems.readUserWalletItem.code === "200") {
      setwalletItemsData({
        totalCount: UserWalletItems.readUserWalletItem.totalCount,
        walletItemsList: UserWalletItems.readUserWalletItem.walletItems,
      });
    }
  }, [UserWalletItems, walletItemsLoading]);

  if (
    loading ||
    appDashboardDataLoading ||
    userWalletItemsAndStatisticsForPrepaidDataLoading ||
    accountStatusDataLoading
  ) {
    return <Loader />;
  }

  const currentData = {
    creditBalance:
      Number(data?.readAppDashboardData?.appDashboardData?.wallet.cashBalance) +
      Number(data?.readAppDashboardData?.appDashboardData?.wallet.adminBalance),
    bonusAmount:
      data?.readAppDashboardData?.appDashboardData?.fuelDiscount?.bonusAmount ||
      0,
    totalFuelQuantity:
      data?.readAppDashboardData?.appDashboardData?.fuelDiscount
        ?.totalFuelQuantity || 0,
    bonusType:
      data?.readAppDashboardData?.appDashboardData?.fuelDiscount?.bonusType ||
      "",
    referralBalance:
      data?.readAppDashboardData?.appDashboardData?.wallet?.referralBalance ||
      0,
    signOnBalance:
      data?.readAppDashboardData?.appDashboardData?.wallet?.signOnBalance || 0,
    targetFuelQuantity:
      data?.readAppDashboardData?.appDashboardData?.fuelDiscount
        ?.targetFuelQuantity || 0,
    amountCorrection:
      data?.readAppDashboardData?.appDashboardData?.amountCorrection || 0,
  };
  const userSetting = data?.readAppDashboardData?.appDashboardData?.setting;
  const userCards = data?.readAppDashboardData?.appDashboardData?.cards || {
    active: 0,
    inactive: 0,
    canceled: 0,
  };
  const currentWeekSavings =
    data?.readAppDashboardData?.appDashboardData?.totalSavings;
  const currentWeekSpend =
    data?.readAppDashboardData?.appDashboardData?.totalSpendings;
  const weeklySpendsData =
    data?.readAppDashboardData?.appDashboardData?.weeklySpends || [];
  const categoryBreakdown =
    data?.readAppDashboardData?.appDashboardData?.categoryBreakdown || {};
  const customerType = readCurrentUserAuth?.fuelCard?.customerType;
  const businessName = readCurrentUserAuth?.businessName;
  const totalFraudDeclinedTransaction =
    data?.readAppDashboardData?.appDashboardData
      ?.totalFraudDeclinedTransaction || 0;
  const totalFraudSavings =
    data?.readAppDashboardData?.appDashboardData?.totalFraudSavings || 0;
  const totalLifeTimeSavings =
    data?.readAppDashboardData?.appDashboardData?.totalLifeTimeSavings || 0;
  let timezoneOffsetMillisecondsStart = 0;
  let timezoneOffsetMillisecondsEnd = 0;
  if (
    data?.readAppDashboardData?.appDashboardData?.currentCycle.startDt &&
    data?.readAppDashboardData?.appDashboardData?.currentCycle.endDt
  ) {
    timezoneOffsetMillisecondsStart =
      new Date(
        data?.readAppDashboardData?.appDashboardData?.currentCycle.startDt,
      ).getTimezoneOffset() * 60000;
    timezoneOffsetMillisecondsEnd =
      new Date(
        data?.readAppDashboardData?.appDashboardData?.currentCycle.endDt,
      ).getTimezoneOffset() * 60000;
  }

  let tz = "";
  if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "EST") {
    tz = "America/New_York";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "PST") {
    tz = "America/Los_Angeles";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "UTC") {
    tz = "UTC";
  } else if (readCurrentUserAuth?.accountDisplaySetting?.timeZone === "CT") {
    tz = "America/Chicago";
  }

  const overviewStats = [
    {
      id: 1,
      name: "Credit Cycle",
      stat: `${
        data?.readAppDashboardData?.appDashboardData?.currentCycle.endDt &&
        data?.readAppDashboardData?.appDashboardData?.currentCycle.startDt
          ? differenceInDays(
              addMilliseconds(
                new Date(
                  data?.readAppDashboardData?.appDashboardData?.currentCycle.endDt,
                ),
                +timezoneOffsetMillisecondsEnd,
              ) || 0,
              addMilliseconds(
                new Date(
                  data?.readAppDashboardData?.appDashboardData?.currentCycle.startDt,
                ),
                +timezoneOffsetMillisecondsStart,
              ) || 0,
            ) + 1
          : 0
      } days`,
      icon: ArrowPathIcon,
      // subText: () => <span>(Mon to Sun)</span>,
    },
    {
      id: 2,
      name: "Number of Cards",
      stat: `${userCards?.active + userCards?.inactive} (${
        userCards?.active
      } Active)`,
      icon: BanknotesIcon,
    },
    {
      id: 3,
      name: "Upcoming Payment",
      stat: appDashboardDataLoading
        ? "Loading..."
        : !valueIsNullOrUndefined(
            data?.readAppDashboardData?.appDashboardData?.outstandingAmount,
          ) &&
          !valueIsNullOrUndefined(
            data?.readAppDashboardData?.appDashboardData?.outstandingAmount
              ?.currentStatementDue,
          )
        ? dollarFormatter(
            +(
              data?.readAppDashboardData?.appDashboardData?.outstandingAmount
                ?.outstandingAmountAfterWalletDeduction || 0
            ) / 100,
          )
        : "-",
      icon: CreditCardIcon,
    },
    {
      id: 3,
      name: "Due Date",
      stat: `${
        appDashboardDataLoading
          ? "Loading..."
          : !valueIsNullOrUndefined(
              data?.readAppDashboardData?.appDashboardData?.outstandingAmount
                .currentStatementDue,
            ) &&
            data?.readAppDashboardData?.appDashboardData?.latestCycle?.endDt &&
            userSetting?.creditCycleType
          ? `${calculateDueDate(
              data?.readAppDashboardData?.appDashboardData?.latestCycle?.endDt,
              tz,
            )}`
          : "-"
      }`,
      icon: CreditCardIcon,
    },
  ];

  const statsForNextCycle = [
    {
      id: 4,
      name: "Account Credit",
      stat:
        !appDashboardDataLoading && currentData.creditBalance
          ? +currentData.creditBalance / 100
          : 0,
      icon: CreditCardIcon,
    },
    {
      id: 5,
      name: "Referral bonus",
      stat:
        !appDashboardDataLoading && currentData.referralBalance
          ? +currentData.referralBalance / 100
          : 0,
      icon: UsersIcon,
    },
    {
      id: 6,
      name: "Signon bonus",
      stat:
        !appDashboardDataLoading && currentData?.signOnBalance
          ? +currentData.signOnBalance / 100
          : 0,
      icon: EnvelopeOpenIcon,
    },
  ];

  const bonusAndCreditStats = [
    {
      id: 1,
      name: "Credit Limit",
      stat: userSetting?.creditLimit || 0,
    },
    {
      id: 2,
      name: "Spend",
      stat: currentWeekSpend || 0,
    },
    {
      id: 3,
      name: "Savings",
      stat: currentWeekSavings || 0,
    },
    {
      id: 4,
      name: "Credit Remaining",
      stat: data?.readAppDashboardData?.appDashboardData?.creditRemaining || 0,
    },
  ];

  const fuelBonusPercentage =
    ((currentData?.totalFuelQuantity || 0) /
      (currentData?.targetFuelQuantity || 0)) *
      100 || 0;

  const displayValueTemplate = () => {
    return (
      <p className="flex flex-row items-center justify-center h-full text-sm text-black md:text-base">
        <span>
          <span className="font-medium">
            {currentData?.totalFuelQuantity || 0} gallons
          </span>
          <span>{fuelBonusPercentage < 100 ? " purchased to date" : null}</span>
        </span>
      </p>
    );
  };

  return (
    <DashboardUI
      {...{
        bonusAndCreditStats,
        currentData,
        fuelBonusPercentage,
        displayValueTemplate,
        statsForNextCycle,
        overviewStats,
        customerType,
        businessName,
        readCurrentUserAuth,
        prepaidUsersStatistics,
        currentCycleData:
          data?.readAppDashboardData?.appDashboardData?.currentCycle || null,
        weeklySpendsData,
        categoryBreakdown,
        totalFraudDeclinedTransaction,
        totalFraudSavings,
        totalLifeTimeSavings,
        userAccountStatusData:
          accountStatusData?.readAccountStatus?.userAccount,
        walletItemsData,
        walletItemsLoading,
        walletItemsLazyParams,
        setWalletItemsLazyParams,
      }}
    />
  );
};
