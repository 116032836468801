/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { GEOTAB_GET_TRIP_HISTORY } from "@roadflex/graphql";
import { GeoTabTripHistory } from "@roadflex/types";
import { useFormik } from "formik";

type Values = {
  database: string;
  orgName: string;
  startDate: string;
  endDate: string;
  fromVersion: string;
  resultsLimit: number;
  deviceId: string;
};

type UseGeoTabGetTripHistory = {
  initialValues: Values;
  onSubmit: (
    val: {
      eldDeviceTripHistory: {
        fromVersion: string;
        eldDeviceTripHistory: GeoTabTripHistory[];
      };
    } | null,
    err: Error | null,
  ) => void;
};

export const useGeoTabGetTripHistory = ({
  onSubmit,
  initialValues,
}: UseGeoTabGetTripHistory) => {
  const [geoTabGetTripHistory] = useMutation<{
    eldDeviceTripHistory: {
      fromVersion: string;
      eldDeviceTripHistory: GeoTabTripHistory[];
    };
  }>(GEOTAB_GET_TRIP_HISTORY);

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        // console.log("data => ", data);
        const res = await geoTabGetTripHistory({
          variables: { data },
        });
        onSubmit(res?.data || null, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    geoTabGetTripHistoryValues: values,
    geoTabGetTripHistoryHandleChange: handleChange,
    geoTabGetTripHistoryHandleSubmit: handleSubmit,
    geoTabGetTripHistoryIsSubmitting: isSubmitting,
    geoTabGetTripHistorySetFieldValue: setFieldValue,
    ...rest,
  };
};
