export const isEmpty = (obj: JSON): boolean => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};

export const dollarFormatter = (input: number): string => {
  return `$${input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
};

export const centToDollarFormatter = (input: number): string => {
  const dollarAmount = input / 100;
  return dollarFormatter(dollarAmount);
};

export const truncateString = (
  input: string,
  truncateLength: number,
): string => {
  const length = input.length;
  if (length <= truncateLength) {
    return input;
  }
  return `${input.slice(0, truncateLength)}...`;
};

export function createFailureReason(
  type: string | null,
  code: string | null | undefined,
  description: string | null | undefined,
) {
  return {
    type: type,
    code: code,
    description: description,
  };
}
