/** @format */
import { useReadDriversAnalysis } from "@roadflex/react-hooks";
import { DriverAnalysisPerformanceType, UserAuthType } from "@roadflex/types";
import { useState } from "react";
import DriverPerformanceUI from "./driver-performance.ui";

export const DriverPerformance = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  const [timePeriod, setTimePeriod] = useState(90);

  const {
    driverAnalysisData,
    driverAnalysisDataRefetch,
    driverAnalysisDataLoading,
  } = useReadDriversAnalysis(timePeriod);

  const data = driverAnalysisData?.readDriverAnalysisData?.data || [];

  if (data.length > 1) {
    data.sort((a, b) => (a.totalSpend > b.totalSpend ? -1 : 1));
  }

  const dataFilter = data.filter((x) => x.totalSpend > 0);
  const length = dataFilter?.length > 20 ? 10 : dataFilter?.length;

  const topN: DriverAnalysisPerformanceType[] =
    dataFilter?.length > 0 ? dataFilter.slice(0, length) : [];
  const bottomN: DriverAnalysisPerformanceType[] =
    dataFilter?.length > 0 ? dataFilter.slice(-length) : [];

  if (data.length > 1) {
    data.sort((a, b) => (a.declineCount > b.declineCount ? -1 : 1));
  }

  const delinedDataTopN: DriverAnalysisPerformanceType[] =
    data?.length > 0
      ? data.slice(0, 20).filter((x) => Number(x.declineCount) !== 0)
      : [];

  const noSpend = data.filter((x) => Number(x.totalSpend) <= 0);

  return (
    <DriverPerformanceUI
      {...{
        loading: driverAnalysisDataLoading,
        topN: topN,
        bottomN: bottomN,
        number: length,
        noSpend: noSpend,
        decline: delinedDataTopN,
        timePeriod,
        setTimePeriod,
      }}
    />
  );
};
