/** @format */
import { useQuery } from "@apollo/client";
import { READ_BILLING_CYCLE } from "@roadflex/graphql";
import { BillingCycleType } from "@roadflex/types";

export const useReadBillingCycleList = () => {
  const { data, ...rest } = useQuery<{
    readBillingCycle: {
      code: string;
      message: string;
      billingCycleList: BillingCycleType[];
    };
  }>(READ_BILLING_CYCLE, {
    fetchPolicy: "no-cache",
  });
  return { data, ...rest };
};
