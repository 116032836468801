/** @format */
import { useQuery } from "@apollo/client";
import { PAYMENT_HISTORY_LIST } from "@roadflex/graphql";
import {
  ManualPaymentType,
  PaymentHistoryType,
  RefundPaymentType,
} from "@roadflex/types";

export const usePaymentHistoryList = () => {
  const { data, loading, refetch, ...rest } = useQuery<{
    paymentHistoryList: {
      success: boolean;
      code: string;
      type: string;
      message: string;
      detail: string;
      help: string;
      paymentHistory: PaymentHistoryType[];
      manualPayment: ManualPaymentType[];
      refundPayment: RefundPaymentType[];
    };
  }>(PAYMENT_HISTORY_LIST, {
    fetchPolicy: "no-cache",
  });
  return {
    paymentHistoryList: data,
    loadingPaymentHistory: loading,
    refetchPaymentHistory: refetch,
    ...rest,
  };
};
