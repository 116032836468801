/** @format */

import {
  useReadFilterOptions,
  useReadTransactions,
} from "@roadflex/react-hooks";
import {
  CardsFilterOptions,
  DriversFilterOptions,
  ParameterFilterType,
  TransactionType,
  UserAuthType,
  VehiclesFilterOptions,
} from "@roadflex/types";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Toast } from "../../../toast-message/toast";
import TransactionsFlaggedUI from "./transactions-flagged.ui";

type TransactionsFlaggedProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  cardTags: ParameterFilterType[];
};

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterConfigType = {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};

const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

const flaggedTransactionInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
};

export const TransactionsFlagged = ({
  readCurrentUserAuth,
  cardTags,
  loading,
}: TransactionsFlaggedProps) => {
  const [flaggedTransactionData, setFlaggedTransactionData] = useState<{
    transactions: TransactionType[];
    totalCount: number;
  }>({
    totalCount: 0,
    transactions: [],
  });
  const [flaggedTransactionsLazyParams, setFlaggedTransactionsLazyParams] =
    useState<DataTablePFSEvent>({
      ...flaggedTransactionInitialLazyParams,
    });

  const {
    transactionData: transactions,
    transactionRefetch,
    transactionLoading,
  } = useReadTransactions(
    flaggedTransactionsLazyParams,
    false,
    false,
    true,
    cardTags.length > 0 ? cardTags.map((x) => x.tag) : undefined,
  );

  const { filterOptions } = useReadFilterOptions();

  const [transactionFilterOptions, setTransactionFilterOptions] = useState<{
    vehiclesFilterOptions: VehiclesFilterOptions[];
    cardsFilterOptions: CardsFilterOptions[];
    driversFilterOptions: DriversFilterOptions[];
  }>({
    vehiclesFilterOptions: [],
    cardsFilterOptions: [],
    driversFilterOptions: [],
  });

  useEffect(() => {
    transactionRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flaggedTransactionsLazyParams]);

  useEffect(() => {
    if (
      !transactionLoading &&
      transactions &&
      transactions?.readTransactionList?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: transactions?.readTransactionList?.message || "",
      });
    }
    if (transactions && transactions?.readTransactionList.code === "200") {
      setFlaggedTransactionData({
        totalCount: transactions?.readTransactionList.totalCount,
        transactions: transactions?.readTransactionList.transactions,
      });
    }
  }, [transactions, transactionLoading]);

  useEffect(() => {
    transactionRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flaggedTransactionsLazyParams]);

  useEffect(() => {
    if (filterOptions?.transactionFilterOptions) {
      setTransactionFilterOptions({
        cardsFilterOptions: filterOptions.transactionFilterOptions.cards.map(
          (value) => {
            return {
              lastFour: value.lastFour,
            };
          },
        ),
        vehiclesFilterOptions:
          filterOptions.transactionFilterOptions.vehicles.map((value) => {
            return {
              vehicleName: value.fleetName,
            };
          }),
        driversFilterOptions:
          filterOptions.transactionFilterOptions.drivers.map((value) => {
            return {
              driverName: value.name,
            };
          }),
      });
    }
  }, [filterOptions?.transactionFilterOptions]);

  if (loading) {
    return null;
  }

  return (
    <TransactionsFlaggedUI
      {...{
        flaggedTransactionData,
        transactionLoading,
        flaggedTransactionsLazyParams,
        setFlaggedTransactionsLazyParams,
        transactionFilterOptions,
        readCurrentUserAuth,
      }}
    />
  );
};
