/** @format */
import { useReadTechnicalAnalysis } from "@roadflex/react-hooks";
import { UserAuthType } from "@roadflex/types";
import { useState } from "react";
import TechnicalAnalysisUI from "./technical-analysis.ui";

export const TechnicalAnalysis = ({
  readCurrentUserAuth,
}: {
  readCurrentUserAuth: UserAuthType;
}) => {
  const [queryTable, setQueryTable] = useState("CARDS");
  const cardsDefaultFilters: {
    operationalHours: string[];
    cardAssignments: string[];
    transactionControls: string[];
    spendingLimits: string[];
    merchantCategories: string[];
  } = {
    operationalHours: ["operationalHours"],
    cardAssignments: [
      // "driver", "vehicle"
    ],
    transactionControls: [
      // "smsSecurity",
      // "odometerSecurity",
      // "requireJobID",
      // "enableSmsReceiptUpload",
      // "fuelTypeSecurity",
      // "tankCapacitySecurity",
      // "suspiciousFuelingSecurity",
      // "flaggedMerchantCategories",
      // "telematicsSecurity",
    ],
    spendingLimits: [
      "perTransactionSpendingLimit",
      "dailySpendingLimit",
      "weeklySpendingLimit",
      "monthlySpendingLimit",
    ],
    merchantCategories: [
      // "fuelExpenses",
      // "vehicleExpenses",
      // "businessExpenses",
      // "additionalMerchants",
    ],
  };
  const driversDefaultFilters: {
    assignments: string[];
    spend: string[];
    discount: string[];
    fuel: string[];
  } = {
    assignments: ["card", "vehicle"],
    spend: ["spend30Days", "spend60Days", "spend90Days"],
    discount: ["discount30Days", "discount60Days", "discount90Days"],
    fuel: ["fuel"],
  };
  const vehiclesDefaultFilters: {
    assignments: string[];
    spend: string[];
    discount: string[];
    fuel: string[];
  } = {
    assignments: ["card", "driver"],
    spend: ["spend30Days", "spend60Days", "spend90Days"],
    discount: ["discount30Days", "discount60Days", "discount90Days"],
    fuel: ["fuel"],
  };
  const [cardsFilters, setCardsFilters] = useState({ ...cardsDefaultFilters });
  const [driversFilters, setDriversFilters] = useState({
    ...driversDefaultFilters,
  });
  const [vehiclesFilters, setVehiclesFilters] = useState({
    ...vehiclesDefaultFilters,
  });

  const {
    technicalAnalysisData,
    technicalAnalysisDataRefetch,
    technicalAnalysisDataLoading,
  } = useReadTechnicalAnalysis(queryTable);

  const cards = technicalAnalysisData?.readTechnicalAnalysisData?.cards || [];
  const drivers =
    technicalAnalysisData?.readTechnicalAnalysisData?.drivers || [];
  const vehicles =
    technicalAnalysisData?.readTechnicalAnalysisData?.vehicles || [];

  const resetFilters = () => {
    if (queryTable === "CARDS") {
      setCardsFilters({ ...cardsDefaultFilters });
    } else if (queryTable === "DRIVERS") {
      setDriversFilters({ ...driversDefaultFilters });
    } else if (queryTable === "VEHICLES") {
      setVehiclesFilters({ ...vehiclesDefaultFilters });
    }
  };

  return (
    <TechnicalAnalysisUI
      {...{
        loading: technicalAnalysisDataLoading,
        queryTable,
        setQueryTable,
        cards,
        cardsFilters,
        setCardsFilters,
        drivers,
        driversFilters,
        setDriversFilters,
        vehicles,
        vehiclesFilters,
        setVehiclesFilters,
        resetFilters,
      }}
    />
  );
};
