/** @format */

import {
  ENUM_CUSTOMER_TYPE,
  ENUM_WALLET_ACTIVITY_TYPE,
  ENUM_WALLET_ITEMS_TYPE,
  ENUM_WALLET_TYPE,
} from "@prisma/client";
import { ENUM_BONUS_TYPE } from "@roadflex/constants";
import { UserAccountStatusData, UserAuthType } from "@roadflex/types";
import { dollarFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { DataTablePFSEvent } from "primereact/datatable";
import { ProgressBar } from "primereact/progressbar";
import { CreditDashboardUI } from "./credit-dashboard.ui";
import { PrepaidDashboardUI } from "./prepaid-dashboard.ui";

const bonusTypeMap = {
  AMAZON_GIFTCARD: "amazon gift card",
  CREDIT: "credit",
};

type IconSVGProps = React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> &
  React.RefAttributes<SVGSVGElement>;
type IconProps = IconSVGProps & {
  title?: string;
  titleId?: string;
};

type DashboardUIProps = {
  bonusAndCreditStats: {
    id: number;
    name: string;
    stat: number;
  }[];
  currentData: {
    creditBalance: number;
    bonusAmount: number;
    totalFuelQuantity: number;
    bonusType: string;
    referralBalance: number;
    signOnBalance: number;
    targetFuelQuantity: number;
    amountCorrection: number;
  };
  fuelBonusPercentage: number;
  displayValueTemplate: () => JSX.Element;
  statsForNextCycle: {
    id: number;
    name: string;
    stat: number;
    icon: React.FC<IconProps>;
    subText?: () => JSX.Element;
  }[];
  overviewStats: {
    id: number;
    name: string;
    stat: string | number;
    icon: React.FC<IconProps>;
    subText?: () => JSX.Element;
  }[];
  customerType: ENUM_CUSTOMER_TYPE;
  businessName?: string;
  prepaidUsersStatistics?: {
    totalAmountSpentInPendingAuthorizations: number;
    totalAmountSpentInTransactions: number;
    totalAmountUsedForWalletRecharges: number;
  };
  readCurrentUserAuth: UserAuthType;
  currentCycleData: {
    startDt: Date;
    endDt: Date;
  } | null;
  weeklySpendsData: Array<[number, number]>;
  categoryBreakdown: { [key: string]: string };
  totalFraudDeclinedTransaction: number;
  totalFraudSavings: number;
  totalLifeTimeSavings: number;
  walletItemsData: {
    walletItemsList: {
      type: ENUM_WALLET_TYPE;
      ItemType: ENUM_WALLET_ITEMS_TYPE;
      amount: number;
      classification: ENUM_WALLET_ACTIVITY_TYPE;
      fuelDiscountAmount: number;
      createdAt: Date;
    }[];
    totalCount: number;
  };
  walletItemsLoading: boolean;
  walletItemsLazyParams: DataTablePFSEvent;
  setWalletItemsLazyParams: (value: DataTablePFSEvent) => void;
  userAccountStatusData?: UserAccountStatusData;
};

export const DashboardUI = ({
  bonusAndCreditStats,
  currentData,
  fuelBonusPercentage,
  displayValueTemplate,
  statsForNextCycle,
  overviewStats,
  customerType,
  businessName,
  prepaidUsersStatistics,
  currentCycleData,
  weeklySpendsData,
  categoryBreakdown,
  userAccountStatusData,
  totalFraudDeclinedTransaction,
  totalFraudSavings,
  totalLifeTimeSavings,
  readCurrentUserAuth,
  walletItemsData,
  walletItemsLoading,
  walletItemsLazyParams,
  setWalletItemsLazyParams,
}: DashboardUIProps) => {
  return (
    <div className="flex flex-col flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4">
      {/* heading */}
      <div className="flex items-center md:my-12">
        <span className="text-xl font-bold text-brown-500 md:text-2xl">
          {`Fleet Dashboard`.toUpperCase()}
        </span>
        {userAccountStatusData && (
          <span
            className={classNames(
              userAccountStatusData.cardholderStatus === "ACTIVE"
                ? "bg-green-100 text-green-700 border-green-700"
                : "bg-red-100 text-red-700 border-red-700",
              "ml-3 px-2.5 py-0.5 rounded-2xl font-medium  inline-flex items-center",
            )}
          >
            {userAccountStatusData.cardholderStatus}
          </span>
        )}
      </div>

      {(customerType === ENUM_CUSTOMER_TYPE.CREDIT ||
        customerType === ENUM_CUSTOMER_TYPE.CREDIT_PLUS ||
        customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE) && (
        <CreditDashboardUI
          {...{
            businessName,
            bonusAndCreditStats,
            currentData,
            overviewStats,
            currentCycleData,
            weeklySpendsData,
            categoryBreakdown,
            userAccountStatusData,
            totalFraudDeclinedTransaction,
            totalFraudSavings,
            totalLifeTimeSavings,
            readCurrentUserAuth,
          }}
        ></CreditDashboardUI>
      )}

      {(customerType === ENUM_CUSTOMER_TYPE.PREPAID ||
        customerType === ENUM_CUSTOMER_TYPE.PREPAID_PLUS ||
        customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE) && (
        <PrepaidDashboardUI
          {...{
            businessName,
            currentData,
            prepaidUsersStatistics,
            weeklySpendsData,
            categoryBreakdown,
            userAccountStatusData,
            totalFraudDeclinedTransaction,
            totalFraudSavings,
            totalLifeTimeSavings,
            readCurrentUserAuth,
            walletItemsData,
            walletItemsLoading,
            walletItemsLazyParams,
            setWalletItemsLazyParams,
          }}
        ></PrepaidDashboardUI>
      )}

      {Boolean(currentData?.bonusAmount) &&
        Boolean(currentData?.targetFuelQuantity) && (
          <div className="p-8 mt-4 bg-white rounded-md shadow">
            <div className="text-sm md:text-base">
              <div className="py-1">
                <div className="flex items-center gap-4 font-semibold text-brown-500">
                  FUEL LIFETIME BONUS
                </div>
              </div>
              <div className="">
                <div className="grid gap-2">
                  <div key={1} className="">
                    <ProgressBar
                      color="#e6b68b"
                      style={{
                        border: "1px lightGray solid",
                        fontFamily: "Inter",
                        height: "30px",
                      }}
                      className="!bg-brown-200 !border-brown-200"
                      value={fuelBonusPercentage}
                      displayValueTemplate={displayValueTemplate}
                    ></ProgressBar>
                  </div>
                  <div key={2} className="pt-4 text-center">
                    {fuelBonusPercentage >= 100 ? (
                      <p className="">
                        You have earned{" "}
                        <span className="font-medium">
                          {dollarFormatter(currentData?.bonusAmount / 100 || 0)}
                        </span>{" "}
                        in{" "}
                        {bonusTypeMap[
                          currentData?.bonusType as ENUM_BONUS_TYPE
                        ] || "bonus"}
                      </p>
                    ) : (
                      <p className="">
                        <span className="font-medium">
                          {currentData?.targetFuelQuantity || 0} gallons
                        </span>{" "}
                        to receive{" "}
                        <span className="font-medium">
                          {dollarFormatter(currentData?.bonusAmount / 100 || 0)}
                        </span>
                        {" in "}
                        {bonusTypeMap[
                          currentData?.bonusType as ENUM_BONUS_TYPE
                        ] || "bonus"}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
