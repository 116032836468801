/** @format */

import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { PasswordFormValues } from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { TextInput } from "../inputs";
import { OnboardBannerComponent } from "./common-banner-component";

export interface SetPasswordUIProps extends FormikProps<PasswordFormValues> {
  isSubmitting: boolean;
  isSubmittingOnboardStatus: boolean;
}

const SetPasswordUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  isSubmittingOnboardStatus,
  handleBlur,
  touched,
  errors,
  ...rest
}: SetPasswordUIProps) => {
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent
        header="Application"
        subText="Create your account password."
      />

      <div className="flex flex-col justify-center w-full text-center rounded-md">
        <div className="flex items-center justify-center min-h-[245px]">
          <form
            className="flex flex-col w-full px-4 py-8 md:p-16"
            onSubmit={handleSubmit}
          >
            <div className="relative w-full">
              <TextInput
                label="Password"
                type="password"
                required
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                errorMessage={errors.password}
                touched={touched.password}
                variant="responsive"
              ></TextInput>
            </div>
            <div className="relative w-full mt-4">
              <TextInput
                label="Confirm Password"
                type="password"
                required
                name="retypePassword"
                value={values.retypePassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.retypePassword && Boolean(errors.retypePassword)}
                errorMessage={errors.retypePassword}
                touched={touched.retypePassword}
                variant="responsive"
              ></TextInput>
            </div>
            <div className="mt-6 text-right">
              <Button
                type={ButtonType.Submit}
                variant={ButtonVariant.Orange}
                size={ButtonSize.Normal}
                className="w-full"
                disabled={isSubmitting || isSubmittingOnboardStatus}
                loading={isSubmitting || isSubmittingOnboardStatus}
              >
                Submit
              </Button>
            </div>
            <div className="mt-12 text-xs italic text-center text-black">
              You can always come back and resume by logging in with your email.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordUI;
