/** @format */

import { ENUM_FLEET_DRIVER_STATUS } from "@prisma/client";
import {
  useInviteDriverToApp,
  useReadDrivers,
  useUpdateDriver,
} from "@roadflex/react-hooks";
import { ChildFeatureType, UserAuthType } from "@roadflex/types";
import { phoneFormatter } from "@roadflex/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Toast } from "../../../toast-message/toast";
import { DriversUI } from "./driver.ui";

type DriversEditProps = {
  readCurrentUserAuth: UserAuthType;
  driverId: string;
  childFeatures: ChildFeatureType[];
};
export const DriversEdit = ({
  readCurrentUserAuth,
  driverId,
  childFeatures,
}: DriversEditProps) => {
  const tabs = [{ id: 1, name: "Driver", current: true }];
  const [update, setUpdate] = useState(false);
  const router = useRouter();

  const { getDriversData, getDriversLoading } = useReadDrivers([driverId]);

  const {
    inviteDriverToAppHandleSubmit,
    inviteDriverToAppIsSubmitting,
    inviteDriverToAppResetForm,
  } = useInviteDriverToApp({
    initialValues: {
      idList: [driverId],
    },
    onSubmit(res, err) {
      inviteDriverToAppResetForm();
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      }
    },
  });
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    setValues,
    resetForm,
    ...rest
  } = useUpdateDriver({
    initialValues: {
      name: getDriversData?.readDrivers?.drivers[0]?.name || "",
      notes: getDriversData?.readDrivers?.drivers[0]?.notes || "",
      email: getDriversData?.readDrivers?.drivers[0]?.email || "",
      inviteDriver: false,
      phoneNumber: phoneFormatter(
        getDriversData?.readDrivers?.drivers[0]?.phoneNumber || "",
      ),
      status:
        getDriversData?.readDrivers?.drivers[0]?.status ||
        ENUM_FLEET_DRIVER_STATUS.NOT_INVITED,
      id: getDriversData?.readDrivers?.drivers[0]?.id,
    },
    onSubmit(res, err) {
      setUpdate(false);
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      }
      router.push("/drivers");
    },
  });

  useEffect(() => {
    if (getDriversData?.readDrivers?.drivers[0]) {
      setValues({
        name: getDriversData?.readDrivers?.drivers[0]?.name,
        notes: getDriversData?.readDrivers?.drivers[0]?.notes,
        email: getDriversData?.readDrivers?.drivers[0]?.email,
        inviteDriver: false,
        phoneNumber: phoneFormatter(
          getDriversData?.readDrivers?.drivers[0]?.phoneNumber,
        ),
        status: getDriversData?.readDrivers?.drivers[0]?.status,
        id: getDriversData?.readDrivers?.drivers[0]?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDriversData?.readDrivers?.drivers[0]]);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);
  return (
    <DriversUI
      {...{
        customerType: readCurrentUserAuth.fuelCard.customerType,
        tabs,
        driverId,
        inviteDriverToAppHandleSubmit,
        inviteDriverToAppIsSubmitting,
        timer,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        values,
        isSubmitting,
        errors,
        touched,
        setValues,
        update,
        setUpdate,
        ...rest,
        childFeatures,
      }}
    />
  );
};
