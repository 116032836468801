/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { ENUM_FUEL_TYPE } from "@prisma/client";
import {
  READ_AUTHORIZATION_LIST_PAGINATION,
  READ_TRANSACTION_DASHBOARD_DETAILS,
  READ_TRANSACTION_LIST_PAGINATION,
  TRANSACTION_FILTER_OPTIONS,
  UPDATE_BLOCK_MERCHANTS,
  UPDATE_TRANSACTION_MEMO,
} from "@roadflex/graphql";
import {
  AuthorizationType,
  TransactionDashboadDetailsType,
  TransactionType,
} from "@roadflex/types";
import { useFormik } from "formik";
import { DataTablePFSEvent } from "primereact/datatable";

type Values = {
  memo: string;
  transactionId: string;
  transactionTagId?: string;
  cardTagId?: string;
  driverTagId?: string;
  vehicleTagId?: string;
  type: string;
};
type useUpdateTransactionMemoProps = {
  initialValues: Values;
  onSubmit: (val: any, err: any) => void;
};

type useUpdateBlockedMerchantsProps = {
  initialValues: {
    blockedMerchants: string;
  };
  onSubmit: (val: any, err: any) => void;
};

export const useReadTransactionDashboard = () => {
  const {
    data: transactionDashboardData,
    refetch: transactionDashboardRefetch,
    loading: transactionDashboardLoading,
  } = useQuery<{
    readTransactionDashboardDetails: {
      transactionDashboardDetails: TransactionDashboadDetailsType;
      code: string;
      message: string;
    };
  }>(READ_TRANSACTION_DASHBOARD_DETAILS, {
    fetchPolicy: "no-cache",
  });

  return {
    transactionDashboardData,
    transactionDashboardRefetch,
    transactionDashboardLoading,
  };
};

export const useReadAuthorizations = (
  authorizationLazyParams: DataTablePFSEvent,
  approvalStatus: boolean,
  authorizationStatus: string,
  filterCardTags?: string[],
) => {
  const {
    data: authorizationData,
    refetch: authorizationRefetch,
    loading: authorizationLoading,
  } = useQuery<{
    readAuthorizationList: {
      code: string;
      message: string;
      authorizations: AuthorizationType[];
      totalCount: number;
    };
  }>(READ_AUTHORIZATION_LIST_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...authorizationLazyParams,
        approvalStatus,
        authorizationStatus,
        filterCardTags,
      },
    },
  });
  return {
    authorizationData,
    authorizationRefetch,
    authorizationLoading,
  };
};

export const useReadTransactions = (
  transactionLazyParams: DataTablePFSEvent,
  receiptUploaded: boolean,
  receiptRequire: boolean,
  flagged: boolean,
  filterCardTags?: string[],
  transactionTags?: string[],
  cardTags?: string[],
  driverTags?: string[],
  vehicleTags?: string[],
) => {
  const {
    data: transactionData,
    refetch: transactionRefetch,
    loading: transactionLoading,
  } = useQuery<{
    readTransactionList: {
      code: string;
      message: string;
      transactions: TransactionType[];
      totalCount: number;
    };
  }>(READ_TRANSACTION_LIST_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...transactionLazyParams,
        receiptUploaded,
        receiptRequire,
        flagged,
        filterCardTags,
        transactionTags,
        cardTags,
        driverTags,
        vehicleTags,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
  });
  return {
    transactionData,
    transactionRefetch,
    transactionLoading,
  };
};

export const useReadFilterOptions = () => {
  const { data: filterOptions, loading } = useQuery<{
    transactionFilterOptions: {
      code: string;
      message: string;
      cards: {
        id: string;
        createdAt: Date;
        lastFour: string;
      }[];
      drivers: {
        id: string;
        createdAt: Date;
        name: string;
        isPhoneVerified?: boolean;
      }[];
      vehicles: {
        id: string;
        createdAt: Date;
        fleetName: string;
        tankCapacity: number;
        fuelType: ENUM_FUEL_TYPE;
        eldOrgName: string;
      }[];
    };
  }>(TRANSACTION_FILTER_OPTIONS, { fetchPolicy: "no-cache" });
  return {
    filterOptions,
    loading,
  };
};

export const useUpdateBlockedMerchants = ({
  onSubmit,
  initialValues,
}: useUpdateBlockedMerchantsProps) => {
  const [UpdateBlockedMerchantsFn] = useMutation(UPDATE_BLOCK_MERCHANTS);

  const {
    handleChange: handleChangeBlockedMerchants,
    handleSubmit: blockedMerchantsHandleSubmit,
    values: blockedMerchantsValues,
    isSubmitting: blockedMerchantsIsSubmitting,
    resetForm: blockedMerchantsResetForm,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        const data = { ...values };
        const res = await UpdateBlockedMerchantsFn({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });
  return {
    blockedMerchantsValues,
    handleChangeBlockedMerchants,
    blockedMerchantsHandleSubmit,
    blockedMerchantsIsSubmitting,
    blockedMerchantsResetForm,
  };
};
export const useUpdateTransactionMemo = ({
  onSubmit,
  initialValues,
}: useUpdateTransactionMemoProps) => {
  const [updateTransactionMemo] = useMutation(UPDATE_TRANSACTION_MEMO);

  const {
    handleChange: handleUpdateTransactionMemoChange,
    handleSubmit: handleUpdateTransactionMemoSubmit,
    values: updateTransactionMemoValues,
    isSubmitting: updateTransactionMemoSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        const data = { ...values };
        const res = await updateTransactionMemo({
          variables: { data },
        });
        onSubmit(res, null);
      } catch (error) {
        onSubmit(null, error);
      }
    },
  });

  return {
    updateTransactionMemoValues,
    handleUpdateTransactionMemoChange,
    handleUpdateTransactionMemoSubmit,
    updateTransactionMemoSubmitting,
    setFieldValue,
  };
};
