import { ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING } from "@prisma/client";
import { Loader } from "@roadflex/react-components-www";
import { useReadAccountControlSetting } from "@roadflex/react-hooks";
import { AccountSettingDisplayType, FeatureType } from "@roadflex/types";
import ControlCenterUI from "./control-center.ui";

type ControlCenterProps = {
  readAccountDisplaySetting: AccountSettingDisplayType;
  controlCenterFeatureAccess: FeatureType[];
};

const ControlCenter = ({
  readAccountDisplaySetting,
  controlCenterFeatureAccess,
}: ControlCenterProps) => {
  const { accountControlSettingData, accountControlSettingLoading } =
    useReadAccountControlSetting();
  if (accountControlSettingLoading || !accountControlSettingData) {
    return <Loader></Loader>;
  }
  return (
    <ControlCenterUI
      readAccountDisplaySetting={readAccountDisplaySetting}
      dynamicPurchaseControlsLinkedTo={
        accountControlSettingData?.readAccountControlSettings
          ?.dynamicPurchaseControlsLinkedTo ||
        ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING.CARD
      }
      controlCenterFeatureAccess={controlCenterFeatureAccess}
    />
  );
};
export default ControlCenter;
