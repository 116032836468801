/** @format */

import { useManageAddresses } from "@roadflex/react-hooks";
import { ExtendedAddressType } from "@roadflex/types";
import getConfig from "next/config";
import { useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import Toast from "../../toast-message/toast";
import ShippingAddressUI from "./shipping-address.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

const ShippingAddress = ({
  businessId,
  initialAddresses,
}: {
  businessId: string;
  initialAddresses: ExtendedAddressType[];
}) => {
  const onSubmitError = (message: string) => {
    Toast({ type: "error", message: message });
  };
  const {
    addresses,
    selectAddress,
    removeAddress,
    formik,
    setShowAddressInputs,
    showAddressInputs,
  } = useManageAddresses(
    initialAddresses.map((address) => {
      return { ...address, __typename: undefined };
    }),
    businessId,
    onSubmitError,
  );

  const { ref: shippingAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      let streetAddress = "";
      let city = "";
      let state = "";
      let zip = "";

      if (place.address_components) {
        for (const component of place.address_components) {
          const componentType = component.types;
          if (componentType.includes("street_number")) {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType.includes("route")) {
            streetAddress += component.long_name;
          }
          if (componentType.includes("postal_code")) {
            zip = component.long_name;
          }
          if (componentType.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (
            componentType.includes("locality") ||
            componentType.includes("sublocality")
          ) {
            city = component.long_name;
          }
        }
      }

      formik.setValues({
        ...formik.values,
        deliveryAddress: {
          ...formik.values.deliveryAddress,
          streetAddress,
          city,
          state,
          zip,
        },
      });
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  const autoCompleteRef = useRef({
    shippingAutoCompleteRef,
  });

  return (
    <ShippingAddressUI
      {...{
        ...formik,
      }}
      addresses={addresses}
      onAddressSelect={selectAddress}
      onAddressRemove={removeAddress}
      ref={autoCompleteRef}
      setShowAddressInputs={setShowAddressInputs}
      showAddressInputs={showAddressInputs}
    />
  );
};

export default ShippingAddress;
