/** @format */

import {
  ENUM_MANUAL_PAYMENT_HISTORY_STATUS,
  ENUM_PAYMENTHISTORY_STATUS,
} from "@prisma/client";
import { dollarFormatter } from "@roadflex/constants";
import { usePaymentHistoryList } from "@roadflex/react-hooks";
import { PaymentHistoryType, UserAuthType } from "@roadflex/types";
import { useEffect } from "react";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { PaymentsHistoryUI } from "./payment-history.ui";

const amountTemplate = (val: PaymentHistoryType) => {
  return (
    <span className="whitespace-nowrap">
      {dollarFormatter(val ? +val / 100 : 0)}
    </span>
  );
};

const statusTemplate = (rowData: PaymentHistoryType) => {
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full font-medium
      ${
        rowData?.status === ENUM_PAYMENTHISTORY_STATUS.SUCCEEDED
          ? "bg-green-100 text-green-800"
          : "bg-orange-100 text-orange-800"
      }
      `}
    >
      {rowData?.type === "Manual" &&
      rowData?.status === ENUM_PAYMENTHISTORY_STATUS.PENDING
        ? "payment cancelled"
        : rowData?.status
        ? rowData?.status[0].toUpperCase() +
          rowData?.status.slice(1).toLowerCase()
        : "-"}
    </span>
  );
};

interface PaymentsHistoryProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
}

export const PaymentsHistory = ({
  readCurrentUserAuth,
  loading,
}: PaymentsHistoryProps) => {
  const { paymentHistoryList, loadingPaymentHistory } = usePaymentHistoryList();
  useEffect(() => {
    if (
      !loadingPaymentHistory &&
      paymentHistoryList &&
      !paymentHistoryList?.paymentHistoryList?.success
    ) {
      Toast({
        type: "error",
        message: paymentHistoryList.paymentHistoryList.message || "",
      });
    }
  }, [paymentHistoryList, loadingPaymentHistory]);

  const completePaymentList = () => {
    let list: PaymentHistoryType[] = [];
    if (paymentHistoryList?.paymentHistoryList) {
      const data = paymentHistoryList?.paymentHistoryList;

      const { paymentHistory, manualPayment, refundPayment } = data;
      if (refundPayment && refundPayment?.length > 0) {
        list = list.concat(
          refundPayment.map((m) => ({
            ...m,
            type: "Refunded",
            payId: "",
            mtResponse: {},
            failureReason: {},
            refunded: false,
          })),
        );
      }
      if (paymentHistory && paymentHistory?.length > 0) {
        list = list
          .concat(
            paymentHistory
              .filter(
                (f) =>
                  f?.payId !== "Wallet" &&
                  f?.paymentType !== "DEPOSIT" &&
                  f?.paymentType !== "SETUP_FEE",
              )
              .map((m) => ({ ...m, type: "Automated" })),
          )
          .concat(
            paymentHistory
              .filter((f) => f?.paymentType === "DEPOSIT")
              .map((m) => ({ ...m, type: "Deposit" })),
          )
          .concat(
            paymentHistory
              .filter((f) => f?.paymentType === "SETUP_FEE")
              .map((m) => ({ ...m, type: "Setup Fees" })),
          );
      }

      if (manualPayment && manualPayment?.length > 0) {
        list = list.concat(
          manualPayment
            .filter(function (f) {
              return (
                f.status !== ENUM_MANUAL_PAYMENT_HISTORY_STATUS.PENDING &&
                f.status !== ENUM_MANUAL_PAYMENT_HISTORY_STATUS.CREATED
              );
            })
            .map((m) => ({
              ...m,
              type: "Manual",
              payId: "",
              mtResponse: {},
              failureReason: {},
              refunded: false,
            })),
        );
      }
      return list;
    }
    return [];
  };

  if (loading || loadingPaymentHistory) {
    return <Loader />;
  }

  return (
    <PaymentsHistoryUI
      {...{
        paymentHistoryList: completePaymentList(),
        amountTemplate,
        statusTemplate,
        loadingPaymentHistory,
      }}
    />
  );
};
