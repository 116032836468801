/** @format */

import { useUpdateFleetInformation } from "@roadflex/react-hooks";
import { UserDetailsType } from "@roadflex/types";
import { Toast } from "../../../../src/toast-message/toast";
import UpdateFleetInfoUI from "./update-fleet-info.ui";

type UpdateFleetProps = {
  readUserDetailsData: UserDetailsType;
};

const UpdateFleet = ({ readUserDetailsData }: UpdateFleetProps) => {
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useUpdateFleetInformation({
    initialValues: {
      businessId: readUserDetailsData?.businessDetails?.id || "",
      fleetType: readUserDetailsData?.fleet?.fleetType || "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      } else {
        Toast({ type: "error", message: res?.message || "" });
      }
    },
  });

  return (
    <UpdateFleetInfoUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        ...rest,
      }}
    />
  );
};

export default UpdateFleet;
