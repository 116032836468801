/** @format */

import {
  CheckCircleIcon,
  CogIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { ENUM_CARDHOLDER_INACTIVE_REASON } from "@roadflex/constants";
import { UserAccountStatusData } from "@roadflex/types";
import classNames from "classnames";
import Link from "next/link";

export const AccountAttentionUI = ({
  userAccountStatusData,
}: {
  userAccountStatusData?: UserAccountStatusData;
}) => {
  if (!userAccountStatusData) {
    return null;
  }
  return (
    <div className="flex flex-col w-full h-full gap-4 p-4 bg-white rounded-md shadow text-brown-500">
      <div className="flex flex-col gap-2 p-4 border border-orange-500">
        <div className={classNames(" flex flex-row items-center")}>
          {userAccountStatusData.cardholderStatus === "ACTIVE" ? (
            <CheckCircleIcon className="w-5 h-5 mr-3 text-orange-500"></CheckCircleIcon>
          ) : (
            <XCircleIcon className="w-5 h-5 mr-3 text-orange-500"></XCircleIcon>
          )}
          <div className={classNames("font-semibold text-orange-500")}>
            {userAccountStatusData.inactiveReasons?.length || 0} items need your
            attention
          </div>
        </div>
        {userAccountStatusData.inactiveReasons?.length !== 0 ? (
          <div className="flex flex-col gap-3">
            {userAccountStatusData.inactiveReasons.map((reason, i) => (
              <div
                className="flex flex-row flex-wrap items-center justify-between gap-3"
                key={i}
              >
                <div className="text-orange-500">
                  {" "}
                  {i + 1}. {reason.message}
                </div>
                {reason.type ===
                  ENUM_CARDHOLDER_INACTIVE_REASON.EMAIL_NOT_VERIFIED && (
                  <Link href="/account-settings">
                    <a
                      href="/account-settings"
                      className="font-medium whitespace-nowrap"
                    >
                      Update email&nbsp;
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </Link>
                )}
                {reason.type === ENUM_CARDHOLDER_INACTIVE_REASON.PLAID && (
                  <Link href="/payments">
                    <a
                      href="/payments"
                      className="font-medium whitespace-nowrap"
                    >
                      Update payment methods&nbsp;
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </Link>
                )}
                {reason.type ===
                  ENUM_CARDHOLDER_INACTIVE_REASON.FAILED_BILLING_CYCLE && (
                  <Link href="/payments">
                    <a
                      href="/payments"
                      className="font-medium whitespace-nowrap"
                    >
                      Make payment&nbsp;
                      <span aria-hidden="true">&rarr;</span>
                    </a>
                  </Link>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-sm">
            There are currently no items that need your attention.
          </div>
        )}
      </div>

      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col gap-2 p-4 bg-orange-100 md:w-1/2">
          <div className="flex flex-row text-orange-500">
            <QuestionMarkCircleIcon className="w-5 h-5 mr-3 text-orange-500"></QuestionMarkCircleIcon>
            <div className="font-semibold">Need Help?</div>
          </div>
          <div className="text-sm">
            Visit the{" "}
            <span>
              <a
                href="https://roadflex.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block italic text-brown-700"
              >
                Help Center
              </a>
            </span>{" "}
            to learn more and make the most of your RoadFlex software.
          </div>
        </div>
        <div className="flex flex-col gap-2 p-4 bg-orange-100 md:w-1/2">
          <div className="flex flex-row text-orange-500">
            <CogIcon className="w-5 h-5 mr-3 text-orange-500"></CogIcon>
            <div className="font-semibold">Settings Modifications</div>
          </div>
          <div className="text-sm">
            View details of your account in{" "}
            <a
              href="/account-settings"
              target="_self"
              rel="noopener noreferrer"
              className="inline-block italic text-brown-700"
            >
              Account Settings
            </a>{" "}
            and make modifications all in one place.
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row">
        <div className="flex flex-col gap-2 p-4 bg-orange-100 md:w-1/2 w-">
          <div className="flex flex-row text-orange-500">
            <UsersIcon className="w-5 h-5 mr-3 text-orange-500"></UsersIcon>
            <div className="font-semibold">Refer A Friend</div>
          </div>
          <div className="text-sm">
            Go to{" "}
            <a
              href="/referrals"
              target="_self"
              rel="noopener noreferrer"
              className="inline-block italic text-brown-700"
            >
              Refer & Earn
            </a>{" "}
            to learn more about how you can refer a client and earn credit.
          </div>
        </div>
        <div className="flex flex-col gap-2 p-4 bg-orange-100 md:w-1/2">
          <div className="flex flex-row text-orange-500">
            <LightBulbIcon className="w-5 h-5 mr-3 text-orange-500"></LightBulbIcon>
            <div className="font-semibold">Suggest A Feature</div>
          </div>
          <div className="text-sm">
            If you would like a feature, email us at{" "}
            <a
              href="mailto:support@roadflex.com"
              className="inline-block italic text-brown-700"
            >
              {" "}
              support@roadflex.com
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};
