/** @format */

import { ENUM_ADDRESS_TYPE } from "@prisma/client";
import { useUpdateAddress } from "@roadflex/react-hooks";
import { UserDetailsType } from "@roadflex/types";
import getConfig from "next/config";
import { useEffect, useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Toast } from "../../../../src/toast-message/toast";
import UpdateAddressesUI from "./update-address.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

type UpdateAddressesProps = {
  readUserDetailsData: UserDetailsType;
};

const UpdateAddresses = ({ readUserDetailsData }: UpdateAddressesProps) => {
  const addresses = readUserDetailsData?.address;

  let isSameAddressInitState = false;
  const addressCountMap: Record<string, number> = {};
  const addressArray = addresses.map((add) => {
    if (addressCountMap[add.id]) {
      addressCountMap[add.id]++;
    } else {
      addressCountMap[add.id] = 1;
    }
    return { ...add };
  });

  for (const count of Object.values(addressCountMap)) {
    if (count > 1) {
      isSameAddressInitState = true;
      break; // If found, exit early
    }
  }

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    handleReset,
    setFieldValue,
    ...rest
  } = useUpdateAddress({
    initialValues: {
      businessId: readUserDetailsData?.businessDetails?.id || "",
      businessAddress: {
        id: "",
        streetAddress: "",
        streetDetails: "",
        state: "",
        city: "",
        zip: "",
        type: ENUM_ADDRESS_TYPE.PERMANENT,
      },
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      // eslint-disable-next-line no-use-before-define
      setDefaultAddress();
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      }
    },
  });

  const { ref: businessAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      let streetAddress = "";
      let zip = "";
      let state = "";
      let city = "";
      if (place.address_components) {
        for (const component of place.address_components) {
          const componentType = component.types;
          if (componentType.includes("street_number")) {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType.includes("route")) {
            streetAddress += component.long_name;
          }

          if (componentType.includes("postal_code")) {
            zip = component.long_name;
          }
          if (componentType.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (
            componentType.includes("locality") ||
            componentType.includes("sublocality")
          ) {
            city = component.long_name;
          }
        }
      }
      setFieldValue("businessAddress.streetAddress", streetAddress);
      setFieldValue("businessAddress.zip", zip);
      setFieldValue("businessAddress.state", state);
      setFieldValue("businessAddress.city", city);
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  const setDefaultAddress = () => {
    if (addresses && addresses.length > 0) {
      addresses.forEach(async (address) => {
        if (address.type === ENUM_ADDRESS_TYPE.PERMANENT) {
          await setFieldValue("businessAddress.id", address.id);
          await setFieldValue(
            "businessAddress.streetAddress",
            address.streetAddress,
          );
          await setFieldValue(
            "businessAddress.streetDetails",
            address.streetDetails,
          );
          await setFieldValue("businessAddress.state", address.state);
          await setFieldValue("businessAddress.city", address.city?.trim());
          await setFieldValue("businessAddress.zip", address.zip);
          await setFieldValue("businessAddress.type", address.type);
        }
      });
    }
  };
  useEffect(() => {
    setDefaultAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(addresses)]);

  const autoCompleteRef = useRef({
    businessAutoCompleteRef,
  });
  return (
    <UpdateAddressesUI
      {...{
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        handleReset,
        isSameAddressInitState,
        touched,
        errors,
        setFieldValue,
        setDefaultAddress,
        ...rest,
      }}
      ref={autoCompleteRef}
    />
  );
};

export default UpdateAddresses;
