/** @format */

import getConfig from "next/config";
import { Component } from "react";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;
interface State {
  hasError: boolean;
  error?: Error | null;
}

// FOR DEVELOPERS
// check console of browser when error in coming. This page is basically for hosted URLs like Develop, Release, Production...  --> For Developers (error and info)
// this page is for user to redirect on production to signin page, if anything breaks out.

/* eslint-disable-next-line */
export class ErrorBoundaries extends Component<any, State> {
  /* eslint-disable-next-line */
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  public override componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.warn("Error captured by error boundary as", error);
    console.warn("Info captured by error boundary as", info);
  }
  // FOR DEVELOPERS
  // check console of browser when error in coming. This page is basically for hosted URLs like Develop, Release, Production...  --> For Developers (error and info)
  // this page is for user to redirect on production to signin page, if anything breaks out for user on app.
  public override render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="my-8 text-center ">
          Thank you for your patience. There is an error loading the page.
          <div className="text-sm italic">
            Please try again in a few minutes. Otherwise, reach out to{" "}
            <a
              className="text-blue-500 underline"
              href="mailto:support@roadflex.com"
              target="_blank"
              rel="noreferrer"
            >
              support@roadflex.com
            </a>{" "}
            .
          </div>
          <br />
          <a
            className="text-gray-500 text-bold"
            href={`${NEXT_PUBLIC_WWW_URL}/signin`}
          >
            Redirect to Home
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}
