/** @format */

import classNames from "classnames";
import { forwardRef } from "react";
import { ButtonSize, ButtonType, ButtonVariant, classes } from "../constants";

/* eslint-disable-next-line */
export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  type?: ButtonType;
  className?: string;
  variant: ButtonVariant;
  size?: ButtonSize;
  pill?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      type = ButtonType.Button,
      className,
      variant = ButtonVariant.Primary,
      size = ButtonSize.Normal,
      pill,
      disabled = false,
      loading = false,
      ...props
    },
    ref,
  ) => {
    const btnClass = classNames(
      disabled ? classes.disabled : "",
      classes.base,
      classes.size[size],
      classes.variant[variant],
      {
        [classes.pill]: pill,
      },
      className,
    );
    return (
      <button
        ref={ref}
        disabled={disabled}
        type={type}
        className={btnClass}
        {...props}
      >
        {loading && (
          <svg
            className={classNames(
              classes.loader[variant],
              "w-5 h-5 mr-3 -ml-1 animate-spin",
            )}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {children}
      </button>
    );
  },
);

export default Button;
