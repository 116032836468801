/** @format */
import { useQuery } from "@apollo/client";
import { READ_FLEET_INFORMATION } from "@roadflex/graphql";

interface UseReadFleetInformationProps {
  businessId: string;
}

export const useReadFleetInformation = ({
  businessId,
}: UseReadFleetInformationProps) => {
  const { data, refetch, ...rest } = useQuery(READ_FLEET_INFORMATION, {
    variables: {
      where: {
        businessId,
      },
    },
    fetchPolicy: "no-cache",
  });
  const fleetInfo = data?.readFleetInfo;
  return { fleetInfo, refetch, ...rest };
};
