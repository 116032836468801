type OnboardBannerComponentProps = {
  header: string;
  subText?: string;
  subText2?: string;
};

export const OnboardBannerComponent = ({
  header,
  subText,
  subText2,
}: OnboardBannerComponentProps) => {
  return (
    <div className="w-full text-center py-14 md:block md:py-28 bg-brown-800">
      <div className={`flex flex-col justify-center h-full animate-slidein`}>
        <div className="mb-3 text-2xl font-semibold text-white md:text-4xl">
          <h1 className="">{header}</h1>
          <div className="p-3 mt-2 text-base font-normal md:text-lg">
            {subText}
          </div>
          <div className="p-3 mt-4 text-base font-normal md:text-lg">
            {subText2}
          </div>
        </div>
      </div>
    </div>
  );
};
