/** @format */

import { FeatureType, UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import { ReportsUI } from "./reports.ui";

interface ReportProps {
  readCurrentUserAuth: UserAuthType;
  childFeatures: FeatureType[];
  loading: boolean;
}

export const Reports = ({
  readCurrentUserAuth,
  childFeatures,
  loading,
}: ReportProps) => {
  const { query } = useRouter();
  const [tabOpen, setTabOpen] = useState(1);
  useEffect(() => {
    const { tab } = query;
    let element = null;

    if (tab === "1") {
      setTabOpen(1);
      return;
    }
    if (tab === "2") {
      setTabOpen(2);
      return;
    }
    if (tab === "3") {
      setTabOpen(3);
      return;
    }
    if (tab) {
      element = document.querySelector(`#${tab}`);
    }
    if (element) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.scrollY - 80,
        left: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  if (loading) {
    return <Loader />;
  }
  return (
    <ReportsUI
      {...{ tabOpen, setTabOpen, childFeatures, readCurrentUserAuth }}
    />
  );
};
