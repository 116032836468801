/**
 * /* eslint-disable-next-line
 *
 * @format
 */

import { usePlatformDetect } from "@roadflex/react-hooks-www";
import classNames from "classnames";
import { useRouter } from "next/router";
import WwwFooter from "../footers/www-footer/www-footer";
import Header from "../headers/header/header";

export interface WwwLayoutProps {
  children: JSX.Element;
}

export function WwwLayout(props: WwwLayoutProps) {
  const { children } = props;
  const router = useRouter();
  const { isMobile } = usePlatformDetect();
  return (
    <>
      {router.pathname !== "/whitepaper/download" && <Header />}
      <main
        className={classNames(
          router.pathname !== "/whitepaper/download"
            ? "flex flex-col items-center min-h-[calc(100vh-64px)] overflow-x-hidden pt-[64px]"
            : "",
        )}
      >
        {children}
      </main>

      {router.pathname !== "/whitepaper/download" &&
        !(router.pathname === "/signup" && !isMobile()) &&
        !(router.pathname === "/signin" && !isMobile()) &&
        !(router.pathname === "/forgot-password" && !isMobile()) &&
        !(router.pathname === "/account/reset-password" && !isMobile()) &&
        !(router.pathname === "/contact-us" && !isMobile()) &&
        !(router.pathname === "/savings" && !isMobile()) && <WwwFooter />}
    </>
  );
}

export default WwwLayout;
