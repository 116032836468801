/** @format */
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import {
  REPORTS_BAR_GRAPH_CONFIG,
  REPORTS_LINE_GRAPH_CONFIG,
  REPORTS_PIE_GRAPH_CONFIG,
  REPORTS_SCATTER_GRAPH_CONFIG,
} from "@roadflex/constants";
import {
  DriverSpendData,
  UserAuthType,
  VehicleSpendData,
} from "@roadflex/types";
import { convertISOToDateMonthPST } from "@roadflex/utils";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useRouter } from "next/router";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonSize, ButtonVariant } from "../../../../src/buttons";
import { InlineLoader } from "../../../loader";
import ExportImportModalLoader from "../../../loader/export-import-modal-loader";

interface AccountAnalysisProps {
  vehicleSpendData: number[];
  driverSpendData: number[];
  vehicleSpendCategories: string[];
  driverSpendCategories: string[];
  driverSpendOverTimeData: { name: string; data: [number, number][] }[];
  vehicleSpendOverTimeData: { name: string; data: [number, number][] }[];
  dailyTransactionVolumeData: { name: string; data: [number, number][] }[];
  merchantAnalysisData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[];
  merchantCategoryBreakdownData: {
    name: string;
    y: number;
    sliced: boolean;
    selected: boolean;
  }[];
  dates: Date[] | Date | undefined;
  setDates: (value: Date[]) => void;
  allVehicleSpendData: VehicleSpendData[];
  allDriverSpendData: DriverSpendData[];
  onDateRangeChange: (e: CalendarChangeParams) => void;
  transactionListLoading: boolean;
  readCurrentUserAuth: UserAuthType;
}

const AccountAnalysisUI = ({
  vehicleSpendData,
  driverSpendData,
  vehicleSpendCategories,
  driverSpendCategories,
  driverSpendOverTimeData,
  vehicleSpendOverTimeData,
  dailyTransactionVolumeData,
  merchantAnalysisData,
  merchantCategoryBreakdownData,
  allDriverSpendData,
  allVehicleSpendData,
  dates,
  setDates,
  onDateRangeChange,
  transactionListLoading,
  readCurrentUserAuth,
}: AccountAnalysisProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const driverSpend = {
    ...REPORTS_BAR_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    xAxis: {
      categories: driverSpendCategories,
      labels: {
        overflow: "justify",
      },
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      pointFormat: "${point.y:.2f}",
    },
    series: [
      {
        name: "Driver Spend",
        data: driverSpendData,
      },
    ],
  };
  const vehicleSpend = {
    ...REPORTS_BAR_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    xAxis: {
      categories: vehicleSpendCategories,
      labels: {
        overflow: "justify",
      },
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      pointFormat: "${point.y:.2f}",
    },
    series: [
      {
        name: "Vehicle Spend",
        data: vehicleSpendData,
      },
    ],
  };

  const driverSpendOverTime = {
    ...REPORTS_SCATTER_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `${this?.series?.name}: $${Highcharts.numberFormat(
          this.y || 0,
          2,
        )} (${new Date(Number(this.x)).toDateString()})`;
      },
    },
    series: driverSpendOverTimeData.map((data, index) => ({
      name: data.name,
      data: data.data,
      visible: index < 3, // Initially show the first three series, hide the rest
    })),
    // series: driverSpendOverTimeData,
  };

  const vehicleSpendOverTime = {
    ...REPORTS_SCATTER_GRAPH_CONFIG,
    title: {
      text: "",
    },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `${this?.series?.name}: $${Highcharts.numberFormat(
          this.y || 0,
          2,
        )} (${new Date(Number(this.x)).toDateString()})`;
      },
    },
    series: vehicleSpendOverTimeData.map((data, index) => ({
      name: data.name,
      data: data.data,
      visible: index < 3, // Initially show the first three series, hide the rest
    })),
  };

  const dailyTransactionVolume = {
    ...REPORTS_LINE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%d %b %Y",
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valuePrefix: "$",
      crosshairs: true,
      shared: true,
      pointFormat: "${point.y:.2f}",
    },
    // tooltip: {
    //   valuePrefix: "$",
    //   crosshairs: true,
    //   shared: true,
    //   pointFormat: "${this.y:.2f}",
    //   formatter: function (this: Highcharts.TooltipFormatterContextObject) {
    //     return `${this?.series?.name}: $${Highcharts.numberFormat(
    //       this.y || 0,
    //       2,
    //     )} (${new Date(Number(this.x)).toDateString()})`;
    //   },
    // },
    series: dailyTransactionVolumeData,
  };

  const merchantAnalysis = {
    ...REPORTS_PIE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valueSuffix: "%",
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Merchant Analysis",
        data: merchantAnalysisData,
      },
    ],
  };

  const merchantCategoryBreakdown = {
    ...REPORTS_PIE_GRAPH_CONFIG,
    title: {
      text: "",
    },
    colors: ["#bf3d00", "#1D1D20", "#a19d93"],
    tooltip: {
      valueSuffix: "%",
      crosshairs: true,
      shared: true,
    },
    series: [
      {
        name: "Merchant Categories Breakdown",
        data: merchantCategoryBreakdownData,
      },
    ],
  };

  const minDate = new Date();
  minDate.setMonth(0); //means January, because 0-indexed
  minDate.setFullYear(2022);
  minDate.setDate(1);
  const csvLinkRef1 = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const csvLinkRef2 = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const router = useRouter();

  const onExportCSV = () => {
    setShowExportModal(true);
    setTimeout(() => {
      csvLinkRef1?.current?.link.click();
      csvLinkRef2?.current?.link.click();
      setShowLoading(false);
      setShowComplete(true);
    }, 2000);
  };

  const navigateToAlerts = () => {
    router.push({
      pathname: "/alerts",
      query: { tab: 3 },
    });
  };

  const driverSpendDataHeader = [
    { label: "Name", key: "driverName" },
    { label: "Location", key: "location" },
    { label: "Fuel Spend", key: "fuelSpends" },
    {
      label: `Other Spend`,
      key: "otherSpends",
    },
    {
      label: `Total Spend`,
      key: "totalSpends",
    },
  ];

  const vehicleSpendDataHeader = [
    { label: "Name", key: "vehicleName" },
    { label: "License Plate", key: "licensePlate" },
    { label: "Location", key: "location" },
    { label: "Fuel Spend", key: "fuelSpends" },
    {
      label: `Other Spend`,
      key: "otherSpends",
    },
    {
      label: `Total Spend`,
      key: "totalSpends",
    },
  ];

  const NoDataToShowMessage = () => {
    return (
      <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-70">
        <div className="p-6 text-base font-semibold bg-white border-2 rounded-md border-brown-500 text-brown-500 md:text-lg">
          No Data to Show
        </div>
      </div>
    );
  };

  const currentDate = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

  return (
    <div className="grid grid-cols-2 gap-3 text-sm ">
      <div className="col-span-2">
        <div className="flex items-end justify-end mb-4">
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.AppSize}
            onClick={() => navigateToAlerts()}
            className="!h-fit"
          >
            Configure Report Notifications
          </Button>
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <label className="pr-2 font-semibold" htmlFor="range">
              Date Range ({readCurrentUserAuth?.accountDisplaySetting?.timeZone}
              )<div className="italic font-normal">Max Range: 1 month</div>
            </label>
            <Calendar
              id="range"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              placeholder="Select Date Range"
              className="custom-calendar"
              panelClassName="custom-calendar-panel"
              minDate={threeMonthsAgo}
              maxDate={currentDate}
              inputClassName="!py-2"
              readOnlyInput
            />
            {transactionListLoading && <InlineLoader />}
          </div>
          <div className="flex flex-row justify-end ml-3">
            {(dates as Date[])[0] !== null && (dates as Date[])[1] !== null && (
              <>
                <CSVLink
                  filename={`driver-${
                    dates
                      ? `${convertISOToDateMonthPST(
                          (dates as Date[])[0].toISOString(),
                        )}-${convertISOToDateMonthPST(
                          (dates as Date[])[1].toISOString(),
                        )}`
                      : ""
                  }.csv`}
                  ref={csvLinkRef1}
                  data={allDriverSpendData}
                  headers={driverSpendDataHeader}
                  target="_blank"
                ></CSVLink>
                <CSVLink
                  filename={`vehicle-${
                    dates
                      ? `${convertISOToDateMonthPST(
                          (dates as Date[])[0].toISOString(),
                        )}-${convertISOToDateMonthPST(
                          (dates as Date[])[1].toISOString(),
                        )}`
                      : ""
                  }.csv`}
                  ref={csvLinkRef2}
                  data={allVehicleSpendData}
                  headers={vehicleSpendDataHeader}
                  target="_blank"
                ></CSVLink>
              </>
            )}
            <Button
              variant={ButtonVariant.Secondary}
              size={ButtonSize.AppSize}
              disabled={
                dates
                  ? (dates as Date[])[0] === null ||
                    (dates as Date[])[1] === null
                  : true
              }
              onClick={() => onExportCSV()}
              className="!h-fit"
            >
              <ArrowUpTrayIcon className="w-5 h-5 mr-2"></ArrowUpTrayIcon>
              Export
            </Button>
          </div>
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Top Spend by Driver
        </label>
        <div className="relative">
          <HighchartsReact highcharts={Highcharts} options={driverSpend} />
          {driverSpendData.some((value) => value !== 0) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Top Spend by Vehicle
        </label>
        <div className="relative">
          <HighchartsReact highcharts={Highcharts} options={vehicleSpend} />
          {vehicleSpendData.some((value) => value !== 0) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Driver Spend Over Time
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={driverSpendOverTime}
          />
          {driverSpendOverTimeData.some(
            (dataObj) => dataObj.data.length > 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Vehicle Spend Over Time
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={vehicleSpendOverTime}
          />
          {vehicleSpendOverTimeData.some(
            (dataObj) => dataObj.data.length > 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Daily Transaction Volume
        </label>
        <div className="relative">
          <HighchartsReact
            highcharts={Highcharts}
            options={dailyTransactionVolume}
          />
          {dailyTransactionVolumeData.some(
            (dataObj) => dataObj.data.length > 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-1 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Merchant Analysis
        </label>
        <div className="relative">
          <HighchartsReact highcharts={Highcharts} options={merchantAnalysis} />

          {merchantAnalysisData.some(
            (merchantData) => merchantData.y !== 0,
          ) ? null : (
            <NoDataToShowMessage />
          )}
        </div>
      </div>
      <div className="col-span-2 p-4 mt-8 bg-white rounded-md shadow md:col-span-2 ">
        <label className="py-2 text-base font-semibold text-brown-500 md:text-lg">
          Spending Breakdown (Merchant Category)
        </label>
        <div className="flex justify-center">
          <div className="relative">
            <HighchartsReact
              highcharts={Highcharts}
              options={merchantCategoryBreakdown}
            />
            {merchantCategoryBreakdownData.some(
              (merchantCatData) => merchantCatData.y !== 0,
            ) ? null : (
              <NoDataToShowMessage />
            )}
          </div>
        </div>
      </div>

      <Dialog
        header={
          <span className="text-base md:text-lg">Export Account Analysis</span>
        }
        visible={showExportModal}
        style={{ fontFamily: "Inter" }}
        className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
        footer={
          <div className="flex flex-row justify-end">
            <Button
              variant={ButtonVariant.GrayOutline}
              size={ButtonSize.Normal}
              onClick={() => {
                setShowExportModal(false);
                setShowLoading(true);
                setShowComplete(false);
              }}
            >
              Close
            </Button>
          </div>
        }
        closable={false}
        onHide={() => {
          setShowExportModal(false);
        }}
      >
        <div className="space-y-3 text-sm md:text-base">
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AccountAnalysisUI;
