/** @format */
import { useMutation } from "@apollo/client";
import { READ_USER_DETAILS, USER_UPDATE } from "@roadflex/graphql";
import { PersonalInformationValues } from "@roadflex/types";
import { capitalizeFirstLetters } from "@roadflex/utils";
import { uploadFile } from "@roadflex/web-api";
import { useFormik } from "formik";
import * as Yup from "yup";

type useUserUpdateProps = {
  onSubmit: (val: { code: string } | null, err: Error | null) => void;
  initialValues: PersonalInformationValues;
  validationSchema: Yup.AnyObjectSchema;
};

export const useUserUpdate = ({
  onSubmit,
  initialValues,
  validationSchema,
}: useUserUpdateProps) => {
  const [updatePersonalInformation] = useMutation(USER_UPDATE, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      let licenceUrl;
      let licenceBackUrl;
      if (value?.fleetLicense && typeof value?.fleetLicense !== "string") {
        licenceUrl = await uploadFile(value.fleetLicense as File, "license");
      }
      if (value?.fleetLicenseBack && typeof value?.fleetLicense !== "string") {
        licenceBackUrl = await uploadFile(
          value.fleetLicenseBack as File,
          "license_back",
        );
      }
      const data = {
        firstName: capitalizeFirstLetters(value.firstName),
        lastName: capitalizeFirstLetters(value.lastName),
        phoneNumber: value.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
        ssn: value.ssn ? value.ssn.replace(/\D/g, "") : "",
        fleetLicense:
          typeof value.fleetLicense === "string"
            ? value.fleetLicense
            : licenceUrl,
        fleetLicenseBack:
          typeof value.fleetLicense === "string"
            ? value.fleetLicenseBack
            : licenceBackUrl,
        personaInquiryId: value?.personaInquiryId,
        personaFields: value?.personaFields,
        dateOfBirth: value?.dateOfBirth,
        executiveJobTitle: value?.executiveJobTitle,
        id: value?.id,
      };
      try {
        const res = await updatePersonalInformation({
          variables: { data },
        });
        onSubmit(res?.data?.userUpdate, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
