/** @format */

import { useEffect, useState } from "react";

const calculateRange = (data: any, rowsPerPage: any) => {
  const range = [];
  const num = Math.ceil(data.length / rowsPerPage);
  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

const sliceData = (data: any, page: any, rowsPerPage: any) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

export const useTable = (data: any, page: any, rowsPerPage: any) => {
  const [tableRange, setTableRange] = useState<Array<unknown>>([]);
  const [slice, setSlice] = useState<Array<unknown>>([]);

  useEffect(() => {
    const range = calculateRange(data, rowsPerPage);
    setTableRange([...range]);

    const slice1 = sliceData(data, page, rowsPerPage);
    setSlice([...slice1]);
  }, [data, setTableRange, page, setSlice, rowsPerPage]);

  return { slice, range: tableRange };
};
