/** @format */

import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import { dollarFormatter } from "@roadflex/constants";
import router from "next/router";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";

export const FraudActivityDetails = ({
  totalFraudDeclinedTransaction,
  totalFraudSavings,
  totalLifeTimeSavings,
}: {
  totalFraudDeclinedTransaction: number;
  totalFraudSavings: number;
  totalLifeTimeSavings: number;
}) => {
  const stats = [
    {
      name: "Savings from Fraud and Fuel Theft Prevention",
      stat: dollarFormatter(totalFraudSavings / 100),
      key: 1,
    },
    {
      name: "Fraud & Fuel Theft Transaction",
      stat: totalFraudDeclinedTransaction,
      key: 2,
    },
    {
      name: "Total saved with RoadFlex",
      stat: dollarFormatter(totalLifeTimeSavings / 100),
      key: 3,
    },
  ];
  return (
    <div>
      <dl className="grid grid-cols-1 gap-4 2xl:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className="px-4 py-5 overflow-hidden bg-white rounded-md shadow sm:p-6"
          >
            <dt className="flex items-center gap-3 text-sm font-medium text-brown-500">
              <div className="truncate "> {item.name}</div>
              {item.key === 2 && (
                <div className="bg-orange-100 ">
                  <Button
                    variant={ButtonVariant.Transparent}
                    size={ButtonSize.AppSize}
                    className="!px-2 !py-1"
                    onClick={() => {
                      router.push("/reports?tab=2");
                    }}
                  >
                    <div className="flex text-orange-500">
                      <MagnifyingGlassCircleIcon className="w-4 h-4 mr-1 md:w-5 md:h-5"></MagnifyingGlassCircleIcon>
                      <div className="text-xs font-semibold md:text-sm whitespace-nowrap">
                        Learn More
                      </div>
                    </div>
                  </Button>
                </div>
              )}
            </dt>
            <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
