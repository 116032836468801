/** @format */

import { UserAuthType } from "@roadflex/types";
import { createContext } from "react";

export interface CurrentUserContextType extends UserAuthType {
  isLoading: boolean;
  refetch: () => void;
}

export const CurrentUserContext = createContext<
  CurrentUserContextType | undefined
>(undefined);

export default CurrentUserContext;
