/** @format */

import { CogIcon } from "@heroicons/react/24/outline";
import { ELD_SYSTEMS } from "@roadflex/constants";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import { useGeoTabGetLiveLocation } from "@roadflex/react-hooks";
import { MapType } from "@roadflex/types";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { Toast } from "../../toast-message/toast";
import { LiveLocationMap } from "./LiveLocationMap";

export interface GMapsModalProps {
  openGMapsModal: boolean;
  setOpenGMapsModal: (value: boolean) => void;
  orgName: string;
  database: string;
  mapValues: MapType;
  setMapValues: (value: MapType) => void;
}

export default function GMapsModal({
  openGMapsModal,
  setOpenGMapsModal,
  orgName,
  database,
  mapValues,
  setMapValues,
}: GMapsModalProps) {
  const [isLiveLocationLoading, setLiveLocationLoading] = useState(true);

  const {
    geoTabGetLiveLocationHandleSubmit,
    geoTabGetLiveLocationIsSubmitting,
    geoTabGetLiveLocationSetFieldValue,
  } = useGeoTabGetLiveLocation({
    initialValues: {
      orgName: orgName,
      database: database,
      deviceId: mapValues?.deviceId || "",
    },

    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        setOpenGMapsModal(false);
        return;
      }
      const deviceData =
        res?.eldDeviceLiveLocation && res?.eldDeviceLiveLocation[0];
      if (deviceData) {
        setMapValues({
          showAdditionalInfo: orgName === ELD_SYSTEMS.GEOTAB,
          ...deviceData,
        });
      } else {
        Toast({
          type: "error",
          message: "Cannot fetch live location data, please try again",
        });
      }
      setLiveLocationLoading(false);
    },
  });

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        <Button
          variant={ButtonVariant.AppOrange}
          size={ButtonSize.AppSize}
          disabled={geoTabGetLiveLocationIsSubmitting}
          loading={geoTabGetLiveLocationIsSubmitting}
          onClick={() => {
            geoTabGetLiveLocationHandleSubmit();
          }}
        >
          Refresh Location
        </Button>
        <Button
          variant={ButtonVariant.SecondaryOutline}
          size={ButtonSize.AppSize}
          onClick={() => setOpenGMapsModal(false)}
        >
          Close
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (openGMapsModal && !geoTabGetLiveLocationIsSubmitting) {
      geoTabGetLiveLocationSetFieldValue("orgName", orgName);
      geoTabGetLiveLocationSetFieldValue("database", database);
      geoTabGetLiveLocationSetFieldValue("deviceId", mapValues.deviceId);
      if (orgName !== "" && mapValues.deviceId !== "") {
        geoTabGetLiveLocationHandleSubmit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openGMapsModal]);

  return (
    <Dialog
      header={
        <div className="flex flex-col flex-wrap justify-start w-full col-span-1 gap-4 sm:w-auto sm:flex-row">
          <div className="flex items-center text-base">{`Live location for ${mapValues.deviceName}`}</div>
        </div>
      }
      visible={openGMapsModal}
      className="w-[95%] sm:w-[60vw]"
      footer={renderFooter()}
      onHide={() => setOpenGMapsModal(false)}
      closable={false}
    >
      <div className="border-b border-gray-200 shadow sm:rounded-lg">
        {isLiveLocationLoading ? (
          <div className="flex justify-center h-full align-middle">
            <span className="inline-flex items-center">
              <CogIcon
                className="w-6 h-6 mr-2 -ml-1 animate-spin"
                aria-hidden="true"
              />
              {"Fetching Location..."}
            </span>
          </div>
        ) : (
          <LiveLocationMap
            {...{
              mapValues,
            }}
          />
        )}
      </div>
    </Dialog>
  );
}
