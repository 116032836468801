import { useReadUserActivityLogs } from "@roadflex/react-hooks";
import { AccountSettingDisplayType } from "@roadflex/types";
import ActivityLogsUI from "./activity-logs.ui";

interface ActivityLogsProps {
  readAccountDisplaySetting: AccountSettingDisplayType;
}

const ActivityLogs = ({ readAccountDisplaySetting }: ActivityLogsProps) => {
  const { userActivityLogsData, userActivityLogsLoading } =
    useReadUserActivityLogs();

  return (
    <ActivityLogsUI
      userActivityLogsLoading={userActivityLogsLoading}
      userActivityLogsData={
        userActivityLogsData?.readUserActivityLogs?.userActivityLogs || []
      }
      readAccountDisplaySetting={readAccountDisplaySetting}
    />
  );
};
export default ActivityLogs;
