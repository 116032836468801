/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { GEOTAB_GET_DEVICES } from "@roadflex/graphql";
import { EldDevices, GeoTabGetDevicesValues } from "@roadflex/types";
import { useFormik } from "formik";

type UseGeoTabGetDevicesType = {
  initialValues: GeoTabGetDevicesValues;
  onSubmit: (
    val: { getEldDevices: EldDevices[] } | null,
    err: Error | null,
  ) => void;
};

export const useGeoTabGetDevices = ({
  onSubmit,
  initialValues,
}: UseGeoTabGetDevicesType) => {
  const [geoTabGetDevices] = useMutation<{
    getEldDevices: EldDevices[];
  }>(GEOTAB_GET_DEVICES, { fetchPolicy: "no-cache" });

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        // console.log("data => ", data);
        const res = await geoTabGetDevices({
          variables: { data },
        });
        onSubmit(res.data || null, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    ...rest,
  };
};
