/** @format */

import { GoogleMap, Marker } from "@react-google-maps/api";
import { dollarFormatter } from "@roadflex/constants";
import { GoogleMapsMarkersType } from "@roadflex/types";
import classNames from "classnames";
import getConfig from "next/config";
import { useEffect, useState } from "react";
import SwiperCore, { EffectFlip, Navigation, Pagination } from "swiper";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, EffectFlip]);
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

type FuelStationFinderMapUIProps = {
  mapOptions: google.maps.MapOptions;
  markers: GoogleMapsMarkersType[];
  handleMouseOver: (info: number) => void;
  handleMouseExit: () => void;
  showInfo: number | null;
  parameters: {
    latitude: number;
    longitude: number;
    address: string;
  };
};

const FuelStationFinderMapUI = ({
  mapOptions,
  markers,
  handleMouseOver,
  handleMouseExit,
  showInfo,
  parameters,
}: FuelStationFinderMapUIProps) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const { isMobile } = usePlatformDetect();
  const slideTo = (index: number) => {
    if (swiperInstance) swiperInstance.slideTo(index);
  };

  useEffect(() => {
    if (showInfo) {
      slideTo(showInfo <= markers.length - 2 ? showInfo : markers.length - 2);
    }
  }, [showInfo]);
  const mapCenter = {
    lat: parameters?.latitude,
    lng: parameters?.longitude,
  };

  return (
    <>
      <div
        className={`${isMobile() ? "h-[calc(35vh)]" : "h-[calc(60vh)]"} mb-8`}
      >
        <GoogleMap
          options={mapOptions}
          zoom={15}
          center={mapCenter}
          mapTypeId={"roadmap"}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "15px 15px 15px 15px",
          }}
        >
          <Marker position={mapCenter} />
          {markers?.map((mark, index) => (
            <Marker
              key={index}
              title={mark?.title}
              position={{ lat: mark?.latitude, lng: mark?.longitude }}
              onMouseOver={(e) => handleMouseOver(index)}
              onMouseOut={handleMouseExit}
              icon={`${NEXT_PUBLIC_CDN_URL}/common/images/fuel-station-used-in-app-station-finder.png`}
            ></Marker>
          ))}
        </GoogleMap>
      </div>
      <Swiper
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        slidesPerView={3}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          "@1.50": {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        lazyPreloadPrevNext={3}
        className="child:!ease-linear"
      >
        {markers?.length > 0 &&
          markers.map((marker: GoogleMapsMarkersType, idx: number) => (
            <SwiperSlide key={idx}>
              <div
                className={classNames(
                  idx === showInfo ? "bg-orange-200" : "bg-lightyellow",
                  "p-2 rounded-md",
                )}
              >
                <div className="font-medium">{marker.title}</div>
                <div className="">
                  Rating: {marker.rating || "-"} (# reviews:{" "}
                  {marker.ratingNumber || "-"})
                </div>
                <div className="mb-2">Address: {marker.vicinity}</div>
                {marker?.fuelPrices?.map((m) => (
                  <div className="text-sm text-gray-600">
                    {m.type}
                    {": "}
                    {dollarFormatter(
                      Number(m.units) + Number(m.nanos) * 10 ** -9,
                    )}{" "}
                    (last updated: {m.updateTime.toString().split("T")[0]})
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        <div className="h-12"></div>
      </Swiper>
    </>
  );
};

export default FuelStationFinderMapUI;
