/* eslint-disable dot-notation */
/** @format */

import { useLazyQuery } from "@apollo/client";
import { FUEL_PRODUCT_CODE } from "@roadflex/constants";
import {
  IFTA_TRANSACTION_LIST,
  READ_TRANSACTION_LIST_PAGINATION,
} from "@roadflex/graphql";
import { useReadAccountTag } from "@roadflex/react-hooks";
import {
  AccountTagType,
  IFTATransactionType,
  TransactionType,
} from "@roadflex/types";
import {
  convertISOStringToDate,
  convertISOStringToDateNew,
  humanize,
} from "@roadflex/utils";
import { dollarFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import getConfig from "next/config";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import {
  DataTableFilterMetaData,
  DataTableOperatorFilterMetaData,
  DataTablePFSEvent,
} from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { CustomMultiSelect } from "../../dropdowns";
import ExportImportModalLoader from "../../loader/export-import-modal-loader";
import { Toast } from "../../toast-message/toast";

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const JSZip = require("jszip");

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

type FilterProps =
  | "date"
  | "merchantName"
  | "merchantState"
  | "transactionAmount"
  | "status";

type FilterProps2 = "driver" | "vehicle" | "vehicleVIN" | "lastFour";
type FilterConfigType = {
  [Key in FilterProps]: {
    operator: FilterOperator;
    constraints: [{ value: null | string | Date; matchMode: FilterMatchMode }];
  };
} & {
  [Key in FilterProps2]: {
    value: null | string;
    matchMode: FilterMatchMode;
  };
};
const initFilterConfig: FilterConfigType = {
  date: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  merchantName: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  merchantState: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  transactionAmount: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.OR,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  lastFour: { value: null, matchMode: FilterMatchMode.IN },
  driver: { value: null, matchMode: FilterMatchMode.IN },
  vehicle: { value: null, matchMode: FilterMatchMode.IN },
  vehicleVIN: { value: null, matchMode: FilterMatchMode.IN },
};

const transactionInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: initFilterConfig,
  sortField: "date",
  sortOrder: -1,
  multiSortMeta: null,
  isReport: false,
};

type ExportTransactionsModalProps = {
  open: boolean;
  onCloseExportModal: () => void;
  exportTransactionType: "TRANSACTION" | "RECEIPT";
  timeZone: string;
};

let imageFiles: Blob[] = [];

const downloadFile = async (fileUrl: string) => {
  await fetch(`${NEXT_PUBLIC_API_URL}/api/files/${fileUrl}`, {
    method: "GET",
    credentials: "include",
  })
    .then(async (response) => {
      if (!response.ok) {
        // make the promise be rejected if we didn't get a 2xx response
        throw new Error("Failed to download the file, please try again.");
      }
      await response
        .clone()
        .blob()
        .then((res) => {
          imageFiles.push(res);
        });
      await response.text();
    })
    .catch((error) => {
      throw error;
    });
};

export const ExportTransactionsModal = ({
  open,
  onCloseExportModal,
  exportTransactionType = "TRANSACTION",
  timeZone,
}: ExportTransactionsModalProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [dates, setDates] = useState<Date | Date[] | undefined>();
  const [transactionTags, setTransactionTags] = useState([]);
  const [cardTags, setCardTags] = useState([]);
  const [driverTags, setDriverTags] = useState([]);
  const [vehicleTags, setVehicleTags] = useState([]);
  const [selectedTransactionTags, setSelectedTransactionTags] = useState<
    string[]
  >([]);
  const [selectedCardTags, setSelectedCardTags] = useState<string[]>([]);
  const [selectedDriverTags, setSelectedDriverTags] = useState<string[]>([]);
  const [selectedVehicleTags, setSelectedVehicleTags] = useState<string[]>([]);
  const [transactionLazyParams, setTransactionLazyParams] =
    useState<DataTablePFSEvent>({
      ...transactionInitialLazyParams,
    });

  const ACCOUNT_TAGS_COLOR = [
    "bg-slate-200",
    "bg-red-200",
    "bg-amber-200",
    "bg-green-200",
    "bg-teal-200",
    "bg-gray-200",
    "bg-purple-200",
    "bg-blue-200",
    "bg-lime-200",
    "bg-orange-200",
    "bg-sky-200",
    "bg-pink-200",
  ];

  const accountTagItemTemplate = (accountTagItem: AccountTagType) => {
    const index = Number(accountTagItem?.colorCode) || 0;
    const length = ACCOUNT_TAGS_COLOR.length;
    const colorCode = ACCOUNT_TAGS_COLOR[index % length];
    return (
      <div>
        <span
          className={classNames(colorCode, "rounded-full text-sm px-2 mr-3")}
        ></span>
        <span>
          {accountTagItem.title} ({accountTagItem.tagId})
        </span>
      </div>
    );
  };

  const [type, setType] = useState<string>("Generic");
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const csvLinkRef1 = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const [iftaTransactionsList, setIftaTransactionsList] = useState<
    IFTATransactionType[]
  >([]);
  const [exportTransactionList, setExportTransactionList] = useState<
    {
      authorizationDate: string;
      transactionDate: string;
      timezone: string;
      driverName: string;
      vehicleName: string;
      vin: string;
      location: string;
      lastFour: string;
      merchantName: string;
      merchantCategory: string;
      amount: string;
      fuelType: string;
      discount: string;
      gallons?: string | number;
      status: string;
      pricePerGallon: string;
      receipts: {
        fileName: string;
        folderName: string;
        fileExtension: string;
      }[];
    }[]
  >([]);
  const [iftaTransactionsFn, { loading: iftaTransactionLoading }] =
    useLazyQuery<{
      iftaTransaction: {
        success: boolean;
        code: string;
        type: string;
        message: string;
        detail: string;
        help: string;
        iftaTransactions: IFTATransactionType[];
      };
    }>(IFTA_TRANSACTION_LIST, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data.iftaTransaction.success) {
          setIftaTransactionsList(data.iftaTransaction.iftaTransactions);
        } else {
          Toast({
            type: "error",
            message: data.iftaTransaction.message || "",
          });
        }
      },
      onError: (error) => {
        Toast({
          type: "error",
          message: `IFTA Transactions: ${error.message}`,
        });
      },
      notifyOnNetworkStatusChange: true,
    });

  const [transactionsListFn, { loading: transactionListLoading }] =
    useLazyQuery<{
      readTransactionList: {
        code: string;
        message: string;
        transactions: TransactionType[];
        totalCount: number;
      };
    }>(READ_TRANSACTION_LIST_PAGINATION, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          ...transactionLazyParams,
          receiptUploaded: exportTransactionType === "RECEIPT",
          receiptRequire: false,
          flagged: false,
          transactionTags: selectedTransactionTags,
          cardTags: selectedCardTags,
          driverTags: selectedDriverTags,
          vehicleTags: selectedVehicleTags,
          isReport: true,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
      onCompleted: async (transactionData) => {
        if (
          transactionData &&
          transactionData?.readTransactionList?.code !== "200"
        ) {
          Toast({
            type: "error",
            message: transactionData?.readTransactionList?.message || "",
          });
        }
        if (
          transactionData &&
          transactionData?.readTransactionList?.code === "200"
        ) {
          if (exportTransactionType === "TRANSACTION") {
            const transactions =
              transactionData.readTransactionList.transactions.map(
                (transaction) => {
                  return {
                    authorizationDate: convertISOStringToDate(
                      transaction?.authorizationDate?.toString(),
                      false,
                      false,
                      true,
                    ),
                    transactionDate: convertISOStringToDate(
                      transaction?.transactionDate?.toString(),
                      false,
                      false,
                      true,
                    ),
                    timezone: new Date().toTimeString().slice(9),
                    driverName: transaction.driverName
                      ? transaction.driverName
                      : "",
                    vehicleName: transaction.vehicleName
                      ? transaction.vehicleName
                      : "",
                    vin: transaction.vehicleVIN ? transaction.vehicleVIN : "",
                    location: `${
                      transaction?.stripeResponse?.merchant_data?.city
                        ? `${transaction?.stripeResponse?.merchant_data?.city},`
                        : ""
                    } ${
                      transaction?.merchantState
                        ? transaction.merchantState
                        : ""
                    } ${
                      transaction?.merchantPostCd
                        ? transaction.merchantPostCd
                        : ""
                    } `,
                    lastFour: `${transaction.card.lastFour}`,
                    metaData: `${transaction.card.metaData}`
                      ? (
                          transaction.card.metaData as unknown as {
                            policyName: string;
                          }
                        )?.policyName
                      : "",
                    merchantName: transaction.merchantName,
                    merchantCategory: humanize(
                      transaction.stripeResponse?.merchant_data?.category || "",
                    ),
                    amount: dollarFormatter(
                      transaction.transactionAmount / 100,
                    ),
                    fuelType: humanize(
                      transaction.purchaseDetails?.fuel?.type || "",
                    ),
                    fuelCategory:
                      transaction.purchaseDetails?.fuel
                        ?.industry_product_code &&
                      FUEL_PRODUCT_CODE[
                        transaction.purchaseDetails.fuel
                          .industry_product_code as keyof typeof FUEL_PRODUCT_CODE
                      ]?.longName
                        ? `${
                            FUEL_PRODUCT_CODE[
                              transaction.purchaseDetails.fuel
                                .industry_product_code as keyof typeof FUEL_PRODUCT_CODE
                            ].longName
                          } | ${
                            FUEL_PRODUCT_CODE[
                              transaction.purchaseDetails.fuel
                                .industry_product_code as keyof typeof FUEL_PRODUCT_CODE
                            ].subCategory
                          }`
                        : "-",
                    discount:
                      transaction?.fuelDiscountAmount > 0
                        ? dollarFormatter(transaction?.fuelDiscountAmount / 100)
                        : "-",
                    gallons: transaction?.fuelQuantity,
                    status: "Approved",
                    pricePerGallon: transaction?.purchaseDetails?.fuel
                      ?.unit_cost_decimal
                      ? dollarFormatter(
                          Number(
                            transaction?.purchaseDetails?.fuel
                              ?.unit_cost_decimal,
                          ) / 100,
                        )
                      : "-",
                    memo: transaction?.memo || "",
                    transactionTagId: transaction?.transactionTagId || "",
                    transactionTagName: transaction?.transactionTagName || "",
                    transactionTagDescription:
                      transaction?.transactionTagDescription || "",
                    cardTagId: transaction?.cardTagId || "",
                    cardTagName: transaction?.cardTagName || "",
                    cardTagDescription: transaction?.cardTagDescription || "",
                    driverTagId: transaction?.driverTagId || "",
                    driverTagName: transaction?.driverTagName || "",
                    driverTagDescription:
                      transaction?.driverTagDescription || "",
                    vehicleTagId: transaction?.vehicleTagId || "",
                    vehicleTagName: transaction?.vehicleTagName || "",
                    vehicleTagDescription:
                      transaction?.vehicleTagDescription || "",
                    receipts: transaction?.receipts,
                  };
                },
              );
            setExportTransactionList(transactions);
          } else {
            const zip = new JSZip();
            for (
              let i = 0;
              i < transactionData.readTransactionList.transactions.length;
              i++
            ) {
              const currTransaction =
                transactionData.readTransactionList.transactions[i];
              const receiptName = `${convertISOStringToDateNew(
                currTransaction.authorizationDate?.toString(),
                false,
                false,
                true,
                timeZone,
              )
                .replace(/[/]/g, "-")
                .replace(/[,]/g, "")}, ${currTransaction.merchantName}`;
              imageFiles = [];
              for (let j = 0; j < currTransaction.receipts.length; j++) {
                const currReceipt = currTransaction.receipts[j];
                const receiptURL = `${currReceipt.folderName}/${currReceipt.fileName}.${currReceipt.fileExtension}`;
                await downloadFile(receiptURL);
              }
              for (let j = 0; j < imageFiles.length; j++) {
                zip.file(
                  `${receiptName}_${j + 1}.${
                    currTransaction.receipts[j].fileExtension
                  }`,
                  imageFiles[j],
                );
              }
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            zip.generateAsync({ type: "blob" }).then((blobdata: any) => {
              const zipblob = new Blob([blobdata]);
              const elem = window.document.createElement("a");
              elem.href = window.URL.createObjectURL(zipblob);
              elem.download = `transaction-receipts.zip`;
              elem.click();
            });
            setShowLoading(false);
            setShowComplete(true);
          }
        }
      },
      onError: (err) => {
        Toast({
          type: "error",
          message: `Something went wrong`,
        });
      },
    });

  const { data: accountTagsData, loading: accountTagsDataLoading } =
    useReadAccountTag({});

  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setType(e.target.value);
    }
  };

  const onFetchTransactionsForReceipts = async () => {
    try {
      setShowLoading(true);
      await transactionsListFn();
    } catch (err) {
      Toast({
        type: "error",
        message: "There was some error processing the data",
      });
    }
  };

  const onFetchGenericTransactions = async () => {
    await transactionsListFn();
    if (csvLinkRef1 && csvLinkRef1?.current) {
      setTimeout(() => {
        csvLinkRef1?.current?.link.click();
      }, 0);
      setShowLoading(false);
      setShowComplete(true);
    }
  };

  const onFetchIFTATransactions = async (
    dateFilter: DataTableFilterMetaData | DataTableOperatorFilterMetaData,
  ) => {
    await iftaTransactionsFn({
      variables: {
        data: {
          dateFilter,
          transactionTags: selectedTransactionTags,
          cardTags: selectedCardTags,
          driverTags: selectedDriverTags,
          vehicleTags: selectedVehicleTags,
        },
      },
    });
    if (csvLinkRef && csvLinkRef?.current) {
      setTimeout(() => {
        csvLinkRef?.current?.link.click();
      }, 0);
      setShowLoading(false);
      setShowComplete(true);
    }
  };

  const dateFilterApplied = (e: unknown) => {
    const event = e as {
      field: string;
      constraints: {
        operator: FilterOperator;
        constraints: [
          {
            value: Date | null;
            matchMode: FilterMatchMode;
          },
        ];
      };
    };
    const constraints =
      event.constraints.constraints && event.constraints.constraints.length > 0
        ? event.constraints.constraints.map((value) => {
            return {
              matchMode: value?.matchMode,
              value: value?.value,
            };
          })
        : initFilterConfig.date.constraints;
    setTransactionLazyParams({
      ...transactionLazyParams,
      filters: {
        ...initFilterConfig,
        date: {
          operator:
            event.constraints.operator || initFilterConfig.date.operator,
          constraints: constraints,
        },
      },
    });
  };
  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    setDates(dateArray);
    if (dateArray[0] !== null && dateArray[1] !== null) {
      dateFilterApplied({
        field: "date",
        constraints: {
          operator: "and",
          constraints: [
            {
              value: new Date(dateArray[0]),
              matchMode: "dateAfter",
            },
            {
              value: new Date(dateArray[1]),
              matchMode: "dateBefore",
            },
          ],
        },
      });
    }
  };

  const onExportCSV = () => {
    setShowLoading(true);
    setTimeout(() => {
      if (type === "Generic") {
        onFetchGenericTransactions();
      } else {
        onFetchIFTATransactions(transactionLazyParams.filters["date"]);
      }
    }, 2000);
  };

  const iftaTransactionHeader = [
    { label: "Date", key: "date" },
    { label: "Jurisdiction", key: "jurisdiction" },
    { label: "Vehicle Name", key: "vehicle" },
    { label: "Card Last Four", key: "lastFour" },
    { label: "Fuel Type", key: "fuelType" },
    { label: "Volume", key: "volume" },
    { label: "Cost", key: "cost" },
    { label: "Vendor", key: "vendor" },
    { label: "Transaction Tag Name", key: "transactionTagName" },
    { label: "Transaction Tag ID", key: "transactionTagId" },
    { label: "Transaction Tag Description", key: "transactionTagDescription" },
    { label: "Card Tag Name", key: "cardTagName" },
    { label: "Card Tag ID", key: "cardTagId" },
    { label: "Card Tag Description", key: "cardTagDescription" },
    { label: "Driver Tag Name", key: "driverTagName" },
    { label: "Driver Tag ID", key: "driverTagId" },
    { label: "Driver Tag Description", key: "driverTagDescription" },
    { label: "Vehicle Tag Name", key: "vehicleTagName" },
    { label: "Vehicle Tag ID", key: "vehicleTagId" },
    { label: "Vehicle Tag Description", key: "vehicleTagDescription" },
  ];

  useEffect(() => {
    if (accountTagsData?.readAccountTags?.accountTagList) {
      const accountTagList =
        accountTagsData?.readAccountTags?.accountTagList || {};

      setTransactionTags(accountTagList?.TRANSACTION?.active || []);
      setCardTags(accountTagList?.CARD?.active || []);
      setDriverTags(accountTagList?.DRIVER?.active || []);
      setVehicleTags(accountTagList?.VEHICLE?.active || []);
    }
  }, [accountTagsData]);

  const transactionHeader = [
    { label: "Date", key: "authorizationDate" },
    { label: "Posted Date", key: "transactionDate" },
    { label: "Timezone Reported In", key: "timezone" },
    { label: "Driver Name", key: "driverName" },
    { label: "Vehicle Name", key: "vehicleName" },
    { label: "VIN", key: "vin" },
    { label: "Location", key: "location" },
    { label: "Card Last Four", key: "lastFour" },
    { label: "Policy Last Applied", key: "metaData" },
    { label: "Merchant", key: "merchantName" },
    { label: "Merchant Category", key: "merchantCategory" },
    { label: "Amount", key: "amount" },
    { label: "Discount", key: "discount" },
    { label: "Fuel Type", key: "fuelType" },
    { label: "Fuel Category", key: "fuelCategory" },
    { label: "Fuel in Gallons", key: "gallons" },
    { label: "Price per Gallon", key: "pricePerGallon" },
    { label: "Status", key: "status" },
    { label: "Memo", key: "memo" },
    { label: "Transaction Tag Name", key: "transactionTagName" },
    { label: "Transaction Tag ID", key: "transactionTagId" },
    { label: "Transaction Tag Description", key: "transactionTagDescription" },
    { label: "Card Tag Name", key: "cardTagName" },
    { label: "Card Tag ID", key: "cardTagId" },
    { label: "Card Tag Description", key: "cardTagDescription" },
    { label: "Driver Tag Name", key: "driverTagName" },
    { label: "Driver Tag ID", key: "driverTagId" },
    { label: "Driver Tag Description", key: "driverTagDescription" },
    { label: "Vehicle Tag Name", key: "vehicleTagName" },
    { label: "Vehicle Tag ID", key: "vehicleTagId" },
    { label: "Vehicle Tag Description", key: "vehicleTagDescription" },
  ];

  const footer = (
    <div className="flex flex-row justify-end mt-2">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.Normal}
        onClick={() => {
          setSelectedTransactionTags([]);
          setSelectedCardTags([]);
          setSelectedVehicleTags([]);
          setSelectedDriverTags([]);
          onCloseExportModal();
        }}
      >
        Close
      </Button>
      <CSVLink
        filename="ifta-report.csv"
        ref={csvLinkRef}
        data={iftaTransactionsList}
        headers={iftaTransactionHeader}
        target="_blank"
      ></CSVLink>
      <CSVLink
        filename="transaction-report.csv"
        ref={csvLinkRef1}
        data={exportTransactionList}
        headers={transactionHeader}
        target="_blank"
      ></CSVLink>
      {!showLoading && !showComplete && (
        <Button
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          loading={iftaTransactionLoading || transactionListLoading}
          disabled={
            dates
              ? (dates as Date[])[0] === null || (dates as Date[])[1] === null
              : true
          }
          onClick={() => {
            if (exportTransactionType === "TRANSACTION") {
              onExportCSV();
            } else {
              onFetchTransactionsForReceipts();
            }
          }}
        >
          Export as {exportTransactionType === "TRANSACTION" ? "CSV" : "ZIP"}
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={
        <span className="text-base md:text-lg">
          Export{" "}
          {exportTransactionType === "TRANSACTION"
            ? "Transactions"
            : "Receipts"}
        </span>
      }
      visible={open}
      style={{ fontFamily: "Inter" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={onCloseExportModal}
    >
      <div className="space-y-3 text-sm md:text-base">
        {showLoading || showComplete ? (
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
          />
        ) : (
          <div>
            {exportTransactionType === "TRANSACTION" && (
              <div className="space-y-4">
                <label>Export Type*</label>
                <div className="relative flex items-start my-2">
                  <div className="flex items-center h-5">
                    <input
                      id={"cb"}
                      type="checkbox"
                      value={"Generic"}
                      name={"cb"}
                      checked={type === "Generic"}
                      onChange={onTypeChange}
                      className="w-4 h-4 text-orange-600 border-gray-300 rounded-full cursor-pointer focus:ring-opacity-0 focus:ring-orange-500"
                    />
                  </div>
                  <div className="ml-3">
                    <label htmlFor={"cb"} className="font-medium text-gray-700">
                      Generic
                    </label>
                    <div className="text-sm italic">
                      Exports all information available.
                    </div>
                  </div>
                </div>
                <div className="relative flex items-start my-1">
                  <div className="flex items-center h-5">
                    <input
                      id={"cb2"}
                      type="checkbox"
                      name={"cb2"}
                      value={"IFTA"}
                      checked={type === "IFTA"}
                      onChange={onTypeChange}
                      className="w-4 h-4 text-orange-600 border-gray-300 rounded-full cursor-pointer focus:ring-opacity-0 focus:ring-orange-500"
                    />
                  </div>
                  <div className="ml-3">
                    <label
                      htmlFor={"cb2"}
                      className="font-medium text-gray-700"
                    >
                      IFTA
                    </label>
                    <div className="text-sm italic">
                      Reports diesel transactions with the totals by
                      jurisdiction.
                    </div>
                  </div>
                </div>
                <div className="my-4 text-sm">
                  Exports data to a CSV. Any active filters from the page will
                  automatically apply to the export.
                </div>
                {type === "IFTA" && (
                  <div className="p-4 text-red-500 bg-red-100 rounded-md">
                    IFTA exports can be incomplete as some merchants do not
                    report full fuel transaction data.
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col pt-4">
              <label htmlFor="range">Date Range*</label>
              <Calendar
                id="range"
                value={dates}
                onChange={onDateRangeChange}
                selectionMode="range"
                className="mt-2 custom-calendar"
                panelClassName="custom-calendar-panel"
                placeholder="Select Date Range"
                maxDate={new Date()}
                inputClassName="!py-2"
                readOnlyInput
              />
            </div>
            <div className="flex flex-col pt-4">
              <label htmlFor="transaction-tag">Transaction Tag</label>
              <CustomMultiSelect
                id="transaction-tag"
                value={selectedTransactionTags}
                options={transactionTags}
                onChange={(e) => {
                  setSelectedTransactionTags(e.value);
                }}
                placeholder="Any"
                className="mt-2"
                optionLabel="title"
                optionValue="id"
                maxSelectedLabels={1}
                itemTemplate={accountTagItemTemplate}
                disabled={accountTagsDataLoading}
              />
            </div>
            <div className="flex flex-col pt-4">
              <label htmlFor="card-tag">Card Tag</label>
              <CustomMultiSelect
                id="card-tag"
                value={selectedCardTags}
                options={cardTags}
                onChange={(e) => {
                  setSelectedCardTags(e.value);
                }}
                placeholder="Any"
                className="mt-2"
                optionLabel="title"
                optionValue="id"
                maxSelectedLabels={1}
                itemTemplate={accountTagItemTemplate}
                disabled={accountTagsDataLoading}
              />
            </div>
            <div className="flex flex-col pt-4">
              <label htmlFor="driver-tag">Driver Tag</label>
              <CustomMultiSelect
                id="driver-tag"
                value={selectedDriverTags}
                options={driverTags}
                onChange={(e) => {
                  setSelectedDriverTags(e.value);
                }}
                style={{
                  fontFamily: "Inter",
                  fontSize: "0.75rem",
                }}
                placeholder="Any"
                className="mt-2"
                optionLabel="title"
                optionValue="id"
                maxSelectedLabels={1}
                itemTemplate={accountTagItemTemplate}
                disabled={accountTagsDataLoading}
              />
            </div>
            <div className="flex flex-col pt-4">
              <label htmlFor="vehicle-tag">Vehicle Tag</label>
              <CustomMultiSelect
                id="vehicle-tag"
                value={selectedVehicleTags}
                options={vehicleTags}
                onChange={(e) => {
                  setSelectedVehicleTags(e.value);
                }}
                style={{
                  fontFamily: "Inter",
                  fontSize: "0.75rem",
                }}
                placeholder="Any"
                className="mt-2"
                optionLabel="title"
                optionValue="id"
                maxSelectedLabels={1}
                itemTemplate={accountTagItemTemplate}
                disabled={accountTagsDataLoading}
              />
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};
