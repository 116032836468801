/** @format */
/** @format */
import { useMutation } from "@apollo/client";
import { BILLING_SUMMARY } from "@roadflex/graphql";
import { BillingSummaryType } from "@roadflex/types";

export const useBillingSummary = () => {
  const [makeBillingSummary, { loading: makeBillingSummaryLoading }] =
    useMutation<{
      billingSummary: BillingSummaryType[];
    }>(BILLING_SUMMARY, {
      fetchPolicy: "no-cache",
    });
  return { makeBillingSummary, makeBillingSummaryLoading };
};
