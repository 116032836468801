export const phoneFormatter = (input: string | undefined | null): string => {
  let newvalue = "";

  if (input) {
    const value = input as string;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) {
      newvalue = phoneNumber.toString();
    } else if (phoneNumberLength < 7) {
      newvalue = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    } else {
      newvalue = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6,
      )}-${phoneNumber.slice(6, 10)}`;
    }
  }
  return newvalue;
};

export const convertISOToDateMonthPST = (isoDate: string) => {
  let convertedDate = "-";
  if (isoDate) {
    const dateInMillis = Date.parse(isoDate);
    const dateObj = new Date(dateInMillis);
    convertedDate = dateObj.toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
    });
  }
  return convertedDate;
};

export const convertISOStringToDateNew = (
  isoDate: string,
  onlyDate?: boolean,
  stringDate?: boolean,
  dateAndShortTime?: boolean,
  timeZone?: string,
) => {
  let convertedDate = "-";
  if (isoDate) {
    const dateInMillis = Date.parse(isoDate);
    const dateObj = new Date(dateInMillis);
    let format: any = {
      timeZone: timeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    if (onlyDate) {
      if (timeZone !== "") {
        format = {
          timeZone: timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
      } else {
        format = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        };
      }
    }
    if (stringDate) {
      if (timeZone !== "") {
        format = {
          timeZone: timeZone,
          year: "numeric",
          month: "short",
          day: "2-digit",
        };
      } else {
        format = {
          year: "numeric",
          month: "short",
          day: "2-digit",
        };
      }
    }
    if (dateAndShortTime) {
      if (timeZone !== "") {
        format = {
          timeZone: timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
      } else {
        format = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        };
      }
    }
    convertedDate = dateObj.toLocaleString("en-US", format);
  }
  return convertedDate;
};

export const convertISOStringToDate = (
  isoDate: string,
  onlyDate?: boolean,
  stringDate?: boolean,
  dateAndShortTime?: boolean,
) => {
  let convertedDate = "-";
  if (isoDate) {
    const dateInMillis = Date.parse(isoDate);
    const dateObj = new Date(dateInMillis);
    let format: any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    if (onlyDate) {
      format = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
    }
    if (stringDate) {
      format = {
        year: "numeric",
        month: "short",
        day: "2-digit",
      };
    }
    if (dateAndShortTime) {
      format = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
    }
    convertedDate = dateObj.toLocaleString("en-US", format);
  }
  return convertedDate;
};

export const convertPSTToDateOnly = (isoDate: string) => {
  let convertedDate = "-";
  if (isoDate) {
    const year = isoDate.slice(0, 4);
    const month = isoDate.slice(5, 7);
    const date = isoDate.slice(8, 10);

    convertedDate = `${month}/${date}/${year}`; //  mm/dd/yyyy
  }
  return convertedDate;
};

export const convertPSTToDateOnlyDashFormat = (isoDate: string) => {
  let convertedDate = "-";
  if (isoDate) {
    const year = isoDate.slice(0, 4);
    const month = isoDate.slice(5, 7);
    const date = isoDate.slice(8, 10);

    convertedDate = `${month}-${date}-${year}`; //  mm/dd/yyyy
  }
  return convertedDate;
};

export const convertISOStringToPSTDateTime = (
  isoDate: string,
  onlyDate?: boolean,
  shortDateAndShortTime?: boolean,
) => {
  let convertedDate = "-";
  if (isoDate) {
    const dateInMillis = Date.parse(isoDate);
    const dateObj = new Date(dateInMillis);
    let format: any = {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    if (onlyDate) {
      format = {
        timeZone: "America/Los_Angeles",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
    }
    if (shortDateAndShortTime) {
      format = {
        timeZone: "America/Los_Angeles", // PST timezone
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
    }
    convertedDate = dateObj.toLocaleString("en-US", format);
  }
  return convertedDate;
};

export const formatArrayToSQLArray = (array: string[]) => {
  return `(${array.map((element) => `'${element}'`).join(",")})`;
};
