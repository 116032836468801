/** @format */

import { STATE_CODES } from "@roadflex/constants";
import { AddressUpdateValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { forwardRef, LegacyRef, Ref, SyntheticEvent, useState } from "react";
import { Toast } from "../../../../src/toast-message/toast";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { Select } from "../../../dropdowns";
import { TextInput } from "../../../inputs";
interface UpdateAddressesUIProps extends FormikProps<AddressUpdateValues> {
  isSameAddressInitState: boolean;
  setDefaultAddress: () => void;
}

const UpdateAddressesUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      errors,
      touched,
      handleReset,
      isSameAddressInitState,
      setFieldValue,
      setDefaultAddress,
      setFieldTouched,
      ...rest
    }: UpdateAddressesUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { businessAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      businessAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };
    const [update, setupdate] = useState(false);
    const toggle = () => setupdate((t) => !t);

    const submitAction = (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!isEmpty(errors)) {
        Toast({
          type: "error",
          message: "Validation failed, please check your inputs",
        });
        return;
      }
      handleSubmit();
      toggle();
    };

    const discardChanges = () => {
      handleReset();
      setDefaultAddress();
      setFieldValue("differentDeliveryAddress", !isSameAddressInitState);
      setupdate(false);
    };

    return (
      <form
        onSubmit={submitAction}
        className="flex flex-col w-full text-sm md:text-base"
      >
        <div>
          <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
            <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
              Addresses
            </div>
            <div>
              <div className="w-full mt-2">
                <h1 className="font-medium leading-6">Business Address</h1>
              </div>

              <div className="flex flex-col gap-4 mt-4 sm:flex-row flex-grow-1">
                <div className="relative w-full">
                  <TextInput
                    disabled={!update}
                    label="Street Address"
                    labelClass="font-medium"
                    name="businessAddress.streetAddress"
                    ref={businessAutoCompleteRef as Ref<HTMLInputElement>}
                    value={values?.businessAddress?.streetAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={30}
                    error={
                      touched?.businessAddress?.streetAddress &&
                      Boolean(errors?.businessAddress?.streetAddress)
                    }
                    errorMessage={errors?.businessAddress?.streetAddress}
                    touched={touched?.businessAddress?.streetAddress}
                    variant="medium"
                    placeholder="715 Townsend Street"
                    required
                  ></TextInput>
                </div>

                <div className="relative w-full">
                  <TextInput
                    disabled={!update}
                    label="Additional Info"
                    labelClass="font-medium"
                    name="businessAddress.streetDetails"
                    value={values?.businessAddress?.streetDetails}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={200}
                    error={
                      touched?.businessAddress?.streetDetails &&
                      Boolean(errors?.businessAddress?.streetDetails)
                    }
                    errorMessage={errors?.businessAddress?.streetDetails}
                    touched={touched?.businessAddress?.streetDetails}
                    variant="medium"
                    placeholder="Apt, Floor, Suite, etc (optional)"
                    required
                  ></TextInput>
                </div>
              </div>

              <div className="flex flex-col gap-4 mt-4 sm:flex-row flex-grow-1">
                <div className="relative w-full">
                  <TextInput
                    label="City"
                    labelClass="font-medium"
                    name="businessAddress.city"
                    value={values?.businessAddress?.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={15}
                    error={
                      touched?.businessAddress?.city &&
                      Boolean(errors?.businessAddress?.city)
                    }
                    errorMessage={errors?.businessAddress?.city}
                    touched={touched?.businessAddress?.city}
                    variant="medium"
                    required
                    disabled={!update}
                    placeholder="San Francisco"
                  ></TextInput>
                </div>

                <div className="relative w-full">
                  <Select
                    label="State"
                    labelClass="font-medium"
                    value={values?.businessAddress?.state}
                    options={[
                      {
                        label: "-- Select a State--",
                        value: "",
                        disabled: true,
                      },
                    ].concat(
                      STATE_CODES.map((m) => {
                        return {
                          label: `${m.label} (${m.value})`,
                          value: m.value,
                          disabled: false,
                        };
                      }),
                    )}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) =>
                      setFieldValue("businessAddress.state", e.value)
                    }
                    onBlur={() =>
                      setFieldTouched("businessAddress.state", true)
                    }
                    placeholder="Select"
                    touched={touched?.businessAddress?.state}
                    error={
                      touched?.businessAddress?.state &&
                      Boolean(errors?.businessAddress?.state)
                    }
                    errorMessage={errors?.businessAddress?.state}
                    required
                    disabled={!update}
                  ></Select>
                </div>

                <div className="relative w-full">
                  <TextInput
                    label="Zip / Postal"
                    labelClass="font-medium"
                    name="businessAddress.zip"
                    value={values?.businessAddress?.zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={5}
                    error={
                      touched?.businessAddress?.zip &&
                      Boolean(errors?.businessAddress?.zip)
                    }
                    errorMessage={errors?.businessAddress?.zip}
                    touched={touched?.businessAddress?.zip}
                    variant="medium"
                    required
                    disabled={!update}
                    placeholder="94305"
                  ></TextInput>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              {!update && (
                <Button
                  onClick={toggle}
                  variant={ButtonVariant.AppOrange}
                  size={ButtonSize.AppSize}
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              )}

              {update && (
                <div className="flex gap-2">
                  <Button
                    onClick={discardChanges}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    );
  },
);

export default UpdateAddressesUI;
