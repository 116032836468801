import { CogIcon } from "@heroicons/react/24/outline";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { convertISOStringToDate } from "@roadflex/utils";
import getConfig from "next/config";
import { useCallback, useRef, useState } from "react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;

const containerStyle = {
  height: "100%",
  width: "100%",
};

const arrowSymbolOffsets = ["10%", "30%", "50%", "70%", "90%"];

export function TripHistoryMap({
  tripCoordinatesArray,
  markersArray,
}: {
  tripCoordinatesArray: {
    lat: number;
    lng: number;
  }[];
  markersArray: {
    position: {
      lat: number;
      lng: number;
    };
    speed: string;
    time: string;
    showInfo: boolean;
    key: number;
  }[];
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
  });

  // const [arrowSymbol, setArrowSymbol] = useState<{
  //   path: google.maps.SymbolPath;
  //   fillColor: string;
  //   fillOpacity: number;
  //   strokeWeight: number;
  //   rotation: number;
  //   scale: number;
  // } | null>(null);
  const [circleSymbol, setCircleSymbol] = useState<
    string | google.maps.Icon | google.maps.Symbol | undefined
  >(undefined);
  const [polyLineOptions, setPolyLineOptions] = useState<
    google.maps.PolylineOptions | undefined
  >(undefined);
  const [map, setMap] = useState<null>(null);
  const [polyInfoWindowData, setPolyInfoWindowData] = useState<{
    show: boolean;
    speed: string | null;
    time: string | null;
    key: number;
  }>({
    show: false,
    speed: null,
    time: null,
    key: -1,
  });
  const [coords, setCoords] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >({
    lat: 0,
    lng: 0,
  });
  const [showStartInfo, setShowStartInfo] = useState(true);
  const [showEndInfo, setShowEndInfo] = useState(true);
  // const polyInfoWindowRef = useRef<google.maps.PolylineOptions | undefined>(
  //   undefined,
  // );
  const polyLineRef = useRef<Polyline>(null);

  const onLoad = useCallback(
    (mapObj: { fitBounds: (arg0: google.maps.LatLngBounds) => void }) => {
      const latLngBounds = new google.maps.LatLngBounds();
      tripCoordinatesArray?.forEach((coordinates) => {
        latLngBounds.extend(coordinates);
      });
      const bounds = new window.google.maps.LatLngBounds(latLngBounds);
      mapObj.fitBounds(bounds);
      setMap(map);
      const arrowSymb = {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        fillColor: "blue",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 4,
      };
      // setArrowSymbol(arrowSymb);
      setPolyLineOptions({
        strokeColor: "#e64900",
        strokeOpacity: 0.6,
        strokeWeight: 6,
        icons: arrowSymbolOffsets.map((offVal) => {
          return {
            icon: arrowSymb,
            offset: offVal,
          };
        }),
      });
      setCircleSymbol({
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "white",
        fillOpacity: 0.9,
        strokeWeight: 0,
        rotation: 0,
        scale: 4,
      });
      setCoords(tripCoordinatesArray[0]);
    },
    [map, tripCoordinatesArray],
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // const getPixelPositionOffset = (width: number, height: number) => ({
  //   x: -(width / 2),
  //   y: -(height / 2),
  // });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={tripCoordinatesArray[0]}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <Marker
        position={tripCoordinatesArray[0]}
        title={"Trip Started"}
        animation={window.google.maps.Animation.DROP}
        // icon={{
        //   url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        // }}
        onClick={() => {
          setShowStartInfo(true);
        }}
        key={"tripStartMarkerKey"}
      >
        {showStartInfo && (
          <InfoWindow
            onCloseClick={() => {
              setShowStartInfo(false);
            }}
          >
            <div>
              <div>
                <b>Trip Started</b>
              </div>
              <div>
                <b>Time:</b> {convertISOStringToDate(markersArray[0]?.time)}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
      <Marker
        animation={window.google.maps.Animation.DROP}
        // icon={{
        //   url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        // }}
        title={"Trip Ended"}
        position={tripCoordinatesArray[tripCoordinatesArray.length - 1]}
        onClick={() => {
          setShowEndInfo(true);
        }}
        key={"tripEndMarkerKey"}
      >
        {showEndInfo && (
          <InfoWindow
            onCloseClick={() => {
              setShowEndInfo(false);
            }}
          >
            <div>
              <div>
                <b>Trip Ended</b>
              </div>
              <div>
                <b>Time: </b>
                {convertISOStringToDate(
                  markersArray[markersArray.length - 1]?.time,
                )}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
      <Polyline
        ref={polyLineRef}
        onMouseOver={(e) => {
          setPolyLineOptions({
            ...polyLineOptions,
            strokeOpacity: 0.9,
            strokeWeight: 8,
          });
        }}
        onMouseOut={(e) => {
          // console.log("onMouseOut", e.latLng?.lat(), e.latLng?.lng());
          setPolyLineOptions({
            ...polyLineOptions,
            strokeOpacity: 0.6,
            strokeWeight: 6,
          });
        }}
        onMouseMove={(e) => {
          // console.log("onMouseMove", e.latLng?.lat(), e.latLng?.lng());
          setCoords({ lat: e.latLng?.lat() || 0, lng: e.latLng?.lng() || 0 });
          const closestMarker = markersArray?.reduce(function (prev, curr) {
            const cpos = google.maps.geometry.spherical.computeDistanceBetween(
              { lat: e.latLng?.lat() || 0, lng: e.latLng?.lng() || 0 },
              curr.position,
            );
            const ppos = google.maps.geometry.spherical.computeDistanceBetween(
              { lat: e.latLng?.lat() || 0, lng: e.latLng?.lng() || 0 },
              prev.position,
            );

            return cpos < ppos ? curr : prev;
          });

          if (closestMarker && polyInfoWindowData.key !== closestMarker.key) {
            setPolyInfoWindowData({
              show: true,
              speed: closestMarker.speed,
              time: closestMarker.time,
              key: closestMarker.key,
            });
          }
        }}
        path={tripCoordinatesArray}
        options={polyLineOptions}
      ></Polyline>
      <Marker
        position={coords}
        visible={polyInfoWindowData.show}
        icon={circleSymbol}
        key={"polyMarkerKey"}
      >
        {polyInfoWindowData.show && (
          <InfoWindow
            onCloseClick={() => {
              setPolyInfoWindowData({
                ...polyInfoWindowData,
                show: false,
              });
            }}
          >
            <div>
              <div>
                <b>Speed:</b> {polyInfoWindowData.speed}mph
              </div>
              <div>
                <b>Time:</b>
                {convertISOStringToDate(polyInfoWindowData?.time || "")}
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  ) : (
    <div className="flex justify-center h-full align-middle">
      <span className="inline-flex items-center">
        <CogIcon
          className="w-6 h-6 mr-2 -ml-1 animate-spin"
          aria-hidden="true"
        />
        {"Loading Map..."}
      </span>
    </div>
  );
}
