/** @format */

import { getCookie, setCookie } from "@roadflex/web-lib";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

export const adminUploadFile = async (
  file: File | string,
  fileType: string,
  userId?: string,
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileType", fileType);
  if (userId) {
    formData.append("userId", userId);
  }
  // @NOTE: sending auth token in header as authorisation is creating cors issue,
  // so using cookie here for now.
  const token = getCookie("adminToken") as string;
  const existingUserToken = getCookie("token") as string;
  // replacing the user token with the admin token temporarily
  setCookie("token", token, 30);

  const response = await fetch(`${NEXT_PUBLIC_API_URL}/api/files/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });

  const { fileName } = await response.json();
  // replacing the user token with the original one
  setCookie("token", existingUserToken, 30);

  return fileName;
};

export const adminOutreachFileUpload = async (
  file: File | string,
  description?: string,
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", description || "");
    // @NOTE: sending auth token in header as authorisation is creating cors issue,
    // so using cookie here for now.
    const token = getCookie("adminToken") as string;
    const existingUserToken = getCookie("token") as string;
    // replacing the user token with the admin token temporarily
    setCookie("token", token, 30);

    const response = await fetch(
      `${NEXT_PUBLIC_API_URL}/api/files/prospectiveLeadsUpload`,
      {
        method: "POST",
        body: formData,
        credentials: "include",
      },
    );

    const status = response.status;
    const res = await response.json();

    if (status !== 200) {
      throw new Error(res.message);
    }

    // replacing the user token with the original one
    setCookie("token", existingUserToken, 30);

    return res.fileName;
  } catch (err: any) {
    throw new Error(err.message);
  }
};
