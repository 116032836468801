/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { appReferralSharingSchema } from "@roadflex/constants";
import { READ_REFERRALS, SHARE_REFERRAL_CODE } from "@roadflex/graphql";
import { BonusType, ReferralValues, ReferredType } from "@roadflex/types";
import { useFormik } from "formik";

type useShareReferralProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: ReferralValues;
};

export const useShareReferral = ({
  onSubmit,
  initialValues,
}: useShareReferralProps) => {
  const [shareReferralCodeFn] = useMutation<{
    shareReferralCode: {
      code: string;
      message: string;
    };
  }>(SHARE_REFERRAL_CODE);

  const { ...rest } = useFormik({
    initialValues,
    validationSchema: appReferralSharingSchema,
    onSubmit: async (value) => {
      try {
        const res = await shareReferralCodeFn({
          variables: { data: value },
        });
        if (res?.data?.shareReferralCode) {
          onSubmit(res?.data?.shareReferralCode, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    ...rest,
  };
};

export function useReadReferrals() {
  const { data, ...rest } = useQuery<{
    readReferrals: {
      code: string;
      message: string;
      referralEarnings: number;
      referralCode: string;
      referralSetting: BonusType;
      referred: ReferredType[];
    };
  }>(READ_REFERRALS, {
    fetchPolicy: "no-cache",
  });
  return { data, ...rest };
}
