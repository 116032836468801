/** @format */

import { useRouter } from "next/router";
import { InputSwitchChangeParams } from "primereact/inputswitch";
import React from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Toast } from "../toast-message/toast";

type PermissionGuardNewProps = {
  isOwner: boolean;
  pageAccess: boolean;
  children: React.ReactNode;
  isEnterprise?: boolean;
};

type PermissionGuardComponentNewProps = {
  type: "dropdown" | "input" | "button" | "checkbox" | "inputswitch";
  children: React.ReactNode;
  featurePermission: boolean;
  messageType?: string;
};

export const FuelCardPermissionGuardNew = (props: PermissionGuardNewProps) => {
  const router = useRouter();
  const { isOwner, pageAccess, children, isEnterprise = false } = props;
  if (pageAccess) {
    return <>{children || null}</>;
  }
  if (isOwner) {
    const line1 = isEnterprise ? "ENTERPRISE FEATURE" : "PREMIUM FEATURE";
    return (
      <div className="flex items-center justify-center w-full h-full flex-col min-h-[calc(100vh-128px)]">
        <div className="text-xl font-semibold text-yellow-600">{line1}</div>
        {!isEnterprise && (
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Black}
            className="mt-4"
            onClick={() => {
              router.push("/account-settings?tab=2");
            }}
          >
            Upgrade To Premium
          </Button>
        )}
        {isEnterprise && (
          <div className="mt-4">
            Contact{" "}
            <a
              className="text-gray-500 underline"
              href="mailto:support@roadflex.com"
              target="_blank"
              rel="noreferrer"
            >
              support@roadflex.com
            </a>{" "}
            to upgrade to Enterprise
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="flex items-center justify-center  w-full h-full flex-col  min-h-[calc(100vh-128px)]">
      <div className="text-xl font-semibold text-yellow-600">
        NO ACCESS TO THIS PAGE
      </div>
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.Black}
        className="mt-4"
        onClick={() => {
          router.push("/dashboard");
        }}
      >
        Access Dashboard
      </Button>
    </div>
  );
};

export const FuelCardComponentPermissionGuard = (
  props: PermissionGuardComponentNewProps,
) => {
  const router = useRouter();
  const { type, children, featurePermission, messageType = "" } = props;
  let message = "";
  let buttonMessage = "";
  if (messageType === "enterprise") {
    message = "This is an enterprise feature";
  } else if (messageType === "premium") {
    message = "This is a premium feature";
  } else {
    message = "Your role does not permit this action";
  }
  if (messageType === "enterprise") {
    buttonMessage = "Contact Account Owner or Manager";
  } else if (messageType === "premium") {
    buttonMessage = "Upgrade";
  } else {
    buttonMessage = "Contact Account Owner or Manager";
  }
  if (type === "dropdown") {
    return (
      <React.Fragment>
        {React.Children.map(children as React.ReactPortal, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onChange: featurePermission
                ? child.props.onChange
                : (e: React.MouseEvent<HTMLSelectElement, MouseEvent>) => {
                    e.stopPropagation();
                    e.preventDefault();
                    Toast({
                      type: "info",
                      message: (
                        <>
                          <div>{message}</div>
                          <Button
                            type={ButtonType.Button}
                            variant={ButtonVariant.Black}
                            size={ButtonSize.Small}
                            className="mt-2"
                            onClick={() => {
                              router.push("/account-settings?tab=2");
                            }}
                          >
                            {buttonMessage}
                          </Button>
                        </>
                      ),
                    });
                  },
            });
          }
          return child;
        })}
      </React.Fragment>
    );
  }
  if (type === "input") {
    return (
      <React.Fragment>
        {React.Children.map(children as React.ReactPortal, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onMouseDown: featurePermission
                ? child.props.onMouseDown
                : (e: React.MouseEvent<HTMLSelectElement, MouseEvent>) => {
                    e.stopPropagation();
                    e.preventDefault();
                    Toast({
                      type: "info",
                      message: (
                        <>
                          <div>{message}</div>
                          <Button
                            type={ButtonType.Button}
                            variant={ButtonVariant.Black}
                            size={ButtonSize.Small}
                            className="mt-2"
                            onClick={() => {
                              router.push("/account-settings?tab=2");
                            }}
                          >
                            {buttonMessage}
                          </Button>
                        </>
                      ),
                    });
                  },
            });
          }
          return child;
        })}
      </React.Fragment>
    );
  }
  if (type === "inputswitch") {
    return (
      <React.Fragment>
        {React.Children.map(children as React.ReactPortal, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onChange: featurePermission
                ? child.props.onChange
                : (e: InputSwitchChangeParams) => {
                    e.stopPropagation();
                    Toast({
                      type: "info",
                      message: (
                        <>
                          <div>{message}</div>
                          <Button
                            type={ButtonType.Button}
                            variant={ButtonVariant.Black}
                            size={ButtonSize.Small}
                            className="mt-2"
                            onClick={() => {
                              router.push("/account-settings?tab=2");
                            }}
                          >
                            {buttonMessage}
                          </Button>
                        </>
                      ),
                    });
                  },
            });
          }
          return child;
        })}
      </React.Fragment>
    );
  }
  if (type === "checkbox") {
    return (
      <React.Fragment>
        {React.Children.map(children as React.ReactPortal, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onChange: featurePermission
                ? child.props.onChange
                : (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                    Toast({
                      type: "info",
                      message: (
                        <>
                          <div>{message}</div>
                          <Button
                            type={ButtonType.Button}
                            variant={ButtonVariant.Black}
                            size={ButtonSize.Small}
                            className="mt-2"
                            onClick={() => {
                              router.push("/account-settings?tab=2");
                            }}
                          >
                            {buttonMessage}
                          </Button>
                        </>
                      ),
                    });
                  },
            });
          }
          return child;
        })}
      </React.Fragment>
    );
  }
  if (type === "button") {
    return (
      <React.Fragment>
        {React.Children.map(children as React.ReactPortal, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              onClick: featurePermission
                ? child.props.onClick
                : (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    Toast({
                      type: "info",
                      message: (
                        <>
                          <div>{message}</div>
                          <Button
                            type={ButtonType.Button}
                            variant={ButtonVariant.Black}
                            size={ButtonSize.Small}
                            className="mt-2"
                            onClick={() => {
                              router.push("/account-settings?tab=2");
                            }}
                          >
                            {buttonMessage}
                          </Button>
                        </>
                      ),
                    });
                  },
            });
          }
          return child;
        })}
      </React.Fragment>
    );
  }

  return <>{children || null}</>;
};
