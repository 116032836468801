import { ENUM_TRANSACTION_STATUS } from "@prisma/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IsCSR } from "@roadflex/constants";
import { useBillingSummary } from "@roadflex/react-hooks";
import {
  BillingCycleType,
  BillingSummaryType,
  Transactions,
} from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { dollarFormatter } from "@roadflex/web-lib";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import * as Yup from "yup";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import StatementPdf from "../../load-pdf/statement-pdf/statement-pdf";
import ExportImportModalLoader from "../../loader/export-import-modal-loader";
import { Toast } from "../../toast-message/toast";

const initialValues = {
  type: "pdf",
};

export const StatementsDownloadModal = ({
  modal,
  toggle,
  data,
  email,
}: {
  modal: boolean;
  toggle: () => void;
  data: BillingCycleType;
  email: string;
}) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [info, setInfo] = useState<BillingSummaryType | null>(null);
  const [disable, setDisable] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { makeBillingSummary, makeBillingSummaryLoading } = useBillingSummary();

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Pick One at least."),
  });

  const { handleSubmit, values, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {
      toggle();
    },
  });

  async function getStatements() {
    try {
      const newData = {
        billingCycleIds: [data.billingCycleId],
        email,
      };
      setDisable(true);
      const response = await makeBillingSummary({
        variables: { data: newData },
      });

      if (response.data?.billingSummary[0]) {
        setInfo({
          company: response.data.billingSummary[0].company,
          transactionDetails:
            response.data.billingSummary[0].transactionDetails,
          startingDate: response.data.billingSummary[0].startingDate,
          endingDate: response.data.billingSummary[0].endingDate,
          spendings: response.data.billingSummary[0].spendings,
          lateFees: response.data.billingSummary[0].lateFees,
          rewards: response.data.billingSummary[0].rewards,
          outstandingAmount: response.data.billingSummary[0].outstandingAmount,
          billingCycleStatus:
            response.data.billingSummary[0].billingCycleStatus,
          billingCycleInvoiceId:
            response.data.billingSummary[0].billingCycleInvoiceId,
          createdManually: response.data.billingSummary[0].createdManually,
          paymentTerms: response.data.billingSummary[0].paymentTerms,
          billingCycleItems: response.data.billingSummary[0].billingCycleItems,
        });
      }

      if (
        !response?.data?.billingSummary[0]?.transactionDetails?.some(
          (transactionDetail) => transactionDetail?.transactions?.length > 0,
        ) &&
        !response?.data?.billingSummary[0]?.createdManually
      ) {
        Toast({
          type: "error",
          message: "There are no transactions in this duration",
        });
        toggle();
      }
      setDisable(false);
    } catch (error) {
      Toast({
        type: "error",
        message: "There was some problem fetching transactions data",
      });
      setDisable(false);
      toggle();
    }
  }

  useEffect(() => {
    getStatements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headers = [
    { key: "date", label: "Transaction Date" },
    { key: "user", label: "User" },
    { key: "amount", label: "Amount (in $)" },
    { key: "merchantName", label: "Merchant Name" },
    { key: "merchantState", label: "Merchant State" },
    { key: "merchantPostCd", label: "Merchant Postal Code" },
    { key: "merchantCategory", label: "Merchant Category" },
    { key: "status", label: "Transaction Type" },
  ];

  const emptyRow = [
    {
      amount: "",
      date: "",
      merchantName: "",
      merchantPostCd: "",
      merchantState: "",
      merchantCategory: "",
      user: "",
      status: "",
    },
  ];

  const formattedData: Transactions[] = [];

  info?.transactionDetails?.forEach((transactionDetail) =>
    transactionDetail?.transactions?.forEach((m) => {
      formattedData.push({
        ...m,
        date: convertISOStringToDate(m.date.toString()),
        amount: dollarFormatter(+m.amount / 100),
        status: m.status === ENUM_TRANSACTION_STATUS.CAPTURE ? "Dr" : "Cr",
      });
    }),
  );

  const enterpriseInvoiceHeaders = [
    { key: "itemName", label: "Item" },
    { key: "itemAmount", label: "Amount (in $)" },
  ];

  const enterpriseInvoiceEmptyRow = [
    {
      itemAmount: "",
      itemName: "",
    },
  ];

  const formattedEnterpriseInvoiceData: {
    itemName: string;
    itemAmount: string;
  }[] = [];

  info?.billingCycleItems?.forEach((billingCycleItem) => {
    formattedEnterpriseInvoiceData.push({
      ...billingCycleItem,
      itemAmount: dollarFormatter(+billingCycleItem.itemAmount / 100),
    });
  });

  const csvReport = {
    filename: `Report_${new Date().toLocaleDateString()}.csv`,
    headers: headers,
    data: formattedData || emptyRow,
  };
  const csvEnterpriseInvoice = {
    filename: `Invoice_${new Date().toLocaleDateString()}.csv`,
    headers: enterpriseInvoiceHeaders,
    data: formattedEnterpriseInvoiceData || enterpriseInvoiceEmptyRow,
  };

  const csvReportToExport = info?.createdManually
    ? csvEnterpriseInvoice
    : csvReport;

  const showExportLoader = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowLoading(false);
      setShowComplete(true);
    }, 2000);
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Pdf = () => <StatementPdf {...info!} />;

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.GrayOutline}
        size={ButtonSize.AppSize}
        onClick={() => {
          toggle();
        }}
      >
        Close
      </Button>
      {!showLoading && !showComplete && (
        <Button
          type={ButtonType.Submit}
          variant={ButtonVariant.Primary}
          size={ButtonSize.Small}
          disabled={isEmpty(info) || makeBillingSummaryLoading || buttonLoading}
          loading={makeBillingSummaryLoading || buttonLoading}
        >
          {isEmpty(info) && "Loading document..."}
          {values.type === "csv" ? (
            <CSVLink
              onClick={() => {
                showExportLoader();
              }}
              {...csvReportToExport}
            >
              Download CSV
            </CSVLink>
          ) : (
            !isEmpty(info) &&
            IsCSR && (
              <PDFDownloadLink
                document={<Pdf />}
                fileName={`Statements_${new Date().toLocaleDateString()}.pdf`}
                onClick={() => {
                  setButtonLoading(true);
                  setTimeout(() => {
                    showExportLoader();
                  }, 1000);
                }}
              >
                {({ blob, url, loading, error }) => {
                  // console.error(error);
                  return loading || makeBillingSummaryLoading
                    ? "Loading document..."
                    : "Download PDF";
                }}
              </PDFDownloadLink>
            )
          )}
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={
        <span className="text-base md:text-lg">Select your report format</span>
      }
      visible={modal}
      style={{ fontFamily: "Inter" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={() => toggle()}
    >
      <form onSubmit={handleSubmit} className="w-full">
        {showLoading || showComplete ? (
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
          />
        ) : (
          <div className="py-4">
            <div className="flex flex-col gap-2 py-2">
              <div className="flex items-center gap-2">
                <RadioButton
                  disabled={disable}
                  id="type-csv"
                  value="csv"
                  name="type"
                  onChange={handleChange}
                  checked={values.type === "csv"}
                />
                <label className="cursor-pointer" htmlFor="type-csv">
                  CSV
                </label>
              </div>
              <div className="flex items-center gap-2">
                <RadioButton
                  disabled={disable}
                  id="type-pdf"
                  value="pdf"
                  name="type"
                  onChange={handleChange}
                  checked={values.type === "pdf"}
                />
                <label
                  // className="text-gray-400 cursor-pointer"
                  htmlFor="type-pdf"
                >
                  PDF
                </label>
              </div>
            </div>
          </div>
        )}
      </form>
    </Dialog>
  );
};
