/** @format */

import { useReadAllParentCompanyUsers } from "@roadflex/react-hooks";
import ListUsersUI from "./listUsers.ui";
const ListUsers = () => {
  const {
    data: allUsers,
    refetch: usersRefetch,
    loading: usersLoading,
  } = useReadAllParentCompanyUsers();

  return (
    <ListUsersUI
      {...{
        usersRefetch,
        usersLoading,
        users: allUsers?.readAllParentCompanyUsers?.users || [],
      }}
    />
  );
};
export default ListUsers;
