/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ENUM_SUPER_USER_USER_TYPE } from "@prisma/client";
import { ParentCompanyUserType } from "@roadflex/types";
import { capitalizeFirstLetter, humanizeEnum } from "@roadflex/utils";
import getConfig from "next/config";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";
import { ConfirmModal } from "../../modals";
import UpdatePassword from "../account-settings/profile/update-password";
import AddUser from "./add-user/addUser";
import ListUsers from "./list-users/listUsers";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_APP_URL } = publicRuntimeConfig;

type ParentCompanyUserUIProps = {
  role: ENUM_SUPER_USER_USER_TYPE;
  profilesAuthorized: ParentCompanyUserType[];
  handleSubmitImpersonatorSignIn: () => void;
  isSubmittingImpersonatorSignIn: boolean;
  setFieldValueImpersonatorSignIn: any;
  valuesImpersonatorSignIn: any;
  setShowImpersonatorMode: any;
  showImpersonatorMode: any;
};

export const ParentCompanyUserUI = ({
  role,
  profilesAuthorized,
  handleSubmitImpersonatorSignIn,
  isSubmittingImpersonatorSignIn,
  setFieldValueImpersonatorSignIn,
  valuesImpersonatorSignIn,
  setShowImpersonatorMode,
  showImpersonatorMode,
}: ParentCompanyUserUIProps) => {
  const authorizedProfileList = profilesAuthorized
    .sort((a, b) => a.businessName.localeCompare(b.businessName))
    .map((profile, id) => ({
      ...profile,
      businessName: `${capitalizeFirstLetter(
        profile.businessName,
      )} (${humanizeEnum(profile?.roles?.[0]?.role?.name)})`,
    }));

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {"Parent Company".toUpperCase()}
      </div>

      <div className="col-span-6 mt-4 sm:col-span-6 md:mt-0">
        <label
          htmlFor="authorizedProfile"
          className="block mb-2 text-sm font-medium text-gray-700"
        >
          Authorized Profiles
        </label>
        <Select
          id="authorizedProfile"
          value={valuesImpersonatorSignIn.email}
          options={authorizedProfileList}
          onChange={(e) => {
            setFieldValueImpersonatorSignIn("email", e.target.value);
          }}
          style={{
            fontFamily: "Inter",
            fontSize: "0.75rem",
          }}
          placeholder="No Profile Selected"
          optionLabel="businessName"
          optionValue="email"
        ></Select>
        <div className="flex justify-end">
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Brown}
            size={ButtonSize.AppSize}
            className="!mt-2 text-sm flex justify-end"
            disabled={
              isSubmittingImpersonatorSignIn || !valuesImpersonatorSignIn.email
            }
            onClick={() => {
              handleSubmitImpersonatorSignIn();
            }}
          >
            Impersonate Profile
          </Button>
        </div>
      </div>
      <div className="col-span-6 mt-4 md:mt-8 sm:col-span-6">
        <UpdatePassword />
      </div>
      {role === ENUM_SUPER_USER_USER_TYPE.SUPER_USER && (
        <div className="col-span-6 mt-4 md:mt-8 sm:col-span-6">
          <AddUser {...{ profilesAuthorized }} />
        </div>
      )}
      <div className="col-span-6 mt-4 md:mt-8 sm:col-span-6">
        <ListUsers />
      </div>

      {showImpersonatorMode && (
        <ConfirmModal
          title={"Impersonator Mode"}
          body={`Confirm to enter impersonator mode.`}
          setShow={setShowImpersonatorMode}
          submitting={false}
          confirm={() => {
            try {
              setShowImpersonatorMode(false);
              window.open(`${NEXT_PUBLIC_APP_URL}`, "_blank", "noreferrer");
            } catch (e) {
              console.error("Error caught: ", e);
            }
          }}
          show={showImpersonatorMode}
        ></ConfirmModal>
      )}
    </div>
  );
};
