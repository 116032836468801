/** @format */
import { useQuery } from "@apollo/client";
import { READ_OWNERS_AND_EXECUTIVES } from "@roadflex/graphql";
import { OwnerAndExecutiveType } from "@roadflex/types";

export const useReadOwnersAndExecutives = () => {
  const { data, ...rest } = useQuery<{
    readOwnersAndExecutives: {
      ownersAndExecutives: OwnerAndExecutiveType[];
      code: string;
      message: string;
    };
  }>(READ_OWNERS_AND_EXECUTIVES, {
    fetchPolicy: "no-cache",
  });

  return { data: data?.readOwnersAndExecutives, ...rest };
};
