// type AccountControlsProps = {
// };

import { ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING } from "@prisma/client";
import { Toast } from "@roadflex/react-components-www";
import { useUpdateAccountControlSetting } from "@roadflex/react-hooks";
import { FeatureType } from "@roadflex/types";
import AccountControlsUI from "./account-controls.ui";

type AccountControlProps = {
  dynamicPurchaseControlsLinkedTo: ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING;
  controlCenterFeatureAccess: FeatureType[];
};
const AccountControls = (props: AccountControlProps) => {
  const { dynamicPurchaseControlsLinkedTo, controlCenterFeatureAccess } = props;
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    handleReset,
    setFieldValue,
    ...rest
  } = useUpdateAccountControlSetting({
    initialValues: {
      dynamicPurchaseControlsLinkedTo: dynamicPurchaseControlsLinkedTo,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      }
    },
  });
  return (
    <AccountControlsUI
      {...{
        values,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        controlCenterFeatureAccess,
        ...rest,
      }}
    />
  );
};
export default AccountControls;
