/** @format */

import { useUpdateBusinessInformation } from "@roadflex/react-hooks";
import { UserDetailsType } from "@roadflex/types";
import { Toast } from "../../../../src/toast-message/toast";
import UpdateBusinessUI from "./update-business.ui";

type UpdateBusinessProps = {
  readUserDetailsData: UserDetailsType;
};

const UpdateBusiness = ({ readUserDetailsData }: UpdateBusinessProps) => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    handleReset,
    resetForm,
    setFieldValue,
    ...rest
  } = useUpdateBusinessInformation({
    initialValues: {
      title: readUserDetailsData?.businessDetails?.title || "",
      cardName: readUserDetailsData?.businessDetails?.cardName || "",
      description: readUserDetailsData?.businessDetails?.description || "",
      type: readUserDetailsData?.businessDetails?.type || "",
      dot: readUserDetailsData?.businessDetails?.dot || "",
      ein: readUserDetailsData?.businessDetails?.ein || "",
      mc: readUserDetailsData?.businessDetails?.mc || "",
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err?.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res?.message });
      } else {
        Toast({ type: "error", message: res?.message || "" });
      }
    },
  });

  return (
    <UpdateBusinessUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        handleBlur,
        handleReset,
        errors,
        touched,
        resetForm,
        setFieldValue,
        ...rest,
      }}
    />
  );
};

export default UpdateBusiness;
