/** @format */
/** @format */
import { useMutation } from "@apollo/client";
import {
  READ_CURRENT_USER_AUTH,
  SUBMIT_ONBOARD_APPLICATION,
} from "@roadflex/graphql";

export function useSubmitOnboardApplication() {
  const [onSubmitOnboardApplicationFn, { error, loading, called }] =
    useMutation(SUBMIT_ONBOARD_APPLICATION, {
      refetchQueries: [READ_CURRENT_USER_AUTH],
    });

  return { onSubmitOnboardApplicationFn, error, loading, called };
}
