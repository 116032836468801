/** @format */

import { ENUM_FLEET_DRIVER_STATUS } from "@prisma/client";
import { useCreateDriver } from "@roadflex/react-hooks";
import { ChildFeatureType, UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useState } from "react";
import { Toast } from "../../../toast-message/toast";
import { DriversUI } from "./driver.ui";

type DriversNewProps = {
  readCurrentUserAuth: UserAuthType;
  childFeatures: ChildFeatureType[];
};
export const DriversNew = ({
  readCurrentUserAuth,
  childFeatures,
}: DriversNewProps) => {
  const tabs = [{ id: 1, name: "Driver", current: true }];
  const [update, setUpdate] = useState(false);
  const router = useRouter();

  const { resetForm: createDriverResetForm, ...driverFormikValues } =
    useCreateDriver({
      initialValues: {
        name: "",
        email: "",
        phoneNumber: "",
        inviteDriver: false,
        notes: "",
        status: ENUM_FLEET_DRIVER_STATUS.NOT_INVITED,
      },
      onSubmit(res, err) {
        if (err) {
          Toast({ type: "error", message: err.message });
        }
        if (res?.code === "200") {
          Toast({ type: "success", message: res.message });
        }
        createDriverResetForm();
        router.push("/drivers");
      },
    });
  return (
    <DriversUI
      {...{
        customerType: readCurrentUserAuth.fuelCard.customerType,
        tabs,
        resetForm: createDriverResetForm,
        update,
        setUpdate,
        ...driverFormikValues,
        childFeatures,
      }}
    />
  );
};
