/** @format */

import { useMutation } from "@apollo/client";
import {
  LIST_CUSTOMER_PAYMENT_METHODS,
  STRIPE_CONFIRM_SETUP_INTENT,
} from "@roadflex/graphql";
import { capitalizeFirstLetter } from "@roadflex/utils";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import getConfig from "next/config";
import Link from "next/link";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Button, ButtonType, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_APP_URL, NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

type StripeSetupIntentFormProps = {
  setShowSetupIntentForm: (value: boolean) => void;
  showSetupIntentForm: boolean;
  onRepaymentActivate?: () => void;
  onDeposit?: () => void;
  phrasing: string;
};
export default function StripeSetupIntentForm({
  setShowSetupIntentForm,
  showSetupIntentForm,
  onRepaymentActivate,
  onDeposit,
  phrasing,
}: StripeSetupIntentFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [confirmSetupIntentFn] = useMutation(STRIPE_CONFIRM_SETUP_INTENT, {
    refetchQueries: onDeposit ? [] : [LIST_CUSTOMER_PAYMENT_METHODS],
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${NEXT_PUBLIC_APP_URL}/payments`,
      },
      redirect: "if_required",
    });
    if (setupIntent) {
      try {
        await confirmSetupIntentFn({
          variables: {
            data: {
              setupIntentId: setupIntent.id,
            },
          },
        });
        setIsLoading(false);
        setShowSetupIntentForm(false);
        if (onRepaymentActivate) {
          onRepaymentActivate();
        }
        if (onDeposit) {
          onDeposit();
        }
        return;
      } catch (err) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        }
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setIsLoading(false);
      Toast({ type: "error", message: error.message || "" });
      return;
    }
    if (error?.type) {
      setIsLoading(false);
      Toast({ type: "error", message: error.message || "" });
      return;
    }

    setIsLoading(false);
    setShowSetupIntentForm(false);
  };

  return (
    <Dialog
      header={
        onDeposit
          ? `Make Deposit`
          : `${capitalizeFirstLetter(phrasing.toLowerCase())}`
      }
      visible={showSetupIntentForm}
      style={{ minWidth: "40vw", maxWidth: "80vw", fontFamily: "Inter" }}
      onHide={() => setShowSetupIntentForm(false)}
    >
      <form id="payment-form" className="mt-0.5" onSubmit={handleSubmit}>
        <PaymentElement
          id="payment-element"
          options={{
            terms: {
              card: "never",
            },
          }}
          className="StripeElement"
        />
        <div className="mt-2 text-xs text-scarpa-flow-50">
          By providing your card information, you allow RoadFlex to charge your
          card for future payments in accordance with{" "}
          <span className="underline">
            <Link href={`${NEXT_PUBLIC_WWW_URL}/terms-of-service`}>
              <a
                target="_blank"
                href={`${NEXT_PUBLIC_WWW_URL}/terms-of-service`}
                rel="noreferrer"
              >
                these terms
              </a>
            </Link>
          </span>
          .
        </div>
        <div className="flex justify-center p-4 text-center">
          <Button
            variant={ButtonVariant.AppOrange}
            loading={isLoading}
            disabled={isLoading || !stripe || !elements}
            id="submit"
            type={ButtonType.Submit}
          >
            {onDeposit ? "Pay Now" : "Save"}
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
