/** @format */
import { useQuery } from "@apollo/client";
import { READ_CARD_POLICY } from "@roadflex/graphql";
import { CardRule } from "@roadflex/types";

export const useReadCardPolicy = (policyId: string | null) => {
  const { data, ...rest } = useQuery<{
    readCardPolicy: {
      policy: [CardRule];
      code: string;
      message: string;
    };
  }>(READ_CARD_POLICY, {
    fetchPolicy: "no-cache",

    variables: {
      data: { cardRuleId: policyId },
    },
  });
  return { data, ...rest };
};
