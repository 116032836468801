/** @format */

import { PasswordFormValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import { TextInput } from "../../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddUserUIProps extends FormikProps<PasswordFormValues> {}

const AddUserUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  touched,
  errors,
  ...rest
}: AddUserUIProps) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full text-sm md:text-base"
    >
      <div>
        <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8 ">
          <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
            Password Settings
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="current-password" className="block font-medium">
              Current Password
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-md sm:ml-4">
              <TextInput
                type="password"
                name="currentPassword"
                value={values.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.currentPassword && Boolean(errors.currentPassword)
                }
                errorMessage={errors.currentPassword}
                touched={touched.currentPassword}
                variant="medium"
                placeholder="Enter your existing password"
              ></TextInput>
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="new-password" className="block font-medium">
              New Password
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-md sm:ml-4">
              <TextInput
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                errorMessage={errors.password}
                touched={touched.password}
                variant="medium"
                placeholder="Enter your new password"
              ></TextInput>
            </div>
          </div>
          <div className="flex flex-col justify-between sm:flex-row sm:items-center">
            <label htmlFor="new-password" className="block font-medium">
              Confirm New Password
            </label>
            <div className="relative w-full sm:max-w-[250px] lg:max-w-md sm:ml-4">
              <TextInput
                type="password"
                name="retypePassword"
                value={values.retypePassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.retypePassword && Boolean(errors.retypePassword)}
                errorMessage={errors.retypePassword}
                touched={touched.retypePassword}
                variant="medium"
                placeholder="Retype your new password"
              ></TextInput>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              type={ButtonType.Submit}
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              disabled={
                !values.currentPassword || isSubmitting || !isEmpty(errors)
              }
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddUserUI;
