/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import { LINK_ELD_TO_VEHICLE } from "@roadflex/graphql";
import { useFormik } from "formik";

type Values = {
  fleetId: string;
  eldDeviceDetailsId: string;
};

type UseLinkEldToVehicle = {
  initialValues: Values;
  onSubmit: (
    val: {
      linkEldDeviceToVehicle: {
        result: string;
      };
    } | null,
    err: Error | null,
  ) => void;
};

export const useLinkEldToVehicle = ({
  onSubmit,
  initialValues,
}: UseLinkEldToVehicle) => {
  const [linkEldToVehicle] = useMutation<{
    linkEldDeviceToVehicle: {
      result: string;
    };
  }>(LINK_ELD_TO_VEHICLE);

  const { handleChange, handleSubmit, values, isSubmitting, ...rest } =
    useFormik({
      initialValues,
      onSubmit: async (value) => {
        const data = {
          ...value,
        };
        try {
          const res = await linkEldToVehicle({
            variables: { data },
          });
          onSubmit(res?.data || null, null);
        } catch (error) {
          if (error instanceof Error) {
            onSubmit(null, error);
          } else {
            onSubmit(null, new Error("Something went wrong"));
          }
        }
      },
    });
  return {
    linkEldToVehicleValues: values,
    linkEldToVehicleHandleChange: handleChange,
    linkEldToVehicleHandleSubmit: handleSubmit,
    linkEldToVehicleIsSubmitting: isSubmitting,
    ...rest,
  };
};
