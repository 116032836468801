/** @format */

import { ENUM_CHILD_USER_TYPE } from "@prisma/client";
import { appChildUserValidationSchema } from "@roadflex/constants";
import { useCreateAccountUser } from "@roadflex/react-hooks";
import { NotificationsTypeExtended, UserAuthType } from "@roadflex/types";
import { useRouter } from "next/router";
import { useState } from "react";
import { Toast } from "../../../../src/toast-message/toast";
import { UserUI } from "./user.ui";

type UserNewProps = {
  readCurrentUserAuth: UserAuthType;
};
export const UserNew = ({ readCurrentUserAuth }: UserNewProps) => {
  const tabs = [{ id: 1, name: "User", current: true }];
  const [update, setUpdate] = useState(true);
  const [notifications, setNotifications] = useState<
    NotificationsTypeExtended[]
  >([]);
  const router = useRouter();

  const { setValues, resetForm, ...childUserFormikValues } =
    useCreateAccountUser({
      initialValues: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        roleName: ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
        id: "",
        owner: false,
        userNotification: [],
      },
      appChildUserValidationSchema,
      onSubmit(res, err) {
        if (err) {
          Toast({ type: "error", message: err.message });
          return;
        }
        resetForm();
        router.push("/multiple-users");
        if (res?.code === "200") {
          Toast({ type: "success", message: res.message });
        } else {
          Toast({ type: "error", message: res?.message || "" });
        }
      },
    });

  return (
    <UserUI
      {...{
        tabs,
        update,
        setUpdate,
        notifications,
        setNotifications,
        userId: "",
        setValues,
        resetForm,
        ...childUserFormikValues,
      }}
    />
  );
};
