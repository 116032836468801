/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserAuthType } from "@roadflex/types";
import getConfig from "next/config";
import { Fragment } from "react";
import NavigationLinks from "./navigation-links";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

interface SidebarOverlayedProps {
  toggle: () => void;
  open: boolean;
  readCurrentUserAuth?: UserAuthType | undefined;
}

const SidebarOverlayed = ({
  toggle,
  open,
  readCurrentUserAuth,
}: SidebarOverlayedProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 flex md:hidden"
        onClose={toggle}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 pt-2 -mr-8">
                <button
                  type="button"
                  className="flex items-center justify-center w-6 h-6 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={toggle}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Transition.Child>
            <div
              className={`h-full md:flex md:w-64 md:flex-col md:inset-y-0 bg-gradient-to-br from-brown-850 to-brown-750`}
            >
              <div className="flex flex-col flex-grow h-full overflow-y-auto">
                <div className="relative h-16 m-2">
                  <img
                    className="scale-75"
                    src={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-full-logo.svg`}
                    alt="RoadFlex"
                  />
                </div>
                <NavigationLinks
                  readCurrentUserAuth={readCurrentUserAuth}
                ></NavigationLinks>
              </div>
            </div>
          </div>
        </Transition.Child>

        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SidebarOverlayed;
