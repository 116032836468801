/** @format */

import { Button, ButtonVariant } from "../buttons";

type EmailVerificationUIProps = {
  isEmailVerified: boolean | undefined;
};

const EmailVerificationUI = ({ isEmailVerified }: EmailVerificationUIProps) => {
  return (
    <div className="transition-all duration-150 ease-linear">
      <h2 className="">
        {isEmailVerified ? (
          "Email is Verified"
        ) : (
          <div className="text-white">
            <div className="mb-8 text-xl font-semibold text-center">
              Please Verify Your Email
            </div>
            <div className="mx-auto mb-6 text-center">
              You&apos;re almost there! Please check your email to verify email
              address.
            </div>
            <div className="mx-auto text-center">
              If you don&apos;t see it, check your spam folder.
            </div>
            <div className="mt-12">
              <div className="mb-2 text-center">Having Trouble?</div>
              <Button variant={ButtonVariant.Orange} className="w-full">
                Resend Email
              </Button>
            </div>
          </div>
        )}
      </h2>
    </div>
  );
};

export default EmailVerificationUI;
