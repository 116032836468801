import { useMutation, useQuery } from "@apollo/client";
import { ENUM_PAYMENT_METHOD_TYPE } from "@prisma/client";
import {
  ADD_PLAID_PENDING_CONNECTION,
  DELETE_PLAID_PENDING_CONNECTION,
  READ_ACCOUNT_STATUS,
  READ_BUSINESS_INFORMATION,
  READ_PLAID_PENDING_CONNECTION,
  SAVE_PLAID_INFO_PAYMENT,
} from "@roadflex/graphql";
import { useLinkToken } from "@roadflex/react-hooks";
import { PlaidType, UserAuthType } from "@roadflex/types";
import { Toast } from "../../toast-message/toast";
import { PlaidPaymentMethodsUI } from "./PlaidPaymentMethods.ui";
import { PlaidPaymentMethodsAltUI } from "./PlaidPaymentMethodsAlt.ui";

interface PlaidPaymentMethodsProps {
  ui: "box" | "dropdown";
  plaidData: PlaidType[];
  businessId: string;
  selectedPlaidAccount?: string | null;
  setSelectedPlaidAccount?: (value: string) => void;
  primaryPaymentMethodType?: ENUM_PAYMENT_METHOD_TYPE;
  readCurrentUserAuth: UserAuthType;
}

export const PlaidPaymentMethods = ({
  ui,
  plaidData,
  businessId,
  selectedPlaidAccount,
  setSelectedPlaidAccount,
  primaryPaymentMethodType,
  readCurrentUserAuth,
}: PlaidPaymentMethodsProps) => {
  const { linkToken: plaidToken, loading: plaidLoading } = useLinkToken({
    id: businessId,
    isUpdateMode: false,
    isWithTransaction: false,
  });

  const {
    data: plaidPendingConnections,
    loading: plaidPendingConnectionLoading,
  } = useQuery<{
    readPlaidPendingConnection: {
      code: string;
      type: string;
      message: string;
      success: boolean;
      plaidPendingConnections: {
        linkToken: string;
        institution: string;
        plaidId: string;
      }[];
    };
  }>(READ_PLAID_PENDING_CONNECTION, {
    variables: {
      data: {
        businessId: businessId,
      },
    },
  });
  const [savePlaidInfo, { loading: savePlaidLoading }] = useMutation(
    SAVE_PLAID_INFO_PAYMENT,
    {
      refetchQueries: [
        READ_BUSINESS_INFORMATION,
        READ_ACCOUNT_STATUS,
        READ_PLAID_PENDING_CONNECTION,
      ],
      fetchPolicy: "no-cache",
    },
  );
  const [addPlaidPendingConnection] = useMutation(
    ADD_PLAID_PENDING_CONNECTION,
    {
      refetchQueries: [READ_PLAID_PENDING_CONNECTION],
      fetchPolicy: "no-cache",
    },
  );

  const [deletePlaidPendingConnection] = useMutation(
    DELETE_PLAID_PENDING_CONNECTION,
    {
      refetchQueries: [READ_PLAID_PENDING_CONNECTION],
      fetchPolicy: "no-cache",
    },
  );

  const handlePlaidConnect = async (data: {
    isUpdateMode?: boolean;
    plaidId?: string;
    publicToken: string;
    institution: string;
    businessId?: string;
    pendingConnection?: boolean;
  }) => {
    const { pendingConnection, ...rest } = data;
    const plaidDataInput = {
      ...rest,
      businessId: businessId,
      institution: data.institution,
      publicToken: data.publicToken,
    };

    try {
      if (!data.pendingConnection) {
        const res = await savePlaidInfo({
          variables: { input: plaidDataInput },
        });
        if (res.data?.savePlaidInformationFromPayment?.code !== "200") {
          Toast({
            type: "error",
            message:
              res.data?.savePlaidInformationFromPayment?.message ||
              "Something went wrong",
          });
        }
      } else {
        const res = await addPlaidPendingConnection({
          variables: { data: plaidDataInput },
        });
        if (!res.data?.addPlaidPendingConnection?.success) {
          Toast({
            type: "error",
            message:
              res.data?.addPlaidPendingConnection?.message ||
              "Something went wrong",
          });
        }
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        Toast({
          type: "error",
          message: err.message,
        });
      } else {
        Toast({
          type: "error",
          message: "Something went wrong",
        });
      }
    }
  };

  const onDeletePlaidPendingConnection = async (plaidId?: string) => {
    try {
      const res = await deletePlaidPendingConnection({
        variables: { data: { plaidId: plaidId } },
      });
      if (!res.data?.deletePlaidPendingConnection?.success) {
        Toast({
          type: "error",
          message:
            res.data?.deletePlaidPendingConnection?.message ||
            "Something went wrong.",
        });
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        Toast({
          type: "error",
          message: err.message,
        });
      } else {
        Toast({
          type: "error",
          message: "Something went wrong",
        });
      }
    }
  };

  return ui === "box" && primaryPaymentMethodType ? (
    <PlaidPaymentMethodsUI
      savePlaidLoading={savePlaidLoading}
      plaidData={plaidData}
      businessId={businessId}
      plaidToken={plaidToken}
      plaidTokenLoading={plaidLoading}
      handleSubmit={handlePlaidConnect}
      primaryPaymentMethodType={primaryPaymentMethodType}
      plaidPendingConnectionLoading={plaidPendingConnectionLoading}
      plaidPendingConnections={
        plaidPendingConnections?.readPlaidPendingConnection
          ?.plaidPendingConnections || []
      }
      onDeletePlaidPendingConnection={onDeletePlaidPendingConnection}
      readCurrentUserAuth={readCurrentUserAuth}
    ></PlaidPaymentMethodsUI>
  ) : setSelectedPlaidAccount ? (
    <PlaidPaymentMethodsAltUI
      savePlaidLoading={savePlaidLoading}
      plaidData={plaidData}
      businessId={businessId}
      plaidToken={plaidToken}
      plaidTokenLoading={plaidLoading}
      handleSubmit={handlePlaidConnect}
      selectedPlaidAccount={selectedPlaidAccount || ""}
      setSelectedPlaidAccount={setSelectedPlaidAccount}
    ></PlaidPaymentMethodsAltUI>
  ) : null;
};
