import { tz } from "moment-timezone";

export const getUtcToPstDifferenceInMinutes = () => {
  const now = tz("UTC");
  const localOffset = now.utcOffset();
  now.tz("America/Los_Angeles"); // your time zone, not necessarily the server's
  const centralOffset = now.utcOffset();
  const diffInMinutes = localOffset - centralOffset;
  return diffInMinutes;
};

export const isObjectExistant = (obj: any): boolean =>
  obj && Object.keys(obj).length > 0;

export const capitalizeFirstLetter = (value: string | null = "") => {
  if (!value) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const capitalizeFirstLetters = (value = "") => {
  return value.split(" ").map(capitalizeFirstLetter).join(" ");
};

const IP_ADDRESS_REGEX =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const isIPAddress = (str: string): boolean => {
  return IP_ADDRESS_REGEX.test(str);
};

export const getMinDate = (date1: Date, date2: Date): Date => {
  return date1 < date2 ? date1 : date2;
};

export const getStartOfWeek = (date: Date): Date => {
  const startOfWeek = new Date(date);
  // Set the date to the start of the week (Monday) using UTC methods
  const dayOfWeek = (startOfWeek.getUTCDay() + 6) % 7; // Get Monday as the first day
  startOfWeek.setUTCDate(startOfWeek.getUTCDate() - dayOfWeek);
  // Set the time to 00:00:00 in UTC
  startOfWeek.setUTCHours(0, 0, 0, 0);
  return startOfWeek;
};

export const getEndOfWeek = (date: Date): Date => {
  const endOfWeek = new Date(date);
  const dayOfWeek = date.getDay();
  // If the input date is not Sunday (0), set to the last day of the current week (Sunday)
  if (dayOfWeek !== 0) {
    endOfWeek.setDate(date.getDate() + (7 - dayOfWeek));
  }
  return endOfWeek;
};

export const trimTo50Characters = (
  inputString: string | null | undefined,
): string => {
  if (!inputString) {
    return "";
  }
  if (inputString.length <= 50) {
    return inputString;
  }
  return inputString.slice(0, 50);
};

export const convertToUTC = (date: Date): Date => {
  const utcDate = new Date(date);
  utcDate.setMinutes(utcDate.getMinutes() - utcDate.getTimezoneOffset());
  return utcDate;
};

export const getTimeZoneOffset = (timeZone = "UTC", date = new Date()) => {
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  let selectedTimeZone = timeZone;
  if (timeZone === "EST") {
    selectedTimeZone = "America/New_York";
  } else if (timeZone === "PST") {
    selectedTimeZone = "America/Los_Angeles";
  } else if (timeZone === "UTC") {
    selectedTimeZone = "UTC";
  } else if (timeZone === "CT") {
    selectedTimeZone = "America/Chicago";
  }
  const tzDate = new Date(
    date.toLocaleString("en-US", { timeZone: selectedTimeZone }),
  );
  return (utcDate.getTime() - tzDate.getTime()) / 6e4;
};

export const adminDashboardGraphSorter = (a: [number], b: [number]) => {
  return a[0] - b[0];
};

export const appChartsGraphSorter = (a: number[], b: number[]) => {
  return a[0] - b[0];
};

export const stringSorter = (a: string, b: string) => {
  return a.localeCompare(b);
};

// Function to convert camelCase to human-readable form
export const camelCaseToHumanReadable = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
    .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize the first letter
};

// Recursive function to format JSON with human-readable keys
export const formatJson = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map((item) => formatJson(item));
  }
  if (typeof data === "object" && data !== null) {
    return Object.keys(data).reduce((acc: any, key: string) => {
      const humanReadableKey = camelCaseToHumanReadable(key);
      acc[humanReadableKey] = formatJson(data[key]);
      return acc;
    }, {});
  }
  return data;
};

export const getDaysInCurrentMonth = (): number => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return new Date(year, month, 0).getDate();
};
